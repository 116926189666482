import { useQuery } from "@tanstack/react-query";
import { ORDERS_QUERY_KEY } from "../../features/Stores/Orders/constants";

import { fetchTodaysOrders } from "../../services/orders";
import useOrgId from "../organizations/useOrgId";

export default function useTodaysOrders(
  storeId: string | null,
  filter: string | null
) {
  const orgId = useOrgId();

  return useQuery(
    [ORDERS_QUERY_KEY, orgId, storeId, filter],
    () => fetchTodaysOrders(storeId, filter),
    {
      enabled: storeId !== null && filter !== null,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
