import Spinner from "../Spinner";

export default function RequestStatusLoading({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div
      tabIndex={-1}
      aria-hidden={false}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-full bg-black bg-opacity-40"
    >
      <div className="bg-white h-14 w-56 flex justify-center rounded">
        <Spinner>{children}</Spinner>
      </div>
    </div>
  );
}
