import { InfoIcon } from "../../../../../components/Icons";

export default function MenuManagementErrorHints() {
  return (
    <div className="flex flex-col p-4 mb-4 rounded-lg bg-blue-50 text-blue-500 text-sm">
      <div className="flex items-center mb-4">
        <InfoIcon className="w-6 h-6 mr-2" />
        <span className="font-semibold">
          Ensure that you have properly setup the menu in Centegra
        </span>
      </div>
      <div className="pl-8">
        <ul className="list-disc list-inside">
          <li>The menu must have Products added to it.</li>
          <li>Prices for all Products must be set.</li>
        </ul>
      </div>
    </div>
  );
}
