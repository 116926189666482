interface RestaurantContentProps {
  children?: React.ReactNode;
  className?: string;
}
export default function RestaurantContent({
  children,
  className,
}: RestaurantContentProps) {
  return <div className={`flex-auto ${className}`}>{children}</div>;
}
