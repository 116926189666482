import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import ActionMenu from "../../../../../components/ActionMenu";
import ActionMenuItem from "../../../../../components/ActionMenu/ActionMenuItem";
import { EditIcon, TrashcanIcon } from "../../../../../components/Icons";
import Table from "../../../../../components/Table";
import { OrganizationRegionSetupInterface } from "../../../types";

interface RegionSetupTableProps {
  data: OrganizationRegionSetupInterface[];
  onEditRegion: (region: OrganizationRegionSetupInterface) => void;
  onDeleteRegion: (region: OrganizationRegionSetupInterface) => void;
}

export default function RegionSetupTable({
  data,
  onEditRegion,
  onDeleteRegion,
}: RegionSetupTableProps) {
  const columnHelper = createColumnHelper<OrganizationRegionSetupInterface>();

  const membersColumns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Region",
        cell: (info) => (
          <div className="w-28 truncate">{info.row.original.name}</div>
        ),
      }),
      columnHelper.accessor("sites", {
        header: "Site",
        cell: (info) => (
          <div className="line-clamp-1">
            {info.row.original.sites.map((site) => site.name).join(", ")}
          </div>
        ),
      }),
      columnHelper.display({
        id: "actions",
        cell: (props) => (
          <div className="flex justify-end">
            <div className="w-6">
              <ActionMenu position="left">
                <ActionMenuItem
                  onClick={() => onEditRegion(props.row.original)}
                >
                  <EditIcon className="w-6 h-6 text-gray-500 mr-2.5" />
                  Edit
                </ActionMenuItem>
                <ActionMenuItem
                  onClick={() => onDeleteRegion(props.row.original)}
                  className="text-red-500"
                >
                  <TrashcanIcon className="w-6 h-6 mr-2.5" />
                  Delete
                </ActionMenuItem>
              </ActionMenu>
            </div>
          </div>
        ),
      }),
    ],
    [columnHelper, onEditRegion, onDeleteRegion]
  );

  return (
    <Table<OrganizationRegionSetupInterface>
      data={data}
      columns={membersColumns}
    />
  );
}
