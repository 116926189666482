import tinycolor2, { ColorFormats } from "tinycolor2";

function multiply(rgb1: ColorFormats.RGBA, rgb2: ColorFormats.RGBA) {
  rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
  rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
  rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
  return tinycolor2("rgb " + rgb1.r + " " + rgb1.g + " " + rgb1.b);
}

export default function generateSiteColors(baseColor: string) {
  const baseLight = tinycolor2("#ffffff");
  const baseDark = multiply(
    tinycolor2(baseColor).toRgb(),
    tinycolor2(baseColor).toRgb()
  );

  const color50 = tinycolor2.mix(baseLight, baseColor, 12);
  const color100 = tinycolor2.mix(baseLight, baseColor, 30);
  const color200 = tinycolor2.mix(baseLight, baseColor, 50);
  const color300 = tinycolor2.mix(baseLight, baseColor, 70);
  const color400 = tinycolor2.mix(baseLight, baseColor, 85);
  const color500 = tinycolor2.mix(baseLight, baseColor, 100);
  const color600 = tinycolor2.mix(baseDark, baseColor, 87);
  const color700 = tinycolor2.mix(baseDark, baseColor, 70);
  const color800 = tinycolor2.mix(baseDark, baseColor, 54);
  const color900 = tinycolor2.mix(baseDark, baseColor, 25);

  const lightContrastFontColor = "#1A1A1A";
  const darkContrastFontColor = "#FFFFFF";

  return {
    pallete: {
      "50": color50.toHexString(),
      "100": color100.toHexString(),
      "200": color200.toHexString(),
      "300": color300.toHexString(),
      "400": color400.toHexString(),
      "500": color500.toHexString(),
      "600": color600.toHexString(),
      "700": color700.toHexString(),
      "800": color800.toHexString(),
      "900": color900.toHexString(),
    },
    fontPallete: {
      "50": color50.isLight() ? lightContrastFontColor : darkContrastFontColor,
      "100": color100.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,
      "200": color200.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,

      "300": color300.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,
      "400": color400.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,
      "500": color500.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,
      "600": color600.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,
      "700": color700.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,
      "800": color800.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,
      "900": color900.isLight()
        ? lightContrastFontColor
        : darkContrastFontColor,
    },
  };
}
