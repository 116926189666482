import { useQuery } from "@tanstack/react-query";
import { USERS_SITES_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchUserSites } from "../../services/sites";
import useOrgId from "../organizations/useOrgId";

export default function useUserSites() {
  const orgId = useOrgId();
  return useQuery([USERS_SITES_QUERY_KEY, orgId], () => fetchUserSites(), {
    enabled: orgId !== undefined && orgId !== "",
    retry: false,
    staleTime: 1 * 60 * 1000,
  });
}
