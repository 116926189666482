import { AnchorHTMLAttributes } from "react";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";

export interface SidebarItemProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  title: string;
  to: string;
  end?: boolean;
  preserveQuery?: boolean;
  icon?: React.ReactNode;
  onCloseSidebar: () => void;
}

const normalClassName =
  "flex py-2.5 px-5 leading-6 text-sparkd-black items-center";
const activeClassName = `${normalClassName} bg-gray-100 rounded-lg active-sidebar-item`;

export default function SidebarItem({
  title,
  to,
  end,
  className,
  preserveQuery,
  icon,
  onCloseSidebar,
}: SidebarItemProps) {
  const { search } = useLocation();

  const match = useMatch(`/${ROUTES.TOP_LEVEL_ORG}/*`);

  return (
    <li>
      <NavLink
        to={
          preserveQuery
            ? match?.pathnameBase + "/" + to + search
            : match?.pathnameBase + "/" + to
        }
        className={({ isActive }) =>
          isActive
            ? `${activeClassName} ${className}`
            : `${normalClassName} ${className}`
        }
        end={end}
        onClick={() => onCloseSidebar()}
      >
        <div className="flex">
          {icon ? <div className="w-6 h-6 mr-2.5">{icon}</div> : null}
          {title}
        </div>
      </NavLink>
    </li>
  );
}
