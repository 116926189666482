import { MenuInterface } from "../types";

export default function countMenuErrors(menu: MenuInterface): number {
  let count = 0;

  if (!menu.items || menu.items.length < 1) {
    return count;
  }

  menu.items.forEach((item) => {
    count += item.errors.length;

    if (!item.modifierGroup || item.modifierGroup.length < 1) {
      return;
    }

    item.modifierGroup.forEach((group) => {
      count += group.errors.length;

      if (!group.modifiers || group.modifiers.length < 1) {
        return;
      }

      group.modifiers.forEach((modifier) => {
        count += modifier.errors.length;
      });
    });
  });

  return count;
}
