import { useMemo } from "react";
import { Field } from "react-final-form";
import ActionMenu from "../../../../components/ActionMenu";
import {
  AddCircleOutlinedIcon,
  CheckCircleIcon,
  ChevronDownIcon,
} from "../../../../components/Icons";
import Typography from "../../../../components/Typography";
import { RoleGroupInterface } from "../../types";

interface RoleGroupProps {
  roleGroup: RoleGroupInterface;
}

export default function RoleGroup({ roleGroup }: RoleGroupProps) {
  const roleNameLookup: Record<string, string> = useMemo(
    () =>
      roleGroup.roles
        ? roleGroup.roles.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item.displayName,
          };
        }, {})
        : {},
    [roleGroup]
  );

  return (
    <div className="p-4 bg-gray-50 rounded-lg">
      <Typography as="body-2" className="font-semibold mb-4">
        {roleGroup.name}
      </Typography>

      <ActionMenu
        triggerElement={
          <button
            type="button"
            aria-label="add role"
            className="w-full h-6 flex items-center"
          >
            <Field<string>
              name={`roles.${roleGroup.name}`}
              subscription={{ value: true }}
              render={({ input }) => (
                <div className="flex w-full justify-start items-center text-black text-base">
                  {input.value && roleNameLookup[input.value] ? (
                    <CheckCircleIcon className="w-5 h-5 text-green-500 mr-2" />
                  ) : (
                    <AddCircleOutlinedIcon className="w-5 h-5 text-black mr-2" />
                  )}
                  {input.value && roleNameLookup[input.value] ? (
                    <div className="w-full text-xs font-normal flex justify-between items-center">
                      <div className="flex items-center">
                        <strong className="mr-1">Role:</strong>
                        {roleNameLookup[input.value]}
                        <ChevronDownIcon className="w-4 h-4 text-black" />
                      </div>
                      <span
                        className="underline text-xs font-bold"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          input.onChange(undefined);
                        }}
                      >
                        Remove
                      </span>
                    </div>
                  ) : (
                    <div className="flex w-full justify-start items-center text-black text-base h-6">
                      <div className="font-bold underline text-xs">
                        Add role
                      </div>
                      <ChevronDownIcon className="w-4 h-4 text-black" />
                    </div>
                  )}
                </div>
              )}
            />
          </button>
        }
        position="right"
      >
        {roleGroup.roles.map((role, index) => (
          <li
            key={`roles.${roleGroup.name}.${index}`}
            className="w-full block group"
          >
            <Field<string>
              type="radio"
              name={`roles.${roleGroup.name}`}
              value={role.id}
              render={({ input }) => (
                <>
                  <input
                    {...input}
                    id={`${input.name}.${roleGroup.name}.${index}`}
                    className="hidden peer"
                  />
                  <label
                    htmlFor={`${input.name}.${roleGroup.name}.${index}`}
                    className={`flex items-center w-full text-base font-normal text-left px-4 py-3 hover:bg-gray-100 group-first:rounded-t-lg group-last:rounded-b-lg peer-checked:bg-gray-100`}
                    onClick={() => {
                      input.onChange(input.value);
                      input.onBlur();
                    }}
                  >
                    <div className="flex flex-col">
                      <Typography as="body-2" className="font-semibold mb-1">
                        {role.displayName}
                      </Typography>
                      <Typography as="caption">{role.info}</Typography>
                    </div>
                  </label>
                </>
              )}
            />
          </li>
        ))}
      </ActionMenu>
    </div>
  );
}
