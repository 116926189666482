const currencyMap: Record<string, string> = {
  USD: "$",
  GBP: "£",
};

export default function currencySymbol(currency: string) {
  return currencyMap[currency.toUpperCase()]
    ? currencyMap[currency.toUpperCase()]
    : currency;
}
