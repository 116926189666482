import { useQueryClient } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import FeatureContentWrapper from "../../../components/FeatureContentWrapper";
import FeatureHeader from "../../../components/FeatureHeader";
import FeatureHeaderSiteDropdown from "../../../components/FeatureHeader/FeatureHeaderSiteDropdown";
import FeatureTitle from "../../../components/FeatureTitle";
import Typography from "../../../components/Typography";
import useCreatePartnerRoundUpsFeature from "../../../hooks/features/useCreatePartnerRoundUpsFeature";
import usePartnerFeatures from "../../../hooks/features/usePartnerFeatures";
import useUpdatePartnerRoundUpsFeature from "../../../hooks/features/useUpdatePartnerRoundUpsFeature";
import useOrgId from "../../../hooks/organizations/useOrgId";
import { PARTNER_FEATURES_QUERY_KEY } from "../FeatureManagement/constants";
import { PartnerFeatureRoundUpInterface } from "../FeatureManagement/types";
import RoundUpsContent from "./components/RoundUpsContent";
import RoundUpsStart from "./components/RoundUpsStart";
import RoundUpsForm from "./forms/RoundUpsForm";

export default function RoundUps() {
  const queryClient = useQueryClient();
  const orgId = useOrgId();
  const [queryParams] = useSearchParams();

  const site = queryParams.get("site") || "";

  const { data: partnerFeatures } = usePartnerFeatures(site);

  const createPartnerRoundUp = useCreatePartnerRoundUpsFeature();
  const updatePartnerRoundUp = useUpdatePartnerRoundUpsFeature();

  const onSubmit = async (values: {
    roundUp: PartnerFeatureRoundUpInterface;
    isActive: boolean;
  }) => {
    // check if previosly round up has been setup in order to decide whether to create or update
    if (!partnerFeatures?.roundUp && !partnerFeatures?.hasRoundUpBeenSetup) {
      return createPartnerRoundUp
        .mutateAsync({
          partnerId: site,
          isActive: values.isActive,
          roundUp: values.roundUp,
        })
        .then(() => {
          queryClient.invalidateQueries([
            PARTNER_FEATURES_QUERY_KEY,
            orgId,
            site,
          ]);
          toast.success("Round Up successfully created.");
        })
        .catch(() => {
          toast.error("Failed to create Round Up. Please try again.");
        });
    }

    return updatePartnerRoundUp
      .mutateAsync({
        partnerId: site,
        isActive: values.isActive,
        roundUp: values.roundUp,
      })
      .then(() => {
        queryClient.invalidateQueries([
          PARTNER_FEATURES_QUERY_KEY,
          orgId,
          site,
        ]);
        toast.success("Round Up successfully updated.");
      })
      .catch(() => {
        toast.error("Failed to update Round Up. Please try again.");
      });
  };

  if (site === "") {
    return (
      <FeatureContentWrapper>
        <Helmet>
          <title>Connect - Round ups</title>
        </Helmet>
        <RoundUpsStart />
      </FeatureContentWrapper>
    );
  }

  return (
    <FeatureContentWrapper>
      <Helmet>
        <title>Connect - Round ups</title>
      </Helmet>

      {partnerFeatures && (
        <RoundUpsContent>
          <div className="mb-6">
            <FeatureTitle className="mb-3">Round ups</FeatureTitle>
            <Typography as="body-2">All fields are required.</Typography>
          </div>

          <RoundUpsForm
            key={site}
            roundUp={partnerFeatures.roundUp}
            isActive={partnerFeatures.hasRoundUp}
            onSubmit={onSubmit}
          />
        </RoundUpsContent>
      )}
    </FeatureContentWrapper>
  );
}
