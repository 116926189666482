import { useQuery } from "@tanstack/react-query";
import { STORE_MENU_QUERY_KEY } from "../../features/Stores/MenuManagement/constants";
import { fetchMenu } from "../../services/menus";
import useOrgId from "../organizations/useOrgId";

export default function useMenu(storeId: string, menuId: number | undefined) {
  const orgId = useOrgId();
  return useQuery(
    [STORE_MENU_QUERY_KEY, orgId, storeId, menuId],
    () => fetchMenu(storeId, menuId),
    {
      enabled: storeId !== "" && menuId !== undefined,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
