const permissionPrefix = "connect";

export const PERMISSIONS_USER = {
  VIEW: `${permissionPrefix}:user:view`,
  ADD: `${permissionPrefix}:user:add`,
  UPDATE: `${permissionPrefix}:user:update`,
  DELETE: `${permissionPrefix}:user:delete`,
};

export const PERMISSIONS_ONBOARD_MANAGEMENT = {
  VIEW: `${permissionPrefix}:onboard:view`,

  SITE_ADD: `${permissionPrefix}:onboard:site:add`,
  SITE_UPDATE: `${permissionPrefix}:onboard:site:update`,
  SITE_DELETE: `${permissionPrefix}:onboard:site:delete`,

  STORE_ADD: `${permissionPrefix}:onboard:store:add`,
  STORE_UPDATE: `${permissionPrefix}:onboard:store:update`,
  STORE_DELETE: `${permissionPrefix}:onboard:store:delete`,
};

export const PERMISSIONS_FEATURE_MANAGEMENT = {
  ROUNDUP_VIEW: `${permissionPrefix}:feature:roundUpView`,
  ROUNDUP_ENABLE: `${permissionPrefix}:feature:roundUpEnable`,
  ROUNDUP_TOGGLE: `${permissionPrefix}:feature:roundUpToggle`,
  ROUNDUP_UPDATE: `${permissionPrefix}:feature:roundUpUpdate`,

  TIPPING_VIEW: `${permissionPrefix}:feature:tippingView`,
  TIPPING_ENABLE: `${permissionPrefix}:feature:tippingEnable`,
  TIPPING_TOGGLE: `${permissionPrefix}:feature:tippingToggle`,

  DISCOUNT_VIEW: `${permissionPrefix}:feature:discountView`,
  DISCOUNT_TOGGLE: `${permissionPrefix}:feature:discountToggle`,
  DISCOUNT_ADD: `${permissionPrefix}:feature:discountAdd`,
  DISCOUNT_DELETE: `${permissionPrefix}:feature:discountDelete`,

  SPECIAL_INSTRUCTION: `${permissionPrefix}:feature:specialInstruction`,
};

export const PERMISSIONS_OPS_MANAGEMENT = {
  KIOSK_ENABLE: `${permissionPrefix}:ops:kioskEnable`,

  MOBILE_ENABLE: `${permissionPrefix}:ops:mobileEnable`,

  TABLE_MANAGEMENT: `${permissionPrefix}:ops:tableMgmt`,

  VIEW_HOURS: `${permissionPrefix}:ops:viewHours`,
  STORE_HOURS: `${permissionPrefix}:ops:storeHours`,

  ADVANCED_ORDERING: `${permissionPrefix}:ops:advOrdering`,

  CAPACITY: `${permissionPrefix}:ops:capacity`,

  REFUND: `${permissionPrefix}:ops:refund`,
};

export const PERMISSIONS_MENU_MANAGEMENT = {
  VIEW: `${permissionPrefix}:menu:view`,

  PRODUCT_SUSPENSION: `${permissionPrefix}:menu:prodSuspend`,

  MENU_ASSIGN: `${permissionPrefix}:menu:assign`,
  MENU_PRODUCT: `${permissionPrefix}:menu:product`,
  MENU_IMAGE: `${permissionPrefix}:menu:image`,
  MENU_PRICE: `${permissionPrefix}:menu:price`,
  MENU_TAX: `${permissionPrefix}:menu:tax`,
  MENU_DESCRIPTION: `${permissionPrefix}:menu:description`,
};

export const ALL_PERMISSIONS = [
  Object.values(PERMISSIONS_USER),
  Object.values(PERMISSIONS_ONBOARD_MANAGEMENT),
  Object.values(PERMISSIONS_FEATURE_MANAGEMENT),
  Object.values(PERMISSIONS_OPS_MANAGEMENT),
  Object.values(PERMISSIONS_MENU_MANAGEMENT),
].flat();
