import Spinner from "../../../../../components/Spinner";

export default function RefundProcessingModal() {
  return (
    <div
      id="refund-processing-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="refund-processing-modal"
      aria-hidden={false}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-full bg-black bg-opacity-40"
    >
      <div className="relative w-54 ">
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow">
          {/*  <!-- Modal body --> */}
          <div className="py-4 px-6">
            <Spinner>
              <span className="text-sm">Processing Refund</span>
            </Spinner>
          </div>
        </div>
      </div>
    </div>
  );
}
