import { Outlet, useSearchParams } from "react-router-dom";
import FeatureContentWrapper from "../../components/FeatureContentWrapper";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureHeaderSiteDropdown from "../../components/FeatureHeader/FeatureHeaderSiteDropdown";

export default function Configurations() {
  const [queryParams] = useSearchParams();

  const contextValues = {
    site: queryParams.get("site") || "",
  };

  return (
    <FeatureContentWrapper>
      <div className="border-b border-gray-300 mb-7">
        <FeatureHeader>
          <FeatureHeaderSiteDropdown />
        </FeatureHeader>
      </div>
      <Outlet context={contextValues} />
    </FeatureContentWrapper>
  );
}
