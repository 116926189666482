import { useState } from "react";
import BadgeDisabled from "../../../../components/Badges/BadgeDisabled";
import BadgeSuccess from "../../../../components/Badges/BadgeSuccess";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import FeatureWidget from "../../../../components/FeatureWidget";
import useStore from "../../../../hooks/stores/useStore";
import AdvancedOrderingSidePanel from "../AdvancedOrderingSidePanel";

interface AdvancedOrderingWidgetProps {
  storeId: string;
  orgId: string | undefined;
}
export default function AdvancedOrderingWidget({
  storeId,
  orgId,
}: AdvancedOrderingWidgetProps) {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const { data: store } = useStore(storeId);
  return (
    <div>
      {store ? (
        <div>
          <FeatureWidget
            title={
              <div className="featureStatus flex justify-between items-start">
                Advanced ordering
                {store.hasAdvancedOrdering ? (
                  <BadgeSuccess>Enabled</BadgeSuccess>
                ) : (
                  <BadgeDisabled>Disabled</BadgeDisabled>
                )}
              </div>
            }
            content={
              <div>
                Advanced ordering allows guests to place their order in advance.
                Currently, only same day advanced ordering is available.
              </div>
            }
            action={
              <PrimaryButton className="w-full" onClick={onOpen}>
                Manage advanced ordering
              </PrimaryButton>
            }
          />

          <AdvancedOrderingSidePanel
            open={open}
            store={store}
            orgId={orgId}
            onClose={onClose}
          />
        </div>
      ) : null}
    </div>
  );
}
