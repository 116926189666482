import React, { ForwardedRef, KeyboardEvent } from "react";
import Label from "../Label";
import { FieldRenderProps } from "react-final-form";
import { CloseCircleIcon } from "../../Icons";

interface InputFieldProps {
  label?: React.ReactNode;
  icon?: React.ReactNode;
  allowClear?: boolean;
  onClear?: () => void;
  helperText?: string;
}

const InputField = React.forwardRef(function InputField(
  {
    input,
    meta,
    label,
    icon,
    className,
    allowClear,
    onClear,
    helperText,
    ...rest
  }: FieldRenderProps<string, any> & InputFieldProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  return (
    <div className="relative">
      {label && <Label disabled={rest.disabled}>{label}</Label>}
      <div className="relative">
        {icon && (
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            {icon}
          </div>
        )}
        <input
          {...input}
          {...rest}
          ref={ref}
          aria-label={input.name}
          className={`input-field mb-0.5 ${icon ? "pl-11" : ""}  ${
            hasError ? "text-red-500 border-red-500 ring-1 ring-error-500" : ""
          } ${className}`}
        />

        {allowClear && !rest.disabled && (
          <button
            type="button"
            className="flex absolute z-10 inset-y-0 right-0 items-center pr-4 focus:outline-cinchio-blue-500"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              typeof onClear === "function" && onClear();
            }}
            onKeyDown={(e: KeyboardEvent) => {
              if (e.code === "Enter" || e.code === "Space") {
                if (typeof onClear === "function") {
                  e.preventDefault();
                  e.stopPropagation();
                  onClear();
                }
              }
            }}
            aria-label="clear"
          >
            <CloseCircleIcon className="w-4 h-4 text-cinchio-blue-500 hover:text-gray-900" />
          </button>
        )}
      </div>
      {hasError ? (
        <p className="text-xs text-red-500 h-6 absolute mt-0">
          {meta.error || meta.submitError}
        </p>
      ) : null}
      {!hasError && helperText ? (
        <p className="text-gray-500 text-sm mb-5">{helperText}</p>
      ) : null}
    </div>
  );
});

export default InputField;
