import Typography from "../Typography";

interface FeatureWidgetProps {
  title: React.ReactNode;
  content: React.ReactNode;
  action: React.ReactNode;
}

const FeatureWidget = ({ title, content, action }: FeatureWidgetProps) => {
  return (
    <div>
      <div className="flex flex-shrink flex-col justify-between p-4 rounded-md shadow-md shadow-stone-400">
        <Typography as="subtitle-1" className="mb-3 font-bold">
          {title}
        </Typography>
        <div className="flex text-sm mb-4 min-h-[12.188rem]">{content}</div>
        <div className="">{action}</div>
      </div>
    </div>
  );
};

export default FeatureWidget;
