import { useMemo } from "react";
import ExternalLink from "../../../../../components/ExternalLink";
import LaunchIcon from "../../../../../components/Icons/LauchIcon";
import { useMenuCurrency } from "../../hooks/useMenuCurrency";
import { BothPriceType, MenuItemInterface } from "../../types";
import MenuPreviewItemCustomizations from "../MenuPreviewItemCustomizations";
import MenuPreviewItemImage from "../MenuPreviewItemImage";
import MenuPreviewItemProperty from "../MenuPreviewItemProperty";

import { DineInPriceType, TakeOutPriceType } from "../../types";
import { useMenuOrderType } from "../../hooks/useMenuOrderType";

export default function MenuPreviewItem({ item }: { item: MenuItemInterface }) {
  const errors: Record<string, string[]> = useMemo(
    () =>
      item.errors.reduce((acc: Record<string, string[]>, item) => {
        if (Array.isArray(acc[item.property.toLowerCase()])) {
          return {
            ...acc,
            [item.property.toLowerCase()]: acc[
              item.property.toLowerCase()
            ].concat(item.message),
          };
        }
        return { ...acc, [item.property.toLowerCase()]: [item.message] };
      }, {}),
    [item]
  );

  const { currency } = useMenuCurrency();

  const { orderType } = useMenuOrderType();

  const dineInPrice = item.prices.find(
    (price) => price.type.toLowerCase() === DineInPriceType.toLowerCase()
  );
  const dineInTaxRate = dineInPrice
    ? dineInPrice.taxRate || dineInPrice.taxRate === 0
      ? dineInPrice.taxRate * 100
      : null
    : null;

  const takeOutPrice = item.prices.find(
    (price) => price.type.toLowerCase() === TakeOutPriceType.toLowerCase()
  );
  const takeOutTaxRate = takeOutPrice
    ? takeOutPrice.taxRate || takeOutPrice.taxRate === 0
      ? takeOutPrice.taxRate * 100
      : null
    : null;

  const showTakeOut =
    orderType.toLowerCase() === TakeOutPriceType.toLowerCase() ||
    orderType.toLowerCase() === BothPriceType.toLowerCase();

  const showDineIn =
    orderType.toLowerCase() === DineInPriceType.toLowerCase() ||
    orderType.toLowerCase() === BothPriceType.toLowerCase();

  return (
    <div className="pb-4 mb-4 border-b border-gray-300 last:border-b-0">
      <div className="flex justify-between">
        <div>
          <MenuPreviewItemProperty
            title="Product ID:"
            errors={errors["productid"]}
          >
            <ExternalLink url={item.deeplink}>
              <span className="mr-1">{item.productId}</span>
              <LaunchIcon className="w-[1.125rem] h-[1.125rem]" />
            </ExternalLink>
          </MenuPreviewItemProperty>
          <MenuPreviewItemProperty title="Name:" errors={errors["name"]}>
            {item.name}
          </MenuPreviewItemProperty>
          <MenuPreviewItemProperty
            title="Description:"
            errors={errors["description"]}
          >
            {item.description}
          </MenuPreviewItemProperty>

          {showTakeOut && (
            <div className="mt-3.5">
              <MenuPreviewItemProperty
                title="Takeaway Price:"
                errors={errors["pricetakeout"]}
              >
                {takeOutPrice &&
                (takeOutPrice.price || takeOutPrice.price === 0)
                  ? `${currency}${takeOutPrice.price}`
                  : null}
              </MenuPreviewItemProperty>
            </div>
          )}

          {showTakeOut && (
            <MenuPreviewItemProperty
              title="Takeaway Tax:"
              errors={errors["taxratetakeout"]}
            >
              {takeOutTaxRate || takeOutTaxRate === 0
                ? `${takeOutTaxRate.toFixed()}%`
                : null}
            </MenuPreviewItemProperty>
          )}

          {showTakeOut && (
            <MenuPreviewItemProperty
              title="Takeaway Price Excludes Tax:"
              errors={errors["pricingnettakeout"]}
            >
              {takeOutPrice && takeOutPrice.pricingNet ? "Yes" : "No"}
            </MenuPreviewItemProperty>
          )}

          {showDineIn && (
            <div className="mt-3.5">
              <MenuPreviewItemProperty
                title="Dine In Price:"
                errors={errors["pricedinein"]}
              >
                {dineInPrice && (dineInPrice.price || dineInPrice.price === 0)
                  ? `${currency}${dineInPrice.price}`
                  : null}
              </MenuPreviewItemProperty>
            </div>
          )}

          {showDineIn && (
            <MenuPreviewItemProperty
              title="Dine In Tax:"
              errors={errors["taxratedinein"]}
            >
              {dineInTaxRate || dineInTaxRate === 0
                ? `${dineInTaxRate.toFixed()}%`
                : null}
            </MenuPreviewItemProperty>
          )}

          {showDineIn && (
            <MenuPreviewItemProperty
              title="Dine In Price Excludes Tax:"
              errors={errors["pricingnetdinein"]}
            >
              {dineInPrice && dineInPrice.pricingNet ? "Yes" : "No"}
            </MenuPreviewItemProperty>
          )}
        </div>
        <MenuPreviewItemImage url={item.imageUrl} errors={errors["imageurl"]} />
      </div>
      {item.modifierGroup.length ? (
        <div className="mt-3">
          <MenuPreviewItemCustomizations modifierGroup={item.modifierGroup} />
        </div>
      ) : null}
    </div>
  );
}
