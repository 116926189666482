import { sprintf } from "sprintf-js";
import {
  PARTNER_FEATURE_CREATE_ROUNDUPS_API_ENDPOINT,
  PARTNER_FEATURE_MANAGEMENT_API_ENDPOINT,
  PARTNER_FEATURE_UPDATE_DISCOUNTS_API_ENDPOINT,
  PARTNER_FEATURE_UPDATE_ROUNDUPS_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  PartnerFeatureRoundUpInterface,
  PartnerFeaturesInterface,
} from "../features/Configurations/FeatureManagement/types";
import http from "./http";

export function fetchPartnerFeatures(
  partnerId: string
): Promise<PartnerFeaturesInterface> {
  return http
    .get(
      sprintf(PARTNER_FEATURE_MANAGEMENT_API_ENDPOINT, {
        partnerId,
      })
    )
    .then((response) => response.data);
}

export function updatePartnerPromotionsFeature(
  partnerId: string,
  isActive: boolean
): Promise<void> {
  return http
    .put(
      sprintf(PARTNER_FEATURE_UPDATE_DISCOUNTS_API_ENDPOINT, {
        partnerId,
      }),
      { isActive }
    )
    .then((response) => response.data);
}

export function createPartnerRoundUpsFeature(
  partnerId: string,
  isActive: boolean,
  roundUp: PartnerFeatureRoundUpInterface
): Promise<PartnerFeatureRoundUpInterface> {
  return http.post(
    sprintf(PARTNER_FEATURE_CREATE_ROUNDUPS_API_ENDPOINT, { partnerId }),
    {
      isActive,
      roundUp,
    }
  );
}

export function updatePartnerRoundUpsFeature(
  partnerId: string,
  isActive: boolean,
  roundUp: PartnerFeatureRoundUpInterface | null
): Promise<PartnerFeatureRoundUpInterface> {
  return http
    .patch(
      sprintf(PARTNER_FEATURE_UPDATE_ROUNDUPS_API_ENDPOINT, { partnerId }),
      {
        isActive,
        roundUp,
      }
    )
    .then((response) => response.data);
}
