import ActionModal from "../../../../../components/ActionModal";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import { AxiosError } from "axios";
import { WarningOutlinedIcon } from "../../../../../components/Icons";
import Typography from "../../../../../components/Typography";

interface RefundErrorModalProps {
  error: AxiosError;
  onClose: () => void;
}

export default function RefundErrorModal({
  error,
  onClose,
}: RefundErrorModalProps) {
  const title =
    error.response?.status === 500
      ? "Oops, something went wrong."
      : "Refund could not be processed.";
  const description =
    error.response?.status === 500
      ? "We encountered a technical problem. Please try again."
      : "We experienced a technical problem while trying to process the refund. Please wait a moment and try again.";

  const _error = error ? (error as AxiosError<{ message: string }>) : null;
  return (
    <ActionModal
      icon={<WarningOutlinedIcon className="w-10 h-8 mb-2" />}
      title={title}
      content={
        <div className="flex flex-col">
          <Typography as="body-2">{description}</Typography>
          {_error && _error.response?.data.message && (
            <Typography as="body-2" className="mt-2">
              {_error.response.data.message}
            </Typography>
          )}
        </div>
      }
      footer={
        <>
          <PrimaryButton className="w-full" onClick={onClose}>
            OK
          </PrimaryButton>
        </>
      }
      onClose={onClose}
    />
  );
}
