import { useQuery } from "@tanstack/react-query";
import { ORGANIZATION_STORE_KIOSK_SETUP_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchStoreKioskSetup } from "../../services/stores";

export default function useStoreKioskSetup(storeId: string | null) {
  return useQuery(
    [ORGANIZATION_STORE_KIOSK_SETUP_QUERY_KEY, storeId],
    () => fetchStoreKioskSetup(storeId),
    {
      enabled: storeId !== "" && storeId !== null,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}
