import { useState } from "react";
import ActionModal from "../ActionModal";
import PrimaryButton from "../Buttons/PrimaryButton";
import WarningOutlinedIcon from "../Icons/WarningOutlinedIcon";

export default function RequestStatusError({
  title,
  children,
  onOkCallback,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  onOkCallback?: () => void;
}) {
  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
    typeof onOkCallback === "function" && onOkCallback();
  };

  if (!open) {
    return null;
  }

  return (
    <ActionModal
      icon={<WarningOutlinedIcon className="w-10 h-8 mb-2" />}
      title={
        <div className="text-center text-base mb-2">
          {title ? title : "Something went wrong."}
        </div>
      }
      content={
        <div className="flex flex-col items-center">
          <div className="mb-6 text-xs text-center">{children}</div>
          <PrimaryButton className="w-full" onClick={onClose}>
            OK
          </PrimaryButton>
        </div>
      }
      onClose={onClose}
    />
  );
}
