interface BoxProps {
  children: React.ReactNode;
}
export default function Box({ children }: BoxProps) {
  return (
    <div className="border rounded-md p-4 drop-shadow-md bg-white mb-3">
      {children}
    </div>
  );
}
