import { sprintf } from "sprintf-js";
import {
  ORGANIZATION_STORE_API_ENDPOINT,
  ORGANIZATION_STORE_KIOSK_API_ENDPOINT,
  ORGANIZATION_STORE_MOBILE_API_ENDPOINT,
  ORGANIZATION_STORE_UPDATE_API_ENDPOINT,
  STORE_API_ENDPOINT,
  STORE_UPDATE_KIOSK_ORDERING_API_ENDPOINT,
  STORE_UPDATE_MOBILE_ORDERING_API_ENDPOINT,
  STORE_UPDATE_ONSITE_ORDERING_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  OrganizationStoreInterface,
  OrganizationStoreKioskSetupInterface,
  OrganizationStoreMobileSetupInterface,
} from "../features/Organizations/types";
import { StoreInterface } from "../features/Stores/types";
import http from "./http";

export function fetchSiteStoresForOnboarding(
  siteId: string
): Promise<OrganizationStoreInterface[]> {
  return http
    .get(sprintf(ORGANIZATION_STORE_API_ENDPOINT, { siteId }))
    .then((response) => response.data);
}

export function updateStore(
  storeId: string,
  description: string,
  descriptionSpanish: string,

  colorConfig?: {
    palette: Record<string, string>;
    fontPalette: Record<string, string>;
  },
  colorChoice?: string
): Promise<OrganizationStoreInterface> {
  return http
    .patch(sprintf(ORGANIZATION_STORE_UPDATE_API_ENDPOINT, { storeId }), {
      description,
      descriptionSpanish,
      colorConfig,
      colorChoice,
    })
    .then((response) => response.data);
}

export function createStore(
  siteId: string,
  centegraLocationId: number,
  description: string,
  descriptionSpanish: string,

  colorConfig?: {
    palette: Record<string, string>;
    fontPalette: Record<string, string>;
  },
  colorChoice?: string
): Promise<OrganizationStoreInterface> {
  return http
    .post(sprintf(ORGANIZATION_STORE_API_ENDPOINT, { siteId }), {
      centegraLocationId,
      description,
      descriptionSpanish,
      colorConfig,
      colorChoice,
    })
    .then((response) => response.data);
}

export function updateOnsiteOrdering(storeId: string, tableManagement: string) {
  return http
    .put(
      sprintf(STORE_UPDATE_ONSITE_ORDERING_API_ENDPOINT, {
        restaurantId: storeId,
      }),
      {
        tableManagement,
      }
    )
    .then((response) => response.data);
}

export function fetchStore(storeId: string): Promise<StoreInterface> {
  return http
    .get(sprintf(STORE_API_ENDPOINT, { storeId }))
    .then((response) => response.data);
}

export function updateStoreMobileOrderingActive(
  storeId: string,
  active: boolean
): Promise<void> {
  return http
    .put(
      sprintf(STORE_UPDATE_MOBILE_ORDERING_API_ENDPOINT, {
        restaurantId: storeId,
      }),
      { active }
    )
    .then((response) => response.data);
}

export function updateStoreKioskOrderingActive(
  storeId: string,
  active: boolean
): Promise<void> {
  return http
    .put(
      sprintf(STORE_UPDATE_KIOSK_ORDERING_API_ENDPOINT, {
        restaurantId: storeId,
      }),
      { active }
    )
    .then((response) => response.data);
}

export function fetchStoreMobileSetup(
  storeId: string | null
): Promise<OrganizationStoreMobileSetupInterface> {
  return http
    .get(sprintf(ORGANIZATION_STORE_MOBILE_API_ENDPOINT, { storeId }))
    .then((response) => response.data);
}

export function createStoreMobileSetup(
  storeId: string,
  primaryUrl: string
): Promise<void> {
  return http
    .post(sprintf(ORGANIZATION_STORE_MOBILE_API_ENDPOINT, { storeId }), {
      primaryUrl,
    })
    .then((response) => response.data);
}

export function updateStoreMobileSetup(
  storeId: string,
  primaryUrl: string
): Promise<void> {
  return http
    .patch(sprintf(ORGANIZATION_STORE_MOBILE_API_ENDPOINT, { storeId }), {
      primaryUrl,
    })
    .then((response) => response.data);
}

export function fetchStoreKioskSetup(
  storeId: string | null
): Promise<OrganizationStoreKioskSetupInterface> {
  return http
    .get(sprintf(ORGANIZATION_STORE_KIOSK_API_ENDPOINT, { storeId }))
    .then((response) => response.data);
}

export function createStoreKioskSetup(
  storeId: string,
  screens: {
    header: string;
    headerLogo: string | undefined;
    welcome: (string | undefined)[];
    welcomeLogo: string;
  }
): Promise<void> {
  return http
    .post(sprintf(ORGANIZATION_STORE_KIOSK_API_ENDPOINT, { storeId }), {
      screens,
    })
    .then((response) => response.data);
}

export function updateStoreKioskSetup(
  storeId: string,
  screens: {
    header: string;
    headerLogo: string | undefined;
    welcome: (string | undefined)[];
    welcomeLogo: string;
  }
): Promise<void> {
  return http
    .patch(sprintf(ORGANIZATION_STORE_KIOSK_API_ENDPOINT, { storeId }), {
      screens,
    })
    .then((response) => response.data);
}
