import { Field, Form } from "react-final-form";

import QRCode from "qrcode";
import QRCodeSVG from "qrcode-svg";
import Dropdown from "../../../../components/Dropdown/Dropdown";

export interface QrCodeDownloadMenuProps {
  href: string;
  title: string;
  filename: string;
}

const normalizeFilename = (filename: string) => {
  return filename
    .replace(/[^a-z0-9]/gi, "_")
    .replace(/_{2,}/g, "_")
    .toLowerCase();
};

type ImageType = "image/png" | "image/jpeg" | "image/webp" | "image/svg";

const downloadImage = (url: string, data: string) => {
  const downloadImage = document.createElement("a");
  document.body.appendChild(downloadImage);
  downloadImage.setAttribute("download", url);
  downloadImage.href = data;
  downloadImage.rel = "noreferrer noopener";
  downloadImage.click();
  downloadImage.remove();
};

const generateImage = (type: ImageType, url: string, filename: string) => {
  if (type === "image/svg") {
    let qrCode = null;
    qrCode = new QRCodeSVG({
      content: url,
      join: false,
      predefined: false,
    });

    const svg = qrCode.svg();
    qrCode = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);

    downloadImage(filename, qrCode);
    return;
  }

  QRCode.toDataURL(
    url,
    {
      scale: 24,
      type: type,
    },
    (_, image) => {
      downloadImage(filename, image);
    }
  );
};

export default function QrCodeDownloadMenu(props: QrCodeDownloadMenuProps) {
  return (
    <Form
      onSubmit={() => {}}
      render={() => (
        <Field
          name="type"
          render={({ input, meta }) => (
            <Dropdown
              input={{
                ...input,
                onChange: (e) => {
                  let ext = typeof e === "string" ? e.split(".") : "";
                  ext = ext[ext.length - 1];
                  const type = `image/${ext}` as ImageType;
                  generateImage(type, props.href, e);
                  return;
                },
              }}
              meta={meta}
              placeholder="Download"
              placeholderClassName="!text-black"
              options={[
                {
                  label: "PNG",
                  value: `${normalizeFilename(props.filename)}.png`,
                },
                {
                  label: "SVG",
                  value: `${normalizeFilename(props.filename)}.svg`,
                },
                {
                  label: "WEBP",
                  value: `${normalizeFilename(props.filename)}.webp`,
                },
              ]}
            />
          )}
        />
      )}
    />
  );
}
