import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import { APP_ENVIRONMENT } from "../../../../../config/appConfig";
import { API_BASE_URL } from "../../../../../config/servicesEndpoints";
import useOrgId from "../../../../../hooks/organizations/useOrgId";
import http from "../../../../../services/http";
import { USERS_SITES_QUERY_KEY } from "../../../constants";

export default function ResetOnboardSite() {
  const shouldRender =
    APP_ENVIRONMENT !== "production" ||
    window.location.host.includes("dev") ||
    window.location.host.includes("staging");

  const orgId = useOrgId();

  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(() =>
    http
      .delete(`${API_BASE_URL}/connect/onboard/site`)
      .then((response) => response.data)
  );

  const onResetSite = () => {
    return mutateAsync()
      .then(() => {
        toast.success("Onboard site reset success");

        queryClient.invalidateQueries([USERS_SITES_QUERY_KEY, orgId]);
      })
      .catch(() => {
        toast.error("Onboard site reset failed.");
      });
  };

  return shouldRender ? (
    <SecondaryButton
      disabled={isLoading}
      isLoading={isLoading}
      onClick={onResetSite}
      className="py-1.5"
    >
      Reset Site
    </SecondaryButton>
  ) : null;
}
