import Typography from "../../../../../components/Typography";
import checkIcon from "../../assets/checkIcon.svg";
import blockIcon from "../../assets/blockIcon.svg";

interface OrderChannelStatusProps {
  name: string;
  enabled: boolean;
  icon: string;
}
export default function OrderChannelStatus({
  name,
  enabled,
  icon,
}: OrderChannelStatusProps) {
  return (
    <div className="flex items-center border-b border-b-gray-300 last:border-b-0 p-4">
      <div className="w-10 mr-5 flex justify-center">
        <div className="relative">
          <img src={icon} alt={`${name} channel`} className="h-8" />
          {enabled ? (
            <img
              src={checkIcon}
              alt=""
              className="absolute top-[-5px] right-[-10px]"
            />
          ) : (
            <img
              src={blockIcon}
              alt=""
              className="absolute top-[-5px] right-[-10px]"
            />
          )}
        </div>
      </div>
      <Typography
        as="body-2"
        className="orderAvailabilityStatus font-semibold"
      >{`${name} ordering is ${enabled ? "on" : "off"}.`}</Typography>
    </div>
  );
}
