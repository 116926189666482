interface FeatureWidgetGridProps {
  children: React.ReactNode;
  className?: string;
}
export default function FeatureWidgetGrid({
  children,
  className,
}: FeatureWidgetGridProps) {
  return (
    <div
      className={`grid grid-cols-1 3xl:grid-cols-4 max-3xl:grid-cols-3 max-1.5xl:grid-cols-2 max-lg:grid-cols-1 gap-8 mb-6 ${className}`}
    >
      {children}
    </div>
  );
}
