import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import FeatureWidget from "../../../../../components/FeatureWidget";
import LaunchIcon from "../../../../../components/Icons/LauchIcon";
import useStore from "../../../../../hooks/stores/useStore";

interface MenuHoursWidgetProps {
  storeId: string;
}

export default function MenuHoursWidget({ storeId }: MenuHoursWidgetProps) {
  const { data: store } = useStore(storeId);

  return (
    <div>
      {store ? (
        <FeatureWidget
          title="Menu hours"
          content={
            <div className="flex flex-1 flex-col justify-center items-center text-center">
              Mobile ordering hours has been updated to <br />
              ‘Menu hours’, and is now being managed by Centegra.
            </div>
          }
          action={
            <PrimaryButton
              className="w-full"
              onClick={() => window.open(store.menuHours)}
            >
              <span className="flex">
                Manage menu hours <LaunchIcon className="w-5 h-5 ml-2 mt-0.5" />
              </span>
            </PrimaryButton>
          }
        />
      ) : null}
    </div>
  );
}
