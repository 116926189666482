import { useQuery } from "@tanstack/react-query";
import { PARTNER_FEATURES_QUERY_KEY } from "../../features/Configurations/FeatureManagement/constants";
import { fetchPartnerFeatures } from "../../services/features";
import useOrgId from "../organizations/useOrgId";

export default function usePartnerFeatures(partnerId: string) {
  const orgId = useOrgId();
  return useQuery(
    [PARTNER_FEATURES_QUERY_KEY, orgId, partnerId],
    () => fetchPartnerFeatures(partnerId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: partnerId !== "",
      keepPreviousData: true,
    }
  );
}
