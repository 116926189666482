import { format, isMatch, parse } from "date-fns";
import { ExclamationIcon, InfoIcon } from "../../../../../components/Icons";
import ToolTip from "../../../../../components/ToolTip/ToolTip";
import Typography from "../../../../../components/Typography";
import { MenuHours } from "../../types";

interface MenuHoursDisplayProps {
  menuHours: MenuHours[];
}

const blankDays = [
  {
    day: "Monday",
    hours: {
      startTime: "",
      endTime: "",
    },
  },
  {
    day: "Tuesday",
    hours: {
      startTime: "",
      endTime: "",
    },
  },
  {
    day: "Wednesday",
    hours: {
      startTime: "",
      endTime: "",
    },
  },
  {
    day: "Thursday",
    hours: {
      startTime: "",
      endTime: "",
    },
  },
  {
    day: "Friday",
    hours: {
      startTime: "",
      endTime: "",
    },
  },
  {
    day: "Saturday",
    hours: {
      startTime: "",
      endTime: "",
    },
  },
  {
    day: "Sunday",
    hours: {
      startTime: "",
      endTime: "",
    },
  },
];

export default function MenuHoursDisplay({ menuHours }: MenuHoursDisplayProps) {
  const filledHours = blankDays.map((blankDay) => {
    const hasDay = menuHours.find((day) => day.day === blankDay.day);
    if (hasDay) {
      return hasDay;
    }

    return blankDay;
  });

  const hasAtleastOneDaySet = filledHours.filter(
    (day) => day.hours.startTime !== "" || day.hours.endTime !== ""
  );

  if (hasAtleastOneDaySet.length === 0) {
    return (
      <div className="flex self-start relative bg-red-50 border border-red-500 text-red-500 items-center rounded px-3 py-1.5">
        <Typography as="body-2" className="mr-2 inline">
          Menu hours are required
        </Typography>
        <ExclamationIcon className="w-5 h-5 text-red-500" />
      </div>
    );
  }

  return (
    <div className="flex self-start relative bg-gray-50 border border-gray-300 items-center rounded px-3 py-1.5">
      <Typography as="body-2" className="mr-2 inline">
        Menu hours
      </Typography>
      <ToolTip
        trigger={<InfoIcon className="w-5 h-5" />}
        position="bottom"
        wrapperClassName="left-11"
        arrowClassName="before:left-[calc(50%-42px)] after:left-[calc(50%-42px)]"
      >
        <div className="pt-4">
          {filledHours.map((day) => (
            <Typography
              as="body-2"
              className="flex justify-between mb-1 last:mb-0 w-[14.5rem]"
              key={`menu-hours-${day.day}`}
            >
              <span>{day.day}</span>
              <DayHours start={day.hours.startTime} end={day.hours.endTime} />
            </Typography>
          ))}
        </div>
      </ToolTip>
    </div>
  );
}

function DayHours({ start, end }: { start: string; end: string }) {
  if (!isMatch(start, "HH:mm:ss") || !isMatch(end, "HH:mm:ss")) {
    return <span className="text-gray-500">Closed</span>;
  }

  const startTimeHours = parse(start, "HH:mm:ss", new Date());
  const startTime = format(startTimeHours, "hh:mm a");

  const endTimeHours = parse(end, "HH:mm:ss", new Date());
  const endTime = format(endTimeHours, "hh:mm a");

  return <span>{`${startTime} - ${endTime}`}</span>;
}
