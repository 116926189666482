import { ReactNode } from "react";
import Backdrop from "../../Backdrop";

interface SidebarProps {
  open: boolean;
  onCloseSidebar: () => void;
  children: ReactNode;
}
export default function Sidebar({
  open,
  onCloseSidebar,
  children,
}: SidebarProps) {
  return (
    <div>
      <Backdrop show={open} onClick={onCloseSidebar} className="md:hidden" />
      <div
        role="menu"
        className={`border-r border-gray-200 shadow-lg bg-white
           md:shadow-none fixed z-30 ${
             open ? "translate-x-0" : "-translate-x-[312px]"
           } md:block md:top-[60px] md:left-0 md:translate-x-0 w-[312px] transition-transform h-[calc(100vh-60px)]`}
      >
        <ul className="p-2.5">{children}</ul>
      </div>
    </div>
  );
}
