import { useMutation } from "@tanstack/react-query";
import { suspendProduct } from "../../services/menus";

export default function useToggleSuspendProduct() {
  return useMutation(
    ({
      restaurantId,
      productId,
      isSuspended,
    }: {
      restaurantId: string;
      productId: number;
      isSuspended: boolean;
    }) => suspendProduct(restaurantId, productId, isSuspended)
  );
}
