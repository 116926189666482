import { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import ActionModal from "../../../../../components/ActionModal";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import CheckboxDropdown from "../../../../../components/CheckboxDropdown";
import FormControl from "../../../../../components/Forms/FormControl";
import InputField from "../../../../../components/Forms/InputField";
import {
  TrashcanIcon,
  WarningOutlinedIcon,
} from "../../../../../components/Icons";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../../components/SidePanel/SidePanelLayout";
import Typography from "../../../../../components/Typography";
import { ROUTES } from "../../../../../constants/routes";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import {
  OrganizationRegionSetupInterface,
  RegionInterface,
  RegionSiteInterface,
  RegionSiteWithRegionIncludedInterface,
  UpdateRegionFormValues,
} from "../../../types";

interface EditRegionSidePanelProps extends SidePanelVisibilityProps {
  region: OrganizationRegionSetupInterface | null;
  regions: RegionInterface[] | undefined;
  sites: RegionSiteWithRegionIncludedInterface[] | undefined;
  onUpdateRegion: (values: UpdateRegionFormValues) => void;
  onDeleteRegion: (region: OrganizationRegionSetupInterface) => void;
}

export default function EditRegionSidePanel({
  region,
  regions,
  sites,
  onUpdateRegion,
  onDeleteRegion,
  open,
  onClose,
}: EditRegionSidePanelProps) {
  const sitesOptions = useMemo(
    () =>
      sites
        ? sites.map((site) => ({
            label: site.name,
            value: site.id,
            display: (
              <span className="flex flex-col">
                <span>{site.name}</span>
                {site.region ? (
                  <Typography as="caption" className="text-gray-500">
                    {site.region}
                  </Typography>
                ) : null}
              </span>
            ),
          }))
        : [],
    [sites]
  );

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Please enter region name.")
      .max(128, "Region name can have a maximum od 128 characters.")
      .matches(/^[a-zA-Z ]+$/g, "Only alpha characters allowed.")
      .test(
        "test-region-name",
        "The region name already exits. Please enter a new name.",
        (value) => {
          const nameMatches =
            regions &&
            regions.find(
              (currentRegion) =>
                currentRegion.name !== region?.name &&
                currentRegion.name.toLowerCase() === value?.toLowerCase()
            );
          return !nameMatches;
        }
      )
      .trim(),
  });

  const validate = useValidationSchema(validationSchema);

  const [sitesToBeRemovedByReassigning, setSitesToBeRemovedByReassigning] =
    useState<{
      region: string;
      sites: RegionSiteInterface[];
    } | null>();

  const [sitesToBeReassigned, setSitesToBeReassigned] = useState<{
    region: string;
    sites: RegionSiteWithRegionIncludedInterface[];
  } | null>();

  const onShowConfirmation = (regionName: string, selectedSites: string[]) => {
    const removedSiteList = region
      ? region.sites.filter(
          (current) => selectedSites.indexOf(current.id) === -1
        )
      : [];

    if (removedSiteList.length > 0) {
      setSitesToBeRemovedByReassigning({
        region: regionName,
        sites: removedSiteList,
      });
      return;
    }

    const newSiteList = selectedSites
      ? selectedSites.filter((selected) => {
          return !region?.sites.find(
            (regionSite) => regionSite.id === selected
          );
        })
      : [];

    if (newSiteList.length > 0) {
      const siteList = sites
        ? sites.filter((site) => newSiteList.indexOf(site.id) !== -1)
        : [];
      setSitesToBeReassigned({ region: regionName, sites: siteList });
      return;
    }

    onConfirmUpdateRegion();
  };
  const onHideConfirmation = () => {
    setSitesToBeRemovedByReassigning(null);
    setSitesToBeReassigned(null);
  };

  const onConfirmUpdateRegion = () => {
    onHideConfirmation();

    document
      .getElementById("edit-region-form")
      ?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const initialValues = useMemo(
    () => ({ name: region?.name, sites: region?.sites.map((site) => site.id) }),
    [region]
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      {region && (
        <Form
          onSubmit={onUpdateRegion}
          initialValues={initialValues}
          validate={validate}
          subscription={{ submitting: true }}
          render={({ handleSubmit, submitting, form }) => (
            <form
              id="edit-region-form"
              onSubmit={handleSubmit}
              className="h-full"
            >
              <SidePanelLayout>
                <SidePanelHeader title="Edit region" onClose={onClose} />

                <SidePanelContent>
                  <Field<string>
                    name="name"
                    render={({ input, meta }) => (
                      <FormControl>
                        <InputField
                          label="Region name"
                          placeholder="Example: Southwest"
                          input={input}
                          meta={meta}
                        />
                      </FormControl>
                    )}
                  />

                  <Field<string[]>
                    name="sites"
                    defaultValue={[]}
                    render={({ input, meta }) => (
                      <FormControl>
                        <CheckboxDropdown
                          label={
                            <span>
                              Assign a site{" "}
                              <span className="text-gray-500">(Optional)</span>
                            </span>
                          }
                          input={input}
                          meta={meta}
                          options={sitesOptions}
                          noOptionsText={
                            <Typography
                              as="body-2"
                              className=" !text-left whitespace-pre-line"
                            >
                              There are no sites to select because you need to
                              setup sites first in{" "}
                              <Link
                                to={"../" + ROUTES.ORGANIZATION_SITE_SETUP}
                                className="inline"
                              >
                                <strong className="underline">
                                  Site setup
                                </strong>
                              </Link>
                              . You will be able to assign a site to a region
                              once sites have been setup.
                            </Typography>
                          }
                        />
                      </FormControl>
                    )}
                  />
                </SidePanelContent>

                <SidePanelFooter>
                  <div className="flex items-center justify-between">
                    <div className="w-1/4">
                      <button
                        onClick={() => {
                          if (region) {
                            onDeleteRegion(region);
                            onClose();
                          }
                        }}
                        type="button"
                        className="flex text-error-500"
                      >
                        <TrashcanIcon className="h-6 w-6 mr-2" /> Delete
                      </button>
                    </div>
                    <div className="flex w-3/4">
                      <SecondaryButton
                        onClick={onClose}
                        type="button"
                        className="w-full mr-1"
                      >
                        Cancel
                      </SecondaryButton>
                      <Field<string[]>
                        name="sites"
                        subscription={{ value: true }}
                        render={() => (
                          <PrimaryButton
                            type="button"
                            disabled={submitting}
                            isLoading={submitting}
                            className="w-full ml-1"
                            onClick={async (e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              const valid = await validate(
                                form.getState().values
                              );

                              if (valid === undefined) {
                                onShowConfirmation(
                                  form.getState().values.name,
                                  form.getState().values.sites!
                                );
                              }
                            }}
                          >
                            Save
                          </PrimaryButton>
                        )}
                      />
                    </div>
                  </div>
                </SidePanelFooter>
              </SidePanelLayout>
            </form>
          )}
        />
      )}

      {sitesToBeRemovedByReassigning &&
      sitesToBeRemovedByReassigning.sites &&
      sitesToBeRemovedByReassigning.sites.length > 0 ? (
        <ActionModal
          icon={<WarningOutlinedIcon className="w-10 h-10" />}
          title={`${sitesToBeRemovedByReassigning.region} region can't be saved.`}
          onClose={onHideConfirmation}
          content={
            <div className="flex flex-col">
              <Typography as="body-2" className="mb-4">
                <span className="block">{`Looks like you are trying to remove ${
                  sitesToBeRemovedByReassigning.sites.length
                } ${
                  sitesToBeRemovedByReassigning.sites.length === 1
                    ? "site"
                    : "sites"
                } from this region.`}</span>
                <span>
                  Before you can save this region, please reassign the following
                  sites to a new region.
                </span>
              </Typography>
              <ul className="pl-2 list-inside list-disc">
                {sitesToBeRemovedByReassigning.sites.map((site) => (
                  <li key={`selected-site-${site.id}`}>{site.name}</li>
                ))}
              </ul>
            </div>
          }
          footer={
            <div className="flex justify-end">
              <PrimaryButton onClick={onHideConfirmation}>Ok</PrimaryButton>
            </div>
          }
        />
      ) : null}

      {sitesToBeReassigned &&
      sitesToBeReassigned.sites &&
      sitesToBeReassigned.sites.length > 0 ? (
        <ActionModal
          icon={<WarningOutlinedIcon className="w-10 h-10" />}
          title={`Reassign a site to ${sitesToBeReassigned.region} region?`}
          onClose={onHideConfirmation}
          content={
            <div className="flex flex-col">
              <Typography
                as="body-2"
                className="mb-4"
              >{`You have selected sites that are already assigned to different regions. Would you like to reassign the following sites to the ${sitesToBeReassigned.region} region?`}</Typography>
              <ul className="pl-2 list-inside list-disc">
                {sitesToBeReassigned.sites.map((site) => (
                  <li key={`selected-site-${site.id}`}>{site.name}</li>
                ))}
              </ul>
            </div>
          }
          footer={
            <div>
              <SecondaryButton className="mr-1" onClick={onHideConfirmation}>
                Cancel
              </SecondaryButton>
              <PrimaryButton className="ml-1" onClick={onConfirmUpdateRegion}>
                Yes, reassign
              </PrimaryButton>
            </div>
          }
        />
      ) : null}
    </SidePanel>
  );
}
