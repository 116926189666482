import { useMemo } from "react";
import { Field } from "react-final-form";
import { useSearchParams } from "react-router-dom";
import useAvailableMenus from "../../hooks/menus/useAvailableMenus";
import Dropdown from "../Dropdown/Dropdown";
import FormControl from "../Forms/FormControl";

export default function FeatureHeaderMenuDropdown() {
  const [queryParams, setQueryParams] = useSearchParams();

  const site = queryParams.get("site") || "";
  const store = queryParams.get("store") || "";
  const menu = queryParams.get("menu") || "";

  const { data: menus } = useAvailableMenus(store);

  const menusData = useMemo(
    () =>
      menus
        ? menus.map((menu) => ({
          value: menu.menuId.toString(),
          label: menu.menuName,
        }))
        : [],
    [menus]
  );

  return (
    <div className="block w-full mr-0 md:w-80 md:mr-4">
      <Field<string>
        name="menu"
        initialValue={menu}
        validateFields={[]}
        render={({ input, meta }) => (
          <FormControl>
            <Dropdown
              input={{
                ...input,
                onChange: (e) => {
                  input.onChange(e);
                  setQueryParams({
                    site,
                    store,
                    menu: e,
                  });
                },
              }}
              meta={meta}
              label="Menu"
              placeholder="Select a menu"
              disabled={!store}
              options={menusData}
            />
          </FormControl>
        )}
      />
    </div>
  );
}
