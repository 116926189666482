import { Field } from "react-final-form";
import Checkbox from "../Forms/Checkbox";

export interface CheckboxDropdownOptionType {
  value: any;
  label: string | React.ReactNode;
  disabled?: boolean;
  display?: React.ReactNode;
}

export interface CheckboxDropdownOptionProps
  extends CheckboxDropdownOptionType {
  name: string;
  [key: string]: any;
}

export default function CheckboxDropdownOption({
  name,
  value,
  label,
  display,
  disabled,
  ...rest
}: CheckboxDropdownOptionProps) {
  const isDisabled = disabled || false;
  return (
    <Field
      name={name}
      type="checkbox"
      value={value}
      render={({ input }) => (
        <li
          role="option"
          className={`relative p-2.5 text-gray-900 bg-white`}
          aria-selected={input.checked}
          {...rest}
        >
          <div className="flex items-center">
            <Checkbox
              input={input}
              meta={{}}
              label={display ?? label}
              disabled={isDisabled}
            />
          </div>
        </li>
      )}
    />
  );
}
