import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import Backdrop from "../Backdrop";

export interface SidePanelVisibilityProps {
  open: boolean;
  onClose: () => void;
}

interface SidePanelProps extends SidePanelVisibilityProps {
  children: React.ReactNode;
}

export default function SidePanel({ open, children, onClose }: SidePanelProps) {
  const nodeRef = useRef<HTMLDivElement>(null);

  return ReactDOM.createPortal(
    <div>
      <Backdrop show={open} onClick={onClose} />
      <CSSTransition
        in={open}
        timeout={100}
        nodeRef={nodeRef}
        unmountOnExit
        classNames={{
          enter: "translate-x-[calc(100vw)] md:translate-x-[500px]",
          enterActive: "translate-x-0",
          enterDone: "translate-x-0",
          exitActive: "translate-x-[calc(100vw)] md:translate-x-[500px]",
        }}
      >
        <div
          ref={nodeRef}
          role="menu"
          className={`border-l border-gray-200 shadow-lg bg-white
            fixed z-40 block top-[60px] right-0 transition-transform translate-x-[calc(100vw)] md:translate-x-[500px]  w-full md:w-[500px] h-[calc(100%-60px)]`}
        >
          {children}
        </div>
      </CSSTransition>
    </div>,
    document.body
  );
}
