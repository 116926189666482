import { useQuery } from "@tanstack/react-query";
import { STORE_QUERY_KEY } from "../../features/Stores/constants";
import { fetchStore } from "../../services/stores";
import useOrgId from "../organizations/useOrgId";

export default function useStore(storeId: string) {
  const orgId = useOrgId();

  return useQuery(
    [STORE_QUERY_KEY, orgId, storeId],
    () => fetchStore(storeId),
    {
      enabled: storeId !== "",
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000,
    }
  );
}
