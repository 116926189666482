import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { OrganizationInterface } from "../../features/Organizations/types";
import { fetchIdentity } from "../../services/auth";

import http from "../../services/http";

export interface IdentityInterface {
  permissions: string[];
  organization: OrganizationInterface;
}

interface OrganizationsContextProps {
  isLoading: boolean;
  error: unknown;
  organizations: OrganizationInterface[] | undefined;
  selectedOrganization: OrganizationInterface | null;
}

const OrganizationsContext = createContext<OrganizationsContextProps>(
  {} as OrganizationsContextProps
);

function OrganizationsProvider({ children }: { children: ReactNode }) {
  const match = useMatch(`/${ROUTES.TOP_LEVEL_ORG}/*`);

  const {
    user,
    isLoading: isAuth0Loading,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const {
    data: identityData,
    error,
    isLoading,
  } = useQuery(["identity", user?.org_id], () => fetchIdentity(), {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!user?.org_id,
  });

  const organizations = useMemo(
    () => (identityData ? identityData.organizations : []),
    [identityData]
  );

  const initialOrganization = useMemo(() => {
    if (match) {
      const org =
        identityData &&
        identityData.organizations.find((org) => org.id === match.params.orgId);
      if (org) {
        return org;
      }
    }

    if (user && user.org_id) {
      const org =
        identityData &&
        identityData.organizations.find(
          (org) => org.auth0OrgId === user.org_id
        );
      if (org) {
        return org;
      }
    }

    return identityData && identityData.organizations.length
      ? identityData.organizations[0]
      : null;
  }, [identityData, user, match]);

  if (initialOrganization) {
    http.defaults.headers.common["x-org-id"] = initialOrganization.id;
  }

  const [selectedOrganization, setSelectedOrganization] =
    useState(initialOrganization);

  // this makes sure that when somebody shares a link to connect
  // the other person gets logged in into the proper org and redirected to the right section
  // we also can now use this to switch orgs without doing weird things
  useEffect(() => {
    if (initialOrganization && user) {
      if (initialOrganization.auth0OrgId !== user.org_id) {
        const initialLogin = async () => {
          await logout({ openUrl: false });
          await loginWithRedirect({
            authorizationParams: {
              organization: initialOrganization.auth0OrgId,
            },
            appState: {
              returnTo: window.location.pathname,
            },
          }).then(() => {
            http.defaults.headers.common["x-org-id"] = initialOrganization.id;
            setSelectedOrganization(initialOrganization);
          });
        };
        initialLogin();
      }
    }
  }, [initialOrganization, user, loginWithRedirect, logout]);

  const contextValues = useMemo(() => {
    return {
      isLoading,
      error,
      organizations,
      selectedOrganization: selectedOrganization ?? initialOrganization,
    };
  }, [
    organizations,
    isLoading,
    error,
    selectedOrganization,
    initialOrganization,
  ]);

  if (isLoading || isAuth0Loading || !initialOrganization) {
    return null;
  }

  return (
    <OrganizationsContext.Provider value={contextValues}>
      {children}
    </OrganizationsContext.Provider>
  );
}

export { OrganizationsProvider, OrganizationsContext };
