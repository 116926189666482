import { useMemo } from "react";
import { Field, useForm } from "react-final-form";
import { useSearchParams } from "react-router-dom";
import useUserSites from "../../hooks/sites/useUserSites";
import FormControl from "../Forms/FormControl";
import SearchDropdown from "../SearchDropdown";

export default function FeatureHeaderSiteDropdown() {
  const [queryParams, setQueryParams] = useSearchParams();
  const form = useForm();

  const site = queryParams.get("site") || "";

  const { data: userSites } = useUserSites();

  const userSitesOptions = useMemo(
    () =>
      userSites
        ? userSites.map((site) => ({
          label: site.name,
          value: site.id,
        }))
        : [],
    [userSites]
  );

  return (
    <div className="block w-full mr-0 md:w-80 md:mr-4">
      <Field<string>
        name="site"
        initialValue={site}
        validateFields={[]}
        render={({ input, meta }) => (
          <FormControl>
            <SearchDropdown
              input={{
                ...input,
                onChange: (e) => {
                  input.onChange(e);
                  setQueryParams({
                    site: e,
                    store: "",
                  });
                  form.change("store", "");
                },
              }}
              meta={meta}
              label="Site"
              placeholder="Select a site"
              options={userSitesOptions}
            />
          </FormControl>
        )}
      />
    </div>
  );
}
