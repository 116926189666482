import { useMemo } from "react";
import { Field } from "react-final-form";
import FormControl from "../../../../../components/Forms/FormControl";
import InputField from "../../../../../components/Forms/InputField";
import {
  OrganizationPaymentGatewayFieldInterface,
  OrganizationPaymentGatewayFieldsInterface,
} from "../../../types";

interface PaymentProviderFieldsProps {
  parentField: string;
  gateway: string;
  providers: OrganizationPaymentGatewayFieldsInterface[] | undefined;
}

export default function PaymentProviderFields({
  parentField,
  gateway,
  providers,
}: PaymentProviderFieldsProps) {
  const providersMap = useMemo<
    Record<string, OrganizationPaymentGatewayFieldInterface[]>
  >(
    () =>
      providers
        ? providers.reduce((acc, item) => {
          return {
            ...acc,
            [item.gateway.id]: item.fields.filter(
              (field) => field.name !== "country" && field.name !== "currency"
            ),
          };
        }, {})
        : [],
    [providers]
  );

  if (!providersMap[gateway]) {
    return null;
  }
  return (
    <>
      {providersMap[gateway].map((field) => (
        <Field
          key={`${gateway}-${parentField}-${field.name}`}
          name={`${parentField}.config.${field.name}`}
          defaultValue=""
          validate={(value) => {
            if (field.required && (!value || value === "")) {
              return `${field.label} is required.`;
            }

            // edge case for apple pay merchant domain
            // should not use http/s://www protocol
            if (
              value &&
              field.name.toLowerCase().includes("applepaymerchantdomain")
            ) {
              if (
                value.includes("https://") ||
                value.includes("http://") ||
                value.includes("https://www") ||
                value.includes("http://www") ||
                value.includes("www")
              ) {
                return `${field.label} should not start with "https://", "http://", "https://www", "http://www", "www".`;
              }

              // check that the value is at least a valid URL (without protocol) after the check above
              if (
                !value.match(/^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i)
              ) {
                return `${field.label} is an invalid URL.`;
              }
            }

            if (value && field.regex) {
              const fieldRegex = new RegExp(field.regex);
              if (!fieldRegex.test(value)) {
                return `${field.label} has an invalid value.`;
              }
            }

            return undefined;
          }}
          render={({ input, meta }) => (
            <FormControl>
              <InputField
                key={`${gateway}-${parentField}-${field.name}`}
                input={input}
                meta={meta}
                label={
                  field.required ? field.label : `${field.label} (Optional)`
                }
              />
            </FormControl>
          )}
        />
      ))}
    </>
  );
}
