import { InfoIcon } from "../../../../../components/Icons";
import ToolTip from "../../../../../components/ToolTip/ToolTip";
import Typography from "../../../../../components/Typography";
import {
  RegionSiteWithRegionIncludedInterface,
  UpdateActiveSiteFormValues,
} from "../../../types";

import * as yup from "yup";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import generateSiteColors from "../../../utils/generateSiteColors";
import FormControl from "../../../../../components/Forms/FormControl";
import SiteColorField from "../SiteColorField";
import useActiveSite from "../../../../../hooks/sites/useActiveSite";
import { useLayoutEffect, useMemo, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../../constants/routes";
import useOrganizationRegions from "../../../../../hooks/regions/useOrganizationRegions";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import useSitePaymentConfig from "../../../../../hooks/sites/useSitePaymentConfig";

import kioskIcon from "../../../assets/kioskIcon.svg";
import mobileIcon from "../../../assets/mobileIcon.svg";
import { usePaymentProviders } from "../../../../../hooks/payment/usePaymentProviders";
import PaymentProviderFields from "../PaymentProviderFields";
import { Field, Form } from "react-final-form";
import InputField from "../../../../../components/Forms/InputField";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import { usePaymentGatewayFields } from "../../../../../hooks/payment/usePaymentGatewayFields";
import { usePaymentCurrencies } from "../../../../../hooks/payment/usePaymentCurrencies";
import Checkbox from "../../../../../components/Forms/Checkbox";
import WarningAlert from "../../../../../components/Alerts/WarningAlert";

const validatePartner = yup.object({
  region: yup.string().required("Please select Region."),
  lat: yup
    .string()
    .required("Please enter Latitude.")
    .matches(
      /^(-?[1-8]?\d(?:\.\d{12,18})?|90(?:\.0{12,18})?)$/,
      "Please enter valid Latitude."
    ),
  long: yup
    .string()
    .required("Please enter Longitude.")
    .matches(
      /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{12,18})?|180(?:\.0{12,18})?)$/,
      "Please enter valid Longitude."
    ),
  primaryColor: yup
    .string()
    .required("Please enter a six digit code.")
    .matches(/^[0-9A-F]{6}$/i, "Please enter a valid six digit code."),

  currency: yup
    .string()
    .default("")
    .test(
      "currency-required-if-gateway-is-selected",
      "Please select currency.",
      (value, context) => {
        if (!value || value === "") {
          if (
            context.from &&
            Object.hasOwn(context.from[0].value, "kiosk") &&
            context.from[0].value.kiosk.gateway !== ""
          ) {
            return false;
          }

          if (
            context.from &&
            Object.hasOwn(context.from[0].value, "mobile") &&
            context.from[0].value.mobile.gateway !== ""
          ) {
            return false;
          }

          return true;
        }
        return true;
      }
    ),
});

interface ActiveSiteModalProps {
  site: RegionSiteWithRegionIncludedInterface;
  onSubmit: (values: any) => void;
  onClose: () => void;
}
export default function ActiveSiteModal({
  site,
  onSubmit,
  onClose,
}: ActiveSiteModalProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const bankingInfoRef = useRef<HTMLDivElement | null>(null);
  const [queryParams, setQueryParams] = useSearchParams();

  const { data: activeSite, isLoading: activeSiteIsLoading } = useActiveSite(
    site.id
  );

  const {
    isLoading: sitePaymentConfigIsLoading,
    data: sitePaymentConfig,
    error: sitePaymentConfigError,
  } = useSitePaymentConfig(site.id);

  useLayoutEffect(() => {
    const focus = queryParams.get("focus") || null;
    if (focus && focus === "banking") {
      if (
        !activeSiteIsLoading &&
        !sitePaymentConfigIsLoading &&
        contentRef &&
        contentRef.current &&
        bankingInfoRef &&
        bankingInfoRef.current
      ) {
        contentRef.current.scrollTo({
          top: bankingInfoRef.current.offsetTop - 240,
          behavior: "smooth",
        });
        queryParams.delete("focus");
        setQueryParams(queryParams);
      }
    }
  }, [
    queryParams,
    setQueryParams,
    activeSiteIsLoading,
    sitePaymentConfigIsLoading,
  ]);

  const { data: paymentProviders } = usePaymentProviders();

  const { data: paymentGatewayFields } = usePaymentGatewayFields();

  const paymentProvidersOptions = useMemo(
    () =>
      paymentGatewayFields
        ? paymentGatewayFields.map((provider) => ({
            label: provider.gateway.label,
            value: provider.gateway.id,
          }))
        : [],
    [paymentGatewayFields]
  );

  const { data: paymentCurrencies } = usePaymentCurrencies();

  const paymentCurrencyOptions = useMemo(
    () =>
      paymentCurrencies
        ? paymentCurrencies.map((currency) => ({
            label: `${currency.country.toUpperCase()} - ${currency.currency.toUpperCase()}`,
            value: currency.currency,
          }))
        : [],
    [paymentCurrencies]
  );

  const { data: regions } = useOrganizationRegions();
  const regionOptions = useMemo(
    () =>
      regions
        ? regions.map((region) => ({ label: region.name, value: region.id }))
        : [],
    [regions]
  );

  const validate = useValidationSchema(validatePartner);

  const onSubmitSite = (values: any) => {
    const siteColors = generateSiteColors(values.primaryColor);

    const providerKiosk =
      values.kiosk &&
      values.kiosk.gateway &&
      paymentProviders?.find(
        (provider) =>
          provider.gateway.toLowerCase() === values.kiosk.gateway.toLowerCase()
      );

    const providerMobile =
      values.mobile &&
      values.mobile.gateway &&
      paymentProviders?.find(
        (provider) =>
          provider.gateway.toLowerCase() === values.mobile.gateway.toLowerCase()
      );

    const currency = paymentCurrencies?.find(
      (c) => c.currency === values.currency
    );

    const data: UpdateActiveSiteFormValues = {
      siteId: site.id,
      region: values.region,
      location: {
        lat: values.lat,
        long: values.long,
      },
      colorConfig: {
        palette: siteColors.pallete,
        fontPalette: siteColors.fontPallete,
      },
      kiosk:
        values.kiosk && !!values.kiosk.gateway && values.kiosk.gateway !== ""
          ? {
              ...values.kiosk,
              processor: providerKiosk ? providerKiosk.processor : "",
              config: {
                ...values.kiosk.config,
                currency: currency?.currency,
                country: currency?.country,
              },
            }
          : {},

      mobile:
        values.mobile && !!values.mobile.gateway && values.mobile.gateway !== ""
          ? {
              ...values.mobile,
              processor: providerMobile ? providerMobile.processor : "",
              config: {
                ...values.mobile.config,
                currency: currency?.currency,
                country: currency?.country,
              },
            }
          : {},

      hasPaymentConfig: !!sitePaymentConfig && !sitePaymentConfigError,
    };

    return onSubmit(data);
  };

  const initialValues = useMemo(
    () =>
      !activeSiteIsLoading && !sitePaymentConfigIsLoading
        ? {
            region: activeSite?.region.id,
            lat: activeSite?.location.latitude ?? undefined,
            long: activeSite?.location.longitude ?? undefined,
            primaryColor: activeSite?.primary
              ? activeSite?.primary.replaceAll("#", "")
              : undefined,
            currency:
              !sitePaymentConfigError && sitePaymentConfig
                ? sitePaymentConfig?.kiosk?.config?.currency
                  ? sitePaymentConfig.kiosk.config.currency
                  : sitePaymentConfig.mobile?.config?.currency
                  ? sitePaymentConfig.mobile.config.currency
                  : ""
                : "",
            kiosk: sitePaymentConfig?.kiosk
              ? {
                  gateway: sitePaymentConfig?.kiosk.gateway,
                  config: {
                    ...sitePaymentConfig?.kiosk.config,
                  },
                }
              : { gateway: "" },
            mobile: sitePaymentConfig?.mobile
              ? {
                  gateway: sitePaymentConfig?.mobile.gateway,
                  config: {
                    ...sitePaymentConfig?.mobile.config,
                  },
                }
              : { gateway: "" },
          }
        : {},
    [
      activeSite,
      activeSiteIsLoading,
      sitePaymentConfig,
      sitePaymentConfigError,
      sitePaymentConfigIsLoading,
    ]
  );

  return (
    <div className="flex flex-col fixed z-30 left-0 right-0 bottom-0 top-[60px]  bg-gray-200 items-center pt-6 px-6 lg:px-[8.5rem]">
      <div
        role="dialog"
        aria-label="Site Onboard Modal"
        className="flex flex-col bg-white rounded-t-md h-full w-full"
      >
        {!activeSiteIsLoading && !sitePaymentConfigIsLoading ? (
          <div className="flex flex-col w-full h-full">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmitSite}
              validate={validate}
              subscription={{ submitting: true }}
              render={({ handleSubmit, submitting, form }) => (
                <form
                  data-testid="update-site-form"
                  onSubmit={handleSubmit}
                  className="flex flex-col w-full h-full"
                >
                  <div className="flex-none">
                    <div
                      id="SetupHeader"
                      className="flex flex-col rounded-t-md bg-gray-50 pt-6 lg:pt-12 pb-8 px-6 lg:px-32"
                    >
                      <Typography as="body-2" className="text-gray-500 mb-3.5">
                        Site setup
                      </Typography>
                      <Typography as="h4" className="font-bold mb-6 lg:mb-3">
                        {site.name}
                      </Typography>

                      <div className="flex flex-col md:flex-row">
                        <div className="flex mr-6">
                          <Typography as="body-1" className="font-normal mr-2">
                            Site Code •
                          </Typography>

                          <Typography
                            as="body-1"
                            className="font-semibold uppercase"
                          >
                            {activeSite?.shortCode}
                          </Typography>
                        </div>
                        <div className="flex mr-6">
                          <Typography as="body-1" className="font-normal mr-2">
                            State •
                          </Typography>

                          <Typography as="body-1" className="font-semibold ">
                            {activeSite?.state}
                          </Typography>
                        </div>
                        <div className="flex">
                          <Typography as="body-1" className="font-normal mr-2">
                            TimeZone •
                          </Typography>

                          <Typography
                            as="body-1"
                            className="font-semibold capitalize"
                          >
                            {activeSite?.timeZone}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    ref={contentRef}
                    className="flex-grow flex-1 overflow-x-hiden overflow-y-auto"
                  >
                    <div className="px-6 xl:px-32">
                      <div className="flex flex-col pt-10 pb-8">
                        <div className="flex flex-col mb-8">
                          <Typography className="flex" as="h6">
                            <span className="mr-1">Region assignment</span>
                          </Typography>
                        </div>

                        <div className="flex flex-col mb-8 pb-8 border-b">
                          <div className="w-full md:w-[25rem]">
                            <Field
                              name="region"
                              render={({ input, meta }) => (
                                <Dropdown
                                  label="Region"
                                  placeholder="Select a region"
                                  input={input}
                                  meta={meta}
                                  options={regionOptions}
                                  endAdorment={
                                    <Link
                                      to={
                                        "../../" +
                                        ROUTES.ORGANIZATION_REGION_SETUP
                                      }
                                      className="flex items-center py-3"
                                    >
                                      <InfoIcon className="w-5 h-5 cursor-pointer mr-2" />{" "}
                                      <span className="text-xs">
                                        To create a new region, please go to{" "}
                                        <strong className="underline">
                                          Region setup
                                        </strong>{" "}
                                        to add.
                                      </span>
                                    </Link>
                                  }
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="flex mb-8">
                          <Typography className="flex" as="h6">
                            <span className="mr-1">Location Information</span>
                            <ToolTip
                              position="top"
                              trigger={<InfoIcon className="w-4 h-4" />}
                            >
                              <div>
                                Please{" "}
                                <a
                                  target="_blank"
                                  href="https://thessagroup.atlassian.net/wiki/spaces/IG/pages/422641675/Geolocation+for+Partners"
                                  rel="noopener noreferrer"
                                  className="font-bold underline"
                                >
                                  follow these instructions
                                </a>
                                <br />
                                on how to copy the Latitude and
                                <br />
                                Longitude numbers for a<br />
                                site.
                              </div>
                            </ToolTip>
                          </Typography>
                        </div>

                        <div className="flex flex-col md:flex-row mb-8 pb-3 border-b">
                          <div className="md:mr-4">
                            <Field
                              name="lat"
                              render={({ input, meta }) => (
                                <FormControl>
                                  <InputField
                                    input={input}
                                    meta={meta}
                                    label="Latitude"
                                    placeholder="Add latitude"
                                  />
                                </FormControl>
                              )}
                            />
                          </div>
                          <Field
                            name="long"
                            render={({ input, meta }) => (
                              <FormControl>
                                <InputField
                                  input={input}
                                  meta={meta}
                                  label="Longitude"
                                  placeholder="Add longitude"
                                />
                              </FormControl>
                            )}
                          />
                        </div>

                        <div className="flex flex-col mb-8">
                          <Typography className="flex" as="h6">
                            <span className="mr-1">Color settings</span>
                          </Typography>
                        </div>

                        <div className="flex flex-col mb-8 pb-4 border-b">
                          <div className="mb-6">
                            <Typography
                              as="subtitle-1"
                              className="font-semibold"
                            >
                              Brand color
                            </Typography>
                            <Typography as="body-2">
                              Please add the primary color for the site. This
                              value should be provided by the design team.
                            </Typography>
                          </div>
                          <Field
                            name="primaryColor"
                            parse={(value: string) => {
                              if (!value) {
                                return "";
                              }
                              const stripped = value.replace("#", "");
                              return stripped;
                            }}
                            render={({ input, meta }) => (
                              <div className="flex flex-col">
                                <Typography
                                  as="body-2"
                                  className={`mb-4 ${
                                    meta.touched &&
                                    (!!meta.error || !!meta.submitError)
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  <strong>Please enter a six digit code</strong>{" "}
                                  for the color value.
                                </Typography>
                                <FormControl>
                                  <SiteColorField
                                    input={input}
                                    meta={meta}
                                    label="500 (primary)"
                                  />
                                </FormControl>
                              </div>
                            )}
                          />
                        </div>

                        <div
                          ref={bankingInfoRef}
                          className="flex flex-col mb-8"
                        >
                          <Typography className="flex mb-2" as="h6">
                            Bank account information (Optional)
                          </Typography>
                        </div>

                        <div className="flex flex-col mb-3">
                          <div className="w-full md:w-[25rem]">
                            <Field
                              name="currency"
                              render={({ input, meta }) => (
                                <FormControl>
                                  <Dropdown
                                    label="Currency"
                                    placeholder="Select currency"
                                    input={input}
                                    meta={meta}
                                    options={paymentCurrencyOptions}
                                  />
                                </FormControl>
                              )}
                            />
                          </div>
                        </div>

                        {!sitePaymentConfigIsLoading ? (
                          <div className="flex flex-col mb-4">
                            <div className="p-8 pb-3 bg-gray-50 rounded-lg mb-8">
                              <div className="flex flex-col w-full md:w-[23rem]">
                                <div className="flex items-center mb-8">
                                  <img
                                    src={kioskIcon}
                                    alt="Kiosk ordering"
                                    className="w-12 mr-3"
                                  />
                                  <Typography
                                    as="subtitle-1"
                                    className="font-semibold"
                                  >
                                    Kiosk ordering
                                  </Typography>
                                </div>
                                <Field<string>
                                  name="kiosk.gateway"
                                  render={({ input, meta }) => (
                                    <div>
                                      <FormControl>
                                        <Dropdown
                                          label="Payment provider"
                                          placeholder="Select provider"
                                          input={{
                                            ...input,
                                            onChange: (e) => {
                                              input.onChange(e);

                                              // if the user selects a different gateway from the initial one
                                              // reset the fields
                                              // if the user goes back to the initial gateway, restore the fields

                                              if (!initialValues.kiosk) {
                                                return;
                                              }

                                              if (
                                                e !==
                                                initialValues.kiosk.gateway
                                              ) {
                                                if (
                                                  typeof initialValues.kiosk
                                                    .config !== "object" ||
                                                  Object.keys(
                                                    initialValues.kiosk.config
                                                  ).length === 0
                                                ) {
                                                  return;
                                                }

                                                Object.keys(
                                                  initialValues.kiosk.config
                                                ).forEach((key) => {
                                                  if (key !== "gateway") {
                                                    form.change(
                                                      `kiosk.config.${key}`,
                                                      undefined
                                                    );
                                                  }
                                                });
                                              } else {
                                                if (
                                                  typeof initialValues.kiosk
                                                    .config !== "object" ||
                                                  Object.keys(
                                                    initialValues.kiosk.config
                                                  ).length === 0
                                                ) {
                                                  return;
                                                }

                                                Object.keys(
                                                  initialValues.kiosk.config
                                                ).forEach((key) => {
                                                  form.change(
                                                    `kiosk.config.${key}`,
                                                    sitePaymentConfig?.kiosk
                                                      ?.config[key]
                                                  );
                                                });

                                                form.change(
                                                  "kiosk.gateway",
                                                  sitePaymentConfig?.kiosk
                                                    ?.gateway
                                                );
                                              }

                                              form.change(
                                                "same_as_kiosk",
                                                false
                                              );
                                            },
                                          }}
                                          meta={meta}
                                          options={paymentProvidersOptions}
                                        />
                                      </FormControl>
                                      {typeof input.value === "string" &&
                                      input.value.toLowerCase() === "stripe" ? (
                                        <WarningAlert className="mb-5">
                                          Please only include an account number
                                          (Account ID) that is a Connect Stripe
                                          Account.
                                        </WarningAlert>
                                      ) : null}
                                    </div>
                                  )}
                                />

                                <Field
                                  name="kiosk.gateway"
                                  subscription={{ value: true }}
                                  render={({ input }) =>
                                    input.value ? (
                                      <PaymentProviderFields
                                        parentField="kiosk"
                                        gateway={input.value}
                                        providers={paymentGatewayFields}
                                      />
                                    ) : null
                                  }
                                />
                              </div>
                            </div>
                            <div className="p-8 pb-3 bg-gray-50 rounded-lg mb-8">
                              <div className="flex flex-col w-full ">
                                <div className="flex items-center mb-8">
                                  <img
                                    src={mobileIcon}
                                    alt="Mobile ordering"
                                    className="w-12 mr-3"
                                  />
                                  <Typography
                                    as="subtitle-1"
                                    className="font-semibold"
                                  >
                                    Mobile ordering
                                  </Typography>
                                </div>
                                <Field
                                  name="mobile.gateway"
                                  render={({ input, meta }) => (
                                    <div className="flex flex-col-reverse sm:flex-row ">
                                      <div className="md:w-[23rem]">
                                        <FormControl>
                                          <Dropdown
                                            label="Payment provider"
                                            input={{
                                              ...input,
                                              onChange: (e) => {
                                                input.onChange(e);

                                                // if the user selects a different gateway from the initial one
                                                // reset the fields
                                                // if the user goes back to the initial gateway, restore the fields

                                                if (!initialValues.mobile) {
                                                  return;
                                                }

                                                if (
                                                  typeof initialValues.mobile
                                                    .config !== "object" ||
                                                  Object.keys(
                                                    initialValues.mobile.config
                                                  ).length === 0
                                                ) {
                                                  return;
                                                }

                                                if (
                                                  e !==
                                                  initialValues.mobile.gateway
                                                ) {
                                                  Object.keys(
                                                    initialValues.mobile.config
                                                  ).forEach((key) => {
                                                    if (key !== "gateway") {
                                                      form.change(
                                                        `mobile.config.${key}`,
                                                        undefined
                                                      );
                                                    }
                                                  });
                                                } else {
                                                  Object.keys(
                                                    initialValues.mobile.config
                                                  ).forEach((key) => {
                                                    form.change(
                                                      `mobile.config.${key}`,
                                                      sitePaymentConfig?.mobile
                                                        ?.config[key]
                                                    );
                                                  });

                                                  form.change(
                                                    "mobile.gateway",
                                                    sitePaymentConfig?.mobile
                                                      ?.gateway
                                                  );
                                                }

                                                const formState =
                                                  form.getState();

                                                const kioskValues = formState
                                                  .values.kiosk as {
                                                  gateway: string;
                                                  config: Record<
                                                    string,
                                                    unknown
                                                  >;
                                                };

                                                if (kioskValues.gateway !== e) {
                                                  form.change(
                                                    "same_as_kiosk",
                                                    false
                                                  );
                                                }
                                              },
                                            }}
                                            meta={meta}
                                            options={paymentProvidersOptions}
                                          />
                                        </FormControl>

                                        {typeof input.value === "string" &&
                                        input.value.toLowerCase() ===
                                          "stripe" ? (
                                          <WarningAlert className="mb-5">
                                            Please only include an account
                                            number (Account ID) that is a
                                            Connect Stripe Account.
                                          </WarningAlert>
                                        ) : null}
                                      </div>
                                      <div className="mb-5 sm:mb-0 sm:ml-6 sm:mt-9">
                                        <Field
                                          name="kiosk.gateway"
                                          subscription={{ value: true }}
                                          render={({ input: kioskInput }) => (
                                            <Field
                                              type="checkbox"
                                              name="same_as_kiosk"
                                              render={({ input }) => (
                                                <Checkbox
                                                  disabled={
                                                    kioskInput.value === "" ||
                                                    kioskInput.value ===
                                                      undefined
                                                  }
                                                  input={{
                                                    ...input,

                                                    onChange: (e) => {
                                                      input.onChange(e);
                                                      if (e.target.checked) {
                                                        const formState =
                                                          form.getState();

                                                        const kioskValues =
                                                          formState.values
                                                            .kiosk as {
                                                            gateway: string;
                                                            config: Record<
                                                              string,
                                                              unknown
                                                            >;
                                                          };

                                                        form.batch(() => {
                                                          form.change(
                                                            "mobile.gateway",
                                                            kioskValues.gateway
                                                          );

                                                          if (
                                                            typeof kioskValues.config !==
                                                              "object" ||
                                                            Object.keys(
                                                              kioskValues.config
                                                            ).length === 0
                                                          ) {
                                                            return;
                                                          }

                                                          Object.keys(
                                                            kioskValues.config
                                                          ).forEach((key) => {
                                                            form.change(
                                                              `mobile.config.${key}`,
                                                              kioskValues
                                                                .config[key]
                                                            );
                                                          });
                                                        });
                                                      } else {
                                                        form.batch(() => {
                                                          form.change(
                                                            "mobile.gateway",
                                                            initialValues.mobile
                                                              ? initialValues
                                                                  .mobile
                                                                  .gateway
                                                              : ""
                                                          );

                                                          if (
                                                            initialValues.mobile ===
                                                            undefined
                                                          ) {
                                                            return;
                                                          }

                                                          if (
                                                            typeof initialValues
                                                              .mobile.config !==
                                                              "object" ||
                                                            Object.keys(
                                                              initialValues
                                                                .mobile.config
                                                            ).length === 0
                                                          ) {
                                                            return;
                                                          }

                                                          Object.keys(
                                                            initialValues.mobile
                                                              .config
                                                          ).forEach((key) => {
                                                            form.change(
                                                              `mobile.config.${key}`,
                                                              initialValues.mobile &&
                                                                initialValues
                                                                  .mobile.config
                                                                ? initialValues
                                                                    .mobile
                                                                    .config[key]
                                                                : ""
                                                            );
                                                          });
                                                        });
                                                      }
                                                    },
                                                  }}
                                                  label="Same as kiosk"
                                                  meta={{}}
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>
                                  )}
                                />
                                <Field
                                  name="mobile.gateway"
                                  subscription={{ value: true }}
                                  render={({ input }) =>
                                    input.value ? (
                                      <div className="flex flex-col w-full md:w-[23rem]">
                                        <PaymentProviderFields
                                          parentField="mobile"
                                          gateway={input.value}
                                          providers={paymentGatewayFields}
                                        />
                                      </div>
                                    ) : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex-none ">
                    <div className="flex border-t-2 border-gray-300 p-4 justify-end">
                      <SecondaryButton onClick={onClose} className="mr-2">
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton type="submit" disabled={submitting}>
                        Save
                      </PrimaryButton>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
