import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import InfoAlert from "../../../../../components/Alerts/InfoAlert";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import {
  RestaurantIcon,
  WarningOutlinedIcon,
} from "../../../../../components/Icons";
import Typography from "../../../../../components/Typography";
import useActiveSite from "../../../../../hooks/sites/useActiveSite";
import useCreateStore from "../../../../../hooks/stores/useCreateStore";
import useUpdateStore from "../../../../../hooks/stores/useUpdateStore";
import {
  ORGANIZATION_STORES_QUERY_KEY,
  ORGANIZATION_STORE_KIOSK_SETUP_QUERY_KEY,
  ORGANIZATION_STORE_MOBILE_SETUP_QUERY_KEY,
} from "../../../constants";
import { OrganizationStoreInterface } from "../../../types";
import generateSiteColors from "../../../utils/generateSiteColors";
import {
  StoreKioskSetupFormValues,
  StoreMobileSetupFormValues,
  StoreSetupFormValues,
} from "../../types";
import ChannelSetupCard from "../ChannelSetupCard/ChannelSetupCard";
import StoreSetupSidePanel from "../StoreSetupSidePanel";

import mobileIcon from "../../mobileIcon.svg";
import kioskIcon from "../../kioskIcon.svg";
import ChannelEditCard from "../ChannelEditCard";
import useStoreMobileSetup from "../../../../../hooks/stores/useStoreMobileSetup";
import StoreMobileSetupSidePanel from "../StoreMobileSetupSidePanel";
import useCreateStoreMobileSetup from "../../../../../hooks/stores/useCreateStoreMobileSetup";
import useUpdateStoreMobileSetup from "../../../../../hooks/stores/useUpdateStoreMobileSetup";
import useStoreKioskSetup from "../../../../../hooks/stores/useStoreKioskSetup";
import StoreKioskSetupSidePanel from "../StoreKioskSetupSidePanel";
import useCreateStoreKioskSetup from "../../../../../hooks/stores/useCreateStoreKioskSetup";
import useUpdateStoreKioskSetup from "../../../../../hooks/stores/useUpdateStoreKioskSetup";
import useSitePaymentConfig from "../../../../../hooks/sites/useSitePaymentConfig";
import ActionModal from "../../../../../components/ActionModal";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../../constants/routes";

interface StoreSetupModalProps {
  siteId: string;
  store: OrganizationStoreInterface;

  onClose: () => void;
}
export default function StoreSetupModal({
  siteId,
  store,
  onClose,
}: StoreSetupModalProps) {
  const queryClient = useQueryClient();

  const { data: site } = useActiveSite(siteId);
  const { data: sitePaymentConfig } = useSitePaymentConfig(siteId);

  const [storeData, setStoreData] = useState(store);

  const [showStoreSetupSidePanel, setShowStoreSetupSidePanel] = useState(false);

  const onShowSidePanel = () => setShowStoreSetupSidePanel(true);
  const onHideSidePanel = () => setShowStoreSetupSidePanel(false);

  const updateStore = useUpdateStore();

  const createStore = useCreateStore();

  const onSave = async (values: StoreSetupFormValues) => {
    const data: OrganizationStoreInterface = {
      id: store.id,
      centegraLocationId: store.centegraLocationId,
      description: values.description,
      descriptionSpanish: values.descriptionSpanish,
      name: store.name,
      isOnboarded: store.isOnboarded,
      shortCode: store.shortCode,
      primaryColor: values.color ? `#${values.color}` : "",
      colorChoice:
        values.channels && values.channels.length > 1
          ? "both"
          : values.channels && values.channels.length === 1
          ? values.channels[0]
          : "none",
    };

    const colors = values.color ? generateSiteColors(values.color) : undefined;

    if (storeData.isOnboarded && storeData.id) {
      return updateStore
        .mutateAsync({
          storeId: storeData.id,
          description: data.description,
          descriptionSpanish: data.descriptionSpanish,
          colorConfig:
            values.color && colors
              ? {
                  palette: colors.pallete,
                  fontPalette: colors.fontPallete,
                }
              : undefined,
          colorChoice: data.colorChoice !== "" ? data.colorChoice : undefined,
        })
        .then((responseData) => {
          setStoreData({
            shortCode: data.shortCode,
            name: data.name,
            centegraLocationId: data.centegraLocationId,
            id: responseData.id,
            isOnboarded: responseData.isOnboarded,
            colorChoice: responseData.colorChoice,
            description: responseData.description,
            descriptionSpanish: responseData.descriptionSpanish,
            primaryColor: values.color ? `#${values.color}` : "",
          });

          queryClient.invalidateQueries([
            ORGANIZATION_STORES_QUERY_KEY,
            siteId,
          ]);

          onHideSidePanel();
          toast.success(`${store.name} updated successfully.`);
        })
        .catch(() => {
          toast.error("Failed to update store. Please try again.");
        });
    } else {
      return createStore
        .mutateAsync({
          siteId: siteId,
          centegraLocationId: store.centegraLocationId,
          description: data.description,
          descriptionSpanish: data.descriptionSpanish,
          colorConfig:
            values.color && colors
              ? {
                  palette: colors.pallete,
                  fontPalette: colors.fontPallete,
                }
              : undefined,
          colorChoice: data.colorChoice !== "" ? data.colorChoice : undefined,
        })
        .then((responseData) => {
          setStoreData({
            shortCode: data.shortCode,
            name: data.name,
            centegraLocationId: data.centegraLocationId,
            id: responseData.id,
            isOnboarded: true,
            colorChoice: responseData.colorChoice,
            description: responseData.description,
            descriptionSpanish: responseData.descriptionSpanish,
            primaryColor: values.color ? `#${values.color}` : "",
          });
          queryClient.invalidateQueries([
            ORGANIZATION_STORES_QUERY_KEY,
            siteId,
          ]);
          onHideSidePanel();
          toast.success(`${store.name} created successfully.`);
        })
        .catch(() => {
          toast.error("Failed to create store. Please try again.");
        });
    }
  };

  // store mobile setup
  //
  const { data: storeMobileSetup, isLoading: isLoadingStoreMobileSetup } =
    useStoreMobileSetup(storeData.id);

  const [showSetupMobileSidePanel, setShowSetupMobileSidePanel] =
    useState(false);

  const onShowSetupMobileSidePanel = () => {
    if (
      sitePaymentConfig &&
      sitePaymentConfig.mobile &&
      Object.keys(sitePaymentConfig.mobile).length > 0
    ) {
      setShowSetupMobileSidePanel(true);
      return;
    }

    setShowBankingSetupModal(true);
  };

  const onHideSetupMobileSidePanel = () => setShowSetupMobileSidePanel(false);

  const createStoreMobileSetup = useCreateStoreMobileSetup();
  const updateStoreMobileSetup = useUpdateStoreMobileSetup();

  const onSaveMobileSetup = (values: StoreMobileSetupFormValues) => {
    if (storeMobileSetup && !storeMobileSetup?.isOnboarded) {
      return createStoreMobileSetup
        .mutateAsync({
          storeId: storeData.id!,
          primaryUrl: values.primaryImage,
        })
        .then(() => {
          queryClient.invalidateQueries([
            ORGANIZATION_STORE_MOBILE_SETUP_QUERY_KEY,
            storeData.id!,
          ]);

          toast.success(`${storeData.name} Mobile setup successful.`);

          onHideSetupMobileSidePanel();
        })
        .catch(() => {
          queryClient.invalidateQueries([
            ORGANIZATION_STORE_MOBILE_SETUP_QUERY_KEY,
            storeData.id!,
          ]);

          toast.error(
            `${storeData.name} Mobile setup failed. Please try again.`
          );
        });
    }

    if (storeMobileSetup && storeMobileSetup.isOnboarded) {
      return updateStoreMobileSetup
        .mutateAsync({
          storeId: storeData.id!,
          primaryUrl: values.primaryImage,
        })
        .then(() => {
          queryClient.invalidateQueries([
            ORGANIZATION_STORE_MOBILE_SETUP_QUERY_KEY,
            storeData.id!,
          ]);

          toast.success(`${storeData.name} Mobile setup updated successfully.`);

          onHideSetupMobileSidePanel();
        })
        .catch(() => {
          queryClient.invalidateQueries([
            ORGANIZATION_STORE_MOBILE_SETUP_QUERY_KEY,
            storeData.id!,
          ]);

          toast.error(
            `${storeData.name} Mobile setup update failed. Please try again.`
          );
        });
    }
  };

  // store kiosk setup
  //

  const { data: storeKioskSetup, isLoading: isLoadingStoreKioskSetup } =
    useStoreKioskSetup(storeData.id);

  const [showSetupKioskSidePanel, setShowSetupKioskSidePanel] = useState(false);

  const onShowSetupKioskSidePanel = () => {
    if (
      sitePaymentConfig &&
      sitePaymentConfig.kiosk &&
      Object.keys(sitePaymentConfig.kiosk).length > 0
    ) {
      setShowSetupKioskSidePanel(true);
      return;
    }

    setShowBankingSetupModal(true);
  };

  const onHideSetupKioskSidePanel = () => setShowSetupKioskSidePanel(false);

  const createStoreKioskSetup = useCreateStoreKioskSetup();
  const updateStoreKioskSetup = useUpdateStoreKioskSetup();

  const onSaveKioskSetup = (values: StoreKioskSetupFormValues) => {
    const welcome = [
      values.productImage1,
      values.productImage2,
      values.productImage3,
    ].filter((val) => val !== undefined && val !== "");

    const screens = {
      header: values.header,
      headerLogo: values.headerLogo,
      welcomeLogo: values.welcomeLogo,
      welcome,
    };

    if (storeKioskSetup && !storeKioskSetup?.isOnboarded) {
      return createStoreKioskSetup
        .mutateAsync({
          storeId: storeData.id!,
          screens,
        })
        .then(() => {
          queryClient.invalidateQueries([
            ORGANIZATION_STORE_KIOSK_SETUP_QUERY_KEY,
            storeData.id!,
          ]);

          toast.success(`${storeData.name} Kiosk setup successful.`);

          onHideSetupKioskSidePanel();
        })
        .catch(() => {
          queryClient.invalidateQueries([
            ORGANIZATION_STORE_KIOSK_SETUP_QUERY_KEY,
            storeData.id!,
          ]);

          toast.error(
            `${storeData.name} Kiosk setup failed. Please try again.`
          );
        });
    }

    if (storeKioskSetup && storeKioskSetup.isOnboarded) {
      return updateStoreKioskSetup
        .mutateAsync({
          storeId: storeData.id!,
          screens,
        })
        .then(() => {
          queryClient.invalidateQueries([
            ORGANIZATION_STORE_KIOSK_SETUP_QUERY_KEY,
            storeData.id!,
          ]);

          toast.success(`${storeData.name} Kiosk setup updated successfully.`);

          onHideSetupKioskSidePanel();
        })
        .catch((err) => {
          queryClient.invalidateQueries([
            ORGANIZATION_STORE_KIOSK_SETUP_QUERY_KEY,
            storeData.id!,
          ]);

          toast.error(
            `${storeData.name} Kiosk setup update failed. Please try again.`
          );
        });
    }
  };
  const [showBankingSetupModal, setShowBankingSetupModal] = useState(false);

  return (
    <div className="flex flex-col fixed z-30 left-0 right-0 bottom-0 top-[60px] bg-gray-200 items-center pt-6 px-6 lg:px-[8.5rem]">
      <div
        role="dialog"
        aria-label="Site Onboard Modal"
        className="flex flex-col bg-white rounded-t-md h-full w-full"
      >
        <div className="flex-none">
          <div
            id="SetupHeader"
            className="flex flex-col rounded-t-md bg-gray-50 pt-6 lg:pt-12 pb-8 px-6 lg:px-32"
          >
            <Typography as="body-2" className="text-gray-500 mb-3.5">
              Store setup
            </Typography>
            <Typography as="h4" className="font-bold mb-6 lg:mb-3">
              {storeData.name}
            </Typography>

            <div className="flex flex-col md:flex-row">
              <div className="flex">
                <Typography as="body-1" className="font-normal mr-2">
                  Site •
                </Typography>

                <Typography as="body-1" className="font-semibold">
                  {site?.name}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grow flex-1 overflow-x-hiden overflow-y-auto">
          <div className="px-6 xl:px-32">
            <div className="flex flex-col pt-10 pb-8">
              <div className="flex flex-col mb-8">
                <Typography className="flex" as="h6">
                  <span className="mr-1">Store description and branding</span>
                </Typography>
              </div>
              {storeData.isOnboarded && storeData.id !== null ? (
                <div className="flex flex-col mb-8 pb-8 border-b border-b-gray-300">
                  <div className="flex flex-col mb-6">
                    <Typography as="subtitle-1" className="font-semibold mb-2">
                      Store description
                    </Typography>

                    <Typography as="body-2">
                      <strong>English:</strong> {storeData.description}
                    </Typography>
                    <Typography as="body-2">
                      <strong>Spanish:</strong> {storeData.descriptionSpanish}
                    </Typography>
                  </div>
                  <div className="flex flex-col mb-8">
                    <Typography as="subtitle-1" className="font-semibold mb-2">
                      Store brand color
                    </Typography>
                    <Typography as="body-2">
                      <strong>Hex code:</strong>{" "}
                      {storeData.primaryColor ? (
                        <span className="relative">
                          {storeData.primaryColor}
                          <span
                            style={{
                              backgroundColor: storeData.primaryColor,
                            }}
                            className="inline-block relative w-[1.125rem] h-[1.125rem] rounded ml-2 top-1"
                          ></span>
                        </span>
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Typography as="body-2">
                      <strong>Channel:</strong>{" "}
                      {storeData.colorChoice ? (
                        storeData.colorChoice === "both" ? (
                          "Mobile, Kiosk"
                        ) : (
                          <span className="capitalize">
                            {storeData.colorChoice}
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </Typography>
                  </div>
                  <div className="flex">
                    <PrimaryButton className="py-1.5" onClick={onShowSidePanel}>
                      Edit
                    </PrimaryButton>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col mb-8 pb-8 border-b-gray-300">
                  <div className="flex flex-col justify-center items-center py-10 border rounded-md border-dashed border-gray-400">
                    <RestaurantIcon className="w-8 h-8 mb-4" />
                    <Typography as="body-1" className="mb-4">
                      No store description and branding yet.
                    </Typography>
                    <PrimaryButton className="py-1.5" onClick={onShowSidePanel}>
                      Add
                    </PrimaryButton>
                  </div>
                </div>
              )}

              <div className="flex flex-col mb-8 pb-8">
                <Typography className="flex mb-6" as="h6">
                  Mobile and kiosk setup
                </Typography>

                {!storeData.isOnboarded && storeData.id === null && (
                  <InfoAlert className="mb-6">
                    Mobile and kiosk setup will be enabled after store
                    description has been added.{" "}
                  </InfoAlert>
                )}

                <div className="flex flex-row justify-center sm:justify-start">
                  {!isLoadingStoreMobileSetup && (
                    <div className="flex flex-col sm:flex-row flex-grow sm:flex-grow-0">
                      {storeMobileSetup && storeMobileSetup.isOnboarded ? (
                        <div className="mb-4">
                          <ChannelEditCard
                            title="Mobile ordering"
                            className="mr-10"
                            image={
                              <img
                                src={mobileIcon}
                                alt="Mobile ordering"
                                className="w-7"
                              />
                            }
                          >
                            <PrimaryButton
                              disabled={
                                !storeData.isOnboarded ||
                                isLoadingStoreMobileSetup
                              }
                              className="py-1.5 text-sm"
                              onClick={onShowSetupMobileSidePanel}
                              data-testid="store-mobile-setup-edit-button"
                            >
                              Edit
                            </PrimaryButton>
                          </ChannelEditCard>
                        </div>
                      ) : (
                        <div className="mb-4">
                          <ChannelSetupCard
                            title="Mobile ordering"
                            className="mr-0 md:mr-10"
                            image={
                              <img
                                src={mobileIcon}
                                alt="Mobile ordering"
                                className="w-11"
                              />
                            }
                          >
                            <PrimaryButton
                              disabled={
                                !storeData.isOnboarded ||
                                isLoadingStoreMobileSetup
                              }
                              className="py-1.5 text-sm"
                              onClick={onShowSetupMobileSidePanel}
                              data-testid="store-mobile-setup-add-button"
                            >
                              Add mobile
                            </PrimaryButton>
                          </ChannelSetupCard>
                        </div>
                      )}

                      {!isLoadingStoreKioskSetup && (
                        <div className="flex flex-col sm:flex-row flex-grow sm:flex-grow-0">
                          {storeKioskSetup && storeKioskSetup.isOnboarded ? (
                            <div className="mb-4">
                              <ChannelEditCard
                                title="Kiosk ordering"
                                image={
                                  <img
                                    src={kioskIcon}
                                    alt="Kiosk ordering"
                                    className="w-[4.25rem]"
                                  />
                                }
                              >
                                <PrimaryButton
                                  disabled={
                                    !storeData.isOnboarded ||
                                    isLoadingStoreKioskSetup
                                  }
                                  className="py-1.5 text-sm"
                                  onClick={onShowSetupKioskSidePanel}
                                  data-testid="store-kiosk-setup-edit-button"
                                >
                                  Edit
                                </PrimaryButton>
                              </ChannelEditCard>
                            </div>
                          ) : (
                            <div className="mb-4">
                              <ChannelSetupCard
                                title="Kiosk ordering"
                                image={
                                  <img
                                    src={kioskIcon}
                                    alt="Kiosk ordering"
                                    className="w-24"
                                  />
                                }
                              >
                                <PrimaryButton
                                  disabled={
                                    !storeData.isOnboarded ||
                                    isLoadingStoreKioskSetup
                                  }
                                  className="py-1.5 text-sm"
                                  onClick={onShowSetupKioskSidePanel}
                                  data-testid="store-kiosk-setup-add-button"
                                >
                                  Add kiosk
                                </PrimaryButton>
                              </ChannelSetupCard>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-none ">
          <div className="flex border-t-2 border-gray-300 p-4 justify-end">
            <PrimaryButton onClick={onClose}>Done</PrimaryButton>
          </div>
        </div>
      </div>

      <StoreSetupSidePanel
        open={showStoreSetupSidePanel}
        store={storeData}
        onSave={onSave}
        onClose={onHideSidePanel}
      />

      <StoreMobileSetupSidePanel
        open={showSetupMobileSidePanel}
        store={storeData}
        storeSetup={storeMobileSetup}
        onSave={onSaveMobileSetup}
        onClose={onHideSetupMobileSidePanel}
      />

      <StoreKioskSetupSidePanel
        open={showSetupKioskSidePanel}
        store={storeData}
        kioskSetup={storeKioskSetup}
        onSave={onSaveKioskSetup}
        onClose={onHideSetupKioskSidePanel}
      />

      {showBankingSetupModal && (
        <ActionModal
          title="Bank account required."
          icon={<WarningOutlinedIcon className="w-10 h-10" />}
          content={
            <div>
              Please first add bank account information in{" "}
              <Link
                to={
                  "../../" +
                  ROUTES.ORGANIZATION +
                  "/" +
                  ROUTES.ORGANIZATION_SITE_SETUP +
                  "/" +
                  ROUTES.ORGANIZATION_SITE_SETUP_ACTIVE +
                  "?site=" +
                  siteId +
                  "&focus=banking"
                }
                className="font-semibold underline"
              >
                Site setup
              </Link>{" "}
              then add a digital channel.
            </div>
          }
          footer={
            <PrimaryButton onClick={() => setShowBankingSetupModal(false)}>
              Ok
            </PrimaryButton>
          }
          onClose={() => setShowBankingSetupModal(false)}
        />
      )}
    </div>
  );
}
