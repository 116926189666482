import { useMutation } from "@tanstack/react-query";
import { onboardSite } from "../../services/sites";

export default function useOnboardSite() {
  return useMutation(
    ({
      centegraSiteId,
      regionId,
      location,
      colorConfig,
    }: {
      centegraSiteId: number;
      regionId: string;
      location: {
        lat: string;
        long: string;
      };
      colorConfig: {
        palette: Record<string, string>;
        fontPalette: Record<string, string>;
      };
    }) => onboardSite(centegraSiteId, regionId, location, colorConfig)
  );
}
