import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

interface TableProps<TItem> {
  data: TItem[];
  columns: ColumnDef<TItem, any>[];
}

export default function Table<TItem>({ data, columns }: TableProps<TItem>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full h-full border border-gray-300 rounded-lg overflow-x-auto xl:overflow-x-visible">
      <table className="table-auto w-full border-collapse">
        <thead className="bg-gray-50 text-gray-500 text-xs rounded-t-lg border-b border-gray-300">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="first:rounded-t-lg">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="text-left py-4 px-6 first:rounded-tl-lg last:rounded-tr-lg"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="text-sm text-black">
          {table.getRowModel().rows.length === 0 ? (
            <tr role="row">
              <td role="cell" className="px-6 py-4">
                No results found.
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className="border-b border-gray-300 last:border-0"
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="px-6 py-4 ">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.footer,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}
