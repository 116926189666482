import HandshakeIcon from "../../../../../components/Icons/HandshakeIcon";

export default function StoreSetupStart() {
  return (
    <div className="flex-auto flex justify-center">
      <div className="flex flex-col items-center w-52 mt-20 md:-mt-20 md:justify-center">
        <HandshakeIcon
          className="text-black w-11 h-11 mb-2.5"
          viewBox="0 0 44 44"
        />
        <p className="text-gray-500 text-center">
          Please select site to start.
        </p>
      </div>
    </div>
  );
}
