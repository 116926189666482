export default function DropdownNoOptions({
  text,
  ...rest
}: {
  text: React.ReactNode;
}) {
  return (
    <li
      className={`cursor-pointer select-none relative p-2.5 text-gray-900 bg-white hover:bg-gray-50`}
      role="option"
      aria-selected={false}
      {...rest}
    >
      <span className="font-normal block truncate text-base text-center">
        {text}
      </span>
    </li>
  );
}
