import { useDeferredValue, useEffect, useMemo, useRef, useState } from "react";
import { FieldRenderProps } from "react-final-form";

import CheckboxDropdownOption from "../CheckboxDropdown/CheckboxDropdownOption";
import Label from "../Forms/Label";
import { ChevronDownIcon } from "../Icons";
import CheckboxDropdownSelectAllOption from "../CheckboxDropdown/CheckboxDropdownSelectAllOption";
import SearchInput from "../SearchInput";
import { SearchCheckboxDropdownOptionType } from "./SearchCheckboxDropdownOption";
import DropdownNoOptions from "../Dropdown/DropdownNoOptions";

interface SearchCheckboxDropdownProps {
  label?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  options: SearchCheckboxDropdownOptionType[];
  error?: string | undefined;
  helperText?: string;
  enableSelectAll?: boolean;
  noOptionsText?: React.ReactNode;
}

export default function SearchCheckboxDropdown({
  input,
  meta,
  label,
  options,
  helperText,
  enableSelectAll,
  noOptionsText,
  ...rest
}: FieldRenderProps<any[]> & SearchCheckboxDropdownProps) {
  const [show, setShow] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listWrapperRef = useRef<HTMLUListElement>(null);

  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(search);

  const selectedOptions: SearchCheckboxDropdownOptionType[] | undefined =
    options.filter((option) =>
      input.value && input.value.find
        ? input.value.find((inputValue) => inputValue === option.value)
        : false
    );

  const onToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    const onClickAway = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      !wrapperRef.current?.contains(target) && setShow(false);
    };

    document.addEventListener("mousedown", onClickAway);
    return () => document.removeEventListener("mousedown", onClickAway);
  }, [setShow]);

  const placeholder = rest.placeholder ? rest.placeholder : "Select...";
  const isDisabled = rest.disabled || false;
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  let indetermined: "all" | "indetermined" | "none" = "none";
  if (selectedOptions.length !== 0 && selectedOptions.length < options.length) {
    indetermined = "indetermined";
  } else if (
    selectedOptions.length > 0 &&
    selectedOptions.length === options.length
  ) {
    indetermined = "all";
  }

  const onSelectAllChange = () => {
    switch (indetermined) {
      // when nothing is selected, select everything but the disabled options
      // a case for this is when editing a user you shouldnt be able to select and deselect regions you are not assigned to
      // this applies to select all too
      case "none":
        input.onChange(
          options
            .filter((option) => !option.disabled)
            .map((option) => option.value)
        );

        break;

      case "all":
        input.onChange(
          options
            .filter((option) => option.disabled)
            .map((option) => option.value)
        );
        break;
      default:
        input.onChange(options.map((option) => option.value));
        break;
    }
  };

  const handleKeyboard = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!show) {
      return;
    }

    switch (e.code) {
      case "Enter":
        if (show) {
          e.preventDefault();
        }
        break;

      case "Escape":
        setSearch("");
        setShow(false);
        buttonRef.current?.focus();
        break;
    }
  };

  const filteredOptions = useMemo(
    () =>
      deferredSearch === ""
        ? options
        : options.filter((option) =>
            option.label.toLowerCase().includes(deferredSearch.toLowerCase())
          ),
    [options, deferredSearch]
  );

  return (
    <div className="flex flex-col align-top mb-5 relative" ref={wrapperRef}>
      {label && (
        <Label htmlFor={`dropdown-${input.name}`} disabled={isDisabled}>
          {label}
        </Label>
      )}

      <div className="relative" onKeyDown={handleKeyboard}>
        <button
          type="button"
          className={`relative w-full bg-white border border-gray-300 rounded-lg px-3 py-2.5 mb-0.5 text-left cursor-pointer focus:ring-1 focus:ring-cinchio-blue-500 focus:outline-0  focus:border-cinchio-blue-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-100
           ${
             hasError
               ? "text-red-500 ring-1 ring-error-500  border-error-500 disabled:border-error-500"
               : ""
           }`}
          aria-haspopup="listbox"
          aria-expanded={show}
          aria-label={`dropdown-${input.name}`}
          id={`dropdown-${input.name}`}
          onClick={() => onToggle()}
          disabled={isDisabled}
          ref={buttonRef}
        >
          <div className="relative">
            <span className="flex items-center">
              <span className="block truncate pr-5">
                {selectedOptions.length > 0 ? (
                  selectedOptions.map((option) => option.label).join(", ")
                ) : (
                  <span
                    className={isDisabled ? "text-gray-300" : "text-gray-400"}
                  >
                    {placeholder}
                  </span>
                )}{" "}
              </span>
            </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pointer-events-none">
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-500 transition-transform  ${
                  show && "rotate-180"
                }`}
              />
            </span>
          </div>
        </button>

        {show && (
          <div className="absolute z-10 mt-1 w-full bg-white  rounded-lg text-base border border-gray-300  focus:outline-none drop-shadow-md">
            <div className="p-2 bg-gray-50 border-b border-b-gray-300 rounded-t-lg">
              <SearchInput
                placeholder="Search..."
                input={{
                  name: "search",
                  value: search,
                  onChange: (e) => {
                    setSearch(e.target ? e.target.value : e);
                  },
                  onFocus: () => null,
                  onBlur: () => null,
                  onKeyDown: (e: KeyboardEvent) => {
                    if (e.code === "Escape") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch("");

                      setShow(false);
                      buttonRef.current?.focus();
                    }
                  },
                }}
                meta={{}}
                autoComplete="off"
                onClear={() => {
                  setSearch("");
                }}
              />
            </div>
            <ul
              className="max-h-56 rounded-lg overflow-auto outline-transparent focus-visible:!outline-cinchio-blue-500 focus-visible:border-[2px]"
              tabIndex={-1}
              role="listbox"
              aria-label={`dropdown-listbox-${input.name}`}
              ref={listWrapperRef}
            >
              {options.length > 0 &&
              filteredOptions.length === options.length ? (
                <CheckboxDropdownSelectAllOption
                  id={`${input.name}-select-all`}
                  name={`${input.name}-select-all`}
                  value={indetermined}
                  label="Select all"
                  onChange={onSelectAllChange}
                />
              ) : (
                <DropdownNoOptions
                  text={noOptionsText ? noOptionsText : "No options available."}
                />
              )}

              {filteredOptions?.map(
                (option: SearchCheckboxDropdownOptionType) => (
                  <CheckboxDropdownOption
                    id={`${input.name}-${option.value}-${option.label}`}
                    key={`${option.value}-${option.label}`}
                    name={input.name}
                    value={option.value}
                    label={option.label}
                    disabled={option.disabled}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.code === "Enter" || e.code === "Space") {
                        setSearch("");
                      }
                    }}
                  />
                )
              )}
            </ul>
          </div>
        )}
        {hasError ? (
          <p className="text-xs text-red-500 h-6 absolute mt-0">
            {meta.error || meta.submitError}
          </p>
        ) : null}
        {!hasError && helperText ? (
          <p className="text-gray-500 text-sm mb-5">{helperText}</p>
        ) : null}
      </div>
    </div>
  );
}
