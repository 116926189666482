import Button, { ButtonProps } from "../Button/Button";

export default function PrimaryButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      className={`bg-cinchio-blue-500 text-white border-cinchio-blue-500 hover:bg-cinchio-blue-600 active:bg-cinchio-blue-500 disabled:bg-gray-200 disabled:border-gray-200 ${props.className}`}
    >
      {props.children}
    </Button>
  );
}
