export default function HandshakeIcon({
  className = "",
  viewBox = "0 0 24 24",
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox={viewBox}
      className={className}
      {...rest}
    >
      <path d="M24.2 42q-.85 0-1.45-.6t-.6-1.45q0-.35.15-.725t.45-.675L32 29.3l-1.05-1.05-9.25 9.25q-.3.3-.65.45-.35.15-.75.15-.85 0-1.425-.575-.575-.575-.575-1.425 0-.5.15-.825.15-.325.4-.575l9.25-9.25-1.05-1.05-9.25 9.2q-.3.3-.65.45-.35.15-.8.15-.8 0-1.4-.6-.6-.6-.6-1.4 0-.4.15-.75t.45-.65l9.25-9.25-1.05-1.05-9.2 9.25q-.25.25-.6.4-.35.15-.85.15-.85 0-1.425-.55-.575-.55-.575-1.4 0-.4.15-.75t.45-.65L22.25 15.8l6.35 6.35q.55.55 1.225.925t1.625.375q1.6 0 2.7-1.025t1.1-2.775q0-.7-.275-1.375t-.925-1.325L25.1 8l.3-.35q.85-.8 1.9-1.225Q28.35 6 29.4 6q1.3 0 2.4.425 1.1.425 2 1.325l8.45 8.5q.9.9 1.325 2Q44 19.35 44 20.8q0 1-.45 2.025t-1.3 1.875L25.6 41.4q-.4.4-.7.5-.3.1-.7.1ZM8.45 27.4l-2.7-2.7q-.85-.8-1.3-1.9Q4 21.7 4 20.5q0-1.3.5-2.4.5-1.1 1.25-1.85l8.45-8.5q.8-.8 1.9-1.275Q17.2 6 18.25 6q1.35 0 2.4.375 1.05.375 2.05 1.375L32.95 18q.45.45.625.85.175.4.175.8 0 1-.65 1.65-.65.65-1.65.65-.45 0-.925-.225-.475-.225-.925-.675l-7.4-7.4Z" />
    </svg>
  );
}
