import { useMutation } from "@tanstack/react-query";
import { updateRegion } from "../../services/regions";

export default function useUpdateRegion() {
  return useMutation(
    ({
      regionId,
      name,
      sites,
    }: {
      regionId: string;
      name: string;
      sites: string[];
    }) => updateRegion(regionId, name, sites)
  );
}
