import Typography from "../../../../../components/Typography";
import { OrderCartItemInterface } from "../../types";

export default function OrderDetailsItem({
  item,
}: {
  item: OrderCartItemInterface;
}) {
  return (
    <li className="flex justify-between border-b border-gray-300 py-[1.125rem] pl-0.5">
      <Typography as="body-2">
        {item.quantity}x {item.productName}
      </Typography>
      <Typography as="body-2">${item.itemTotalPrice.toFixed(2)}</Typography>
    </li>
  );
}
