import { ErrorIcon } from "../../Icons";

interface WarningAlertProps {
  className?: string;
  children: React.ReactNode;
}
export default function WarningAlert({
  className,
  children,
}: WarningAlertProps) {
  return (
    <div
      className={`flex border rounded-lg bg-warning-50 p-3 border-warning-500 text-warning-700 text-sm items-center ${className}`}
    >
      <div className="w-6 mr-2">
        <ErrorIcon className="h-6 w-6 text-warning-500" />
      </div>
      <div>{children}</div>
    </div>
  );
}
