interface PromotionsContentProps {
  children?: React.ReactNode;
  className?: string;
}
export default function PromotionsContent({
  children,
  className,
}: PromotionsContentProps) {
  return <div className={`flex-auto ${className}`}>{children}</div>;
}
