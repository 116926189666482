interface ActionMenuItemProps {
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
}

export default function ActionMenuItem({
  onClick,
  className,
  children,
}: ActionMenuItemProps) {
  return (
    <li className="w-full block group">
      <button
        onClick={onClick}
        className={`flex items-center w-full text-base font-normal text-left px-4 py-3 hover:bg-gray-100 group-first:rounded-t-lg group-last:rounded-b-lg ${className}`}
      >
        {children}
      </button>
    </li>
  );
}
