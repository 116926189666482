import React, { ReactNode, useState } from "react";
import { useResolvedPath } from "react-router-dom";
import { ChevronDownIcon } from "../../../Icons";
import SidebarItem from "../SidebarItem";

interface SidebarMenuProps {
  to: string;
  title: string;
  icon?: ReactNode;
  children: ReactNode;
}

export default function SidebarMenu({
  to,
  title,
  icon,
  children,
}: SidebarMenuProps) {
  const path = useResolvedPath(".", { relative: "path" });
  const match = path.pathname.includes(to);
  const menuShouldBeOpen = !!match;

  const [open, setOpen] = useState(menuShouldBeOpen);

  return (
    <li>
      <button
        onClick={() => setOpen(!open)}
        className="flex justify-between items-center w-full py-2.5 px-5 leading-6 text-sparkd-black"
      >
        <div className="flex">
          {icon ? <div className="w-6 h-6 mr-2.5">{icon}</div> : null}
          {title}
        </div>
        <ChevronDownIcon
          className={`h-6 w-6 text-gray-500 transition-transform  ${open && "-rotate-180"
            }`}
        />
      </button>
      {open ? (
        <ul>
          {React.Children.toArray(children).map((child) => {
            if (!React.isValidElement(child) || child.type !== SidebarItem) {
              console.warn("SidebarMenu accepts only SidebarItem as children.");
              return null;
            }
            return React.cloneElement(child, {
              ...child.props,
              className: `${child.props.className} pl-[4.1rem]`,
            });
          })}
        </ul>
      ) : null}
    </li>
  );
}
