import { useMutation } from "@tanstack/react-query";
import { createStore } from "../../services/stores";

export default function useCreateStore() {
  return useMutation(
    ({
      siteId,
      centegraLocationId,
      description,
      descriptionSpanish,
      colorConfig,
      colorChoice,
    }: {
      siteId: string;
      centegraLocationId: number;
      description: string;
      descriptionSpanish: string;
      colorConfig?: {
        palette: Record<string, string>;
        fontPalette: Record<string, string>;
      };
      colorChoice?: string;
    }) =>
      createStore(
        siteId,
        centegraLocationId,
        description,
        descriptionSpanish,
        colorConfig,
        colorChoice
      )
  );
}
