import { CloseIcon } from "../Icons";
import Typography from "../Typography";

interface SidePanelHeaderProps {
  title: string;
  subtitle?: string | React.ReactNode;
  onClose: () => void;
}

export default function SidePanelHeader({
  title,
  subtitle,
  onClose,
}: SidePanelHeaderProps) {
  return (
    <div className="flex flex-none justify-between items-start px-4 md:px-6 py-5  border-b border-gray-300">
      <div className="flex flex-col">
        <Typography as="h5" className="mb-2">
          {title}
        </Typography>
        <p className="text-sm">{subtitle}</p>
      </div>

      <button type="button" onClick={onClose} aria-label="close sidepanel">
        <CloseIcon className="w-6 h-6" />
      </button>
    </div>
  );
}
