import { useAuth0 } from "@auth0/auth0-react";

export default function Auth0InvitationRedirect({
  children,
}: {
  children: React.ReactNode;
}) {
  const { loginWithRedirect } = useAuth0();

  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);

  if (inviteMatches && orgMatches) {
    loginWithRedirect({
      authorizationParams: {
        organization: orgMatches[1],
        invitation: inviteMatches[1],
      },
    });

    // we have to return null here so react doesnt render anything because of the redirect login above
    // if we dont return null, react will continue and auth0 will try to authenticate the user
    // which leads to an error message saying the client requires an organization but the user doesnt belong to one
    // effectively breaking the invitation process
    return null;
  }

  return <>{children}</>;
}
