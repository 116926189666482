import { useQuery } from "@tanstack/react-query";
import { ORGANIZATION_SITE_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchActiveSite } from "../../services/sites";
import useOrgId from "../organizations/useOrgId";

export default function useActiveSite(siteId: string) {
  const orgId = useOrgId();
  return useQuery(
    [ORGANIZATION_SITE_QUERY_KEY, orgId, siteId],
    () => fetchActiveSite(siteId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: siteId !== "",
      staleTime: 1 * 1000 * 60,
      cacheTime: 1 * 1000 * 60,
    }
  );
}
