import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import CheckboxDropdown from "../../../../components/CheckboxDropdown";
import FormControl from "../../../../components/Forms/FormControl";
import InputField from "../../../../components/Forms/InputField";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../components/SidePanel/SidePanelLayout";
import Tabs from "../../../../components/Tabs";
import Typography from "../../../../components/Typography";
import useUserRegions from "../../../../hooks/regions/useUserRegions";
import useConnectRoles from "../../../../hooks/roles/useConnectRoles";
import { useValidationSchema } from "../../../../hooks/validations/useValidationSchema";
import { InviteUserFormValuesInterface } from "../../types";
import { validateUser } from "../../validation/validateUser";
import RoleGroup from "../RoleGroup";
import SiteSelector from "../SiteSelector";

interface InviteUserSidePanelProps extends SidePanelVisibilityProps {
  onInviteUser: (values: InviteUserFormValuesInterface) => void;
}

export default function InviteUserSidePanel({
  open,
  onInviteUser,
  onClose,
}: InviteUserSidePanelProps) {
  const { data: roleGroups, isLoading: isLoadingRoleGroups } =
    useConnectRoles();

  const { data: regions } = useUserRegions();

  const regionsOptions = regions
    ? regions.map((region) => ({ label: region.name, value: region.id }))
    : [];

  const validate = useValidationSchema(validateUser);

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        onSubmit={onInviteUser}
        validate={validate}
        subscription={{
          submitting: true,
        }}
        destroyOnUnregister={true}
        render={({ handleSubmit, submitting }) => (
          <form
            onSubmit={handleSubmit}
            className="h-full"
            data-testid="invite-user-form"
          >
            <SidePanelLayout>
              <SidePanelHeader title="Invite user" onClose={onClose} />

              <SidePanelContent>
                <div className="pb-2 mb-8 border-b border-b-gray-300">
                  <Field<string>
                    name="email"
                    render={({ input, meta }) => (
                      <FormControl>
                        <InputField
                          label="Email"
                          placeholder="Add email"
                          input={input}
                          meta={meta}
                        />
                      </FormControl>
                    )}
                  />
                </div>

                <div className="mb-8 border-b border-b-gray-300">
                  <Typography as="h6" className="font-bold mb-4">
                    Store permissions
                  </Typography>
                  <Tabs
                    items={[
                      {
                        id: "by-region",
                        header: "By region",
                        content: (
                          <div className="-mb-3">
                            <Field<string[]>
                              name="regions"
                              render={({ input, meta }) => (
                                <CheckboxDropdown
                                  label="Select region"
                                  placeholder="Select region"
                                  input={input}
                                  meta={meta}
                                  options={regionsOptions}
                                  enableSelectAll={true}
                                />
                              )}
                            />

                            <Field
                              name="validateSites"
                              initialValue={false}
                              render={() => null}
                            />
                          </div>
                        ),
                      },
                      {
                        id: "by-site",
                        header: "By site",
                        content: (
                          <div className="flex flex-col">
                            <Field<boolean>
                              name="validateSites"
                              initialValue={true}
                              validateFields={[]}
                              render={() => null}
                            />

                            <div className="-mb-3">
                              <Field<string[]>
                                name="sites"
                                render={({ input, meta }) => (
                                  <SiteSelector input={input} meta={meta} />
                                )}
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="flex flex-col mb-4 ">
                  <Typography as="h6" className="font-bold mb-6">
                    Role permissions
                  </Typography>

                  <Field
                    name="roles"
                    subscription={{ touched: true, error: true }}
                    render={({ meta }) => (
                      <Typography
                        as="body-2"
                        className={`font-bold mb-4 ${meta.touched && !!meta.error ? "text-red-500" : ""
                          }`}
                      >
                        Required - Please add at least one role.
                      </Typography>
                    )}
                  />

                  {!isLoadingRoleGroups && roleGroups ? (
                    <div className="space-y-4">
                      {roleGroups.map((group) => (
                        <RoleGroup key={group.name} roleGroup={group} />
                      ))}
                    </div>
                  ) : null}
                </div>
              </SidePanelContent>
              <SidePanelFooter>
                <div className="flex justify-evenly">
                  <SecondaryButton className="w-full mr-1" onClick={onClose}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    className="w-full ml-1"
                    disabled={submitting}
                    isLoading={submitting}
                    loadingText="Sending invite..."
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
