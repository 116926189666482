import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import FormControl from "../../../../../components/Forms/FormControl";
import InputField from "../../../../../components/Forms/InputField";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../../components/SidePanel/SidePanelLayout";
import {
  OrganizationStoreInterface,
  OrganizationStoreMobileSetupInterface,
} from "../../../types";
import * as yup from "yup";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import { useMemo } from "react";
import { StoreMobileSetupFormValues } from "../../types";

interface StoreMobileSetupSidePanelProps extends SidePanelVisibilityProps {
  store: OrganizationStoreInterface;
  storeSetup: OrganizationStoreMobileSetupInterface | undefined;
  onSave: (values: StoreMobileSetupFormValues) => void;
}

const validateStoreSetup = yup.object({
  primaryImage: yup
    .string()
    .url("Must be a valid URL.")
    .required("Please enter Store header image link."),
});

export default function StoreMobileSetupSidePanel({
  open,
  store,
  storeSetup,
  onSave,
  onClose,
}: StoreMobileSetupSidePanelProps) {
  const validate = useValidationSchema(validateStoreSetup);

  const initialValues = useMemo(
    () => ({
      primaryImage:
        storeSetup && storeSetup.primaryImage ? storeSetup.primaryImage : "",
    }),
    [storeSetup]
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSave}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader
                title="Mobile setup"
                subtitle={
                  <span>
                    Store • <span className="font-semibold">{store.name}</span>
                  </span>
                }
                onClose={onClose}
              />
              <SidePanelContent>
                <Field<string>
                  name="primaryImage"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        input={input}
                        meta={meta}
                        label="Store header image"
                        placeholder="Add image link"
                      />
                    </FormControl>
                  )}
                />
              </SidePanelContent>
              <SidePanelFooter>
                <div className="flex justinfy-evenly">
                  <SecondaryButton
                    onClick={onClose}
                    type="button"
                    className="w-full mr-1"
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    disabled={submitting}
                    isLoading={submitting}
                    type="submit"
                    className="w-full ml-1"
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
