export interface BadgeProps {
  className?: string;
  children: React.ReactNode;
}
export default function Badge({ className, children }: BadgeProps) {
  return (
    <span
      className={`inline-flex py-0.5 px-2 rounded-3xl text-sm font-normal justify-center items-center ${className}`}
    >
      {children}
    </span>
  );
}
