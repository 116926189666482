import { IconInterface } from "../iconInterface";

export default function ToggleOnIcon({
  className,
  viewBox,
  ...rest
}: IconInterface) {
  const iconClassName = className || "";
  const iconViewBox = viewBox || "0 0 24 24";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={iconViewBox}
      className={iconClassName}
      fill="currentColor"
      {...rest}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zm0 8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
    </svg>
  );
}
