import React from "react";

type AllowedElement =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle-1"
  | "subtitle-2"
  | "body-1"
  | "body-2"
  | "button"
  | "caption"
  | "overline";

const elements: Record<
  AllowedElement,
  ({
    className,
    children,
  }: {
    className: string;
    children: React.ReactNode;
  }) => JSX.Element
> = {
  h1: ({ className, children }) => <h1 className={className}>{children}</h1>,
  h2: ({ className, children }) => <h2 className={className}>{children}</h2>,
  h3: ({ className, children }) => <h3 className={className}>{children}</h3>,
  h4: ({ className, children }) => <h4 className={className}>{children}</h4>,
  h5: ({ className, children }) => <h5 className={className}>{children}</h5>,
  h6: ({ className, children }) => <h6 className={className}>{children}</h6>,

  "subtitle-1": ({ className, children }) => (
    <h6 className={className}>{children}</h6>
  ),
  "subtitle-2": ({ className, children }) => (
    <h6 className={className}>{children}</h6>
  ),

  "body-1": ({ className, children }) => (
    <p className={className}>{children}</p>
  ),
  "body-2": ({ className, children }) => (
    <p className={className}>{children}</p>
  ),
  button: ({ className, children }) => (
    <span className={className}>{children}</span>
  ),
  caption: ({ className, children }) => (
    <span className={className}>{children}</span>
  ),
  overline: ({ className, children }) => (
    <span className={className}>{children}</span>
  ),
};

const fontStyles: Record<AllowedElement, string> = {
  h1: "text-8xl leading-[7rem] -tracking-[0.1rem]",
  h2: "text-6xl leading-[4.5rem] -tracking-[0.031rem]",
  h3: "text-5xl leading-[3.5rem]",
  h4: "text-[2.125rem] leading-[2.25rem] font-bold",
  h5: "text-2xl leading-[1.5rem] font-bold",
  h6: "text-xl leading-[1.5rem] font-bold",
  "subtitle-1": "text-base",
  "subtitle-2": "text-sm leading-[1.5rem] font-bold",
  "body-1": "text-base leading-[1.5rem]",
  "body-2": "text-sm leading-[1.25rem]",
  button: "text-sm leading-[1rem] font-bold uppercase",
  caption: "text-xs leading-[1rem]",
  overline: "text-[0.625rem] leading-[1rem] font-bold uppercase",
};

interface TypographyProps {
  as?: AllowedElement;
  className?: string;
  children: React.ReactNode;
}

export default function Typography({
  as,
  className,
  children,
}: TypographyProps) {
  const TypographyComponent =
    as && elements[as] ? elements[as] : elements["body-1"];

  const styles = as && fontStyles[as] ? fontStyles[as] : fontStyles["body-1"];

  return (
    <TypographyComponent className={`block ${styles} ${className}`}>
      {children}
    </TypographyComponent>
  );
}
