import React, { useState } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";

import { AppLayout } from "./layouts/AppLayout";

import Navbar from "./components/Navigation/Navbar";
import Sidebar from "./components/Navigation/Sidebar";
import SidebarItem from "./components/Navigation/Sidebar/SidebarItem";
import SidebarMenu from "./components/Navigation/Sidebar/SidebarMenu";
import { ROUTES } from "./constants/routes";
import Stores from "./features/Stores";
import Orders from "./features/Stores/Orders";
import MenuManagement from "./features/Stores/MenuManagement";
import { Toaster } from "react-hot-toast";
import Promotions from "./features/Configurations/Promotions";
import { usePermissions } from "./hooks/permissions/usePermissions";
import {
  PERMISSIONS_ONBOARD_MANAGEMENT,
  PERMISSIONS_USER,
  PERMISSIONS_OPS_MANAGEMENT,
  PERMISSIONS_FEATURE_MANAGEMENT,
  PERMISSIONS_MENU_MANAGEMENT,
} from "./constants/permissions";
import { RestaurantIcon } from "./components/Icons";
import HandshakeIcon from "./components/Icons/HandshakeIcon";
import ManageAccountsIcon from "./components/Icons/ManageAccountsIcon";
import ToggleOnIcon from "./components/Icons/ToggleOnIcon";
import RoundUps from "./features/Configurations/RoundUps";
import OrderManagement from "./features/Stores/OrderManagement";
import ProductSuspension from "./features/Stores/ProductSuspension";
import FeatureErrorBoundary from "./components/FeatureErrorBoundary";
import MenuManagementErrorHints from "./features/Stores/MenuManagement/components/MenuManagementErrorHints";
import Users from "./features/Users";
import RegionSetup from "./features/Organizations/RegionSetup";
import Organizations from "./features/Organizations";
import SiteSetup from "./features/Organizations/SiteSetup";

import connectLogo from "./assets/images/connectLogo.svg";
import { useOrganizations } from "./hooks/organizations/useOrganizations";
import NoOrganizationLayout from "./layouts/NoOrganizationLayout";
import OrganizationDropdown from "./components/OrganizationDropdown";
import StoreSetup from "./features/Organizations/StoreSetup";
import Configurations from "./features/Configurations";

const ORGANIZATION_SETUP_TITLE = "Organization setup";
const REGION_SETUP_TITLE = "Region setup";
const SITE_SETUP_TITLE = "Site setup";
const STORE_SETUP_TITLE = "Store setup";
const CONFIGURATIONS_TITLE = "Configurations";
const ROUNDUPS_TITLE = "Round ups";
const PROMOTIONS_TITLE = "Promotions";
const STORES_TITLE = "Stores";
const STORES_ORDER_MANAGEMENT_TITLE = "Order management";
const STORES_ORDERS_TITLE = "Orders";
const STORES_MENU_MANAGEMENT_TITLE = "Menu management";
const STORES_PRODUCT_SUSPENSION_TITLE = "Product suspension";
const USERS_TITLE = "Users";

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const onCloseSidebar = () => setSidebarOpen(false);

  const { hasPermission, isLoading } = usePermissions();

  const { selectedOrganization, organizations } = useOrganizations();

  // if (!isLoading && permissions?.size === 0) {
  //   return <NoRoleLayout />;
  // }
  //
  //
  //

  if (isLoading) {
    return null;
  }

  if (!selectedOrganization && organizations?.length === 0) {
    return <NoOrganizationLayout />;
  }

  return (
    <AppLayout
      navbar={
        <Navbar
          brandLogo={<img src={connectLogo} className="pb-0.5" alt="Connect" />}
          sidebarOpen={sidebarOpen}
          onToggleSidebar={onToggleSidebar}
        >
          <OrganizationDropdown />
          {/* <NavItem onClick={() => onSetSelectedOrganization()}> */}
          {/*   {selectedOrganization?.name} */}
          {/* </NavItem> */}
        </Navbar>
      }
      sidebar={
        <Sidebar open={sidebarOpen} onCloseSidebar={onCloseSidebar}>
          {/* <SidebarItem to="/" title="Dashboard" end onCloseSidebar={onCloseSidebar} /> */}

          {hasPermission(PERMISSIONS_ONBOARD_MANAGEMENT.VIEW) && (
            <SidebarMenu
              to={ROUTES.ORGANIZATION}
              title={ORGANIZATION_SETUP_TITLE}
              icon={
                <HandshakeIcon viewBox="0 0 44 44" className="text-gray-500" />
              }
            >
              <SidebarItem
                to={
                  ROUTES.ORGANIZATION + "/" + ROUTES.ORGANIZATION_REGION_SETUP
                }
                title={REGION_SETUP_TITLE}
                preserveQuery={true}
                onCloseSidebar={onCloseSidebar}
              />

              <SidebarItem
                to={ROUTES.ORGANIZATION + "/" + ROUTES.ORGANIZATION_SITE_SETUP}
                title={SITE_SETUP_TITLE}
                preserveQuery={true}
                onCloseSidebar={onCloseSidebar}
              />

              <SidebarItem
                to={ROUTES.ORGANIZATION + "/" + ROUTES.ORGANIZATION_STORE_SETUP}
                title={STORE_SETUP_TITLE}
                preserveQuery={true}
                onCloseSidebar={onCloseSidebar}
              />
            </SidebarMenu>
          )}

          <SidebarMenu
            to={ROUTES.CONFIGURATIONS}
            title={CONFIGURATIONS_TITLE}
            icon={<ToggleOnIcon className="text-gray-500" />}
          >
            {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.ROUNDUP_VIEW) && (
              <SidebarItem
                preserveQuery={true}
                end
                title={ROUNDUPS_TITLE}
                to={ROUTES.CONFIGURATIONS + "/" + ROUTES.ROUNDUPS}
                onCloseSidebar={onCloseSidebar}
              />
            )}

            {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_VIEW) && (
              <SidebarItem
                end
                preserveQuery={true}
                title={PROMOTIONS_TITLE}
                to={ROUTES.CONFIGURATIONS + "/" + ROUTES.PROMOTIONS}
                onCloseSidebar={onCloseSidebar}
              />
            )}
          </SidebarMenu>

          <SidebarMenu
            to={ROUTES.STORES}
            title={STORES_TITLE}
            icon={<RestaurantIcon className="text-gray-500" />}
          >
            {hasPermission(PERMISSIONS_OPS_MANAGEMENT.VIEW_HOURS) && (
              <SidebarItem
                to={ROUTES.STORES + "/" + ROUTES.STORES_ORDER_MANAGEMENT}
                title={STORES_ORDER_MANAGEMENT_TITLE}
                preserveQuery={true}
                onCloseSidebar={onCloseSidebar}
              />
            )}

            {hasPermission(PERMISSIONS_OPS_MANAGEMENT.REFUND) && (
              <SidebarItem
                to={ROUTES.STORES + "/" + ROUTES.STORES_ORDERS}
                title={STORES_ORDERS_TITLE}
                preserveQuery={true}
                onCloseSidebar={onCloseSidebar}
              />
            )}
            {hasPermission(PERMISSIONS_MENU_MANAGEMENT.VIEW) && (
              <SidebarItem
                to={ROUTES.STORES + "/" + ROUTES.STORES_MENU_MANAGEMENT}
                title={STORES_MENU_MANAGEMENT_TITLE}
                preserveQuery={true}
                onCloseSidebar={onCloseSidebar}
              />
            )}

            {hasPermission(PERMISSIONS_MENU_MANAGEMENT.PRODUCT_SUSPENSION) && (
              <SidebarItem
                to={ROUTES.STORES + "/" + ROUTES.STORES_PRODUCT_SUSPENSION}
                title={STORES_PRODUCT_SUSPENSION_TITLE}
                preserveQuery={true}
                onCloseSidebar={onCloseSidebar}
              />
            )}
          </SidebarMenu>

          {hasPermission(PERMISSIONS_USER.VIEW) && (
            <SidebarItem
              to={ROUTES.USERS}
              preserveQuery={true}
              title={USERS_TITLE}
              onCloseSidebar={onCloseSidebar}
              icon={<ManageAccountsIcon className="text-gray-500" />}
            />
          )}
        </Sidebar>
      }
    >
      <Toaster
        position="bottom-right"
        toastOptions={{
          success: {
            className: "bg-green-500 toast-notification",
          },
          error: {
            className: "toast-notification",
          },
          custom: {
            className: "toast-notification",
          },
        }}
      />
      <FeatureErrorBoundary featureTitle="Connect" recoveryPath={"/"}>
        <Routes>
          <Route
            path="/*"
            element={<Navigate to={`/org/${selectedOrganization?.id}`} />}
          />
          <Route path={`/${ROUTES.TOP_LEVEL_ORG}/*`} element={<Outlet />}>
            <Route index element={<div>Dashboard</div>} />
            <Route path={`${ROUTES.STORES}/*`} element={<Stores />}>
              {hasPermission(PERMISSIONS_OPS_MANAGEMENT.VIEW_HOURS) && (
                <Route
                  path={ROUTES.STORES_ORDER_MANAGEMENT}
                  element={<OrderManagement />}
                />
              )}

              {hasPermission(PERMISSIONS_OPS_MANAGEMENT.REFUND) && (
                <Route path={ROUTES.STORES_ORDERS} element={<Orders />} />
              )}

              {hasPermission(PERMISSIONS_MENU_MANAGEMENT.VIEW) && (
                <Route
                  path={ROUTES.STORES_MENU_MANAGEMENT}
                  element={
                    <FeatureErrorBoundary
                      featureTitle={STORES_MENU_MANAGEMENT_TITLE}
                      recoveryPath={`../${ROUTES.STORES_MENU_MANAGEMENT}`}
                      recoveryHintComponent={<MenuManagementErrorHints />}
                    >
                      <MenuManagement />
                    </FeatureErrorBoundary>
                  }
                />
              )}

              {hasPermission(
                PERMISSIONS_MENU_MANAGEMENT.PRODUCT_SUSPENSION
              ) && (
                <Route
                  path={ROUTES.STORES_PRODUCT_SUSPENSION}
                  element={<ProductSuspension />}
                />
              )}

              <Route path="*" element={<div>Not found.</div>} />
            </Route>

            <Route
              path={`${ROUTES.CONFIGURATIONS}/*`}
              element={<Configurations />}
            >
              {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.ROUNDUP_VIEW) && (
                <Route path={ROUTES.ROUNDUPS} element={<RoundUps />} />
              )}

              {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_VIEW) && (
                <Route path={ROUTES.PROMOTIONS} element={<Promotions />} />
              )}

              <Route path="*" element={<div>Not found.</div>} />
            </Route>

            {hasPermission(PERMISSIONS_ONBOARD_MANAGEMENT.VIEW) && (
              <Route
                path={`${ROUTES.ORGANIZATION}/*`}
                element={<Organizations />}
              >
                <Route
                  path={ROUTES.ORGANIZATION_REGION_SETUP}
                  element={<RegionSetup />}
                />

                <Route
                  path={`${ROUTES.ORGANIZATION_SITE_SETUP}/*`}
                  element={<Outlet />}
                >
                  <Route
                    index
                    element={
                      <Navigate to={ROUTES.ORGANIZATION_SITE_SETUP_ACTIVE} />
                    }
                  />

                  <Route
                    path={ROUTES.ORGANIZATION_SITE_SETUP_ACTIVE}
                    element={<SiteSetup />}
                  />

                  <Route
                    path={ROUTES.ORGANIZATION_SITE_SETUP_ONBOARD}
                    element={<SiteSetup />}
                  />
                </Route>

                <Route
                  path={ROUTES.ORGANIZATION_STORE_SETUP}
                  element={<StoreSetup />}
                />
              </Route>
            )}

            <Route path={`${ROUTES.USERS}/*`} element={<Outlet />}>
              {hasPermission(PERMISSIONS_USER.VIEW) && (
                <Route
                  index
                  element={<Navigate to={ROUTES.USERS_MEMBERS} replace />}
                />
              )}
              {hasPermission(PERMISSIONS_USER.VIEW) && (
                <Route path={ROUTES.USERS_MEMBERS} element={<Users />} />
              )}

              {hasPermission(PERMISSIONS_USER.VIEW) && (
                <Route path={ROUTES.USERS_INVITATIONS} element={<Users />} />
              )}
            </Route>

            <Route path="*" element={<div>Not found.</div>} />
          </Route>
        </Routes>
      </FeatureErrorBoundary>
    </AppLayout>
  );
}

export default App;
