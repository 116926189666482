import Button, { ButtonProps } from "../Button/Button";

export default function DangerButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      className={`bg-error-500 text-white border-error-500 hover:bg-error-600 active:bg-error-500 disabled:bg-gray-200 disabled:border-gray-200 ${props.className}`}
    >
      {props.children}
    </Button>
  );
}
