import { Helmet } from "react-helmet-async";
import FeatureContentWrapper from "../../../components/FeatureContentWrapper";
import FeatureTitle from "../../../components/FeatureTitle";
import useRestaurantsOutletContext from "../../../context/restaurants/useRestaurantsOutletContext";
import useProductSuspensions from "../../../hooks/menus/useProductSuspensions";
import useOrgId from "../../../hooks/organizations/useOrgId";
import RestaurantContent from "../components/RestaurantContent";
import ProductSuspensionNoMenu from "./components/ProductSuspensionNoMenu";
import ProductSuspensionsTable from "./components/ProductSuspensionsTable";
import ProductSuspensionStart from "./components/ProductSuspensionStart";

export default function ProductSuspension() {
  const { site, store } = useRestaurantsOutletContext();
  const orgId = useOrgId();

  const { data: productSuspensions, error } = useProductSuspensions(store);

  if (site === "" || store === "") {
    return (
      <FeatureContentWrapper>
        <ProductSuspensionStart />
      </FeatureContentWrapper>
    );
  }

  if (error) {
    return (
      <FeatureContentWrapper>
        <ProductSuspensionNoMenu />
      </FeatureContentWrapper>
    );
  }

  return (
    <RestaurantContent>
      <Helmet>
        <title>Connect - Sites - Product suspension</title>
      </Helmet>
      <div>
        <FeatureTitle className="mb-7">Product Suspension</FeatureTitle>
        {productSuspensions ? (
          <ProductSuspensionsTable
            orgId={orgId}
            restaurantId={store}
            data={productSuspensions}
          />
        ) : null}
      </div>
    </RestaurantContent>
  );
}
