import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import ActionModal from "../../../../components/ActionModal";
import DangerButton from "../../../../components/Buttons/DangerButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { WarningOutlinedIcon } from "../../../../components/Icons";
import Pagination from "../../../../components/Pagination";
import useOrgId from "../../../../hooks/organizations/useOrgId";
import useConnectUserInvitations from "../../../../hooks/users/useConnectUserInvitations";
import useDeleteUserInvitation from "../../../../hooks/users/useDeleteUserInvitation";
import { CONNECT_USER_INVITATIONS_QUERY_KEY } from "../../constants";
import { UserInvitationInterface } from "../../types";
import InvitationsCard from "../InvitationsCard";
import InvitationsTable from "../InvitationsTable";

export default function Invitations() {
  const [queryParams] = useSearchParams();
  const orgId = useOrgId();
  const currentPage = parseInt(queryParams.get("page") || "1", 10);

  const queryClient = useQueryClient();

  const { data, isLoading } = useConnectUserInvitations(currentPage);

  const deleteUser = useDeleteUserInvitation();
  const [invitationToBeDeleted, setInvitationToBeDeleted] =
    useState<UserInvitationInterface | null>(null);

  const [deletingInvitation, setDeletingInvitation] = useState(false);

  const onShowDeleteInvitation = (invitation: UserInvitationInterface) =>
    setInvitationToBeDeleted(invitation);

  const onHideDeleteInvitation = () => {
    setInvitationToBeDeleted(null);
    setDeletingInvitation(false);
  };

  const onDeleteInvitation = async (invitation: UserInvitationInterface) => {
    setDeletingInvitation(true);
    return deleteUser
      .mutateAsync({
        auth0InvitationId: invitation.id,
      })
      .then(() => {
        queryClient.invalidateQueries([
          CONNECT_USER_INVITATIONS_QUERY_KEY,
          orgId,
        ]);
        onHideDeleteInvitation();
        toast.success(
          `Invitation for ${invitation.email} was successfully deleted.`
        );
      })
      .catch(() => {
        onHideDeleteInvitation();
        toast.error(
          `Failed to delete invitation for ${invitation.email}. Please try again.`
        );
      });
  };

  if (isLoading || !data) {
    return null;
  }

  const { invites, totalPages } = data;
  return (
    <div>
      <Helmet>
        <title>Connect - Users - Invitations</title>
      </Helmet>
      <div className="block md:hidden">
        {invites.length === 0 ? (
          <div>No results...</div>
        ) : (
          invites.map((invitation) => (
            <InvitationsCard
              key={invitation.id}
              invitation={invitation}
              onDeleteInvitation={onShowDeleteInvitation}
            />
          ))
        )}
      </div>
      <div className="hidden md:block">
        <InvitationsTable
          data={invites}
          onDeleteInvitation={onShowDeleteInvitation}
        />
      </div>
      {totalPages > 1 ? (
        <div className="mt-3">
          <Pagination currentPage={currentPage} totalPages={totalPages} />
        </div>
      ) : null}

      {invitationToBeDeleted && (
        <ActionModal
          icon={<WarningOutlinedIcon className="w-9 h-9 mb-2" />}
          title="Delete invitation?"
          content={
            <div>
              The invitation sent to {invitationToBeDeleted.email} will no
              longer be active. You will need to create a new invitation if you
              want this user to receive access to Connect.
            </div>
          }
          footer={
            <>
              <SecondaryButton onClick={onHideDeleteInvitation}>
                Cancel
              </SecondaryButton>
              <DangerButton
                className="ml-2"
                onClick={() => onDeleteInvitation(invitationToBeDeleted)}
                disabled={deletingInvitation}
                isLoading={deletingInvitation}
              >
                Delete
              </DangerButton>
            </>
          }
          onClose={onHideDeleteInvitation}
        />
      )}
    </div>
  );
}
