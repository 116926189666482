import {
  ORGANIZATION_PAYMENT_CURRENCIES_API_ENDPOINT,
  ORGANIZATION_PAYMENT_GATEWAY_FIELDS_API_ENDPOINT,
  ORGANIZATION_PAYMENT_PROVIDERS_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  OrganizationPaymentCurrencyInterface,
  OrganizationPaymentGatewayFieldsInterface,
  OrganizationPaymentGatewayInterface,
} from "../features/Organizations/types";
import http from "./http";

export function fetchPaymentProviders(): Promise<
  OrganizationPaymentGatewayInterface[]
> {
  return http
    .get(ORGANIZATION_PAYMENT_PROVIDERS_API_ENDPOINT)
    .then((response) => response.data);
}

export function fetchPaymentGatewayFields(): Promise<
  OrganizationPaymentGatewayFieldsInterface[]
> {
  return http
    .get(ORGANIZATION_PAYMENT_GATEWAY_FIELDS_API_ENDPOINT)
    .then((response) => response.data);
}

export function fetchPaymentCurrencies(): Promise<
  OrganizationPaymentCurrencyInterface[]
> {
  return http
    .get(ORGANIZATION_PAYMENT_CURRENCIES_API_ENDPOINT)
    .then((response) => response.data);
}
