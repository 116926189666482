import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import { toast } from "react-hot-toast";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../components/SidePanel/SidePanelLayout";
import Switch from "../../../../components/Switch/Switch";
import Typography from "../../../../components/Typography";
import useUpdateSpecialInstructions from "../../../../hooks/restaurants/useUpdateSpecialInstructions";
import { STORE_QUERY_KEY } from "../../constants";
import { StoreInterface } from "../../types";

interface SpecialInstructionsSidePanelProps extends SidePanelVisibilityProps {
  store: StoreInterface;
  orgId: string | undefined;
}

export default function SpecialInstructionsSidePanel({
  open,
  orgId,
  store,
  onClose,
}: SpecialInstructionsSidePanelProps) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateSpecialInstructions } =
    useUpdateSpecialInstructions();

  const onSubmit = async (values: { isActive: boolean }) => {
    return updateSpecialInstructions({
      restaurantId: store.id,
      isActive: values.isActive,
    })
      .then(() => {
        queryClient.invalidateQueries([STORE_QUERY_KEY, orgId, store.id]);
        onClose();
        toast.success("Special Instructions successfully updated.");
      })
      .catch(() => {
        toast.error("Failed to update Special Instructions. Please try again");
      });
  };

  const initialValues = useMemo(
    () => ({ isActive: store.hasItemNotes }),
    [store]
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader
                title="Special instructions"
                subtitle={store.name}
                onClose={onClose}
              />

              <SidePanelContent>
                <div className="flex-col bg-gray-50 p-4 rounded-lg">
                  <div className="flex">
                    <div className="flex-1 flex-row">
                      <Typography as="body-1" className="font-semibold mb-1.5">
                        Enable special instructions
                      </Typography>
                      <span className="block text-xs text-gray-500 pr-7">
                        Enabling special instructions will allow guests to add
                        special instructions for each menu item.
                      </span>
                    </div>

                    <div className="flex items-center">
                      <Field
                        name="isActive"
                        type="checkbox"
                        render={({ input, meta }) => (
                          <Switch
                            label={input.checked ? "On" : "Off"}
                            labelPosition="left"
                            input={input}
                            meta={meta}
                            className="mb-0"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </SidePanelContent>

              <SidePanelFooter>
                <div className="flex justify-evenly">
                  <SecondaryButton onClick={onClose} className="w-full mr-1">
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    className="w-full ml-1"
                    type="submit"
                    disabled={submitting}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
