import { useQuery } from "@tanstack/react-query";
import { CONNECT_USERS_QUERY_KEY } from "../../features/Users/constants";
import { searchConnectUsers } from "../../services/users";
import useOrgId from "../organizations/useOrgId";

export default function useSearchConnectUsers(email: string, page = 1) {
  const orgId = useOrgId();
  return useQuery(
    [CONNECT_USERS_QUERY_KEY, orgId, email, page],
    () => searchConnectUsers(email, page),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: email !== "",
    }
  );
}
