import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { createContext, ReactNode, useCallback, useMemo } from "react";
import { OrganizationInterface } from "../../features/Organizations/types";
import { fetchIdentity } from "../../services/auth";

export interface IdentityInterface {
  permissions: string[];
  organizations: OrganizationInterface[];
}

interface PermissionsContextProps {
  hasPermission: (permissiong: string) => boolean;
  isLoading: boolean;
  error: unknown;
  permissions: Set<string> | undefined;
}

const PermissionsContext = createContext<PermissionsContextProps>(
  {} as PermissionsContextProps
);

function PermissionsProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth0();

  const {
    data: identityData,
    error,
    isLoading,
  } = useQuery(["identity", user?.org_id], () => fetchIdentity(), {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!user,
  });

  const permissions = useMemo(
    () =>
      identityData
        ? new Set(identityData.permissions.map((p) => p.toLowerCase()))
        : new Set([]),
    [identityData]
  );

  const hasPermission = useCallback(
    (permission: string) => permissions.has(permission.toLowerCase()),
    [permissions]
  );

  const contextValues = useMemo(
    () => ({
      hasPermission,
      isLoading,
      error,
      permissions,
    }),
    [hasPermission, permissions, isLoading, error]
  );

  return (
    <PermissionsContext.Provider value={contextValues}>
      {children}
    </PermissionsContext.Provider>
  );
}

export { PermissionsProvider, PermissionsContext };
