import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { CloseIcon } from "../Icons";

interface ActionModalProps {
  icon?: React.ReactNode | null;
  title: string | React.ReactNode | (() => JSX.Element);
  content: React.ReactNode | (() => JSX.Element);
  footer?: React.ReactNode | (() => JSX.Element);
  onClose: () => void;
}

export default function ActionModal({
  icon,
  title,
  content,
  footer,
  onClose,
}: ActionModalProps) {
  const modalRoot = document.createElement("div") as HTMLElement;
  const modalRef = useRef<HTMLElement>(modalRoot);

  useEffect(() => {
    const modalElement = modalRef.current;
    document.body.appendChild(modalElement);
    return () => {
      document.body.removeChild(modalElement);
    };
  }, []);

  return createPortal(
    <div
      tabIndex={-1}
      aria-hidden={false}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000] justify-center items-center w-full  h-full bg-black bg-opacity-40"
    >
      <div
        role="dialog"
        aria-label="action modal"
        className="relative p-4 xs:p-0 w-full md:p-0 md:w-[28.75rem]"
      >
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="action-modal"
            onClick={onClose}
            aria-label="close action modal"
          >
            <CloseIcon className="w-5 h-5" />
          </button>
          {/*  <!-- Modal header --> */}
          <div className="pb-0 px-6 pt-10 rounded-t" />
          {/*  <!-- Modal body --> */}
          <div className="py-4 px-6 rounded-t text-sm">
            {icon && (
              <div className="flex justify-center items-center mb-6 -mt-4">
                {icon}
              </div>
            )}
            <h3 className="text-base font-semibold text-gray-900 mb-1">
              <>{title}</>
            </h3>
            {typeof content === "function" ? content() : content}
          </div>

          {/*  <!-- Modal footer --> */}
          {footer && (
            <div className="p-4 rounded-t flex justify-end">
              {typeof footer === "function" ? footer() : footer}
            </div>
          )}
        </div>
      </div>
    </div>,
    modalRef.current
  );
}
