import React, { useState } from "react";
import ActionModal from "../ActionModal";
import PrimaryButton from "../Buttons/PrimaryButton";
import CheckCircleOutlinedIcon from "../Icons/CheckCircleOutlinedIcon";

export default function RequestStatusSuccess({
  title,
  children,
  onCloseCallback,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  onCloseCallback?: () => void;
}) {
  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
    typeof onCloseCallback === "function" && onCloseCallback();
  };

  if (!open) {
    return null;
  }

  return (
    <ActionModal
      icon={
        <CheckCircleOutlinedIcon className="w-10 h-10 fill-cinchio-blue-500" />
      }
      title={
        <div className="text-center text-base mb-2">
          {title ? title : "Success!"}
        </div>
      }
      content={
        <div className="flex flex-col items-center">
          <div className="mb-6 text-xs text-center">{children}</div>
          <PrimaryButton className="w-full" onClick={onClose}>
            Done
          </PrimaryButton>
        </div>
      }
      onClose={onClose}
    />
  );
}
