import { useQuery } from "@tanstack/react-query";
import { USERS_REGIONS_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchUserRegions } from "../../services/regions";
import useOrgId from "../organizations/useOrgId";

export default function useUserRegions() {
  const orgId = useOrgId();
  return useQuery([USERS_REGIONS_QUERY_KEY, orgId], () => fetchUserRegions(), {
    enabled: true,
    refetchOnWindowFocus: false,
  });
}
