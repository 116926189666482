import { useQuery } from "@tanstack/react-query";
import { CONNECT_ROLES_QUERY_KEY } from "../../features/Users/constants";
import { fetchConnectRoles } from "../../services/roles";
import useOrgId from "../organizations/useOrgId";

export default function useConnectRoles() {
  const orgId = useOrgId();
  return useQuery([CONNECT_ROLES_QUERY_KEY, orgId], () => fetchConnectRoles(), {
    refetchOnWindowFocus: false,
    retry: false,
  });
}
