import { FieldRenderProps } from "react-final-form";
type LabelPosition = "left" | "right";

interface SwitchProps {
  label?: string;
  labelPosition?: LabelPosition;
}

const Switch = ({
  input,
  meta,
  label,
  labelPosition = "right",
  ...rest
}: FieldRenderProps<boolean, any, any> & SwitchProps) => {
  const hasError = meta.touched && !!meta.error;

  return (
    <div
      className={`flex items-center justify-center w-full relative ${rest.className}`}
    >
      <label
        htmlFor={`${input.name}-switch`}
        className="flex items-center cursor-pointer"
      >
        {label && labelPosition === "left" && (
          <div className="ml-3 text-sparkd-black font-small text-xs mr-2">
            {label}
          </div>
        )}
        <div className="relative">
          <input
            {...input}
            {...rest}
            id={`${input.name}-switch`}
            aria-label={`${input.name}-switch`}
            className="sr-only peer"
            disabled={rest.disabled}
          />
          <div className="block bg-gray-300 w-11 h-6 rounded-full transform duration-300 ease-in-out peer-checked:bg-success-500 peer-disabled:bg-gray-200"></div>
          <div className="absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transform duration-300 ease-in-out peer-checked:translate-x-5 peer-disabled:bg-gray-50"></div>
        </div>
        {label && labelPosition === "right" && (
          <div className="ml-3 text-sparkd-black font-small text-xs mr-2">
            {label}
          </div>
        )}
      </label>

      {hasError ? (
        <p className="text-xs text-red-500 h-6 absolute mt-0">{meta.error}</p>
      ) : null}
    </div>
  );
};

export default Switch;
