import http from "./http";

import {
  PARTNER_RESTAURANTS_API_ENDPOINT,
  RESTAURANT_CAPACITY_API_ENDPOINT,
  RESTAURANT_UPDATE_ADVANCED_ORDERING_STATUS_API_ENDPOINT,
  RESTAURANT_UPDATE_SPECIAL_INSTRUCTIONS_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  CapacityInterface,
  CapacityScheduleInterface,
  PartnerRestaurantInterface,
} from "../features/Stores/types";
import { sprintf } from "sprintf-js";

type Restaurants = {
  [key: string]: PartnerRestaurantInterface;
};

export function fetchPartnerRestaurants(
  partner: string | null
): Promise<PartnerRestaurantInterface[]> {
  return http
    .get(sprintf(PARTNER_RESTAURANTS_API_ENDPOINT, { partnerId: partner }))
    .then(({ data }: { data: Restaurants }) => {
      const restaurants: PartnerRestaurantInterface[] = Object.keys(data).map(
        (key) => {
          return {
            ...data[key],
            restaurantId: key,
          };
        }
      );
      return restaurants;
    });
}

export function updateRestaurantAdvancedOrderingStatus(
  restaurantId: string,
  isActive: boolean
): Promise<{ isActive: boolean }> {
  return http
    .put(
      sprintf(RESTAURANT_UPDATE_ADVANCED_ORDERING_STATUS_API_ENDPOINT, {
        restaurantId,
      }),
      { isActive }
    )
    .then((response) => response.data);
}

export function fetchRestaurantCapacity(
  restaurantId: string
): Promise<CapacityInterface> {
  return http
    .get(sprintf(RESTAURANT_CAPACITY_API_ENDPOINT, { restaurantId }))
    .then((response) => response.data);
}

export function updateRestaurantCapacityStatus(
  restaurantId: string,
  isActive: boolean
): Promise<{ isActive: boolean }> {
  return http
    .put(
      sprintf(`${RESTAURANT_CAPACITY_API_ENDPOINT}/active`, { restaurantId }),
      { isActive }
    )
    .then((response) => response.data.schedule);
}

export function addRestaurantCapacitySchedule(
  restaurantId: string,
  schedule: CapacityScheduleInterface[]
): Promise<CapacityScheduleInterface[]> {
  return http
    .post(sprintf(`${RESTAURANT_CAPACITY_API_ENDPOINT}`, { restaurantId }), {
      schedule,
    })
    .then((response) => response.data);
}

export function updateRestaurantCapacitySchedule(
  restaurantId: string,
  schedule: CapacityScheduleInterface[]
): Promise<CapacityScheduleInterface[]> {
  return http
    .put(sprintf(`${RESTAURANT_CAPACITY_API_ENDPOINT}`, { restaurantId }), {
      schedule,
    })
    .then((response) => response.data);
}

export function updateSpecialInstructions(
  restaurantId: string,
  isActive: boolean
): Promise<{ isActive: boolean }> {
  return http
    .put(
      sprintf(RESTAURANT_UPDATE_SPECIAL_INSTRUCTIONS_API_ENDPOINT, {
        restaurantId,
      }),
      { isActive }
    )
    .then((response) => response.data);
}
