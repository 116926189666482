import { useMemo, useState } from "react";
import {
  ChevronUpIcon,
  ExclamationIcon,
} from "../../../../../components/Icons";
import Typography from "../../../../../components/Typography";
import { MenuItemModifierGroupInterface } from "../../types";
import countMenuModifierGroupsErrors from "../../utils/countMenuModifierGroupErrors";
import MenuPreviewItemModifierGroup from "../MenuPreviewItemModifierGroup";

export default function MenuPreviewItemCustomizations({
  modifierGroup,
}: {
  modifierGroup: MenuItemModifierGroupInterface[];
}) {
  const [open, setOpen] = useState(false);

  const onToggle = () => setOpen(!open);

  const errors = useMemo(
    () => countMenuModifierGroupsErrors(modifierGroup),
    [modifierGroup]
  );

  return (
    <div className="bg-gray-50 border border-gray-300 py-3 px-4 rounded-lg">
      <div
        className={`flex justify-between items-start cursor-pointer ${open && "mb-7"
          }`}
        onClick={onToggle}
      >
        <div className="flex flex-col">
          <Typography as="subtitle-2" className="font-bold">
            Customizations
          </Typography>
          {errors ? (
            <div className="flex">
              <Typography
                as="body-2"
                className="flex text-red-500 items-center"
              >
                <ExclamationIcon
                  className="w-[1.125rem] h-[1.125rem] mr-2 "
                  viewBox="2 2 20 20"
                />{" "}
                Items are missing information.
              </Typography>
            </div>
          ) : null}
        </div>
        <ChevronUpIcon className={`w-6 h-6 ${open && "rotate-180"}`} />
      </div>

      <div className={open ? "" : "hidden"}>
        {modifierGroup.map((group, index) => (
          <MenuPreviewItemModifierGroup
            key={`mod-group-${group.name}-${index}`}
            group={group}
          />
        ))}
      </div>
    </div>
  );
}
