import { IconInterface } from "../iconInterface";

export default function CopyIcon({
  className,
  viewBox,
  ...rest
}: IconInterface) {
  const iconClassName = className || "";
  const iconViewBox = viewBox || "0 0 18 18";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={iconClassName}
      viewBox={iconViewBox}
      fill="currentColor"
      {...rest}
    >
      <path
        d="M9 13.5H1.5V3.75C1.5 3.3375 1.1625 3 0.75 3C0.3375 3 0 3.3375 0 3.75V13.5C0 14.325 0.675 15 1.5 15H9C9.4125 15 9.75 14.6625 9.75 14.25C9.75 13.8375 9.4125 13.5 9 13.5ZM12.75 10.5V1.5C12.75 0.675 12.075 0 11.25 0H4.5C3.675 0 3 0.675 3 1.5V10.5C3 11.325 3.675 12 4.5 12H11.25C12.075 12 12.75 11.325 12.75 10.5ZM11.25 10.5H4.5V1.5H11.25V10.5Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}
