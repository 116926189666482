import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import { CheckCircleIcon } from "../../../../../components/Icons";
import HandshakeIcon from "../../../../../components/Icons/HandshakeIcon";
import Typography from "../../../../../components/Typography";
import OnboardCard from "../../../components/OnboardCard";
import { OnboardPartnerInterface } from "../../../types";

interface OnboardSiteCardProps {
  site: OnboardPartnerInterface;
  onShowOnboardModal: (site: OnboardPartnerInterface) => void;
}

export default function OnboardSiteCard({
  site,
  onShowOnboardModal,
}: OnboardSiteCardProps) {
  return (
    <div>
      <OnboardCard
        icon={
          <HandshakeIcon
            className="w-10 h-9 text-gray-500"
            viewBox="3 4 42 38"
          />
        }
        title={site.name}
        type="site"
      >
        <div className="flex justify-between items-center">
          <PrimaryButton
            onClick={() => onShowOnboardModal(site)}
            className="py-1.5"
          >
            Onboard
          </PrimaryButton>
          {site.isReady ? (
            <div className="flex items-center">
              <CheckCircleIcon className="w-6 h-6 mr-1 text-green-500" />{" "}
              <Typography as="caption" className="font-semibold">
                POS Ready
              </Typography>
            </div>
          ) : null}
        </div>
      </OnboardCard>
    </div>
  );
}
