import SensorsIcon from "../../../../../components/Icons/SensorsIcon";
import SensorsOffIcon from "../../../../../components/Icons/SensorsOffIcon";
import Typography from "../../../../../components/Typography";

export default function MenuLiveStatus({ live }: { live: boolean }) {
  return live ? (
    <span className="flex items-center bg-red-500 border border-red-500 p-2 rounded-lg">
      <SensorsIcon className="w-4 h-4 text-white mr-1" viewBox="2 2 20 20" />
      <Typography
        as="caption"
        className="font-semibold text-white uppercase text-xs"
      >
        Live Menu
      </Typography>
    </span>
  ) : (
    <span className="flex items-center bg-gray-100 border border-gray-300 p-2 rounded-lg">
      <SensorsOffIcon
        className="w-4 h-4 text-gray-500 mr-1"
        viewBox="2 2 20 20"
      />
      <Typography
        as="caption"
        className="font-semibold text-gray-500 uppercase text-xs"
      >
        Menu Not Live
      </Typography>
    </span>
  );
}
