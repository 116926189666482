import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-hot-toast";
import { useMatch } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import FeatureContentWrapper from "../../components/FeatureContentWrapper";
import FeatureTitle from "../../components/FeatureTitle";
import Tabs from "../../components/Tabs";
import { PERMISSIONS_USER } from "../../constants/permissions";
import { ROUTES } from "../../constants/routes";
import useOrgId from "../../hooks/organizations/useOrgId";
import { usePermissions } from "../../hooks/permissions/usePermissions";
import useInviteUser from "../../hooks/users/useInviteUser";
import Invitations from "./components/Invitations";
import InviteUserSidePanel from "./components/InviteUserSidePanel";
import Members from "./components/Members";
import { CONNECT_USER_INVITATIONS_QUERY_KEY } from "./constants";
import { InviteUserFormValuesInterface } from "./types";

export default function Users() {
  const { hasPermission } = usePermissions();
  const orgId = useOrgId();

  const queryClient = useQueryClient();

  const matchMembers = useMatch(
    ROUTES.TOP_LEVEL_ORG + "/" + ROUTES.USERS + "/" + ROUTES.USERS_MEMBERS
  );
  const matchInvititations = useMatch(
    ROUTES.TOP_LEVEL_ORG + "/" + ROUTES.USERS + "/" + ROUTES.USERS_INVITATIONS
  );

  const defaultTab = matchMembers
    ? ROUTES.USERS_MEMBERS
    : matchInvititations
      ? ROUTES.USERS_INVITATIONS
      : ROUTES.USERS_MEMBERS;

  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  const onShowInviteMember = () => setShowInviteUserModal(true);
  const onHideInviteMember = () => setShowInviteUserModal(false);

  const inviteUser = useInviteUser();

  const onInviteUser = async (values: InviteUserFormValuesInterface) => {
    const selectedRegions = values.validateSites ? [] : values.regions;

    let inviteUserData: {
      email: string;
      regions: string[];
      roles: string[];
      sites?: string[];
    } = {
      email: values.email,
      regions: selectedRegions!,
      roles: Object.values(values.roles),
      sites: values.validateSites ? values.sites : [],
    };

    return inviteUser
      .mutateAsync(inviteUserData)
      .then(() => {
        queryClient.invalidateQueries([
          CONNECT_USER_INVITATIONS_QUERY_KEY,
          orgId,
        ]);
        onHideInviteMember();
        toast.success("Invitation successfully sent.");
      })
      .catch((err: AxiosError) => {
        const error = err as AxiosError<{ message: string }>;
        toast.error(`Failed to invite user. ${error.response?.data.message}`);
      });
  };

  return (
    <FeatureContentWrapper>
      <Helmet>
        <title>Connect - Users</title>
      </Helmet>
      <div className="border-b border-gray-300 mb-7">
        <div className="flex flex-col md:flex-row md:justify-between items-start sm:items-center pb-4">
          <FeatureTitle className="grow mb-4 md:mb-0">Users</FeatureTitle>
          {hasPermission(PERMISSIONS_USER.ADD) && (
            <PrimaryButton
              className="w-full md:w-auto"
              type="button"
              onClick={onShowInviteMember}
            >
              Invite user
            </PrimaryButton>
          )}
        </div>
      </div>
      <div className="flex-auto flex flex-col">
        <Tabs
          defaultTab={defaultTab}
          items={[
            {
              id: ROUTES.USERS_MEMBERS,
              header: "Members",
              asRoute: true,
              route: "../" + ROUTES.USERS_MEMBERS,
              content: <Members />,
            },
            {
              id: ROUTES.USERS_INVITATIONS,
              header: "Invitations",
              asRoute: true,
              route: "../" + ROUTES.USERS_INVITATIONS,
              content: <Invitations />,
            },
          ]}
        />
      </div>

      <InviteUserSidePanel
        open={showInviteUserModal}
        onInviteUser={onInviteUser}
        onClose={onHideInviteMember}
      />
    </FeatureContentWrapper>
  );
}
