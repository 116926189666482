import { useMutation } from "@tanstack/react-query";
import { deletePromotion } from "../../services/promotions";

export default function useDeleteDiscount() {
  return useMutation(
    ({
      partnerId,
      discountCode,
    }: {
      partnerId: string;
      discountCode: string;
    }) => deletePromotion(partnerId, discountCode)
  );
}
