import { ExclamationIcon } from "../components/Icons";
import Navbar from "../components/Navigation/Navbar";

import connectLogo from "../assets/images/connectLogo.svg";

export default function NoOrganizationLayout() {
  return (
    <div className="w-full max-h-[calc(100vh-60px)] min-h-[calc(100vh-60px)] h-[calc(100vh-60px)] p-6 overflow-hidden overflow-y-auto">
      <Navbar
        brandLogo={<img src={connectLogo} alt="Connect" />}
        sidebarOpen={false}
        onToggleSidebar={() => { }}
        sidebarDisabled={true}
      />
      <div className="max-w-7xl mx-auto p-6 pt-12">
        <div className="p-6 flex flex-col justify-center items-center">
          <ExclamationIcon className="w-10 h-10 p-2 text-red-500 bg-red-200 rounded-full mb-6" />{" "}
          <p className="mb-2">
            Connect is only available to users assigned to an organization.
          </p>
          <p>
            Please contact a Cinchio Admin if you haven't been assigned to an
            organization.
          </p>
        </div>
      </div>
    </div>
  );
}
