import SidePanel from "../../../../components/SidePanel";
import { SidePanelVisibilityProps } from "../../../../components/SidePanel/SidePanel";
import SidePanelHeader from "../../../../components/SidePanel/SidePanelHeader";
import SidePanelContent from "../../../../components/SidePanel/SidePanelContent";
import { OpenInNewIcon } from "../../../../components/Icons";
import { APP_HELP_DESK_URL } from "../../../../config/appConfig";

interface SupportSidePanelProps extends SidePanelVisibilityProps {}

export default function SupportSidePanel({
  open,
  onClose,
}: SupportSidePanelProps) {
  return (
    <SidePanel open={open} onClose={onClose}>
      <SidePanelHeader title="Help" onClose={onClose} />
      <SidePanelContent>
        <div>
          Need assistance or have a digital request, use the SOS portal to
          submit a request.
        </div>
        <div className="mt-8">
          Submit help tickets at{" "}
          <a
            href={APP_HELP_DESK_URL}
            target="_blank"
            className="underline font-bold"
            rel="noreferrer"
          >
            Digital Products Support
            <OpenInNewIcon
              viewBox={"0 0 24 24"}
              className="inline w-4 h-4 no-underline ml-1 mb-0.5"
            />
          </a>
        </div>
      </SidePanelContent>
    </SidePanel>
  );
}
