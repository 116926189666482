import { sprintf } from "sprintf-js";
import {
  REGION_ADD_API_ENDPOINT,
  REGION_DELETE_API_ENDPOINT,
  REGION_SITES_API_ENDPOINT,
  REGION_UPDATE_API_ENDPOINT,
  USER_REGIONS_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  RegionInterface,
  RegionSiteInterface,
} from "../features/Organizations/types";
import http from "./http";

export function fetchRegionSites(
  regionId: string | undefined
): Promise<RegionSiteInterface[]> {
  return http
    .get(sprintf(REGION_SITES_API_ENDPOINT, { regionId }))
    .then((response) => response.data);
}

export function addRegion(name: string, sites: string[]) {
  return http
    .post(REGION_ADD_API_ENDPOINT, { name, sites })
    .then((response) => response.data);
}

export function updateRegion(regionId: string, name: string, sites: string[]) {
  return http
    .patch(sprintf(REGION_UPDATE_API_ENDPOINT, { regionId }), { name, sites })
    .then((response) => response.data);
}

export function deleteRegion(regionId: string) {
  return http
    .delete(REGION_DELETE_API_ENDPOINT, { params: { regionId } })
    .then((response) => response.data);
}

export function fetchUserRegions(): Promise<RegionInterface[]> {
  return http.get(USER_REGIONS_API_ENDPOINT).then((response) => response.data);
}
