import { createContext, useMemo } from "react";
import { OrderPriceType } from "../types";

interface MenuOrderTypeContextProps {
  orderType: OrderPriceType | "";
}

const MenuOrderTypeContext = createContext<MenuOrderTypeContextProps>({
  orderType: "",
} as MenuOrderTypeContextProps);

function MenuOrderTypeProvider({
  orderType,
  children,
}: {
  orderType: OrderPriceType;
  children: React.ReactNode;
}) {
  const contextValue = useMemo(
    () => ({
      orderType: orderType,
    }),
    [orderType]
  );

  return (
    <MenuOrderTypeContext.Provider value={contextValue}>
      {children}
    </MenuOrderTypeContext.Provider>
  );
}

export { MenuOrderTypeProvider, MenuOrderTypeContext };
