export enum ROUTES {
  TOP_LEVEL_ORG = "org/:orgId",
  STORES = "stores",
  STORES_OVERVIEW = "overview",
  STORES_ORDER_MANAGEMENT = `order-management`,
  STORES_ORDERS = "orders",
  STORES_MENU_MANAGEMENT = "menu-management",
  STORES_PRODUCT_SUSPENSION = "product-suspension",
  PROMOTIONS = "promotions",
  ORGANIZATION = "organization",
  ORGANIZATION_REGION_SETUP = "regions",
  ORGANIZATION_SITE_SETUP = "sites",
  ORGANIZATION_SITE_SETUP_ACTIVE = "active",
  ORGANIZATION_SITE_SETUP_ONBOARD = "onboard",
  ORGANIZATION_STORE_SETUP = "stores",
  ROLES_AND_PERMISSIONS = "roles-permissions",
  FEATURE_MANAGEMENT = "feature-management",
  CONFIGURATIONS = "configurations",
  ROUNDUPS = "round-ups",
  USERS = "users",
  USERS_MEMBERS = "members",
  USERS_INVITATIONS = "invitations",
}
