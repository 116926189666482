import { useMutation } from "@tanstack/react-query";
import { updateOnsiteOrdering } from "../../services/stores";

export default function useUpdateOnsiteOrdering() {
  return useMutation(
    ({
      storeId,
      tableManagement,
    }: {
      storeId: string;
      tableManagement: string;
    }) => updateOnsiteOrdering(storeId, tableManagement)
  );
}
