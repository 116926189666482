import { useQuery } from "@tanstack/react-query";
import { PARTNER_DISCOUNTS_QUERY_KEY } from "../../features/Configurations/Promotions/constants";
import { fetchPartnerPromotions } from "../../services/promotions";
import useOrgId from "../organizations/useOrgId";

export default function usePartnerPromotions(partner: string | null) {
  const orgId = useOrgId();
  return useQuery(
    [PARTNER_DISCOUNTS_QUERY_KEY, orgId, partner],
    () => fetchPartnerPromotions(partner),
    {
      enabled: partner !== "",
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000, // 1min
    }
  );
}
