import Button, { ButtonProps } from "../Button/Button";

export default function SecondaryButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      className={`bg-white text-cinchio-blue-500 hover:bg-gray-100 active:bg-white border-cinchio-blue-500 disabled:bg-gray-100 disabled:border-gray-100 ${props.className}`}
    >
      {props.children}
    </Button>
  );
}
