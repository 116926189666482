import { useEffect, useRef, useState } from "react";
import { FieldRenderProps } from "react-final-form";

import DropdownOption, { DropdownOptionType } from "./DropdownOption";
import Label from "../Forms/Label";
import { ChevronDownIcon } from "../Icons";
import DropdownNoOptions from "./DropdownNoOptions";

interface DropdownProps {
  label?: React.ReactNode;
  placeholder?: string;
  placeholderClassName?: string;
  disabled?: boolean;
  options: DropdownOptionType[];
  error?: string | undefined;
  helperText?: string;
  noOptionsText?: React.ReactNode;
  endAdorment?: React.ReactNode;
}

export default function Dropdown({
  input,
  meta,
  label,
  options,
  helperText,
  noOptionsText,
  endAdorment,
  ...rest
}: FieldRenderProps<string | number, any> & DropdownProps) {
  const [show, setShow] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find((option) => option.value === input.value);

  const onToggle = () => {
    setShow(!show);
  };

  const onSelect = (value: string | number) => {
    input.onChange(value);
    input.onBlur();
    setShow(false);
  };

  useEffect(() => {
    const onClickAway = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      !wrapperRef.current?.contains(target) && setShow(false);
    };

    document.addEventListener("mousedown", onClickAway);
    return () => document.removeEventListener("mousedown", onClickAway);
  }, [setShow]);

  const placeholder = rest.placeholder ? rest.placeholder : "Select...";
  const isDisabled = rest.disabled || false;
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  return (
    <div className="flex flex-col align-top relative" ref={wrapperRef}>
      {label && (
        <Label htmlFor={`dropdown-${input.name}`} disabled={isDisabled}>
          {label}
        </Label>
      )}

      <div className="relative">
        <button
          type="button"
          className={`relative w-full bg-white border border-gray-300 rounded-lg px-3 py-2.5 mb-0.5 text-left cursor-pointer focus:ring-1 focus:ring-cinchio-blue-500 focus:outline-0  focus:border-cinchio-blue-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-100
           ${hasError
              ? "text-red-500 ring-1 ring-error-500 border-error-500 disabled:border-error-500"
              : ""
            }`}
          aria-haspopup="listbox"
          aria-expanded={show}
          aria-label={`dropdown-${input.name}`}
          onClick={() => onToggle()}
          disabled={isDisabled}
          id={`dropdown-${input.name}`}
        >
          <div className="relative">
            <span className="flex items-center">
              <span className="dropdownSelection block truncate pr-5">
                {selectedOption && selectedOption.value !== "" ? (
                  selectedOption.label
                ) : (
                  <span
                    className={
                      isDisabled
                        ? "text-gray-300"
                        : `text-gray-400 ${rest.placeholderClassName}`
                    }
                  >
                    {placeholder}
                  </span>
                )}{" "}
              </span>
            </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pointer-events-none">
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-500 transition-transform  ${show && "rotate-180"
                  }`}
              />
            </span>
          </div>
        </button>

        {show && (
          <div className="absolute z-10 mt-1 w-full bg-white  rounded-lg text-base border border-gray-300  focus:outline-none drop-shadow-md">
            <ul
              className="max-h-56 rounded-lg overflow-auto outline-transparent focus-visible:!outline-cinchio-blue-500 focus-visible:border-[2px]"
              tabIndex={-1}
              role="listbox"
              aria-label={`dropdown-listbox-${input.name}`}
            >
              {options.length > 0 ? (
                options.map((option: DropdownOptionType) => (
                  <DropdownOption
                    id={`${input.name}-${option.value}-${option.label}`}
                    key={`${option.value}-${option.label}`}
                    value={option.value}
                    label={option.label}
                    selected={option.value === selectedOption?.value}
                    onSelect={onSelect}
                    disabled={option.disabled}
                  />
                ))
              ) : (
                <DropdownNoOptions
                  text={noOptionsText ? noOptionsText : "No options available."}
                />
              )}
            </ul>

            {endAdorment ? (
              <div className="py-3 px-4 bg-gray-50 border-t border-t-gray-300 rounded-b-lg">
                {endAdorment}
              </div>
            ) : null}
          </div>
        )}
        {hasError ? (
          <p className="text-xs text-red-500 h-6 absolute mt-0">
            {meta.error || meta.submitError}
          </p>
        ) : null}
        {!hasError && helperText ? (
          <p className="text-gray-500 text-sm mb-5">{helperText}</p>
        ) : null}
      </div>
    </div>
  );
}
