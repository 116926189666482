import React, { ReactElement, useEffect, useRef, useState } from "react";
import ActionMenuItem from "./ActionMenuItem";

interface ActionMenuProps {
  position?: "left" | "right";
  className?: string;
  triggerElement?: React.ReactElement;
  children: React.ReactNode;
}

export default function ActionMenu({
  position = "left",
  className,
  triggerElement,
  children,
}: ActionMenuProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [show, setShow] = useState(false);

  const onToggle = () => setShow(!show);

  useEffect(() => {
    const onClickAway = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      !wrapperRef.current?.contains(target) && setShow(false);
    };

    document.addEventListener("mousedown", onClickAway);
    return () => document.removeEventListener("mousedown", onClickAway);
  }, [setShow]);

  return (
    <div ref={wrapperRef} className="relative ">
      <div className="flex justify-center items-center">
        {triggerElement ? (
          React.cloneElement(triggerElement, {
            ...triggerElement.props,
            className: `${
              triggerElement.props.className ?? ""
            } h-6 cursor-pointer`,
            onClick: () => {
              typeof triggerElement.props.onClick === "function" &&
                triggerElement.props.onClick();
              onToggle();
            },
          })
        ) : (
          <button
            ref={buttonRef}
            className="inline-flex items-center h-6 text-sm font-medium text-center text-gray-500 rounded-lg focus:ring-0 focus:ring-cinchio-blue-500 focus:outline-none  "
            type="button"
            aria-label="open action menu"
            onClick={onToggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
            </svg>
          </button>
        )}
      </div>

      {show && (
        <div
          className={`absolute 
         z-10 top-6 bg-white divide-y divide-gray-100 block `}
          style={
            position === "left"
              ? {
                  right: `calc(100% - ${buttonRef.current?.offsetWidth}px)`,
                }
              : {
                  left: `calc(0 + ${buttonRef.current?.offsetWidth}px)`,
                }
          }
        >
          <ul
            className={`text-black border border-gray-300 rounded-lg shadow-md min-w-[13rem] ${className}`}
            aria-labelledby="action menu"
          >
            {React.Children.map(children, (child) => {
              const item = child as ReactElement;
              let props = {};

              if (!item) {
                return null;
              }
              if (item.type === ActionMenuItem) {
                props = item.props;
              }

              return React.cloneElement(item, {
                ...props,
                onClick: () => {
                  typeof item.props.onClick === "function" &&
                    item.props.onClick();
                  setShow(false);
                },
              });
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
