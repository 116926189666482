import * as yup from "yup";

export const validateUser = yup.object({
  email: yup
    .string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email address"
    )
    .required("Please enter email"),
  regions: yup.array(yup.string()).when("validateSites", {
    is: false,
    then: (schema) =>
      schema
        .required("Please select a region")
        .min(1, "Please select at least one region"),
    otherwise: (schema) => schema.nullable(),
  }),
  validateSites: yup.boolean(),
  sites: yup.array(yup.string()).when("validateSites", {
    is: true,
    then: (schema) =>
      schema
        .required("Please select at least one site")
        .min(1, "Please select at least one site"),
    otherwise: (schema) => schema.nullable(),
  }),
  roles: yup
    .object()
    .test(
      "has-atleast-one-role",
      "Please select at least one role",
      (value: undefined | Record<string, string>) => {
        return value !== undefined && Object.values(value).length > 0;
      }
    ),
});
