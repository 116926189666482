import Typography from "../Typography";

interface FeatureTitleProps {
  children: React.ReactNode;
  className?: string;
}
export default function FeatureTitle({
  children,
  className,
}: FeatureTitleProps) {
  return (
    <Typography as="h4" className={className}>
      {children}
    </Typography>
  );
}
