import { useQuery } from "@tanstack/react-query";
import { RESTAURANT_PRODUCT_SUSPENSIONS_QUERY_KEY } from "../../features/Stores/constants";
import { fetchProductSuspensions } from "../../services/menus";
import useOrgId from "../organizations/useOrgId";

export default function useProductSuspensions(restaurantId: string) {
  const orgId = useOrgId();
  return useQuery(
    [RESTAURANT_PRODUCT_SUSPENSIONS_QUERY_KEY, orgId, restaurantId],
    () => fetchProductSuspensions(restaurantId),
    {
      enabled: restaurantId !== "",
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
