import { useQuery } from "@tanstack/react-query";
import { PARTNER_STANDARD_DISCOUNTS_QUERY_KEY } from "../../features/Configurations/Promotions/constants";
import { fetchPartnerStandardPromotions } from "../../services/promotions";
import useOrgId from "../organizations/useOrgId";

export default function useStandardDiscounts(type: string) {
  const orgId = useOrgId();
  return useQuery(
    [PARTNER_STANDARD_DISCOUNTS_QUERY_KEY, orgId, type],
    () => fetchPartnerStandardPromotions(type),
    {
      enabled: type !== "",
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000,
    }
  );
}
