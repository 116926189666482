import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import ActionModal from "../../../components/ActionModal";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import FeatureTitle from "../../../components/FeatureTitle";
import { WarningOutlinedIcon } from "../../../components/Icons";
import useAddPromotion from "../../../hooks/promotions/useAddPromotion";
import useDeletePromotion from "../../../hooks/promotions/useDeletePromotion";
import usePartnerPromotions from "../../../hooks/promotions/usePartnerPromotions";
import PromotionsContent from "./components/PromotionsContent";
import AddPromotionSidePanel from "./components/AddPromotionSidePanel";
import PromotionCard from "./components/PromotionCard";
import PromotionsStart from "./components/PromotionsStart";
import PromotionsTable from "./components/PromotionsTable";
import { PARTNER_DISCOUNTS_QUERY_KEY } from "./constants";
import PromotionsEmptyState from "./components/PromotionsEmptyState";
import { useSearchParams } from "react-router-dom";
import FeatureContentWrapper from "../../../components/FeatureContentWrapper";
import { PermissionsContext } from "../../../context/permissions/PermissionsContext";
import PromotionToggle from "./components/PromotionToggle";
import usePartnerFeatures from "../../../hooks/features/usePartnerFeatures";
import { PERMISSIONS_FEATURE_MANAGEMENT } from "../../../constants/permissions";
import { Helmet } from "react-helmet-async";
import useOrgId from "../../../hooks/organizations/useOrgId";
import DangerButton from "../../../components/Buttons/DangerButton";

export default function Promotions() {
  const [queryParams] = useSearchParams();
  const orgId = useOrgId();

  const siteId = queryParams.get("site") || "";
  const { data: partnerFeatures } = usePartnerFeatures(siteId);

  const { hasPermission } = useContext(PermissionsContext);

  const queryClient = useQueryClient();
  const { data: promotionsData } = usePartnerPromotions(siteId);

  const deletePromotion = useDeletePromotion();
  const addPromotion = useAddPromotion();

  const [promotionToBeDeleted, setPromotionToBeDeleted] = useState<
    string | null
  >(null);

  const [showDeletePromotionModal, setShowDeletePromotionModal] =
    useState(false);

  const [showAddPromotionPanel, setShowAddPromotionPanel] = useState(false);

  const onPromptDeletePromotion = (discountCode: string) => {
    setPromotionToBeDeleted(discountCode);
    setShowDeletePromotionModal(true);
  };

  const onDeletePromotion = () => {
    promotionToBeDeleted &&
      deletePromotion.mutate(
        {
          partnerId: siteId,
          discountCode: promotionToBeDeleted,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([PARTNER_DISCOUNTS_QUERY_KEY, orgId]);

            onHideDeletePromotionModal();
            setPromotionToBeDeleted(null);

            toast.success("Promotion deleted successfully.");
          },
          onError: () => {
            onHideDeletePromotionModal();
            setPromotionToBeDeleted(null);
            toast.error("Failed to delete Promotion. Please try again.");
          },
        }
      );
  };

  const onHideDeletePromotionModal = () => {
    setShowDeletePromotionModal(false);
  };

  const onShowAddPromotionPanel = () => setShowAddPromotionPanel(true);
  const onHideAddPromotionPanel = () => setShowAddPromotionPanel(false);

  const onAddPromotion = async (values: {
    productId: number;
    discountCode: string;
    percentage: number;
  }) => {
    return addPromotion
      .mutateAsync({
        partnerId: siteId,
        ...values,
      })
      .then(() => {
        queryClient.invalidateQueries([PARTNER_DISCOUNTS_QUERY_KEY, orgId]);
        onHideAddPromotionPanel();
        toast.success("Promotion added successfully.");
      })
      .catch(() => {
        return {
          discountCode:
            "The discount code already exists. Please try a new one.",
        };
      });
  };

  // we're hiding Add Promotion just for Cinchio UK
  const showAddPromotionButton =
    !window.location.host.includes("cinchio.co.uk");

  if (siteId === "") {
    return (
      <FeatureContentWrapper>
        <Helmet>
          <title>Connect - Promotions</title>
        </Helmet>
        <PromotionsStart />
      </FeatureContentWrapper>
    );
  }

  return (
    <FeatureContentWrapper>
      <Helmet>
        <title>Connect - Promotions</title>
      </Helmet>
      <PromotionsContent className="flex flex-col">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <FeatureTitle className="mb-7 grow">Promotions</FeatureTitle>
          {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_TOGGLE) && (
            <div>
              <div className="bg-gray-50 rounded-lg px-5 py-3 lg:mr-4 lg:mb-0 mb-4 flex flex-row">
                <div className="mr-5 text-sm mt-0.5 grow font-semibold">
                  Enable promotions
                </div>
                <div>
                  {partnerFeatures && (
                    <PromotionToggle
                      orgId={orgId}
                      partnerId={siteId}
                      enabled={partnerFeatures.hasDiscount}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_ADD) &&
            showAddPromotionButton && (
              <PrimaryButton
                type="button"
                onClick={onShowAddPromotionPanel}
                className="mb-5"
              >
                Add promotion
              </PrimaryButton>
            )}
        </div>
        {promotionsData && promotionsData.length === 0 ? (
          <PromotionsEmptyState />
        ) : null}

        <div className="flex flex-col lg:hidden">
          {promotionsData && promotionsData.length > 0
            ? promotionsData.map((discount) => (
              <PromotionCard
                key={discount.discountCode}
                code={discount.discountCode}
                discount={`${discount.percentage}%`}
                onDeletePromotion={onPromptDeletePromotion}
              />
            ))
            : null}
        </div>

        <div className="hidden lg:flex">
          {promotionsData && promotionsData.length > 0 ? (
            <PromotionsTable
              data={promotionsData}
              onDeletePromotion={onPromptDeletePromotion}
            />
          ) : null}
        </div>

        {promotionToBeDeleted && showDeletePromotionModal ? (
          <ActionModal
            title="Delete Promotion?"
            icon={<WarningOutlinedIcon className="w-10 h-10" />}
            content={`Are you sure you want to delete the ${promotionToBeDeleted} promo?`}
            footer={
              <div>
                <SecondaryButton
                  onClick={onHideDeletePromotionModal}
                  className="py-1.5 px-4 mr-2"
                >
                  Cancel
                </SecondaryButton>
                <DangerButton
                  onClick={onDeletePromotion}
                  className="py-1.5 px-4"
                >
                  Yes, Delete
                </DangerButton>
              </div>
            }
            onClose={onHideDeletePromotionModal}
          />
        ) : null}

        <AddPromotionSidePanel
          open={showAddPromotionPanel}
          onAddPromotion={onAddPromotion}
          onClose={onHideAddPromotionPanel}
        />
      </PromotionsContent>
    </FeatureContentWrapper>
  );
}
