import Typography from "../../../../components/Typography";

import checkIcon from "./checkIcon.png";

interface OnboardCardProps {
  icon: React.ReactNode;
  type: "site" | "store";
  title: string;
  complete?: boolean;
  children: React.ReactNode;
}

export default function OnboardCard({
  icon,
  type,
  title,
  complete,
  children,
}: OnboardCardProps) {
  return (
    <div
      role="listitem"
      aria-label={title}
      className="flex flex-col relative shadow-md shadow-stone-300 rounded-md"
    >
      <div className="flex relative justify-center items-center rounded-t-md bg-gray-100 h-[90px]">
        {icon}
        {complete ? (
          <span
            data-testid="icon-restaurant-complete"
            className="absolute top-3.5 right-3.5 bg-green-500 rounded-full"
          >
            <img src={checkIcon} alt="Complete" />
          </span>
        ) : null}
      </div>

      <div className="flex flex-col p-4">
        <Typography
          as="subtitle-2"
          className="text-gray-500 !font-normal mb-1 capitalize"
        >
          {type}
        </Typography>
        <Typography
          as="body-1"
          className="font-semibold mb-4 tracking-[0.031rem] line-clamp-2 h-12"
        >
          {title}
        </Typography>
        <div>{children}</div>
      </div>
    </div>
  );
}
