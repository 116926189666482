import { format, parseISO } from "date-fns";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { PERMISSIONS_USER } from "../../../../constants/permissions";
import { usePermissions } from "../../../../hooks/permissions/usePermissions";
import { UserInvitationInterface } from "../../types";

interface InvitationsCardProps {
  invitation: UserInvitationInterface;
  onDeleteInvitation: (invitation: UserInvitationInterface) => void;
}

export default function InvitationsCard({
  invitation,
  onDeleteInvitation,
}: InvitationsCardProps) {
  const { hasPermission } = usePermissions();

  return (
    <div className="flex flex-col  justify-between border rounded-md drop-shadow-md px-4 py-4 bg-white mb-3">
      <div className="flex flex-col justify-between mb-3">
        <div className="flex flex-col mb-2">
          <span className="text-xs text-gray-500">Email</span>
          <span className="block truncate w-full text-sm">
            {invitation.email}
          </span>
        </div>
      </div>
      <div className="flex flex-row space-x-2">
        <div className="flex flex-col w-1/2 mb-2 items-start">
          <span className="text-xs text-gray-500">Organization</span>
          <span className="block truncate w-full text-sm">
            {invitation.regions.length && invitation.sites.length === 0
              ? invitation.regions.map((region) => region.name).join(", ")
              : invitation.sites.map((site) => site.name).join(", ")}
          </span>
        </div>
        <div className="flex flex-col w-1/2  mb-2 items-start">
          <span className="text-xs text-gray-500">Role</span>
          <span className="block truncate w-full text-sm">
            {invitation.roles.map((role) => role.name).join(", ")}
          </span>
        </div>
      </div>

      <div className="flex flex-row space-x-2">
        <div className="flex flex-col w-1/2 mb-2 items-start">
          <span className="text-xs text-gray-500 mr-2">Invited</span>
          <span className="block truncate w-full text-sm">
            {format(parseISO(invitation.invitedAt), "MMM d, y")}
          </span>
        </div>
        <div className="flex flex-col w-1/2  mb-2  items-start">
          <span className="text-xs text-gray-500">Expires</span>
          <span className="block truncate w-full text-sm">
            {format(parseISO(invitation.expiresAt), "MMM d, y")}
          </span>
        </div>
      </div>

      <div className="flex justify-start mt-5">
        {hasPermission(PERMISSIONS_USER.DELETE) && (
          <PrimaryButton
            onClick={() => onDeleteInvitation(invitation)}
            className="text-sm !px-4 !py-2 mr-2"
          >
            Delete
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}
