import { useMemo } from "react";
import { FieldRenderProps } from "react-final-form";
import SearchCheckboxDropdown from "../../../../components/SearchCheckboxDropdown";
import { SearchCheckboxDropdownOptionType } from "../../../../components/SearchCheckboxDropdown/SearchCheckboxDropdownOption";
import useUserSites from "../../../../hooks/sites/useUserSites";

export default function SiteSelector({
  disabled,
  input,
  meta,
}: FieldRenderProps<any[]> & {
  disabled?: boolean;
}) {
  const { data: sitesData } = useUserSites();

  const sitesOptions: SearchCheckboxDropdownOptionType[] = useMemo(
    () =>
      sitesData
        ? sitesData.map((site) => ({ label: site.name, value: site.id }))
        : [],
    [sitesData]
  );

  return (
    <SearchCheckboxDropdown
      label="Select site"
      placeholder="Select site"
      input={input}
      meta={meta}
      options={sitesOptions}
      enableSelectAll={true}
      disabled={disabled}
    />
  );
}
