import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import { format } from "date-fns";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";

interface OrderCardProps {
  orderId: number;
  phone: string | undefined;
  total: number;
  channel: string;
  orderDate: string;
  onRefund: () => void;
  onReportIssue: () => void;
}

export default function OrderCard({
  orderId,
  phone,
  total,
  channel,
  orderDate,
  onRefund,
  onReportIssue,
}: OrderCardProps) {
  return (
    <div className="flex flex-col xl:flex-row justify-start lg:justify-between border rounded-md drop-shadow-md px-4 py-4 bg-white mb-3">
      <div className="flex flex-col lg:flex-row lg:justify-between mb-3 xl:mb-0">
        <div className="flex flex-col min-[1440px]:flex-row  mb-2 mr-2 items-start xl:items-center md:mb-0 sm:mr-4 xl:mr-8 2xl:mr-12">
          <span className="text-xs text-gray-500 mr-2">Order ID:</span>
          {orderId}
        </div>
        <div className="flex flex-col min-[1440px]:flex-row mb-2 mr-2 items-start xl:items-center md:mb-0 sm:mr-4 xl:mr-8 2xl:mr-12">
          <span className="text-xs text-gray-500 mr-2">Last-4 Phone #:</span>
          {phone ? phone : "N/A"}
        </div>
        <div className="flex flex-col min-[1440px]:flex-row mb-2 mr-2  items-start xl:items-center  md:mb-0 sm:mr-4 xl:mr-8 2xl:mr-12">
          <span className="text-xs text-gray-500 mr-2">Channel:</span>
          <span className="capitalize">{channel}</span>
        </div>
        <div className="flex flex-col min-[1440px]:flex-row mb-2 mr-2 items-start xl:items-center md:mb-0 sm:mr-4 xl:mr-8 2xl:mr-12">
          <span className="text-xs text-gray-500 mr-2">Order Date:</span>
          {format(new Date(orderDate), "MM/dd/yyyy")}
        </div>
        <div className="flex flex-col min-[1440px]:flex-row mb-2 mr-2 items-start xl:items-center md:mb-0 sm:mr-4 xl:mr-8 2xl:mr-12">
          <span className="text-xs text-gray-500 mr-2">Total:</span>$
          {total.toFixed(2)}
        </div>
      </div>

      <div className="flex justify-start xl:ml-4">
        <SecondaryButton
          onClick={onReportIssue}
          className="text-sm !px-4 !py-2 xl:mr-3 order-2 xl:order-1"
        >
          Report Issue
        </SecondaryButton>
        <PrimaryButton
          className="text-sm !px-4 !py-2 order-1 xl:order-2 mr-3 xl:mr-0"
          onClick={onRefund}
        >
          View Details
        </PrimaryButton>
      </div>
    </div>
  );
}
