import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import CheckboxDropdown from "../../../../components/CheckboxDropdown";
import FormControl from "../../../../components/Forms/FormControl";
import InputField from "../../../../components/Forms/InputField";
import { TrashcanIcon } from "../../../../components/Icons";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../components/SidePanel/SidePanelLayout";
import Tabs from "../../../../components/Tabs";
import Typography from "../../../../components/Typography";
import useUserRegions from "../../../../hooks/regions/useUserRegions";
import useConnectRoles from "../../../../hooks/roles/useConnectRoles";
import { useValidationSchema } from "../../../../hooks/validations/useValidationSchema";
import { UpdateUserFormValuesIntefarce, UserInterface } from "../../types";
import { validateUser } from "../../validation/validateUser";
import RoleGroup from "../RoleGroup";
import SiteSelector from "../SiteSelector";

const TAB_BY_REGION = "by-region";
const TAB_BY_SITE = "by-site";

interface EditUserSidePanelProps extends SidePanelVisibilityProps {
  onUpdateUser: (values: UpdateUserFormValuesIntefarce) => void;
  onDeleteUser: (user: UserInterface) => void;
  user: UserInterface | null;
}

export default function EditUserSidePanel({
  open,
  user,
  onUpdateUser,
  onDeleteUser,
  onClose,
}: EditUserSidePanelProps) {
  const { data: roleGroups, isLoading: isLoadingRoleGroups } =
    useConnectRoles();

  const { data: regions } = useUserRegions();

  let assignedRegions = regions
    ? regions.map((assignedRegion) => ({
      ...assignedRegion,
      disabled: false,
    }))
    : [];

  // combinedRegions is array of the regions that the user were are editing has
  // plus the regions we have
  // the regions that are assigned to the user and we dont have access to should be disabled
  let combinedRegions = [...assignedRegions];

  user?.regions.forEach((userRegion) => {
    if (!assignedRegions.find((region) => region.id === userRegion.id)) {
      combinedRegions = combinedRegions.concat({
        ...userRegion,
        disabled: true,
      });
    }
  });

  let regionsOptions = combinedRegions?.map((region) => ({
    label: region.name,
    value: region.id,
    disabled: region.disabled,
  }));

  const validate = useValidationSchema(validateUser);

  const formInitialValues = useMemo(
    () =>
      user
        ? {
          ...user,
          roles: user
            ? user.roles.reduce((acc, role) => {
              const roleGroup = roleGroups?.find((group) =>
                group.roles.find((groupRole) => groupRole.id === role.id)
              );

              if (!roleGroup) {
                return {
                  ...acc,
                };
              }
              return {
                ...acc,
                [roleGroup.name]: role.id,
              };
            }, {})
            : undefined,
          regions:
            user && user.regions.length > 0 && user.sites.length === 0
              ? user.regions.map((region) => region.id)
              : undefined,
          sites:
            user && user.regions.length === 0 && user.sites.length > 0
              ? user.sites.map((site) => site.id)
              : undefined,
        }
        : {},
    [user, roleGroups]
  );

  const defaultPermissionsTab =
    user?.sites && user?.sites.length > 0 ? TAB_BY_SITE : TAB_BY_REGION;

  return (
    <SidePanel open={open} onClose={onClose}>
      {user && regions && (
        <Form
          initialValues={formInitialValues}
          onSubmit={onUpdateUser}
          validate={validate}
          subscription={{
            submitting: true,
          }}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={handleSubmit}
              className="h-full"
              id="edit-user-form"
            >
              <SidePanelLayout>
                <SidePanelHeader title="Edit user" onClose={onClose} />

                <SidePanelContent>
                  <div className="pb-2 mb-8 border-b border-b-gray-300">
                    <Field<string>
                      name="email"
                      render={({ input, meta }) => (
                        <FormControl>
                          <InputField
                            label="Email"
                            placeholder="Add email"
                            input={input}
                            meta={meta}
                            disabled={true}
                          />
                        </FormControl>
                      )}
                    />
                  </div>

                  <div className="mb-8 border-b border-b-gray-300">
                    <Typography as="h6" className="font-bold mb-4">
                      Store permissions
                    </Typography>
                    <Tabs
                      defaultTab={defaultPermissionsTab}
                      items={[
                        {
                          id: TAB_BY_REGION,
                          header: "By region",
                          content: (
                            <div className="-mb-3">
                              <Field<string[]>
                                name="regions"
                                render={({ input, meta }) => (
                                  <CheckboxDropdown
                                    label="Select region"
                                    input={input}
                                    meta={meta}
                                    options={regionsOptions}
                                  />
                                )}
                              />

                              <Field
                                name="validateSites"
                                initialValue={false}
                                render={() => null}
                              />
                            </div>
                          ),
                        },
                        {
                          id: TAB_BY_SITE,
                          header: "By site",
                          content: (
                            <div className="flex flex-col -mb-3">
                              <Field<boolean>
                                name="validateSites"
                                initialValue={true}
                                validateFields={[]}
                                render={() => null}
                              />

                              <Field<string[]>
                                name="sites"
                                render={({ input, meta }) => (
                                  <SiteSelector input={input} meta={meta} />
                                )}
                              />
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                  <div className="flex flex-col mb-4 ">
                    <Typography as="h6" className="font-bold mb-6">
                      Role permissions
                    </Typography>

                    <Field
                      name="roles"
                      subscription={{ touched: true, error: true }}
                      render={({ meta }) => (
                        <Typography
                          as="body-2"
                          className={`font-bold mb-4 ${meta.touched && !!meta.error ? "text-red-500" : ""
                            }`}
                        >
                          Required - Please add at least one role.
                        </Typography>
                      )}
                    />

                    {!isLoadingRoleGroups && roleGroups ? (
                      <div className="space-y-4">
                        {roleGroups.map((group) => (
                          <RoleGroup key={group.name} roleGroup={group} />
                        ))}
                      </div>
                    ) : null}
                  </div>
                </SidePanelContent>
                <SidePanelFooter>
                  <div className="flex items-center justify-between">
                    <div className="w-1/4">
                      <button
                        onClick={() => onDeleteUser(user)}
                        type="button"
                        className="flex text-error-500"
                      >
                        <TrashcanIcon className="h-6 w-6 mr-2" /> Delete
                      </button>
                    </div>

                    <div className="flex w-3/4">
                      <SecondaryButton
                        className="w-full mr-1"
                        onClick={onClose}
                      >
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton
                        type="submit"
                        className="w-full ml-1"
                        disabled={submitting}
                        isLoading={submitting}
                        loadingText="Updating user..."
                      >
                        Save
                      </PrimaryButton>
                    </div>
                  </div>
                </SidePanelFooter>
              </SidePanelLayout>
            </form>
          )}
        />
      )}
    </SidePanel>
  );
}
