import { useMutation } from "@tanstack/react-query";
import { createSitePaymentConfig } from "../../services/sites";

export default function useCreateSitePaymentConfig() {
  return useMutation(
    ({
      siteId,
      kiosk,
      mobile,
    }: {
      siteId: string;
      kiosk: {
        gateway: string;
        processor: string;
        config: Record<string, unknown>;
      };
      mobile: {
        gateway: string;
        processor: string;
        config: Record<string, unknown>;
      };
    }) => createSitePaymentConfig(siteId, kiosk, mobile)
  );
}
