export const ORGANIZATION_REGIONS_QUERY_KEY = "organization-regions";

export const USERS_SITES_QUERY_KEY = "user-sites";
export const USERS_REGIONS_QUERY_KEY = "user-regions";

export const ORGANIZATION_SITE_QUERY_KEY = "organization-active-sites";

export const ORGANIZATION_ONBOARD_SITE_QUERY_KEY = "organization-onboard-sites";

export const ORGANIZATION_STORES_QUERY_KEY = "organizations-stores";

export const ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY =
  "organization-site-config";

export const ORGANIZATION_PAYMENT_PROVIDERS_QUERY_KEY =
  "organization-payment-providers";

export const ORGANIZATION_PAYMENT_GATEWAY_FIELDS_QUERY_KEY =
  "organization-payment-gateway-fields";

export const ORGANIZATION_PAYMENT_CURRENCIES_QUERY_KEY =
  "organization-payment-currencies";

export const ORGANIZATION_STORE_MOBILE_SETUP_QUERY_KEY = "store-mobile-setup";
export const ORGANIZATION_STORE_KIOSK_SETUP_QUERY_KEY = "store-kiosk-setup";
