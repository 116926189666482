import { useSearchParams } from "react-router-dom";
import SecondaryButton from "../Buttons/SecondaryButton";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
}
export default function Pagination({
  currentPage,
  totalPages,
}: PaginationProps) {
  const [, setQueryParams] = useSearchParams();

  const previousPage = currentPage - 1 >= 1 ? currentPage - 1 : 1;
  const nextPage = currentPage + 1 <= totalPages ? currentPage + 1 : totalPages;

  const onChangePage = (page: number) => {
    setQueryParams((params) => {
      params.set("page", page.toString());
      return params;
    });
  };

  return (
    <div className="flex space-x-2 justify-end items-center">
      <span role="presentation" className="flex align-middle items-center">
        Page {currentPage} of {totalPages}
      </span>
      <SecondaryButton
        className="py-1.5 px-4"
        onClick={() => onChangePage(previousPage)}
        disabled={currentPage === 1}
      >
        Previous
      </SecondaryButton>
      <SecondaryButton
        className="py-1.5 px-4"
        onClick={() => onChangePage(nextPage)}
        disabled={currentPage === totalPages}
      >
        Next
      </SecondaryButton>
    </div>
  );
}
