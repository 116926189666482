import { useMutation } from "@tanstack/react-query";
import { updateActiveSite } from "../../services/sites";

export default function useUpdateActiveSite() {
  return useMutation(
    ({
      siteId,
      region,
      location,
      colorConfig,
    }: {
      siteId: string;
      region: string;
      location: {
        lat: string;
        long: string;
      };
      colorConfig: {
        palette: Record<string, string>;
        fontPalette: Record<string, string>;
      };
    }) => updateActiveSite(siteId, region, location, colorConfig)
  );
}
