import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../../components/SidePanel/SidePanelLayout";
import Switch from "../../../../../components/Switch/Switch";
import Typography from "../../../../../components/Typography";
import { StoreInterface } from "../../../types";

import mobileIcon from "../../assets/mobileIcon.svg";
import kioskIcon from "../../assets/kioskIcon.svg";
import useUpdateStoreMobileOrderingActive from "../../../../../hooks/stores/useUpdateStoreMobileOrderingActive";
import useUpdateStoreKioskOrderingActive from "../../../../../hooks/stores/useUpdateStoreKioskOrderingActive";
import { toast } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { STORE_QUERY_KEY } from "../../../constants";
import { usePermissions } from "../../../../../hooks/permissions/usePermissions";
import { PERMISSIONS_OPS_MANAGEMENT } from "../../../../../constants/permissions";

interface OrderAvailabilitySidePanelProps extends SidePanelVisibilityProps {
  store: StoreInterface;
  orgId: string | undefined;
}

export default function OrderAvailabilitySidePanel({
  open,
  store,
  orgId,
  onClose,
}: OrderAvailabilitySidePanelProps) {
  const queryClient = useQueryClient();

  const { hasPermission } = usePermissions();

  const canChangeMobile = !hasPermission(
    PERMISSIONS_OPS_MANAGEMENT.MOBILE_ENABLE
  );

  const canChangeKiosk = !hasPermission(
    PERMISSIONS_OPS_MANAGEMENT.KIOSK_ENABLE
  );

  const updateMobileActive = useUpdateStoreMobileOrderingActive();

  const updateKioskActive = useUpdateStoreKioskOrderingActive();

  const onSubmit = async (values: {
    isActiveMobile: boolean;
    isActiveKiosk: boolean;
  }) => {
    let mobilePromise = null;
    let kioskPromise = null;

    if (store.isActiveMobile !== values.isActiveMobile) {
      mobilePromise = await updateMobileActive
        .mutateAsync({ storeId: store.id, active: values.isActiveMobile })
        .then(() => {
          toast.success(
            `Mobile ordering successfully turned ${values.isActiveMobile ? "on" : "off"
            }.`
          );
        })
        .catch(() => {
          toast.error(
            `Failed to turn ${values.isActiveMobile ? "on" : "off"
            } Mobile ordering. Please try again.`
          );
        });
    }

    if (store.isActiveKiosk !== values.isActiveKiosk) {
      kioskPromise = await updateKioskActive
        .mutateAsync({ storeId: store.id, active: values.isActiveKiosk })
        .then(() => {
          toast.success(
            `Kiosk ordering successfully turned ${values.isActiveKiosk ? "on" : "off"
            }.`
          );
        })
        .catch(() => {
          toast.error(
            `Failed to turn ${values.isActiveKiosk ? "on" : "off"
            } Kiosk ordering. Please try again.`
          );
        });
    }

    return Promise.allSettled([mobilePromise, kioskPromise])
      .then(() => {
        queryClient.invalidateQueries([STORE_QUERY_KEY, orgId, store.id]);
        onClose();
        return;
      })
      .catch(() => {
        queryClient.invalidateQueries([STORE_QUERY_KEY, orgId, store.id]);
        return;
      });
  };

  const initialValues = useMemo(
    () => ({
      isActiveMobile: store.isActiveMobile,
      isActiveKiosk: store.isActiveKiosk,
    }),
    [store]
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={{ submitting: true }}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader
                title="Order availability"
                subtitle={store.name}
                onClose={onClose}
              />

              <SidePanelContent>
                <div className="flex-col bg-gray-50 p-4 rounded-lg mb-4">
                  <div className="flex">
                    <div className="flex justify-center items-center w-12 h-12 mr-4 rounded-lg bg-white border border-gray-300">
                      <img
                        src={mobileIcon}
                        className="w-4"
                        alt="Mobile ordering"
                      />
                    </div>

                    <div className="flex-1 flex-row">
                      <Typography as="body-2" className="font-semibold mb-1.5">
                        Mobile ordering
                      </Typography>
                      <span className="block text-xs text-gray-500">
                        <Field
                          name="isActiveMobile"
                          subscription={{ value: true }}
                          render={({ input }) =>
                            input.value === true ? (
                              <span>
                                Currently guests can place mobile orders.
                              </span>
                            ) : (
                              <span>
                                Currently guests can <strong>NOT</strong> place
                                mobile orders.
                              </span>
                            )
                          }
                        />
                      </span>
                    </div>

                    <div className="flex items-center">
                      <Field
                        name="isActiveMobile"
                        type="checkbox"
                        render={({ input, meta }) => (
                          <Switch
                            label={input.checked ? "On" : "Off"}
                            labelPosition="left"
                            input={input}
                            meta={meta}
                            disabled={canChangeMobile}
                            className="mb-0"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-col bg-gray-50 p-4 rounded-lg">
                  <div className="flex">
                    <div className="flex justify-center items-center w-12 h-12 mr-4 rounded-lg bg-white border border-gray-300">
                      <img
                        src={kioskIcon}
                        className="w-8 h-8"
                        alt="Kiosk ordering"
                      />
                    </div>
                    <div className="flex-1 flex-row">
                      <Typography as="body-2" className="font-semibold mb-1.5">
                        Kiosk ordering
                      </Typography>
                      <span className="block text-xs text-gray-500 ">
                        <Field
                          name="isActiveKiosk"
                          subscription={{ value: true }}
                          render={({ input }) =>
                            input.value === true ? (
                              <span>
                                Currently guests can place kiosk orders.
                              </span>
                            ) : (
                              <span>
                                Currently guests can <strong>NOT</strong> place
                                kiosk orders.
                              </span>
                            )
                          }
                        />
                      </span>
                    </div>

                    <div className="flex items-center">
                      <Field
                        name="isActiveKiosk"
                        type="checkbox"
                        render={({ input, meta }) => (
                          <Switch
                            label={input.checked ? "On" : "Off"}
                            labelPosition="left"
                            input={input}
                            meta={meta}
                            disabled={canChangeKiosk}
                            className="mb-0"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </SidePanelContent>

              <SidePanelFooter>
                <div className="flex justify-evenly">
                  <SecondaryButton onClick={onClose} className="w-full mr-1">
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    className="w-full ml-1"
                    type="submit"
                    disabled={submitting}
                    isLoading={submitting}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
