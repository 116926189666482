import React, { useState } from "react";
import { LogoutIcon, CloseIcon, MenuIcon, HelpOutlineIcon } from "../../Icons";
import SignoutPromptModal from "./SignoutPromptModal";
// import SupportSidePanel from "../../../features/Support/components/SupportSidePanel";
// import MobileMenu from "./MobileMenu";
// import MobileMenuButton from "./MobileMenuButton";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import SupportSidePanel from "../../../features/Support/components/SupportSidePanel";

interface NavbarProps {
  sidebarOpen: boolean;
  onToggleSidebar: () => void;
  sidebarDisabled?: boolean;
  children?: React.ReactNode;
  brandLogo: React.ReactNode;
}

export default function Navbar({
  sidebarOpen,
  onToggleSidebar,
  sidebarDisabled = false,
  brandLogo,
  children,
}: NavbarProps): JSX.Element {
  const { logout } = useAuth0();

  const [showSignoutModal, setShowSignoutModal] = useState(false);
  const [showSupportSidePanel, setSupportSidePanel] = useState(false);

  const onShowSignoutModal = () => setShowSignoutModal(true);
  const onCloseSignoutModal = () => setShowSignoutModal(false);
  const toggleSupportSidePanel = () =>
    setSupportSidePanel(!showSupportSidePanel);
  const onCloseSupportSidePanel = () => setSupportSidePanel(false);

  // const [showMobileMenu, setShowMobileMenu] = useState(false);
  // const onToggleMenu = () => {
  //   setShowMobileMenu(!showMobileMenu);
  // };

  return (
    <nav className="bg-black fixed z-10 w-full top-0 left-0 right-0 px-4 md:px-6">
      <div className="flex items-center justify-between h-[60px]">
        <div className="flex items-center">
          {!sidebarDisabled && (
            <div className="md:hidden">
              {/* <MobileMenuButton */}
              {/*  show={showMobileMenu} */}
              {/*  onToggleMenu={onToggleMenu} */}
              {/* /> */}
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 -ml-2.5 rounded-md text-white focus:outline-none"
                aria-controls="sidebar-menu"
                aria-expanded={sidebarOpen}
                onClick={onToggleSidebar}
              >
                <span className="sr-only">
                  {sidebarOpen ? "Close" : "Open"} main menu
                </span>

                {!sidebarOpen ? (
                  <MenuIcon
                    className="block h-6 w-6"
                    data-testid="sidebar-menu-icon"
                  />
                ) : (
                  <CloseIcon
                    className="block h-6 w-6"
                    data-testid="sidebar-close-icon"
                  />
                )}
              </button>
            </div>
          )}
          <Link
            to="/"
            className="flex-shrink-0 text-white text-center md:pl-2 items-center"
          >
            {brandLogo}
          </Link>
        </div>

        <div className="flex justify-end items-center -mr-1.5">
          {children && (
            <div className="block">
              <div className="flex items-baseline space-x-4">{children}</div>
            </div>
          )}
          <button
            type="button"
            className="max-w-xs text-white p-1 rounded-full flex items-center text-sm focus:outline-none"
            data-testid="support-button"
            onClick={toggleSupportSidePanel}
          >
            <HelpOutlineIcon className="w-6 h-6" />
            <span className="ml-1">Help</span>
          </button>
          <button
            type="button"
            className="max-w-xs text-white p-1 rounded-full flex items-center text-sm focus:outline-none ml-2"
            data-testid="signout-button"
            onClick={onShowSignoutModal}
          >
            <span className="sr-only">Open user menu</span>
            <LogoutIcon className="w-6 h-6" />
          </button>
        </div>
      </div>

      <SupportSidePanel
        open={showSupportSidePanel}
        onClose={onCloseSupportSidePanel}
      />

      {showSignoutModal && (
        <SignoutPromptModal
          onSignout={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
          onClose={onCloseSignoutModal}
        />
      )}

      {/* {showMobileMenu ? ( */}
      {/*  <MobileMenu */}
      {/*    user={{ */}
      {/*      name: account?.name, */}
      {/*      username: account?.username, */}
      {/*    }} */}
      {/*    onSignOut={onSignOut} */}
      {/*  > */}
      {/*    {children} */}
      {/*  </MobileMenu> */}
      {/* ) : null} */}
    </nav>
  );
}
