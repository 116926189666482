import { InfoIcon } from "../../Icons";

interface InfoAlertProps {
  className?: string;
  children: React.ReactNode;
}
export default function InfoAlert({ className, children }: InfoAlertProps) {
  return (
    <div
      className={`flex border rounded-lg bg-gray-50 p-3 border-gray-300 text-black text-sm items-center ${className}`}
    >
      <div className="w-6 mr-2">
        <InfoIcon className="h-6 w-6" />
      </div>
      <div>{children}</div>
    </div>
  );
}
