import { useQuery } from "@tanstack/react-query";
import { ORGANIZATION_ONBOARD_SITE_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchSitesForOnboarding } from "../../services/sites";
import useOrgId from "../organizations/useOrgId";

export default function useSitesForOnboarding() {
  const orgId = useOrgId();
  return useQuery(
    [ORGANIZATION_ONBOARD_SITE_QUERY_KEY, orgId],
    () => fetchSitesForOnboarding(),
    {
      enabled: orgId !== undefined && orgId !== "",
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
