import { useState } from "react";
import BadgeDisabled from "../../../../components/Badges/BadgeDisabled";
import BadgeSuccess from "../../../../components/Badges/BadgeSuccess";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import FeatureWidget from "../../../../components/FeatureWidget";
import useStore from "../../../../hooks/stores/useStore";
import SpecialInstructionsSidePanel from "../SpecialInstructionsSidePanel";

interface SpecialInstructionsWidgetProps {
  storeId: string;
  orgId: string | undefined;
}

export default function SpecialInstructionsWidget({
  storeId,
  orgId,
}: SpecialInstructionsWidgetProps) {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const { data: store } = useStore(storeId);

  if (!store) {
    return null;
  }

  return (
    <div>
      <FeatureWidget
        title={
          <div className="featureStatus flex justify-between items-start">
            Special instructions
            {store.hasItemNotes ? (
              <BadgeSuccess>Enabled</BadgeSuccess>
            ) : (
              <BadgeDisabled>Disabled</BadgeDisabled>
            )}
          </div>
        }
        content={
          <div>Allow guests to leave special instructions on a menu item.</div>
        }
        action={
          <PrimaryButton className="w-full" onClick={onOpen}>
            Manage special instructions
          </PrimaryButton>
        }
      />

      <SpecialInstructionsSidePanel
        open={open}
        orgId={orgId}
        store={store}
        onClose={onClose}
      />
    </div>
  );
}
