import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import FormControl from "../../../../components/Forms/FormControl";
import SearchInput from "../../../../components/SearchInput";

interface SearchMembersFormProps {
  initialValues: {
    search: string;
  };
  onSubmit: (values: { search: string }) => void;
  onClear?: () => void;
}
export default function SearchMembersForm({
  initialValues,
  onSubmit,
  onClear,
}: SearchMembersFormProps) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field<string>
            name="search"
            parse={(value: string | undefined) => {
              if (!value) {
                return "";
              }

              return value.replaceAll(" ", "");
            }}
            render={({ input, meta }) => (
              <div className="flex justify-start">
                <div className="w-full md:w-80">
                  <FormControl>
                    <SearchInput
                      input={input}
                      meta={meta}
                      placeholder="Search by email"
                      autoComplete="off"
                      onClear={onClear}
                    />
                  </FormControl>
                </div>
                <PrimaryButton type="submit" className="ml-3 h-4 border-none">
                  Search
                </PrimaryButton>
              </div>
            )}
          />
        </form>
      )}
    />
  );
}
