import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { FieldRenderProps } from "react-final-form";

import DropdownOption, { DropdownOptionType } from "../Dropdown/DropdownOption";
import Label from "../Forms/Label";
import { ChevronDownIcon } from "../Icons";
import DropdownNoOptions from "../Dropdown/DropdownNoOptions";
import SearchInput from "../SearchInput";

type ReducerState = {
  options: DropdownOptionType[];
  filter: string;
  filteredOptions: DropdownOptionType[];
  selectedOption: DropdownOptionType | undefined;
  selectedIndex: number;
  activeIndex: number;
};

type InitAction = {
  type: "init";
  payload: ReducerState;
};

type FilterAction = {
  type: "filter";
  payload: string;
};

type SetActiveIndexAction = {
  type: "set_active_index";
  payload: number;
};

type SetSelectedIndexAction = {
  type: "set_selected_index";
  payload: number;
};

type SetActiveToSelectedIndex = {
  type: "set_active_to_selected_index";
};

type ReducerAction =
  | InitAction
  | FilterAction
  | SetActiveIndexAction
  | SetSelectedIndexAction
  | SetActiveToSelectedIndex;

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case "init":
      return {
        ...state,
        ...action.payload,
      };
    case "filter":
      const filteredOptions =
        action.payload === ""
          ? state.options
          : state.options.filter((option) =>
            option.label.toLowerCase().includes(action.payload.toLowerCase())
          );

      let nextActiveIndex = state.activeIndex;

      // if the user clear outs the search field or theres more than one result
      // change the active index to the selected index
      if (action.payload === "" || filteredOptions.length > 1) {
        nextActiveIndex = state.selectedIndex;
      }
      // if theres a single result after search show that as active
      else if (action.payload !== "" && filteredOptions.length === 1) {
        nextActiveIndex = 0;
      }

      return {
        ...state,
        filter: action.payload,
        filteredOptions,
        activeIndex: nextActiveIndex,
      };

    case "set_selected_index":
      return {
        ...state,
        selectedIndex: action.payload,
        selectedOption: state.options.at(action.payload),
      };

    case "set_active_index":
      return {
        ...state,
        activeIndex: action.payload,
      };

    case "set_active_to_selected_index":
      return {
        ...state,
        activeIndex: state.selectedIndex,
      };

    default:
      return state;
  }
}

interface SearchDropdownProps {
  label?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  options: DropdownOptionType[];
  error?: string | undefined;
  helperText?: string;
  noOptionsText?: React.ReactNode;
}

export default function SearchDropdown({
  input,
  meta,
  label,
  options,
  helperText,
  noOptionsText,
  ...rest
}: FieldRenderProps<string | number, any> & SearchDropdownProps) {
  const [show, setShow] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const searchWrapper = useRef<HTMLDivElement>(null);
  const listWrapperRef = useRef<HTMLUListElement>(null);

  const initialSelectedIndex = options.findIndex(
    (option) => option.value === input.value
  );
  const initialSelectedOption = options.find(
    (option) => option.value === input.value
  );
  const [state, dispatch] = useReducer(reducer, {
    options,
    filter: "",
    filteredOptions: options,
    selectedOption: initialSelectedOption,
    selectedIndex: initialSelectedIndex,
    activeIndex: initialSelectedIndex,
  });

  useEffect(
    () =>
      dispatch({
        type: "init",
        payload: {
          options,
          filter: "",
          filteredOptions: options,
          selectedOption: initialSelectedOption,
          selectedIndex: initialSelectedIndex,
          activeIndex: initialSelectedIndex,
        },
      }),
    [options, initialSelectedIndex, initialSelectedOption]
  );

  const onToggle = () => {
    setShow(!show);
    dispatch({
      type: "set_active_to_selected_index",
    });
  };

  const onSelect = (value: string | number) => {
    input.onChange(value);
    input.onBlur();
    const index = state.options.findIndex((option) => option.value === value);
    dispatch({
      type: "set_selected_index",
      payload: index,
    });
    dispatch({
      type: "set_active_index",
      payload: index,
    });
    setShow(false);

    dispatch({
      type: "filter",
      payload: "",
    });
  };

  useEffect(() => {
    const onClickAway = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      !wrapperRef.current?.contains(target) && setShow(false);
    };

    document.addEventListener("mousedown", onClickAway);
    return () => document.removeEventListener("mousedown", onClickAway);
  }, [setShow]);

  const scrollToSelectedIndex = useCallback(
    (selectedIndex: number) => {
      const option = state.filteredOptions.at(selectedIndex);
      if (option) {
        const element = document.getElementById(
          `${input.name}-${option.value}-${option.label}`
        );

        if (element && listWrapperRef.current) {
          element.scrollIntoView({ block: "center" });
        }
      }
    },
    [state.filteredOptions, input.name]
  );

  useLayoutEffect(() => {
    if (show) {
      if (state.selectedIndex > -1) {
        scrollToSelectedIndex(state.selectedIndex);
      }
    }
  }, [show, state.selectedIndex, scrollToSelectedIndex]);

  const handleKeyboard = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (!show) {
      return;
    }

    let nextActiveIndex = state.activeIndex;

    switch (ev.code) {
      case "ArrowDown":
        if (state.activeIndex === -1) {
          nextActiveIndex = 0;
          dispatch({
            type: "set_active_index",
            payload: nextActiveIndex,
          });
          return;
        }
        if (state.activeIndex + 1 > state.filteredOptions.length - 1) {
          nextActiveIndex = 0;
          dispatch({
            type: "set_active_index",
            payload: nextActiveIndex,
          });
        } else {
          nextActiveIndex = state.activeIndex + 1;
          dispatch({
            type: "set_active_index",
            payload: nextActiveIndex,
          });
        }
        break;

      case "ArrowUp":
        if (state.activeIndex === -1) {
          nextActiveIndex = state.filteredOptions.length - 1;
          dispatch({
            type: "set_active_index",
            payload: nextActiveIndex,
          });
          return;
        }
        if (state.activeIndex - 1 < 0) {
          nextActiveIndex = state.filteredOptions.length - 1;
          dispatch({
            type: "set_active_index",
            payload: nextActiveIndex,
          });
        } else {
          nextActiveIndex = state.activeIndex - 1;
          dispatch({
            type: "set_active_index",
            payload: nextActiveIndex,
          });
        }
        break;

      case "Enter":
      case "Space":
        if (show) {
          ev.preventDefault();
        }
        if (state.activeIndex > -1) {
          const element = state.filteredOptions.at(state.activeIndex);

          if (element) {
            onSelect(element.value);
            buttonRef.current?.focus();
          }
        }
        break;

      case "Escape":
        dispatch({
          type: "filter",
          payload: "",
        });

        setShow(false);
        dispatch({
          type: "set_selected_index",
          payload: state.selectedIndex,
        });
        dispatch({
          type: "set_active_index",
          payload: state.selectedIndex,
        });
        break;
    }

    scrollToSelectedIndex(nextActiveIndex);
  };

  const placeholder = rest.placeholder ? rest.placeholder : "Select...";
  const isDisabled = rest.disabled || false;
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  return (
    <div className="flex flex-col align-top relative" ref={wrapperRef}>
      {label && (
        <Label htmlFor={`dropdown-${input.name}`} disabled={isDisabled}>
          {label}
        </Label>
      )}

      <div className="relative" onKeyDown={handleKeyboard}>
        <button
          type="button"
          className={`relative w-full bg-white border border-gray-300 rounded-lg px-3 py-2.5 mb-0.5 text-left cursor-pointer focus:ring-1 focus:ring-cinchio-blue-500 focus:outline-0  focus:border-cinchio-blue-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-100
           ${hasError
              ? "text-red-500 ring-1 ring-error-500 border-error-500 disabled:border-error-500"
              : ""
            }`}
          aria-haspopup="listbox"
          aria-expanded={show}
          aria-label={`dropdown-${input.name}`}
          id={`dropdown-${input.name}`}
          onClick={() => onToggle()}
          disabled={isDisabled}
          ref={buttonRef}
        >
          <div className="relative">
            <span className="flex items-center">
              <span className="dropdownSelection block truncate pr-5">
                {state.selectedOption && state.selectedOption.value !== "" ? (
                  state.selectedOption.label
                ) : (
                  <span
                    className={isDisabled ? "text-gray-300" : "text-gray-400"}
                  >
                    {placeholder}
                  </span>
                )}{" "}
              </span>
            </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pointer-events-none">
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-500 transition-transform  ${show && "rotate-180"
                  }`}
              />
            </span>
          </div>
        </button>

        {show && (
          <div className="absolute z-10 mt-1 w-full bg-white  rounded-lg text-base border border-gray-300  focus:outline-none drop-shadow-md">
            <div
              ref={searchWrapper}
              className="p-2 bg-gray-50 border-b border-b-gray-300 rounded-t-lg"
            >
              <SearchInput
                placeholder="Search..."
                input={{
                  name: "search",
                  value: state.filter,
                  onChange: (e) => {
                    dispatch({
                      type: "filter",
                      payload: e.target ? e.target.value : e,
                    });
                  },
                  onFocus: () => null,
                  onBlur: () => null,
                  onKeyDown: (e: KeyboardEvent) => {
                    if (e.code === "Escape") {
                      dispatch({
                        type: "filter",
                        payload: "",
                      });

                      setShow(false);
                      dispatch({
                        type: "set_active_index",
                        payload: state.selectedIndex,
                      });
                      buttonRef.current?.focus();
                    }
                  },
                }}
                meta={{}}
                autoComplete="off"
                onClear={() => {
                  dispatch({
                    type: "filter",
                    payload: "",
                  });
                }}
              />
            </div>
            <ul
              ref={listWrapperRef}
              className="max-h-56 rounded-lg overflow-auto outline-transparent focus-visible:!outline-cinchio-blue-500 focus-visible:border-[2px]"
              tabIndex={0}
              role="listbox"
              aria-label={`dropdown-listbox-${input.name}`}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.code === "Space") {
                  e.preventDefault();
                  const option = state.filteredOptions.at(state.activeIndex);
                  if (option) {
                    onSelect(option.value);
                    buttonRef.current?.focus();
                  }
                } else if (e.code === "Escape") {
                  buttonRef.current?.focus();
                }
              }}
              onFocus={() => {
                dispatch({
                  type: "set_active_index",
                  payload: 0,
                });
              }}
              onBlur={() => {
                dispatch({
                  type: "set_active_index",
                  payload: state.selectedIndex,
                });
              }}
            >
              {state.filteredOptions.length > 0 ? (
                state.filteredOptions.map(
                  (option: DropdownOptionType, index: number) => (
                    <DropdownOption
                      id={`${input.name}-${option.value}-${option.label}`}
                      key={`${option.value}-${option.label}`}
                      value={option.value}
                      label={option.label}
                      tabIndex={-1}
                      selected={
                        option.value === state.selectedOption?.value ||
                        index === state.activeIndex
                      }
                      onSelect={onSelect}
                      disabled={option.disabled}
                    />
                  )
                )
              ) : (
                <DropdownNoOptions
                  text={noOptionsText ? noOptionsText : "No options available."}
                />
              )}
            </ul>
          </div>
        )}
        {hasError ? (
          <p className="text-xs text-red-500 h-6 absolute mt-0">
            {meta.error || meta.submitError}
          </p>
        ) : null}
        {!hasError && helperText ? (
          <p className="text-gray-500 text-sm mb-5">{helperText}</p>
        ) : null}
      </div>
    </div>
  );
}
