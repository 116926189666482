export interface DropdownOptionType {
  value: string | number;
  label: string;
  disabled?: boolean;
}

export interface DropdownOptionProps extends DropdownOptionType {
  selected: boolean;
  onSelect: (id: string | number) => void;
  [key: string]: any;
}

export default function DropdownOption({
  value,
  label,
  selected,
  disabled,
  onSelect,
  ...rest
}: DropdownOptionProps) {
  const isDisabled = disabled || false;

  return (
    <li
      value={value}
      className={`cursor-pointer select-none relative p-2.5 text-gray-900 bg-white hover:bg-gray-50 ${selected && "bg-gray-100 hover:bg-gray-100"
        } ${isDisabled && "disabled opacity-50"}`}
      role="option"
      aria-selected={selected}
      onClick={() => !isDisabled && onSelect(value)}
      {...rest}
    >
      <div className="flex items-center">
        <span className="font-normal block truncate text-base">{label}</span>
      </div>
    </li>
  );
}
