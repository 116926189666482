import { useQuery } from "@tanstack/react-query";
import { ORGANIZATION_REGIONS_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchOrganizationRegions } from "../../services/organization";
import useOrgId from "../organizations/useOrgId";

export default function useOrganizationRegions() {
  const orgId = useOrgId();
  return useQuery(
    [ORGANIZATION_REGIONS_QUERY_KEY, orgId],
    () => fetchOrganizationRegions(),
    {
      enabled: orgId !== undefined && orgId !== "",
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
