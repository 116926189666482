import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

const ENV = window.DATADOG_ENV;
const SERVICE = window.DATADOG_SERVICE;

const DATADOG_APP_ID = "6be8fa54-fb18-4bd0-8383-2ac08e40cc13";
const DATADOG_CLIENT_TOKEN = "pub665229263c222674ca01648cad1610a6";

const DATADOG_SITE = "us5.datadoghq.com";

export function dataDogLogsInit() {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    sessionSampleRate: 100,
    env: ENV,
    service: SERVICE,
  });

  datadogRum.init({
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: SERVICE,
    env: ENV,
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    replaySampleRate: 0,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}