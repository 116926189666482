import { Outlet } from "react-router-dom";
import FeatureContentWrapper from "../../components/FeatureContentWrapper";

export default function Organizations() {
  return (
    <FeatureContentWrapper>
      <Outlet />
    </FeatureContentWrapper>
  );
}
