import { RestaurantIcon } from "../../../../../components/Icons";
import useStoreKioskSetup from "../../../../../hooks/stores/useStoreKioskSetup";
import useStoreMobileSetup from "../../../../../hooks/stores/useStoreMobileSetup";
import OnboardCard from "../../../components/OnboardCard";
import { OrganizationStoreInterface } from "../../../types";
import StoreChannelBadge from "../StoreChannelBadge/StoreChannelBadge";

interface StoreCardProps {
  store: OrganizationStoreInterface;
  children: React.ReactNode;
}

export default function StoreCard({ store, children }: StoreCardProps) {
  const { data: storeMobileSetup, isLoading: isLoadingMobileSetup } =
    useStoreMobileSetup(store.id);
  const { data: storeKioskSetup, isLoading: isLoadingKioskSetup } =
    useStoreKioskSetup(store.id);

  return (
    <OnboardCard
      icon={<RestaurantIcon className="w-8 h-8" />}
      type="store"
      title={store.name}
    >
      <div className="flex justify-between items-end h-[49px]">
        {children}

        <div className="flex space-x-8">
          {!isLoadingMobileSetup &&
            storeMobileSetup &&
            storeMobileSetup.isOnboarded && (
              <StoreChannelBadge
                title="Mobile"
                type="mobile"
                isOnboarded={store.isOnboarded}
              />
            )}

          {!isLoadingKioskSetup &&
            storeKioskSetup &&
            storeKioskSetup.isOnboarded && (
              <StoreChannelBadge
                title="Kiosk"
                type="kiosk"
                isOnboarded={store.isOnboarded}
              />
            )}
        </div>
      </div>
    </OnboardCard>
  );
}
