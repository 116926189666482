import { useMatch } from "react-router-dom";
import FeatureContentWrapper from "../../../components/FeatureContentWrapper";
import FeatureHeader from "../../../components/FeatureHeader";
import FeatureTitle from "../../../components/FeatureTitle";
import Tabs from "../../../components/Tabs";
import { ROUTES } from "../../../constants/routes";
import useSitesForOnboarding from "../../../hooks/sites/useSitesForOnboarding";
import ActiveSites from "./ActiveSites";
import OnboardSites from "./OnboardSites";

export default function SiteSetup() {
  // getting the sites to be onboarded is kinda slow
  // so prefetch
  useSitesForOnboarding();

  const matchActive = useMatch(
    ROUTES.TOP_LEVEL_ORG +
      "/" +
      ROUTES.ORGANIZATION +
      "/" +
      ROUTES.ORGANIZATION_SITE_SETUP +
      "/" +
      ROUTES.ORGANIZATION_SITE_SETUP_ACTIVE
  );
  const matchOnboard = useMatch(
    ROUTES.TOP_LEVEL_ORG +
      "/" +
      ROUTES.ORGANIZATION +
      "/" +
      ROUTES.ORGANIZATION_SITE_SETUP +
      "/" +
      ROUTES.ORGANIZATION_SITE_SETUP_ONBOARD
  );

  const defaultTab = matchActive
    ? ROUTES.ORGANIZATION_SITE_SETUP_ACTIVE
    : matchOnboard
    ? ROUTES.ORGANIZATION_SITE_SETUP_ONBOARD
    : ROUTES.ORGANIZATION_SITE_SETUP_ACTIVE;

  return (
    <FeatureContentWrapper>
      <div className="mb-8">
        <FeatureHeader>
          <div className="flex flex-grow items-center justify-between">
            <FeatureTitle className="mt-2">Site setup</FeatureTitle>
          </div>
        </FeatureHeader>
      </div>

      <div className="flex-auto flex flex-col">
        <Tabs
          defaultTab={defaultTab}
          items={[
            {
              id: ROUTES.ORGANIZATION_SITE_SETUP_ACTIVE,
              header: "Active sites",
              asRoute: true,
              route: `../${ROUTES.ORGANIZATION_SITE_SETUP_ACTIVE}`,
              content: <ActiveSites />,
            },
            {
              id: ROUTES.ORGANIZATION_SITE_SETUP_ONBOARD,
              header: "Onboard sites",
              asRoute: true,
              route: `../${ROUTES.ORGANIZATION_SITE_SETUP_ONBOARD}`,
              content: <OnboardSites />,
            },
          ]}
        />
      </div>
    </FeatureContentWrapper>
  );
}
