import { IconInterface } from "../iconInterface";

export default function MenuItemPlaceholderIcon({
  className,
  viewBox,
  ...rest
}: IconInterface) {
  const iconClassName = className || "";
  const iconViewBox = viewBox || "0 0 44 44";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={iconViewBox}
      className={iconClassName}
      fill="none"
      {...rest}
    >
      <g id="image / placeholder">
        <path
          id="Polygon-Copy-7 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.85594 9.49277L25.6077 -9.82748C26.0061 -10.0575 26.497 -10.0575 26.8954 -9.82748L60.3591 9.49277C60.7575 9.7228 61.0029 10.1479 61.0029 10.6079V49.2485C61.0029 49.7085 60.7575 50.1336 60.3591 50.3636L26.8954 69.6839C26.497 69.9139 26.0061 69.9139 25.6077 69.6839L-7.85594 50.3636C-8.25436 50.1336 -8.49979 49.7085 -8.49979 49.2485V10.6079C-8.49979 10.1479 -8.25436 9.7228 -7.85594 9.49277ZM-9.35594 6.8947L24.1077 -12.4256C25.4343 -13.1915 27.0688 -13.1915 28.3954 -12.4256L61.8591 6.8947C63.1857 7.66062 64.0029 9.0761 64.0029 10.6079V49.2485C64.0029 50.7803 63.1857 52.1958 61.8591 52.9617L28.3954 72.282C27.0688 73.0479 25.4343 73.0479 24.1077 72.282L-9.35594 52.9617C-10.6826 52.1958 -11.4998 50.7803 -11.4998 49.2485V10.6079C-11.4998 9.0761 -10.6826 7.66062 -9.35594 6.8947Z"
          fill="white"
        />
        <path
          id="Path"
          d="M19.6259 27.6364C19.6415 25.1611 21.1672 23.1839 21.3696 22.9348C22.3348 21.7204 24.0473 20.4905 24.0473 17.7661C24.0473 15.1039 22.9108 7.69332 22.5995 5.76284C22.5528 5.49817 22.3192 5.29579 22.0546 5.29579H21.8989C21.5875 5.29579 21.3384 5.54488 21.354 5.85625L21.6809 18.8092C21.6965 19.1205 21.4318 19.3696 21.136 19.3696H20.6067C20.2954 19.3696 20.0618 19.1205 20.0618 18.8247L19.984 5.84068C19.984 5.52931 19.7349 5.29579 19.4391 5.29579H19.4235C19.1122 5.29579 18.8786 5.54488 18.8786 5.84068V18.8092C18.8786 19.1205 18.6295 19.354 18.3337 19.354H17.9134C17.602 19.354 17.3685 19.105 17.3685 18.8092V5.84068C17.3685 5.52931 17.1194 5.29579 16.8236 5.29579H16.6835C16.3721 5.29579 16.1386 5.54488 16.1386 5.84068V18.8092C16.1386 19.1205 15.8895 19.354 15.5937 19.354H15.2045C14.8931 19.354 14.644 19.0894 14.6596 18.778L15.0955 5.84068C15.1111 5.52931 14.862 5.26465 14.5506 5.26465H14.3171C14.0369 5.26465 13.8033 5.46704 13.7722 5.7317C13.492 7.8023 12.3555 15.9757 12.3555 17.7194C12.3555 19.2606 12.8848 21.0354 14.9554 22.9503C16.0919 24.009 16.5278 25.4257 16.7146 26.4844C16.808 27.0293 16.8392 27.5742 16.8236 28.1346L15.9518 55.8775C15.9518 57.1541 16.9793 58.1972 18.2715 58.1972C19.5481 58.1972 20.5912 57.1541 20.5912 55.8775L19.6259 27.6364Z"
          fill="white"
        />
        <path
          id="Path_2"
          d="M37.2022 58.2442C35.9723 58.2442 34.9603 57.2479 34.9603 56.0024V39.7023C34.9603 39.7023 35.3651 36.3239 32.2358 33.8953C30.7101 32.7121 33.8861 10.2624 37.4668 5.31168C39.444 2.57164 39.444 5.85657 39.444 5.85657V56.018C39.444 57.2479 38.4321 58.2442 37.2022 58.2442Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
