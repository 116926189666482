import { useMutation } from "@tanstack/react-query";
import { PartnerFeatureRoundUpInterface } from "../../features/Configurations/FeatureManagement/types";
import { createPartnerRoundUpsFeature } from "../../services/features";

export default function useCreatePartnerRoundUpsFeature() {
  return useMutation(
    ({
      partnerId,
      isActive,
      roundUp,
    }: {
      partnerId: string;
      isActive: boolean;
      roundUp: PartnerFeatureRoundUpInterface;
    }) => createPartnerRoundUpsFeature(partnerId, isActive, roundUp)
  );
}
