import { useState } from "react";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import FeatureWidget from "../../../../../components/FeatureWidget";
import useStore from "../../../../../hooks/stores/useStore";

import mobileIcon from "../../assets/mobileIcon.svg";
import kioskIcon from "../../assets/kioskIcon.svg";
import OrderChannelStatus from "./OrderChannelStatus";
import OrderAvailabilitySidePanel from "../OrderAvailabilitySidePanel";

interface OrderAvailabilityWidgetProps {
  storeId: string;
  orgId: string | undefined;
}

export default function OrderAvailabilityWidget({
  storeId,
  orgId,
}: OrderAvailabilityWidgetProps) {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const { data: store } = useStore(storeId);

  return (
    <div>
      {store ? (
        <div>
          <FeatureWidget
            title="Order availability"
            content={
              <div>
                <div className="mb-6">
                  Turn mobile and kiosk ordering on and off as needed.
                </div>

                <div>
                <OrderChannelStatus
                  name="Mobile"
                  enabled={store.isActiveMobile}
                  icon={mobileIcon}
                />

                <OrderChannelStatus
                  name="Kiosk"
                  enabled={store.isActiveKiosk}
                  icon={kioskIcon}
                />


                </div>
              </div>
            }
            action={
              <PrimaryButton className="w-full" onClick={onOpen}>
                Manage order availability
              </PrimaryButton>
            }
          />

          <OrderAvailabilitySidePanel
            open={open}
            store={store}
            orgId={orgId}
            onClose={onClose}
          />
        </div>
      ) : null}
    </div>
  );
}
