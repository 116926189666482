import ActionModal from "../../../../../components/ActionModal";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import { WarningOutlinedIcon } from "../../../../../components/Icons";
import Typography from "../../../../../components/Typography";
import { OrderInterface } from "../../types";

export default function RefundConfirmModal({
  order,
  onConfirm,
  onClose,
}: {
  order: OrderInterface;
  onConfirm: () => void;
  onClose: () => void;
}) {
  return (
    <ActionModal
      icon={<WarningOutlinedIcon className="w-9 h-9" />}
      title={`Refund $${order.total.toFixed(2)} for Order ID #${order.orderId
        }?`}
      content={
        <Typography as="body-2">
          You will refund this amount back to the customer.
        </Typography>
      }
      footer={
        <>
          <SecondaryButton onClick={onClose} className="mr-3">
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={onConfirm}>Yes, refund</PrimaryButton>
        </>
      }
      onClose={onClose}
    />
  );
}
