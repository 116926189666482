import { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import ActionModal from "../../../../../components/ActionModal";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import CheckboxDropdown from "../../../../../components/CheckboxDropdown";
import FormControl from "../../../../../components/Forms/FormControl";
import InputField from "../../../../../components/Forms/InputField";
import { WarningOutlinedIcon } from "../../../../../components/Icons";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../../components/SidePanel/SidePanelLayout";
import Typography from "../../../../../components/Typography";
import { ROUTES } from "../../../../../constants/routes";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import {
  AddRegionFormValues,
  RegionInterface,
  RegionSiteWithRegionIncludedInterface,
} from "../../../types";

interface AddRegionSidePanelProps extends SidePanelVisibilityProps {
  regions: RegionInterface[] | undefined;
  sites: RegionSiteWithRegionIncludedInterface[] | undefined;
  onAddRegion: (values: AddRegionFormValues) => void;
}

export default function AddRegionSidePanel({
  regions,
  sites,
  onAddRegion,
  open,
  onClose,
}: AddRegionSidePanelProps) {
  const sitesOptions = useMemo(
    () =>
      sites
        ? sites.map((site) => ({
            label: site.name,
            value: site.id,
            display: (
              <span className="flex flex-col">
                <span>{site.name}</span>
                {site.region ? (
                  <Typography as="caption" className="text-gray-500">
                    {site.region}
                  </Typography>
                ) : null}
              </span>
            ),
          }))
        : [],
    [sites]
  );

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Please enter region name.")
      .max(128, "Region name can have a maximum od 128 characters.")
      .matches(/^[a-zA-Z ]+$/g, "Only alpha characters allowed.")
      .test(
        "test-region-name",
        "The region name already exits. Please enter a new name.",
        (value) => {
          const nameMatches =
            regions &&
            regions.find(
              (region) => region.name.toLowerCase() === value?.toLowerCase()
            );
          return !nameMatches;
        }
      )
      .trim(),
  });

  const validate = useValidationSchema(validationSchema);

  const [sitesToBeReassigned, setSitesToBeReassigned] = useState<{
    region: string;
    sites: RegionSiteWithRegionIncludedInterface[];
  } | null>();

  const onShowConfirmation = (region: string, selectedSites: string[]) => {
    const siteList = sites
      ? sites.filter((current) => selectedSites.indexOf(current.id) > -1)
      : [];

    setSitesToBeReassigned({ region, sites: siteList });
  };
  const onHideConfirmation = () => setSitesToBeReassigned(null);

  const onConfirmAddRegion = () => {
    onHideConfirmation();

    document
      .getElementById("add-region-form")
      ?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        onSubmit={onAddRegion}
        validate={validate}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting, form }) => (
          <form id="add-region-form" onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader title="Add region" onClose={onClose} />

              <SidePanelContent>
                <Field<string>
                  name="name"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        label="Region name"
                        placeholder="Example: Southwest"
                        input={input}
                        meta={meta}
                      />
                    </FormControl>
                  )}
                />

                <Field<string[]>
                  name="sites"
                  initialValue={[]}
                  render={({ input, meta }) => (
                    <FormControl>
                      <CheckboxDropdown
                        label={
                          <span>
                            Assign a site{" "}
                            <span className="text-gray-500">(Optional)</span>
                          </span>
                        }
                        input={input}
                        meta={meta}
                        options={sitesOptions}
                        noOptionsText={
                          <Typography
                            as="body-2"
                            className=" !text-left whitespace-pre-line"
                          >
                            There are no sites to select because you need to
                            setup sites first in{" "}
                            <Link
                              to={"../" + ROUTES.ORGANIZATION_SITE_SETUP}
                              className="inline"
                            >
                              <strong className="underline">Site setup</strong>
                            </Link>
                            . You will be able to assign a site to a region once
                            sites have been setup.
                          </Typography>
                        }
                      />
                    </FormControl>
                  )}
                />
              </SidePanelContent>

              <SidePanelFooter>
                <div className="flex justify-evenly">
                  <SecondaryButton
                    onClick={onClose}
                    type="button"
                    className="w-full mr-1"
                  >
                    Cancel
                  </SecondaryButton>
                  <Field<string[]>
                    name="sites"
                    subscription={{ value: true }}
                    render={({ input }) => (
                      <PrimaryButton
                        type="button"
                        disabled={submitting}
                        isLoading={submitting}
                        className="w-full ml-1"
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          const valid = await validate(form.getState().values);
                          if (
                            valid === undefined &&
                            input.value &&
                            input.value.length > 0
                          ) {
                            onShowConfirmation(
                              form.getState().values.name,
                              form.getState().values.sites!
                            );

                            return;
                          } else {
                            handleSubmit();
                          }
                        }}
                      >
                        Save
                      </PrimaryButton>
                    )}
                  />
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />

      {sitesToBeReassigned &&
      sitesToBeReassigned.sites &&
      sitesToBeReassigned.sites.length > 0 ? (
        <ActionModal
          icon={<WarningOutlinedIcon className="w-10 h-10" />}
          title={`Reassign a site to ${sitesToBeReassigned.region} region?`}
          onClose={onHideConfirmation}
          content={
            <div className="flex flex-col">
              <Typography
                as="body-2"
                className="mb-4"
              >{`You have selected sites that are already assigned to different regions. Would you like to reassign the following sites to the ${sitesToBeReassigned.region} region?`}</Typography>
              <ul className="pl-2 list-inside list-disc">
                {sitesToBeReassigned.sites.map((site) => (
                  <li key={`selected-site-${site.id}`}>{site.name}</li>
                ))}
              </ul>
            </div>
          }
          footer={
            <div>
              <SecondaryButton className="mr-1" onClick={onHideConfirmation}>
                Cancel
              </SecondaryButton>
              <PrimaryButton className="ml-1" onClick={onConfirmAddRegion}>
                Yes, reassign
              </PrimaryButton>
            </div>
          }
        />
      ) : null}
    </SidePanel>
  );
}
