import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import http from "../../../services/http";
export default function AuthenticatedView({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    isLoading: isAuth0Loading,
    error,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  } = useAuth0();

  const [tokenAcquired, setTokenAcquired] = useState<string | null>(null);

  useEffect(() => {
    if (isAuth0Loading) return;
    let tokenInterceptor: number;
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        http.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        tokenInterceptor = http.interceptors.request.use(async function (
          config
        ) {
          const token = await getAccessTokenSilently();
          if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        });

        setTokenAcquired(token);
      } catch (e) {
        // usually auth0 throws an error since we redirect straight to the login flow ( we dont have a login, logout page)
        // so catch the error to silence it

        // and incase the token expires or we get an old token from switching orgs
        // logout the clean up in the session locally
        await logout({ openUrl: false });
        await loginWithRedirect();
      }
    })();

    return () => http.interceptors.request.eject(tokenInterceptor);
  }, [isAuth0Loading, getAccessTokenSilently, loginWithRedirect, logout]);

  if (isAuth0Loading) {
    return null;
  }

  if (!isAuth0Loading && error) {
    return (
      <div>
        {error.message} <button onClick={() => logout()}>Logout</button>
      </div>
    );
  }

  if (!isAuth0Loading && isAuthenticated && tokenAcquired)
    return <>{children}</>;

  return null;
}
