import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import FeatureWidgetGrid from "../../../components/FeatureWidgetGrid";
import RequestStatusError from "../../../components/RequestStatusError";
import RequestStatusLoading from "../../../components/RequestStatusLoading";
import RequestStatusSuccess from "../../../components/RequestStatusSuccess";
import Typography from "../../../components/Typography";
import useOrgId from "../../../hooks/organizations/useOrgId";
import { usePaymentCurrencies } from "../../../hooks/payment/usePaymentCurrencies";
import { usePaymentGatewayFields } from "../../../hooks/payment/usePaymentGatewayFields";
import { usePaymentProviders } from "../../../hooks/payment/usePaymentProviders";
import useOrganizationRegions from "../../../hooks/regions/useOrganizationRegions";
import useCreateSitePaymentConfig from "../../../hooks/sites/useCreateSitePaymentConfig";
import useOnboardSite from "../../../hooks/sites/useOnboardSite";
import useSitesForOnboarding from "../../../hooks/sites/useSitesForOnboarding";
import {
  ORGANIZATION_ONBOARD_SITE_QUERY_KEY,
  ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY,
  USERS_SITES_QUERY_KEY,
} from "../constants";
import { OnboardPartnerInterface, UpdateActiveSiteFormValues } from "../types";
import OnboardSiteCard from "./components/OnboardSiteCard";
import OnboardSiteModal from "./components/OnboardSiteModal";

export default function OnboardSites() {
  const queryClient = useQueryClient();
  const orgId = useOrgId();

  const { data, isLoading } = useSitesForOnboarding();

  const [siteToBeOnboarded, setSiteToBeOnboarded] =
    useState<OnboardPartnerInterface | null>(null);

  const onShowOnboardModal = (site: OnboardPartnerInterface) =>
    setSiteToBeOnboarded(site);
  const onHideOnboardModal = () => setSiteToBeOnboarded(null);

  usePaymentProviders();

  usePaymentGatewayFields();

  usePaymentCurrencies();

  useOrganizationRegions();

  const {
    mutateAsync: onboardSite,
    data: onboardSiteData,
    status: onboardSiteStatus,
    error: onboardSiteError,
    reset: onboardSiteReset,
  } = useOnboardSite();

  const {
    mutateAsync: createSitePaymentConfig,
    status: createSitePaymentConfigStatus,
    error: createSitePaymentConfigError,
    reset: createSitePaymentConfigReset,
  } = useCreateSitePaymentConfig();

  const onOnboardSite = async (values: UpdateActiveSiteFormValues) => {
    return onboardSite({
      centegraSiteId: parseInt(values.siteId, 10),
      regionId: values.region,
      location: values.location,
      colorConfig: values.colorConfig,
    })
      .then(async (response) => {
        onHideOnboardModal();

        queryClient.invalidateQueries([USERS_SITES_QUERY_KEY, orgId]);
        queryClient.invalidateQueries([
          ORGANIZATION_ONBOARD_SITE_QUERY_KEY,
          orgId,
        ]);
        if (
          (Object.hasOwn(values.kiosk, "gateway") &&
            values.kiosk.gateway !== "") ||
          (Object.hasOwn(values.mobile, "gateway") &&
            values.mobile.gateway !== "")
        ) {
          return createSitePaymentConfig({
            siteId: response.partnerId,
            kiosk: values.kiosk,
            mobile: values.mobile,
          })
            .then(() => {
              queryClient.invalidateQueries([
                ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY,
                orgId,
                values.siteId,
              ]);
            })
            .catch(() => {});
        }

        return response;
      })
      .catch(() => {
        queryClient.invalidateQueries([
          ORGANIZATION_ONBOARD_SITE_QUERY_KEY,
          orgId,
        ]);
      });
  };

  const _onboardSiteError = onboardSiteError
    ? (onboardSiteError as AxiosError<{ message: string }>)
    : null;

  const _createSitePaymentConfigError = createSitePaymentConfigError
    ? (createSitePaymentConfigError as AxiosError<{ message: string }>)
    : null;

  if (isLoading || !data) {
    return null;
  }

  return (
    <div className="pt-6">
      <Helmet>
        <title>Connect - Organization - Onboard sites</title>
      </Helmet>
      <FeatureWidgetGrid>
        {data.map((site) => (
          <OnboardSiteCard
            key={`onboard-site-card-${site.centegraSiteId}`}
            site={site}
            onShowOnboardModal={() => onShowOnboardModal(site)}
          />
        ))}
      </FeatureWidgetGrid>

      {siteToBeOnboarded ? (
        <OnboardSiteModal
          site={siteToBeOnboarded}
          onSubmit={onOnboardSite}
          onClose={onHideOnboardModal}
        />
      ) : null}

      {onboardSiteStatus === "loading" && (
        <RequestStatusLoading>Submitting...</RequestStatusLoading>
      )}
      {onboardSiteStatus === "success" &&
        onboardSiteData &&
        (createSitePaymentConfigStatus === "success" ||
          createSitePaymentConfigStatus === "idle") && (
          <RequestStatusSuccess onCloseCallback={() => onboardSiteReset()}>
            <Typography as="caption">
              {onboardSiteData.name} has been successfully onboarded.
            </Typography>
          </RequestStatusSuccess>
        )}
      {onboardSiteStatus === "error" && _onboardSiteError && (
        <RequestStatusError onOkCallback={() => onboardSiteReset()}>
          <Typography as="caption">
            {_onboardSiteError?.response?.data.message}
          </Typography>
        </RequestStatusError>
      )}

      {createSitePaymentConfigStatus === "error" &&
        onboardSiteStatus === "success" &&
        _createSitePaymentConfigError && (
          <RequestStatusError
            onOkCallback={() => createSitePaymentConfigReset()}
          >
            <div className="flex flex-col">
              <Typography as="caption" className="mb-1">
                {onboardSiteData.name} has been successfully onboarded, but we
                failed to save the bank account information.
              </Typography>
              <Typography as="caption" className="mb-1">
                The site will be moved to Active Sites and you can try updating
                the bank account information from there.
              </Typography>
              <Typography as="caption">
                {_createSitePaymentConfigError.response?.data.message}
              </Typography>
            </div>
          </RequestStatusError>
        )}
    </div>
  );
}
