import React, { ReactNode } from "react";

interface SidePanelContentProps {
  children: ReactNode;
}

export default function SidePanelContent({ children }: SidePanelContentProps) {
  return (
    <div className="flex-grow flex-1 overflow-x-hidden overflow-y-auto px-4 md:px-6 py-7 md:py-5">
      {children}
    </div>
  );
}
