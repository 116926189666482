import { useMutation } from "@tanstack/react-query";
import { updateConnectUser } from "../../services/users";

export default function useUpdateConnectUser() {
  return useMutation(
    ({
      auth0UserId,
      roles,
      regions,
      sites,
    }: {
      auth0UserId: string;
      roles: string[];
      regions: string[];
      sites?: string[];
    }) => updateConnectUser(auth0UserId, roles, regions, sites)
  );
}
