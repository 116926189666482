import { createColumnHelper } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useMemo } from "react";
import ActionMenu from "../../../../components/ActionMenu";
import ActionMenuItem from "../../../../components/ActionMenu/ActionMenuItem";
import { TrashcanIcon } from "../../../../components/Icons";
import Table from "../../../../components/Table";
import { PERMISSIONS_USER } from "../../../../constants/permissions";
import { usePermissions } from "../../../../hooks/permissions/usePermissions";
import { UserInvitationInterface } from "../../types";

interface InvitationsTableProps {
  data: UserInvitationInterface[];
  onDeleteInvitation: (user: UserInvitationInterface) => void;
}

export default function InvitationsTable({
  data,
  onDeleteInvitation,
}: InvitationsTableProps) {
  const { hasPermission } = usePermissions();

  const columnHelper = createColumnHelper<UserInvitationInterface>();

  const usersColumns = useMemo(
    () => [
      columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => (
          <span className="block w-36 2xl:w-full truncate">
            {info.row.original.email}
          </span>
        ),
      }),
      columnHelper.accessor("regions", {
        header: "Organization",
        cell: (info) => (
          <span className="block w-36 xl:w-40 2xl:w-72 truncate">
            {info.row.original.regions.length &&
              info.row.original.sites.length === 0
              ? info.row.original.regions
                .map((region) => region.name)
                .join(", ")
              : info.row.original.sites.map((site) => site.name).join(", ")}
          </span>
        ),
      }),
      columnHelper.accessor("roles", {
        header: "Role",
        cell: (info) => (
          <span className="block w-36 xl:w-56 2xl:w-72 truncate">
            {info.row.original.roles.map((role) => role.name).join(", ")}
          </span>
        ),
      }),
      columnHelper.accessor("invitedAt", {
        header: "Invited",
        cell: (info) => (
          <span className="block w-24 truncate">
            {format(parseISO(info.row.original.invitedAt), "MMM d, y")}
          </span>
        ),
      }),
      columnHelper.accessor("expiresAt", {
        header: "Expires",
        cell: (info) => (
          <span className="block w-24 truncate">
            {format(parseISO(info.row.original.expiresAt), "MMM d, y")}
          </span>
        ),
      }),

      columnHelper.display({
        id: "actions",
        cell: (props) =>
          hasPermission(PERMISSIONS_USER.DELETE) ? (
            <div className="flex justify-end">
              <ActionMenu position="left">
                <ActionMenuItem
                  onClick={() => onDeleteInvitation(props.row.original)}
                  className="text-red-500"
                >
                  <TrashcanIcon className="w-6 h-6 mr-2.5" />
                  Delete
                </ActionMenuItem>
              </ActionMenu>
            </div>
          ) : null,
      }),
    ],
    [columnHelper, onDeleteInvitation, hasPermission]
  );

  return <Table<UserInvitationInterface> data={data} columns={usersColumns} />;
}
