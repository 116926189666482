import { useQuery } from "@tanstack/react-query";
import { ORGANIZATION_STORE_MOBILE_SETUP_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchStoreMobileSetup } from "../../services/stores";

export default function useStoreMobileSetup(storeId: string | null) {
  return useQuery(
    [ORGANIZATION_STORE_MOBILE_SETUP_QUERY_KEY, storeId],
    () => fetchStoreMobileSetup(storeId),
    {
      enabled: storeId !== "" && storeId !== null,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}
