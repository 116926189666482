import { LabelHTMLAttributes } from "react";

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  disabled?: boolean;
  children: React.ReactNode;
}

export default function Label({ children, ...rest }: LabelProps) {
  return (
    <div className="mb-1.5">
      <label
        {...rest}
        className={`block text-sm font-bold text-gray-800 mr-2 ${rest.className}`}
      >
        {children}
      </label>
    </div>
  );
}
