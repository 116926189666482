import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import InputField from "../../../../../components/Forms/InputField";
import { InfoIcon } from "../../../../../components/Icons";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../../components/SidePanel/SidePanelLayout";
import ToolTip from "../../../../../components/ToolTip/ToolTip";
import useStandardPromotions from "../../../../../hooks/promotions/usePartnerStandardPromotions";
import { StandardDiscountInterface } from "../../types";
import * as yup from "yup";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import FormControl from "../../../../../components/Forms/FormControl";

interface AddPromotionPanelProps extends SidePanelVisibilityProps {
  onAddPromotion: (values: {
    productId: number;
    discountCode: string;
    percentage: number;
  }) => void;
}

const DISCOUNT_TYPES = [
  { value: "Member", label: "Member" },
  { value: "SSA Employee", label: "SSA Employee" },
  { value: "Partner Employee", label: "Partner Employee" },
];

const validateDiscountSchema = yup.object({
  type: yup.string().required("Please select Promo Type."),
  percentage: yup.number().required("Please select Total Promo."),
  discountCode: yup.string().required("Please enter Promo Code."),
});

function DiscountPercentageDropdown({ type }: { type: string }) {
  const { data } = useStandardPromotions(type);

  const dropdownOptions = useMemo(
    () =>
      data
        ? data.map((item) => {
            return {
              value: item.percentage,
              label: item.discountName,
            };
          })
        : [],
    [data]
  );

  return (
    <Field<number>
      name="percentage"
      render={({ input: percentageInput, meta }) => (
        <>
          <FormControl>
            <Dropdown
              label="Total promo"
              input={percentageInput}
              meta={meta}
              options={dropdownOptions}
            />
          </FormControl>
          <ProductIdField percentage={percentageInput.value} data={data} />
        </>
      )}
    />
  );
}

function ProductIdField({
  percentage,
  data,
}: {
  percentage: number;
  data: StandardDiscountInterface[] | undefined;
}) {
  const productId = data
    ? data.find((item) => item.percentage === percentage)?.productId
    : undefined;

  return (
    <Field<number>
      name="productId"
      initialValue={productId}
      render={() => null}
    />
  );
}

export default function AddPromotionSidePanel({
  open,
  onAddPromotion,
  onClose,
}: AddPromotionPanelProps) {
  const validate = useValidationSchema(validateDiscountSchema);

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        onSubmit={onAddPromotion}
        validate={validate}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting, form }) => (
          <form
            onSubmit={handleSubmit}
            className="h-full"
            data-testid="add-promotion-form"
          >
            <SidePanelLayout>
              <SidePanelHeader
                title="Add Promotion"
                onClose={onClose}
                subtitle={""}
              />
              <SidePanelContent>
                <Field<string>
                  name="type"
                  render={({ input: discountTypeInput, meta }) => (
                    <FormControl>
                      <Dropdown
                        label={
                          <span className="flex">
                            <span className="mr-1">Promo type</span>
                            <ToolTip
                              position="right"
                              trigger={
                                <InfoIcon
                                  role="button"
                                  className="h-4 w-4"
                                  arial-label="info tooltip"
                                />
                              }
                            >
                              Product code ties to
                              <br />
                              Centegra's reporting column
                              <br />
                              under the title Stock Group.
                            </ToolTip>
                          </span>
                        }
                        input={{
                          ...discountTypeInput,
                          onChange: (e) => {
                            discountTypeInput.onChange(e);
                            form.change("percentage");
                          },
                        }}
                        meta={meta}
                        options={DISCOUNT_TYPES}
                        placeholder="Select promo type"
                      />
                    </FormControl>
                  )}
                />
                <Field<string>
                  name="discountCode"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        label="Promo code"
                        placeholder="Add a code"
                        input={input}
                        meta={meta}
                        className="mb-0"
                        helperText="Recommended no more than 7 characters long"
                      />
                    </FormControl>
                  )}
                />
                <Field
                  name="type"
                  subscription={{ value: true }}
                  render={({ input }) => (
                    <DiscountPercentageDropdown type={input.value} />
                  )}
                />
              </SidePanelContent>
              <SidePanelFooter>
                <div className="flex justify-evenly">
                  <SecondaryButton onClick={onClose} className="w-full mr-1">
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    disabled={submitting}
                    className="w-full ml-1"
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
