import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useNavigate } from "react-router-dom";
import "./index.css";

import App from "./App";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PermissionsProvider } from "./context/permissions/PermissionsContext";

import http from "./services/http";
import { dataDogLogsInit } from "./loggin/datadog";
import { datadogLogs } from "@datadog/browser-logs";

import ReactGA from "react-ga4";
import TagManager from "@sooro-io/react-gtm-module";

import { HelmetProvider } from "react-helmet-async";

import {
  Auth0Provider,
  Auth0ProviderOptions,
  AppState,
} from "@auth0/auth0-react";

import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
} from "./config/ssoAuthConfig";

import AuthenticatedView from "./features/Auth/AuthenticatedView";
import Auth0InvitationRedirect from "./features/Auth/Auth0InvitationRedirect";
import { OrganizationsProvider } from "./context/organizations/OrganizationsContext";

dataDogLogsInit();

const queryClient = new QueryClient();

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();
  const onRedirectCallback = async (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.href, {
      replace: true,
    });
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

http.interceptors.request.use(async function (config) {
  const requestLog = {
    application_event: `connect${config.url?.replace(/\//g, ".")}`,
    method: config.method?.toUpperCase(),
    params: config.params,
    data: config.data,
    url: `${config.baseURL}${config.url}`,
  };

  datadogLogs.logger.log(
    `Request | ${config.method?.toUpperCase()} | ${config.baseURL}${
      config.url
    }`,
    requestLog
  );
  // }

  return config;
});

ReactGA.initialize(window.GA_ID);

TagManager.initialize({
  gtmId: window.GTM_ID,
});

http.interceptors.response.use(
  async function (config) {
    const responseLog = {
      application_event: `connect${config.config.url?.replace(/\//g, ".")}`,
      status: config.status,
      statusText: config.statusText,
      url: `${config.config.baseURL}${config.config.url}`,
      data: config.data,
    };

    datadogLogs.logger.log(
      `Response | ${config.config.method?.toUpperCase()} | ${
        config.config.baseURL
      }${config.config.url}`,
      responseLog
    );

    return config;
  },
  function (error) {
    const requestErrorLog = {
      application_event: `connect${error.config.url.replace(/\//g, ".")}`,
      method: error.config.method?.toUpperCase(),
      params: error.config.params,
      data: error.config.data,
      url: `${error.config.baseURL}${error.config.url}`,

      error: error.response.data.error,
      error_message: error.response.data.message,
      status_code: error.response.status,
      status_text: error.response.statusText,
    };

    datadogLogs.logger.error(
      `Request Error | ${error.config.method.toUpperCase()} | ${
        error.config.baseURL
      }${error.config.url}`,
      requestErrorLog
    );

    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: AUTH0_AUDIENCE,
          }}
          cacheLocation="localstorage"
          useRefreshTokens={true}
          useRefreshTokensFallback={true}
        >
          <Auth0InvitationRedirect>
            <QueryClientProvider client={queryClient}>
              <AuthenticatedView>
                <PermissionsProvider>
                  <OrganizationsProvider>
                    <App />
                  </OrganizationsProvider>
                  <ReactQueryDevtools />
                </PermissionsProvider>
              </AuthenticatedView>
            </QueryClientProvider>
          </Auth0InvitationRedirect>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
