import Typography from "../../../../../components/Typography";
import { ProductSuspensionItemInterface } from "../../types";
import ProductSuspensionToggle from "../ProductSuspensionToggle";

export default function ProductSuspensionCard({
  item,
  restaurantId,
  orgId,
}: {
  item: ProductSuspensionItemInterface;
  restaurantId: string;
  orgId: string | undefined;
}) {
  return (
    <div className="border rounded-md drop-shadow-md px-4 py-4 bg-white mb-3">
      <div className="flex flex-col space-y-4">
        <div>
          <img
            src={item.imageUrl}
            alt={item.name}
            className="w-9 h-9 rounded-lg"
          />
        </div>
        <div>
          <Typography as="caption" className="text-gray-500">
            Name
          </Typography>
          <Typography as="body-2">{item.name}</Typography>
        </div>
        <div>
          <Typography as="caption" className="text-gray-500">
            Description
          </Typography>
          <Typography as="body-2" className="truncate">
            {item.description}
          </Typography>
        </div>
        <div className="border-t border-t-gray-300 -ml-4 -mr-4 p-4 pb-0">
          <div className="flex justify-between">
            <Typography as="body-2" className="text-gray-500">
              Visible to guests?
            </Typography>
            <ProductSuspensionToggle
              orgId={orgId}
              restaurantId={restaurantId}
              productId={item.productId}
              isSuspended={item.isSuspended}
              mobileView={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
