import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import { OrganizationRegionSetupInterface } from "../../../types";

interface RegionSetupCardProps {
  region: OrganizationRegionSetupInterface;
  onEditRegion: (region: OrganizationRegionSetupInterface) => void;
  onDeleteRegion: (region: OrganizationRegionSetupInterface) => void;
}

export default function RegionSetupCard({
  region,
  onEditRegion,
  onDeleteRegion,
}: RegionSetupCardProps) {
  return (
    <div className="flex flex-col  justify-between border rounded-md drop-shadow-md px-4 py-4 bg-white mb-3">
      <div className="flex flex-col mb-4">
        <span className="text-xs text-gray-500">Region</span>
        <span className="block truncate w-full text-sm">{region.name}</span>
      </div>

      <div className="flex flex-col mb-4">
        <span className="text-xs text-gray-500">Site</span>
        <span className="block truncate w-full text-sm">
          {region.sites.map((site) => site.name).join(", ")}
        </span>
      </div>

      <div className="flex justify-start mt-3">
        <PrimaryButton
          onClick={() => onEditRegion(region)}
          className="text-sm !px-4 !py-2 mr-2"
        >
          Edit
        </PrimaryButton>
        <SecondaryButton
          className="text-sm !px-4 !py-2"
          onClick={() => onDeleteRegion(region)}
        >
          Delete
        </SecondaryButton>
      </div>
    </div>
  );
}
