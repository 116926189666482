import { PARTNER_RESTAURANTS_QUERY_KEY } from "../../features/Stores/constants";
import { fetchPartnerRestaurants } from "../../services/restaurants";
import { useQuery } from "@tanstack/react-query";
import useOrgId from "../organizations/useOrgId";

export default function usePartnerRestaurants(partner: string | null) {
  const orgId = useOrgId();
  return useQuery(
    [PARTNER_RESTAURANTS_QUERY_KEY, orgId, partner],
    () => fetchPartnerRestaurants(partner),
    {
      enabled: partner !== null && partner !== "",
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000, // 1 min
    }
  );
}
