import { IconInterface } from "../iconInterface";

export default function CheckIcon({
  className,
  viewBox,
  ...rest
}: IconInterface) {
  const iconClassName = className || "";
  const iconViewBox = viewBox || "0 96 960 960";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={iconViewBox}
      className={iconClassName}
      fill="currentColor"
      {...rest}
    >
      <path d="M383 799q-8 0-15.5-3T353 786L184 617q-12-12-11.5-30.5T185 556q12-12 30-12t30 12l140 140 331-331q12-12 29.5-12t29.5 12q12 12 12 29.5T775 424L413 786q-7 7-14.5 10t-15.5 3Z" />
    </svg>
  );
}
