import { Form, Field } from "react-final-form";
import SearchInput from "../../../../../components/SearchInput";
import * as yup from "yup";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import FormControl from "../../../../../components/Forms/FormControl";

interface SearchFormProps {
  resultsDisplayed?: boolean;
  initialValues?: {
    search?: string;
  };
  onSubmit: (values: { search: string }) => void;
  onClear?: () => void;
}

const validateSearch = yup.object({
  search: yup
    .string()
    .default("")
    .matches(
      /^[0-9]*$/,
      "Please enter last-4 digits of phone number or Order ID."
    ),
});

export default function OrderSearchForm({
  resultsDisplayed = false,
  initialValues,
  onSubmit,
  onClear,
}: SearchFormProps) {
  const validate = useValidationSchema(validateSearch);

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field<string>
            name="search"
            render={({ input, meta }) => {
              return (
                <FormControl>
                  <p className="px-2 pt-1 mb-3 text-black">
                    Search by customer’s last 4 digits of a phone number or by
                    Order ID.
                  </p>
                  <div className="flex justify-start">
                    <div className="w-full md:w-80">
                      <SearchInput
                        input={input}
                        meta={meta}
                        placeholder="Search"
                        autoComplete="off"
                        onClear={onClear}
                      />
                    </div>
                    <PrimaryButton
                      onClick={handleSubmit}
                      className="ml-3 h-4 border-none"
                    >
                      Search
                    </PrimaryButton>
                  </div>
                </FormControl>
              );
            }}
          />
        </form>
      )}
    />
  );
}
