import { sprintf } from "sprintf-js";
import {
  STORE_AVAILABLE_MENUS_API_ENDPOINT,
  STORE_MENU_API_ENDPOINT,
  STORE_PRODUCTION_SUSPENSIONS_API_ENDPOINT,
  STORE_PRODUCT_SUSPENSIONS_SUSPEND_PRODUCT_API_ENDPOINT,
  STORE_PUBLISH_MENU_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  AvailableMenuInterface,
  MenuInterface,
} from "../features/Stores/MenuManagement/types";
import { ProductSuspensionItemInterface } from "../features/Stores/ProductSuspension/types";
import http from "./http";

export function fetchAvailableMenus(
  restaurantId: string
): Promise<AvailableMenuInterface[]> {
  return http
    .get(sprintf(STORE_AVAILABLE_MENUS_API_ENDPOINT, { restaurantId }))
    .then((response) => response.data);
}

export function fetchMenu(
  storeId: string,
  menuId: number | undefined
): Promise<MenuInterface> {
  return http
    .get(sprintf(STORE_MENU_API_ENDPOINT, { storeId, menuId }))
    .then((response) => response.data)
    .catch((error) => error.response.data);
}

export function publishMenu(
  storeId: string,
  menuId: number
): Promise<MenuInterface> {
  return http
    .post(sprintf(STORE_PUBLISH_MENU_API_ENDPOINT, { storeId, menuId }))
    .then((response) => response.data);
}

export function fetchProductSuspensions(
  restaurantId: string
): Promise<ProductSuspensionItemInterface[]> {
  return http
    .get(
      sprintf(STORE_PRODUCTION_SUSPENSIONS_API_ENDPOINT, {
        restaurantId,
      })
    )
    .then((response) => response.data);
}

export function suspendProduct(
  restaurantId: string,
  productId: number,
  isSuspended: boolean
): Promise<void> {
  return http.put(
    sprintf(STORE_PRODUCT_SUSPENSIONS_SUSPEND_PRODUCT_API_ENDPOINT, {
      restaurantId,
      productId,
    }),
    { isSuspended }
  );
}
