import { useContext } from "react";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { OrganizationsContext } from "../../context/organizations/OrganizationsContext";
import NavDropdown from "../Navigation/Navbar/NavDropdown";
import NavDropdownItem from "../Navigation/Navbar/NavDropdown/NavDropdownItem";

export default function OrganizationDropdown() {
  const { organizations, isLoading, selectedOrganization } =
    useContext(OrganizationsContext);

  if (isLoading) {
    return null;
  }
  return (
    <NavDropdown selected={selectedOrganization} name="Organizations">
      {() =>
        organizations?.map((org) => (
          <NavDropdownItem
            key={`nav-org-${org.id.toString()}`}
            to={generatePath(ROUTES.TOP_LEVEL_ORG, {
              orgId: org.id.toString(),
            })}
          >
            {org.name}
          </NavDropdownItem>
        ))
      }
    </NavDropdown>
  );
}
