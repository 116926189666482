import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { InfoIcon } from "../../../../components/Icons";
import ToolTip from "../../../../components/ToolTip/ToolTip";
import { PERMISSIONS_USER } from "../../../../constants/permissions";
import { usePermissions } from "../../../../hooks/permissions/usePermissions";
import { UserInterface } from "../../types";

interface MembersCardProps {
  member: UserInterface;
  onEditMember: (member: UserInterface) => void;
  onDeleteMember: (member: UserInterface) => void;
}

export default function MembersCard({
  member,
  onEditMember,
  onDeleteMember,
}: MembersCardProps) {
  const { hasPermission } = usePermissions();

  return (
    <div className="flex flex-col  justify-between border rounded-md drop-shadow-md px-4 py-4 bg-white mb-3">
      <div className="flex flex-col justify-between mb-3 ">
        <div className="flex flex-col  mb-2">
          <span className="text-xs text-gray-500">Email</span>
          <span
            className={`flex w-full text-sm ${
              member.isPending && "text-gray-300"
            }`}
          >
            <span className={`${member.isPending && "mr-2"}`}>
              {member.email}
            </span>
            {member.isPending && (
              <ToolTip
                position="bottom"
                trigger={
                  <InfoIcon
                    role="button"
                    className="h-4 w-4"
                    arial-label="info tooltip"
                    data-testid="login pending icon"
                  />
                }
              >
                Login pending.
              </ToolTip>
            )}
          </span>
        </div>
      </div>
      <div className="flex flex-row space-x-2">
        <div className="flex flex-col w-1/2 mb-2 items-start">
          <span className="text-xs text-gray-500">Organization</span>
          <span
            className={`block truncate w-full text-sm ${
              member.isPending && "text-gray-300"
            }`}
          >
            {member.regions.length && member.sites.length === 0
              ? member.regions.map((region) => region.name).join(", ")
              : member.sites.map((site) => site.name).join(", ")}
          </span>
        </div>
        <div className="flex flex-col w-1/2 mb-2 items-start">
          <span className="text-xs text-gray-500">Role</span>
          <span
            className={`block truncate w-full text-sm ${
              member.isPending && "text-gray-300"
            }`}
          >
            {member.roles.map((role) => role.name).join(", ")}
          </span>
        </div>
      </div>

      <div className="flex justify-start mt-5">
        {hasPermission(PERMISSIONS_USER.UPDATE) && !member.isPending && (
          <PrimaryButton
            onClick={() => onEditMember(member)}
            className="text-sm !px-4 !py-2 mr-2"
          >
            Edit
          </PrimaryButton>
        )}
        {hasPermission(PERMISSIONS_USER.DELETE) && (
          <SecondaryButton
            className="text-sm !px-4 !py-2"
            onClick={() => onDeleteMember(member)}
          >
            Delete
          </SecondaryButton>
        )}
      </div>
    </div>
  );
}
