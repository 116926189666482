import Typography from "../../../../../components/Typography";

import mobileIcon from "./mobileIcon.svg";
import kioskIcon from "./kioskIcon.svg";
import CheckIcon from "../../../../../components/Icons/CheckIcon";

interface StoreChannelBadgeProps {
  title: string;
  type: "mobile" | "kiosk";
  isOnboarded: boolean;
}
export default function StoreChannelBadge({
  title,
  type,
  isOnboarded,
}: StoreChannelBadgeProps) {
  return (
    <div
      className={`flex flex-col justify-end
        } items-center relative h-[49px]`}
    >
      {isOnboarded && (
        <CheckIcon
          className={`absolute -top-1 ${type === "mobile" ? "right-0" : "-right-1"
            } text-white bg-green-500 rounded-full w-4 h-4`}
        />
      )}

      {type === "mobile" ? (
        <img src={mobileIcon} alt="" className="w-4" />
      ) : (
        <img src={kioskIcon} alt="" className="w-8 mb-0.5" />
      )}

      <Typography as="caption" className="font-semibold justify-self-end mt-1">
        {title}
      </Typography>
    </div>
  );
}
