import { Form } from "react-final-form";
import { OrderInterface } from "../../types";

import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";

import SidePanel from "../../../../../components/SidePanel";
import SidePanelLayout from "../../../../../components/SidePanel/SidePanelLayout";
import SidePanelHeader from "../../../../../components/SidePanel/SidePanelHeader";
import SidePanelContent from "../../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../../components/SidePanel/SidePanelFooter";
import React from "react";
import { SidePanelVisibilityProps } from "../../../../../components/SidePanel/SidePanel";
import Typography from "../../../../../components/Typography";
import { format } from "date-fns";
import OrderDetails from "../OrderDetails";
import useOrderDetails from "../../../../../hooks/refund/useOrderDetails";

interface RefundPromptSidePanelProps extends SidePanelVisibilityProps {
  order: OrderInterface | null;
  storeId: string;
  onRefund: (values: OrderInterface) => void;
  onReportIssue: (order: OrderInterface) => void;
}

export default function RefundPromptSidePanel({
  open,
  order,
  storeId,
  onRefund,
  onReportIssue,
  onClose,
}: RefundPromptSidePanelProps) {
  const { data: orderDetails, isLoading: isLoadingOrderDetails } =
    useOrderDetails(storeId, order?.orderId, order?.channel);

  return (
    <SidePanel open={open} onClose={onClose}>
      {order && (
        <Form
          initialValues={order}
          onSubmit={onRefund}
          subscription={{ submitting: true }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="h-full">
              <SidePanelLayout>
                <SidePanelHeader
                  title={`Order # ${order.orderId}`}
                  subtitle={`Last-4 Phone # • ${order.lastFourPhone}`}
                  onClose={onClose}
                />

                <SidePanelContent>
                  <div className="flex flex-col">
                    <div className="mb-8">
                      <div className="flex flex-col">
                        <div className="flex justify-between py-1.5 items-center border-b border-b-gray-300">
                          <span className="text-sm font-medium">
                            Order Date:
                          </span>
                          <span className="text-sm">
                            {format(new Date(order.orderDate), "MM/dd/yyyy")}
                          </span>
                        </div>
                        <div className="flex justify-between py-1.5 items-center border-b border-b-gray-300">
                          <span className="text-sm font-medium">Channel:</span>
                          <span className="text-sm capitalize">
                            {order.channel}
                          </span>
                        </div>
                        <div className="flex justify-between py-1.5 items-center border-b border-b-gray-300">
                          <span className="text-sm font-medium">
                            Payment Gateway
                          </span>
                          <span className="text-sm">
                            {orderDetails?.paymentSystem.gateway}
                          </span>
                        </div>
                        <div className="flex justify-between py-1.5 items-center border-b border-b-gray-300">
                          <span className="text-sm font-medium">
                            Payment Processor:
                          </span>
                          <span className="text-sm">
                            {orderDetails?.paymentSystem.processor}
                          </span>
                        </div>
                      </div>

                      <div className="flex flex-col mt-4">
                        <Typography as="body-2" className="mb-3">
                          Guests will be refunded to their original payment
                          method.
                        </Typography>
                        <Typography as="body-2">
                          Need to provide a guest a refund receipt? Visit the
                          SOS knowledge hub for{" "}
                          <a
                            className="font-bold underline"
                            href="https://thessagroup.atlassian.net/wiki/spaces/KB/pages/611221536/How+do+I+issue+a+guest+a+receipt"
                            target="_blank"
                            rel="noreferrer"
                          >
                            receipt guidelines
                          </a>
                          .
                        </Typography>
                      </div>
                    </div>
                    {orderDetails && (
                      <div>
                        <OrderDetails
                          order={orderDetails}
                          onReportIssue={() => onReportIssue(order)}
                        />
                      </div>
                    )}
                  </div>
                </SidePanelContent>
                <SidePanelFooter>
                  <div className="flex justify-evenly">
                    <SecondaryButton
                      className="w-full mr-1"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      disabled={submitting || isLoadingOrderDetails}
                      className="w-full ml-1"
                    >
                      Refund{" "}
                      {orderDetails &&
                        `• $${orderDetails.summary.total.toFixed(2)}`}
                    </PrimaryButton>
                  </div>
                </SidePanelFooter>
              </SidePanelLayout>
            </form>
          )}
        />
      )}
    </SidePanel>
  );
}
