import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../components/SidePanel/SidePanelContent";
import SidePanelHeader from "../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../components/SidePanel/SidePanelLayout";
import Typography from "../../../../components/Typography";
import { StoreInterface } from "../../types";
import QrCodeDownload from "../QrCodeDownload";

const QR_TITLES: Record<string, string> = {
  GENERAL: "General",
  DINEIN: "Dine In / Onsite Ordering Only",
  TAKEOUT: "Take Out Only",
};

const QR_DESCRIPTIONS: Record<string, string> = {
  GENERAL:
    "Use this QR code to enable the option to toggle takeaway or dine in, if available.",
  DINEIN:
    "Use this QR code to enable the dine in only option with onsite or table ordering, if available.",
  TAKEOUT: "Use this QR code to enable the take out only option, if available.",
};

interface QrCodeSidePanelProps extends SidePanelVisibilityProps {
  store: StoreInterface | undefined;
}

export default function QrCodeSidePanel({
  open,
  store,
  onClose,
}: QrCodeSidePanelProps) {
  if (!store) {
    return null;
  }

  return (
    <SidePanel open={open} onClose={onClose}>
      <div className="h-full">
        <SidePanelLayout>
          <SidePanelHeader
            title="QR codes / URL"
            subtitle={store.name}
            onClose={onClose}
          />

          <SidePanelContent>
            <div className="flex flex-col">
              {store.urls.map((url) =>
                QR_TITLES[url.key.toUpperCase()] ? (
                  <div
                    key={`qr-${QR_TITLES[url.key.toUpperCase()]}`}
                    className="border-b border-b-gray-300 last:border-b-0 pb-8 mb-8"
                  >
                    <Typography as="body-1" className="font-semibold">
                      {QR_TITLES[url.key.toUpperCase()]}
                    </Typography>
                    <Typography as="body-2" className="mb-4">
                      {QR_DESCRIPTIONS[url.key.toUpperCase()]}
                    </Typography>
                    <QrCodeDownload
                      title={QR_TITLES[url.key.toUpperCase()]}
                      url={url.url}
                    />
                  </div>
                ) : null
              )}
            </div>
          </SidePanelContent>
        </SidePanelLayout>
      </div>
    </SidePanel>
  );
}
