import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Switch from "../../../../../components/Switch/Switch";
import useToggleSuspendProduct from "../../../../../hooks/menus/useToggleSuspendProduct";
import { RESTAURANT_PRODUCT_SUSPENSIONS_QUERY_KEY } from "../../../constants";

interface ProductSuspensionToggleProps {
  orgId: string | undefined;
  restaurantId: string;
  productId: number;
  isSuspended: boolean;
  mobileView: boolean;
}

export default function ProductSuspensionToggle({
  orgId,
  restaurantId,
  productId,
  isSuspended,
  mobileView,
}: ProductSuspensionToggleProps) {
  const queryClient = useQueryClient();

  const { mutateAsync: suspendProduct } = useToggleSuspendProduct();

  const onSuspendProductSubmit = async (isSuspended: boolean) => {
    await suspendProduct({
      restaurantId,
      productId,
      isSuspended: !isSuspended,
    })
      .then(() => {
        queryClient.invalidateQueries([
          RESTAURANT_PRODUCT_SUSPENSIONS_QUERY_KEY,
          orgId,
          restaurantId,
        ]);
      })
      .catch((error) => {
        const _error = error as AxiosError<{ message: string }>;
        toast.error(
          `Failed to turn ${isSuspended ? "off" : "on"} the product. ${
            _error.response?.data.message
          }`
        );

        setChecked((prev) => !prev);

        queryClient.invalidateQueries([
          RESTAURANT_PRODUCT_SUSPENSIONS_QUERY_KEY,
          orgId,
          restaurantId,
        ]);
      });
  };

  const [checked, setChecked] = useState(!isSuspended);

  return (
    <div>
      <Switch
        type="checkbox"
        input={{
          name: `isSuspended-${mobileView ? "mobile" : "desktop"}-${productId}`,
          value: checked,
          checked: checked,
          onChange: (e) => {
            onSuspendProductSubmit(e.target.checked);
            setChecked(e.target.checked);
          },
          onBlur: () => {},
          onFocus: () => {},
        }}
        meta={{}}
        label={checked ? "On" : "Off"}
        labelPosition="left"
        className="mb-0"
      />
    </div>
  );
}
