import { useQuery } from "@tanstack/react-query";
import { ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchSitePaymentConfig } from "../../services/sites";
import useOrgId from "../organizations/useOrgId";

export default function useSitePaymentConfig(siteId: string) {
  const orgId = useOrgId();
  return useQuery(
    [ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY, orgId, siteId],
    () => fetchSitePaymentConfig(siteId),
    {
      enabled: siteId !== "",
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}
