import React from "react";
import Label from "../../../../../components/Forms/Label";
import { FieldRenderProps } from "react-final-form";

interface SiteColorFieldProps {
  label?: React.ReactNode;
  helperText?: string;
}

export default function SiteColorField({
  input,
  meta,
  label,
  icon,
  className,
  helperText,
  ...rest
}: FieldRenderProps<string, any> & SiteColorFieldProps) {
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  return (
    <div className="relative w-36">
      {label && <Label disabled={rest.disabled}>{label}</Label>}
      <div className="relative">
        <input
          {...input}
          {...rest}
          maxLength={6}
          aria-label={input.name}
          className={`input-field mb-0.5 ${icon ? "pl-11" : ""}  ${
            hasError ? "text-red-500 border-red-500 ring-1 ring-error-500" : ""
          } ${className}`}
        />

        {input.value !== "" && input.value.length === 6 ? (
          <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
            <div
              role="presentation"
              style={{
                backgroundColor: `#${input.value}`,
              }}
              className={`w-[1.125rem] h-[1.125rem] rounded`}
            />
          </div>
        ) : null}
      </div>
      {hasError ? (
        <p className="text-xs text-red-500 h-6 absolute mt-0">
          {meta.error || meta.submitError}
        </p>
      ) : null}
      {!hasError && helperText ? (
        <p className="text-gray-500 text-sm mb-5">{helperText}</p>
      ) : null}
    </div>
  );
}
