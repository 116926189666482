import http from "./http";
import {
  DiscountInterface,
  StandardDiscountInterface,
} from "../features/Configurations/Promotions/types";
import {
  PARTNER_DISCOUNTS_API_ENDPOINT,
  PARTNER_STANDARD_DISCOUNTS_API_ENDPOINT,
} from "../config/servicesEndpoints";

export function fetchPartnerPromotions(
  partnerId: string | null
): Promise<DiscountInterface[]> {
  return http
    .get(PARTNER_DISCOUNTS_API_ENDPOINT, {
      params: {
        partnerId,
      },
    })
    .then((response) => response.data);
}

export function fetchPartnerStandardPromotions(
  type: string
): Promise<StandardDiscountInterface[]> {
  return http
    .get(PARTNER_STANDARD_DISCOUNTS_API_ENDPOINT, {
      params: {
        type,
      },
    })
    .then((response) => response.data);
}

export function addPromotion(
  partnerId: string,
  discountCode: string,
  productId: number
): Promise<{}> {
  return http.post(PARTNER_DISCOUNTS_API_ENDPOINT, {
    partnerId,
    discountCode,
    productId,
  });
}

export function deletePromotion(
  partnerId: string,
  discountCode: string
): Promise<{ affect: number; raw: any[] }> {
  return http.delete(PARTNER_DISCOUNTS_API_ENDPOINT, {
    params: { partnerId, discountCode },
  });
}
