import InfoAlert from "../../../../../components/Alerts/InfoAlert";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import Typography from "../../../../../components/Typography";
import { OrderDetailsInterface } from "../../types";
import OrderDetailsItem from "../OrderDetailsItem";
import OrderDetailsItems from "../OrderDetailsItems";

export default function OrderDetails({
  order,
  onReportIssue,
}: {
  order: OrderDetailsInterface;
  onReportIssue: () => void;
}) {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <Typography as="h6" className="font-bold">
          Order Summary
        </Typography>
        <SecondaryButton
          onClick={onReportIssue}
          className="!py-2 !px-4 text-sm"
        >
          Report Issue
        </SecondaryButton>
      </div>
      <div className="mb-5">
        <InfoAlert>
          You will only be able to refund the guest once. Fees, tips and round
          up are only included with a full refund.
        </InfoAlert>
      </div>

      <div>
        {order && (
          <div className="mb-4">
            <OrderDetailsItems>
              {order.cart.map((item, index) => (
                <OrderDetailsItem
                  key={`${item.productId}-${item.productName}-${index}`}
                  item={item}
                />
              ))}
            </OrderDetailsItems>
          </div>
        )}
      </div>
      <div>
        {order && order.summary && (
          <ul className="space-y-2">
            <li className="flex justify-between">
              <Typography as="body-2" className="font-semibold">
                Subtotal
              </Typography>
              <Typography as="body-2" className="font-semibold">
                ${order.summary.subtotal.toFixed(2)}
              </Typography>
            </li>
            <li className="flex justify-between">
              <Typography as="body-2">Discount</Typography>
              <Typography as="body-2">{`${order.summary.discount > 0 ? "-" : ""
                }$${order.summary.discount.toFixed(2)}`}</Typography>
            </li>
            <li className="flex justify-between">
              <Typography as="body-2">Tax</Typography>
              <Typography as="body-2">
                ${order.summary.tax.toFixed(2)}
              </Typography>
            </li>
            <li className="flex justify-between">
              <Typography as="body-2">Fees</Typography>
              <Typography as="body-2">
                ${order.summary.fee.toFixed(2)}
              </Typography>
            </li>
            <li className="flex justify-between">
              <Typography as="body-2">Tip</Typography>
              <Typography as="body-2">
                ${order.summary.tip.toFixed(2)}
              </Typography>
            </li>

            <li className="flex justify-between  border-b border-b-gray-300 pb-2 ">
              <Typography as="body-2">Round Up Donation</Typography>
              <Typography as="body-2">
                ${order.summary.roundUp.toFixed(2)}
              </Typography>
            </li>
            <div className="pb-[0.625rem] " />

            <li className="flex justify-between">
              <Typography as="body-2" className="font-semibold">
                Original Order Total
              </Typography>
              <Typography as="body-2" className="font-semibold">
                ${order.summary.total.toFixed(2)}
              </Typography>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
