import { ButtonHTMLAttributes } from "react";
import SpinnerIcon from "../../Icons/SpinnerIcon";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  loadingText?: string;
}

// whenever a button is in a loading state
// we make children invisible so they still take up the space they would
// and add a loading overlay on top
// to avoid the button resizing itself and making the content around it jump

export default function Button(props: ButtonProps) {
  const { isLoading, loadingText, children, className, ...rest } = props;
  return (
    <button
      type="button"
      {...rest}
      className={`inline-flex cursor-default items-center px-4 py-3 h-fit rounded-lg border border-gray-300 text-gray justify-center bg-white hover:bg-gray-100 active:bg-white focus:outline-none  disabled:bg-gray-200 disable:border-gray-200 disabled:text-gray-400 ${className}`}
    >
      <span className={`${isLoading && "invisible z-0"}`}>{children}</span>
      {isLoading && (
        <span className={`flex items-center fixed h-fit `}>
          <SpinnerIcon
            className={`w-6 h-6 !fill-gray-400 ${loadingText && "mr-2"}`}
          />{" "}
          {loadingText ?? ""}
        </span>
      )}
    </button>
  );
}
