import { IconInterface } from "../iconInterface";

export default function InfoIcon({
  className,
  viewBox,
  ...rest
}: IconInterface) {
  const iconClassName = className || "";
  const iconViewBox = viewBox || "0 0 18 18";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={iconViewBox}
      className={iconClassName}
      fill="currentColor"
      {...rest}
    >
      <path
        d="M8.99999 13.168C9.23599 13.168 9.44099 13.081 9.61499 12.907C9.78833 12.7336 9.87499 12.529 9.87499 12.293V9.02197C9.87499 8.78597 9.78833 8.58464 9.61499 8.41797C9.44099 8.2513 9.23599 8.16797 8.99999 8.16797C8.76399 8.16797 8.55899 8.25464 8.38499 8.42797C8.21166 8.60197 8.12499 8.80697 8.12499 9.04297V12.313C8.12499 12.5496 8.21166 12.7513 8.38499 12.918C8.55899 13.0846 8.76399 13.168 8.99999 13.168ZM8.99999 6.47997C9.23599 6.47997 9.44099 6.3933 9.61499 6.21997C9.78833 6.04597 9.87499 5.84097 9.87499 5.60497C9.87499 5.36897 9.78833 5.1643 9.61499 4.99097C9.44099 4.81697 9.23599 4.72997 8.99999 4.72997C8.76399 4.72997 8.55899 4.81697 8.38499 4.99097C8.21166 5.1643 8.12499 5.36897 8.12499 5.60497C8.12499 5.84097 8.21166 6.04597 8.38499 6.21997C8.55899 6.3933 8.76399 6.47997 8.99999 6.47997ZM8.99999 17.334C7.84733 17.334 6.76399 17.1153 5.74999 16.678C4.73599 16.2406 3.85399 15.647 3.10399 14.897C2.35399 14.147 1.76033 13.265 1.32299 12.251C0.885659 11.237 0.666992 10.1536 0.666992 9.00097C0.666992 7.8483 0.885659 6.76497 1.32299 5.75097C1.76033 4.73697 2.35399 3.85497 3.10399 3.10497C3.85399 2.35497 4.73599 1.7613 5.74999 1.32397C6.76399 0.886635 7.84733 0.667969 8.99999 0.667969C10.1527 0.667969 11.236 0.886635 12.25 1.32397C13.264 1.7613 14.146 2.35497 14.896 3.10497C15.646 3.85497 16.2397 4.73697 16.677 5.75097C17.1143 6.76497 17.333 7.8483 17.333 9.00097C17.333 10.1536 17.1143 11.237 16.677 12.251C16.2397 13.265 15.646 14.147 14.896 14.897C14.146 15.647 13.264 16.2406 12.25 16.678C11.236 17.1153 10.1527 17.334 8.99999 17.334ZM8.99999 15.584C10.8193 15.584 12.3713 14.9416 13.656 13.657C14.9407 12.3723 15.583 10.8203 15.583 9.00097C15.583 7.18164 14.9407 5.62964 13.656 4.34497C12.3713 3.0603 10.8193 2.41797 8.99999 2.41797C7.18066 2.41797 5.62866 3.0603 4.34399 4.34497C3.05933 5.62964 2.41699 7.18164 2.41699 9.00097C2.41699 10.8203 3.05933 12.3723 4.34399 13.657C5.62866 14.9416 7.18066 15.584 8.99999 15.584Z"
      />
    </svg>
  );
}
