import { Helmet } from "react-helmet-async";
import { RestaurantIcon } from "../../../../components/Icons";
import RestaurantContent from "../RestaurantContent";

interface RestaurantStartProps {
  partnerOnly?: boolean;
}

export default function RestaurantsStart({
  partnerOnly = false,
}: RestaurantStartProps) {
  return (
    <RestaurantContent className="flex justify-center">
      <Helmet>
        <title>Connect - Sites</title>
      </Helmet>
      <div className="flex flex-col items-center w-52 mt-20 md:-mt-20 md:justify-center">
        <RestaurantIcon className="text-black w-12 h-12 mb-2.5" />
        {!partnerOnly && (
          <p className="text-gray-500 text-center">
            Please select site and store to start.
          </p>
        )}
        {partnerOnly && (
          <p className="text-gray-500 text-center">
            Please select select region and partner to start.
          </p>
        )}
      </div>
    </RestaurantContent>
  );
}
