import { useState } from "react";
import BadgeDisabled from "../../../../components/Badges/BadgeDisabled";
import BadgeSuccess from "../../../../components/Badges/BadgeSuccess";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import FeatureWidget from "../../../../components/FeatureWidget";
import useStore from "../../../../hooks/stores/useStore";
import OnsiteOrderingSidePanel from "../OnsiteOrderingSidePanel";

interface OnsiteOrderingWidgetProps {
  storeId: string;
  orgId: string | undefined;
}
export default function OnsiteOrderingWidget({
  storeId,
  orgId,
}: OnsiteOrderingWidgetProps) {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const { data: store } = useStore(storeId);

  if (!store) {
    return null;
  }

  return (
    <div>
      <FeatureWidget
        title={
          <div className="featureStatus flex justify-between items-start">
            Onsite ordering
            {store.tableManagement !== "none" ? (
              <BadgeSuccess>Enabled</BadgeSuccess>
            ) : (
              <BadgeDisabled>Disabled</BadgeDisabled>
            )}
          </div>
        }
        content={
          <div>
            Turn onsite ordering on if you would like to deliver food to a guest
            at a table or a specific location. When this is enabled guests will
            not receive a second text message.
          </div>
        }
        action={
          <PrimaryButton className="w-full" onClick={onOpen}>
            Manage onsite delivery
          </PrimaryButton>
        }
      />
      <OnsiteOrderingSidePanel
        open={open}
        orgId={orgId}
        store={store}
        onClose={onClose}
      />
    </div>
  );
}
