import { useEffect, useRef, useState } from "react";
import { FieldRenderProps } from "react-final-form";

import CheckboxDropdownOption, {
  CheckboxDropdownOptionType,
} from "./CheckboxDropdownOption";
import Label from "../Forms/Label";
import { ChevronDownIcon } from "../Icons";
import CheckboxDropdownSelectAllOption from "./CheckboxDropdownSelectAllOption";
import DropdownNoOptions from "../Dropdown/DropdownNoOptions";

interface CheckboxDropdownProps {
  label?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  options: CheckboxDropdownOptionType[];
  error?: string | undefined;
  helperText?: string;
  enableSelectAll?: boolean;
  noOptionsText?: React.ReactNode;
}

export default function CheckboxDropdown({
  input,
  meta,
  label,
  options,
  helperText,
  enableSelectAll,
  noOptionsText,
  ...rest
}: FieldRenderProps<any[]> & CheckboxDropdownProps) {
  const [show, setShow] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const selectedOptions: CheckboxDropdownOptionType[] | undefined =
    options.filter((option) =>
      input.value && input.value.find
        ? input.value.find((inputValue) => inputValue === option.value)
        : false
    );

  const onToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    const onClickAway = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      !wrapperRef.current?.contains(target) && setShow(false);
    };

    document.addEventListener("mousedown", onClickAway);
    return () => document.removeEventListener("mousedown", onClickAway);
  }, [setShow]);

  const placeholder = rest.placeholder ? rest.placeholder : "Select...";
  const isDisabled = rest.disabled || false;
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  let indetermined: "all" | "indetermined" | "none" = "none";
  if (selectedOptions.length !== 0 && selectedOptions.length < options.length) {
    indetermined = "indetermined";
  } else if (
    selectedOptions.length > 0 &&
    selectedOptions.length === options.length
  ) {
    indetermined = "all";
  }

  const onSelectAllChange = () => {
    switch (indetermined) {
      // when nothing is selected, select everything but the disabled options
      // a case for this is when editing a user you shouldnt be able to select and deselect regions you are not assigned to
      // this applies to select all too
      case "none":
        input.onChange(
          options
            .filter((option) => !option.disabled)
            .map((option) => option.value)
        );

        break;

      case "all":
        input.onChange(
          options
            .filter((option) => option.disabled)
            .map((option) => option.value)
        );
        break;
      default:
        input.onChange(options.map((option) => option.value));
        break;
    }
  };

  return (
    <div className="flex flex-col align-top mb-5 relative" ref={wrapperRef}>
      {label && <Label disabled={isDisabled}>{label}</Label>}

      <div className="relative">
        <button
          type="button"
          className={`relative w-full bg-white border border-gray-300 rounded-lg px-3 py-2.5 mb-0.5 text-left cursor-pointer focus:ring-1 focus:ring-cinchio-blue-500 focus:outline-0  focus:border-cinchio-blue-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-100
           ${
             hasError
               ? "text-red-500 ring-1 ring-error-500  border-error-500 disabled:border-error-500"
               : ""
           }`}
          aria-haspopup="listbox"
          aria-expanded={show}
          aria-label={`dropdown-${input.name}`}
          onClick={() => onToggle()}
          disabled={isDisabled}
        >
          <div className="relative">
            <span className="flex items-center">
              <span className="block truncate pr-5">
                {selectedOptions.length > 0 ? (
                  selectedOptions.map((option) => option.label).join(", ")
                ) : (
                  <span
                    className={isDisabled ? "text-gray-300" : "text-gray-400"}
                  >
                    {placeholder}
                  </span>
                )}{" "}
              </span>
            </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pointer-events-none">
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-500 transition-transform  ${
                  show && "rotate-180"
                }`}
              />
            </span>
          </div>
        </button>

        {show && (
          <ul
            className="absolute z-10 mt-1 w-full bg-white max-h-56 rounded-lg py-1 text-base border border-gray-300 overflow-auto focus:outline-none drop-shadow-md"
            tabIndex={-1}
            role="listbox"
            aria-label={`dropdown-listbox-${input.name}`}
          >
            {options.length > 0 ? (
              enableSelectAll ? (
                <CheckboxDropdownSelectAllOption
                  id={`${input.name}-select-all`}
                  name={`${input.name}-select-all`}
                  value={indetermined}
                  label="Select all"
                  onChange={onSelectAllChange}
                />
              ) : null
            ) : (
              <DropdownNoOptions
                text={noOptionsText ? noOptionsText : "No options available."}
              />
            )}
            {options?.map((option: CheckboxDropdownOptionType) => (
              <CheckboxDropdownOption
                id={`${input.name}-${option.value}-${option.label}`}
                key={`${option.value}-${option.label}`}
                name={input.name}
                value={option.value}
                label={option.label}
                display={option.display}
                disabled={option.disabled}
              />
            ))}
          </ul>
        )}
        {hasError ? (
          <p className="text-xs text-red-500 h-6 absolute mt-0">
            {meta.error || meta.submitError}
          </p>
        ) : null}
        {!hasError && helperText ? (
          <p className="text-gray-500 text-sm mb-5">{helperText}</p>
        ) : null}
      </div>
    </div>
  );
}
