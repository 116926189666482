import { IconInterface } from "../iconInterface";

const CheckCircleOutlinedIcon = ({ className, viewBox, ...rest }: IconInterface) => {
    const iconClassName = className || "";
    const iconViewBox = viewBox || "0 0 34 34";

    return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={iconViewBox}
      className={iconClassName}
      fill="currentColor"
      {...rest}
    >
      <path d="M14.583 24.625L26.375 12.833L24.292 10.792L14.583 20.542L9.66701 15.625L7.62501 17.625L14.583 24.625ZM17 33.667C14.722 33.667 12.5693 33.2293 10.542 32.354C8.51401 31.4793 6.74301 30.285 5.22901 28.771C3.71501 27.257 2.52067 25.486 1.64601 23.458C0.770674 21.4307 0.333008 19.278 0.333008 17C0.333008 14.6947 0.770674 12.528 1.64601 10.5C2.52067 8.47201 3.71501 6.70801 5.22901 5.20801C6.74301 3.70801 8.51401 2.52067 10.542 1.64601C12.5693 0.770674 14.722 0.333008 17 0.333008C19.3053 0.333008 21.472 0.770674 23.5 1.64601C25.528 2.52067 27.292 3.70801 28.792 5.20801C30.292 6.70801 31.4793 8.47201 32.354 10.5C33.2293 12.528 33.667 14.6947 33.667 17C33.667 19.278 33.2293 21.4307 32.354 23.458C31.4793 25.486 30.292 27.257 28.792 28.771C27.292 30.285 25.528 31.4793 23.5 32.354C21.472 33.2293 19.3053 33.667 17 33.667ZM17 30.875C20.8613 30.875 24.139 29.5277 26.833 26.833C29.5277 24.139 30.875 20.8613 30.875 17C30.875 13.1387 29.5277 9.86101 26.833 7.16701C24.139 4.47234 20.8613 3.12501 17 3.12501C13.1387 3.12501 9.86101 4.47234 7.16701 7.16701C4.47234 9.86101 3.12501 13.1387 3.12501 17C3.12501 20.8613 4.47234 24.139 7.16701 26.833C9.86101 29.5277 13.1387 30.875 17 30.875Z" />
    </svg>

    )
};

export default CheckCircleOutlinedIcon;
