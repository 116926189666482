import { useQuery } from "@tanstack/react-query";
import { STORE_ORDER_DETAILS_KEY } from "../../features/Stores/constants";
import { fetchOrderDetails } from "../../services/orders";
import useOrgId from "../organizations/useOrgId";

export default function useOrderDetails(
  storeId: string,
  orderId: number | undefined,
  channel: string | undefined
) {
  const orgId = useOrgId();
  return useQuery(
    [STORE_ORDER_DETAILS_KEY, orgId, storeId, orderId],
    () => fetchOrderDetails(storeId, orderId, channel),
    {
      enabled: !!orderId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 1000 * 2,
    }
  );
}
