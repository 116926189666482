import { sprintf } from "sprintf-js";
import {
  CONNECT_DELETE_USER_API_ENDPOINT,
  CONNECT_UPDATE_USER_API_ENDPOINT,
  CONNECT_USERS_API_ENDPOINT,
  CONNECT_USER_INVITATIONS_API_ENDPOINT,
  CONNECT_DELETE_INVITATION_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  PaginatedUserInvitationsInterface,
  PaginatedUsersInterface,
  UserInterface,
} from "../features/Users/types";
import http from "./http";

export function fetchConnectUsers(
  page: number
): Promise<PaginatedUsersInterface> {
  return http
    .get(CONNECT_USERS_API_ENDPOINT, { params: { page } })
    .then((response) => response.data);
}

export function fetchConnectUserInvitations(
  page: number
): Promise<PaginatedUserInvitationsInterface> {
  return http
    .get(CONNECT_USER_INVITATIONS_API_ENDPOINT, { params: { page } })
    .then((response) => response.data);
}

export function createConnectUser(
  email: string,
  role: string,
  partners: string[]
): Promise<UserInterface[]> {
  return http
    .post(CONNECT_USERS_API_ENDPOINT, { email, role, partners })
    .then((response) => response.data);
}

export function updateConnectUser(
  auth0UserId: string,
  roles: string[],
  regions: string[],
  sites?: string[]
): Promise<UserInterface[]> {
  return http
    .put(sprintf(CONNECT_UPDATE_USER_API_ENDPOINT, { auth0UserId }), {
      roles,
      regions,
      sites,
    })
    .then((response) => response.data);
}

export function inviteUser(
  email: string,
  regions: string[],
  roles: string[],
  sites?: string[]
) {
  return http
    .post(CONNECT_USER_INVITATIONS_API_ENDPOINT, {
      email,
      regions,
      roles,
      sites,
    })
    .then((response) => response.data);
}

export function deleteConnectUser(auth0UserId: string) {
  return http
    .delete(sprintf(CONNECT_DELETE_USER_API_ENDPOINT, { auth0UserId }))
    .then((response) => response.data);
}

export function deleteUserInvitation(auth0InvitationId: string) {
  return http
    .delete(
      sprintf(CONNECT_DELETE_INVITATION_API_ENDPOINT, { auth0InvitationId })
    )
    .then((response) => response.data);
}

export function searchConnectUsers(
  email: string,
  page: number
): Promise<PaginatedUsersInterface> {
  return http
    .get(CONNECT_USERS_API_ENDPOINT, { params: { email, page } })
    .then((response) => response.data);
}
