import Checkbox from "../Forms/Checkbox";

type CheckBoxIndeterminedState = "all" | "indetermined" | "none";

export interface CheckboxDropdownSelectAllOptionType {
  value: CheckBoxIndeterminedState;
  label: string;
  disabled?: boolean;
}

export interface CheckboxDropdownSelectAllOptionProps
  extends CheckboxDropdownSelectAllOptionType {
  name: string;
  onChange: () => void;
  [key: string]: any;
}

export default function CheckboxDropdownSelectAllOption({
  name,
  value,
  label,
  disabled,
  onChange,
  ...rest
}: CheckboxDropdownSelectAllOptionProps) {
  const isDisabled = disabled || false;
  return (
    <li
      role="option"
      className={`relative p-2.5 text-gray-900 bg-white`}
      aria-selected={value !== "none"}
      {...rest}
    >
      <div className="flex items-center">
        <Checkbox
          input={{
            name: name,
            value: value,
            onBlur: () => {},
            onChange: onChange,
            onFocus: () => {},
          }}
          indetermined={value}
          meta={{}}
          label={label}
          disabled={isDisabled}
        />
      </div>
    </li>
  );
}
