import { CheckCircleIcon } from "../../../../../components/Icons";
import Typography from "../../../../../components/Typography";

interface ChannelEditCardProps {
  image: React.ReactNode;
  title: string;
  children: React.ReactNode;
  className?: string;
}

export default function ChannelEditCard({
  image,
  title,
  children,
  className,
}: ChannelEditCardProps) {
  return (
    <div
      className={`flex flex-col w-full sm:w-[15.563rem] justify-start  shadow shadow-stone-400 rounded-md ${className}`}
    >
      <div className="flex justify-center relative py-5 rounded-t-md bg-gray-50">
        {image}
        <CheckCircleIcon className="absolute top-3.5 right-3.5 w-6 h-6 text-green-500 bg-white" />
      </div>
      <div className="p-4 flex flex-col flex-grow justify-between">
        <div>
          <Typography as="subtitle-2" className="text-gray-500">
            Setup type
          </Typography>
          <Typography as="body-1" className="font-semibold pt-[3px] mb-9">
            {title}
          </Typography>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
