import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import FormControl from "../../../../../components/Forms/FormControl";
import InputField from "../../../../../components/Forms/InputField";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../../components/SidePanel/SidePanelLayout";
import {
  OrganizationStoreInterface,
  OrganizationStoreKioskSetupInterface,
} from "../../../types";
import * as yup from "yup";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import { useMemo } from "react";
import { StoreKioskSetupFormValues } from "../../types";
import Typography from "../../../../../components/Typography";

interface StoreKioskSetupSidePanelProps extends SidePanelVisibilityProps {
  store: OrganizationStoreInterface;
  kioskSetup: OrganizationStoreKioskSetupInterface | undefined;
  onSave: (values: StoreKioskSetupFormValues) => void;
}

const validateStoreSetup = yup.object({
  header: yup
    .string()
    .url("Must be a valid URL.")
    .required("Please add Welcome screen header link."),
  headerLogo: yup
    .string()
    .url("Must be a valid URL.")
    .required("Please add Header logo link."),
  productImage1: yup
    .string()
    .url("Must be a valid URL.")
    .required("Please add Product image 1 link."),
  productImage2: yup.string().url("Must be a valid URL.").optional(),
  productImage3: yup.string().url("Must be a valid URL.").optional(),
  welcomeLogo: yup
    .string()
    .url("Must be a valid URL.")
    .required("Please add Welcome screen logo link."),
});

export default function StoreKioskSetupSidePanel({
  open,
  store,
  kioskSetup,
  onSave,
  onClose,
}: StoreKioskSetupSidePanelProps) {
  const validate = useValidationSchema(validateStoreSetup);

  const initialValues = useMemo(
    () => ({
      header:
        kioskSetup && kioskSetup.screens.header
          ? kioskSetup.screens.header
          : "",
      headerLogo:
        kioskSetup && kioskSetup.screens.headerLogo
          ? kioskSetup.screens.headerLogo
          : undefined,
      productImage1:
        kioskSetup &&
          kioskSetup.screens.welcome &&
          kioskSetup.screens.welcome[0]
          ? kioskSetup.screens.welcome[0]
          : "",
      productImage2:
        kioskSetup &&
          kioskSetup.screens.welcome &&
          kioskSetup.screens.welcome[1]
          ? kioskSetup.screens.welcome[1]
          : undefined,
      productImage3:
        kioskSetup &&
          kioskSetup.screens.welcome &&
          kioskSetup.screens.welcome[2]
          ? kioskSetup.screens.welcome[2]
          : undefined,
      welcomeLogo:
        kioskSetup && kioskSetup.screens.welcomeLogo
          ? kioskSetup.screens.welcomeLogo
          : "",
    }),
    [kioskSetup]
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSave}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader
                title="Kiosk setup"
                subtitle={
                  <span>
                    Store • <span className="font-semibold">{store.name}</span>
                  </span>
                }
                onClose={onClose}
              />
              <SidePanelContent>
                <Typography as="h6" className="font-bold mb-5">
                  Welcome screen display settings
                </Typography>
                <Field<string>
                  name="welcomeLogo"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        input={input}
                        meta={meta}
                        label="Welcome screen logo"
                        placeholder="Add image link"
                      />
                    </FormControl>
                  )}
                />
                <Field<string>
                  name="header"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        input={input}
                        meta={meta}
                        label="Welcome screen header"
                        placeholder="Add image link"
                      />
                    </FormControl>
                  )}
                />

                <Field<string>
                  name="headerLogo"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        input={input}
                        meta={meta}
                        label="Header logo"
                        placeholder="Add image link"
                      />
                    </FormControl>
                  )}
                />

                <Field<string>
                  name="productImage1"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        input={input}
                        meta={meta}
                        label="Product image 1"
                        placeholder="Add image link"
                      />
                    </FormControl>
                  )}
                />
                <Field<string>
                  name="productImage2"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        input={input}
                        meta={meta}
                        label="Product image 2 (optional)"
                        placeholder="Add image link"
                      />
                    </FormControl>
                  )}
                />
                <Field<string>
                  name="productImage3"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        input={input}
                        meta={meta}
                        label="Product image 3 (optional)"
                        placeholder="Add image link"
                      />
                    </FormControl>
                  )}
                />
              </SidePanelContent>

              <SidePanelFooter>
                <div className="flex justinfy-evenly">
                  <SecondaryButton
                    onClick={onClose}
                    type="button"
                    className="w-full mr-1"
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    disabled={submitting}
                    isLoading={submitting}
                    type="submit"
                    className="w-full ml-1"
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
