import { OrderInterface } from "../../types";
import OrderCard from "../OrderCard";

interface OrderListProps {
  orders: OrderInterface[];
  onRefundPrompt: (order: OrderInterface) => void;
  onShowReportIssue: (order: OrderInterface) => void;
}

export default function OrderList({
  orders,
  onRefundPrompt,
  onShowReportIssue,
}: OrderListProps) {
  if (orders.length === 0) {
    return (
      <span className="block px-2">
        We're sorry, we can not find a matching Customer Order. Please check
        your entry and try again.
      </span>
    );
  }
  return (
    <>
      {orders.map((order) => (
        <OrderCard
          key={order.orderId + order.channel}
          orderId={order.orderId}
          phone={order.lastFourPhone}
          channel={order.channel}
          total={order.total}
          orderDate={order.orderDate}
          onRefund={() => onRefundPrompt(order)}
          onReportIssue={() => onShowReportIssue(order)}
        />
      ))}
    </>
  );
}
