import Typography from "../../../../components/Typography";
import QRCode from "qrcode";
import { useEffect, useRef } from "react";
import { CopyIcon } from "../../../../components/Icons";
import toast from "react-hot-toast";
import QrCodeDownloadMenu from "./QrCodeDownloadMenu";

interface QrCodeDownloadProps {
  title: string;
  url: string;
}
const QrCodeDownload = ({ title, url }: QrCodeDownloadProps) => {
  const qrRoot = document.createElement("canvas") as HTMLCanvasElement;
  const qrRef = useRef(qrRoot);

  const handleCopy = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(url).then(() => {
      toast.success("URL copied successfully.");
    });
  };

  useEffect(() => {
    QRCode.toCanvas(qrRef.current, url, { width: 93 } || " ");
  }, [url]);

  return (
    <div className="bg-gray-50 rounded-lg border border-gray-300 p-6">
      <Typography as="subtitle-2" className="mb-5">
        Scan QR code to preview
      </Typography>
      <div className="flex items-center pb-6 mb-4 border-b border-b-gray-300">
        <canvas className="rounded-lg" ref={qrRef} />
        <div className="w-full ml-8" data-testid='qrDropDown'>
          <QrCodeDownloadMenu title="Download" href={url} filename={url} />
        </div>
      </div>
      <div className="flex flex-col">
        <Typography as="subtitle-2" className="uppercase">
          URL{" "}
          <button
            onClick={handleCopy}
            aria-label={`Copy ${title} URL`}
            data-testid="url-copy-button"
          >
            <CopyIcon className="h-5 w-5 inline ml-2" />
          </button>
        </Typography>

        <div className="flex items-center">
          <a
            target="_blank"
            className="block text-sm underline break-all"
            rel="noreferrer noopener"
            href={url}
          >
            {url}
          </a>
        </div>
      </div>
    </div>
  );
};

export default QrCodeDownload;
