import { ErrorIcon } from "../Icons";
import {ReactNode} from "react";

interface WarningMessageProps {
  className?: string;
  children?: ReactNode;
}
const InfoMessage = ({ className, children }: WarningMessageProps) => {
  return (
    <div className={`flex border rounded-lg bg-warning-50 p-2 pr-4 border-warning-500 text-warning-500 items-center ${className}`}>
      <div>
        <ErrorIcon className="h-6 w-8 mt-1" />
      </div>
      {children}
    </div>
  );
};

export default InfoMessage;
