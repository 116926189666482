import { useMutation } from "@tanstack/react-query";
import { updateStore } from "../../services/stores";

export default function useUpdateStore() {
  return useMutation(
    ({
      storeId,
      description,
      descriptionSpanish,
      colorConfig,
      colorChoice,
    }: {
      storeId: string;
      description: string;
      descriptionSpanish: string;
      colorConfig?: {
        palette: Record<string, string>;
        fontPalette: Record<string, string>;
      };
      colorChoice?: string;
    }) =>
      updateStore(
        storeId,
        description,
        descriptionSpanish,
        colorConfig,
        colorChoice
      )
  );
}
