interface BackdropProps {
  show: boolean;
  [key: string]: any;
}

export default function Backdrop({ show, ...rest }: BackdropProps) {
  const backdropClassName = `${show
      ? "bg-opacity-40 pointer-events-auto "
      : "bg-opacity-0 pointer-events-none"
    } overflow-hidden fixed z-30 top-[60px] right-0 left-0 
   w-full  h-full bg-black transition-all ${rest.className}`;
  return (
    <div
      tabIndex={-1}
      aria-hidden={!show}
      role="presentation"
      {...rest}
      className={backdropClassName}
    />
  );
}
