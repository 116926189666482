import ActionModal from "../../../ActionModal";
import SecondaryButton from "../../../Buttons/SecondaryButton";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import { WarningOutlinedIcon } from "../../../Icons/";

interface SignoutPromptModalProps {
  onSignout: () => void;
  onClose: () => void;
}

export default function SignoutPromptModal({
  onSignout,
  onClose,
}: SignoutPromptModalProps) {
  return (
    <ActionModal
      icon={<WarningOutlinedIcon className="w-10 h-8 mb-2" />}
      title="Are you sure you want to sign out?"
      content={<div></div>}
      footer={
        <>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton className="ml-2" onClick={onSignout}>
            Sign out
          </PrimaryButton>
        </>
      }
      onClose={onClose}
    />
  );
}
