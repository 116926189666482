import { Field, Form } from "react-final-form";
import ActionModal from "../../../../../components/ActionModal";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import TextArea from "../../../../../components/Forms/TextArea";
import { OrderInterface } from "../../types";
import * as yup from "yup";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import FormControl from "../../../../../components/Forms/FormControl";

const validateReportSchema = yup.object({
  notes: yup
    .string()
    .required("Please add a description")
    .min(10, "Description must be at least 10 characters long."),
});

export default function RefundReportIssue({
  order,
  onSubmit,
  onClose,
}: {
  order: OrderInterface;
  onSubmit: (values: { notes: string }) => void;
  onClose: () => void;
}) {
  const validate = useValidationSchema(validateReportSchema);

  return (
    <ActionModal
      title={`Report Issue Order # ${order.orderId}`}
      content={
        <Form
          validate={validate}
          subscription={{ submitting: true }}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={handleSubmit}
              id="refund-report-issue-form"
              className="pt-5"
            >
              <Field
                name="notes"
                render={({ input, meta }) => (
                  <FormControl>
                    <TextArea
                      label="Please describe the issue."
                      placeholder="Add a description"
                      input={input}
                      meta={meta}
                      maxLength={255}
                      helperText=" "
                      rows={5}
                    />
                  </FormControl>
                )}
              />
              <div className="flex justify-end pt-3">
                <SecondaryButton onClick={onClose} className="mr-3">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={submitting}
                  isLoading={submitting}
                  type="submit"
                >
                  Send
                </PrimaryButton>
              </div>
            </form>
          )}
        />
      }
      onClose={onClose}
    />
  );
}
