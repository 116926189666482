import React, { useEffect, useRef, useState } from "react";
import { OrganizationInterface } from "../../../../features/Organizations/types";
import { ChevronDownIcon } from "../../../Icons";

interface NavDropdownProps {
  name: string;
  selected: OrganizationInterface | null;
  children: ({
    onSelect,
  }: {
    onSelect: (name: string) => void;
  }) => React.ReactNode;
}

export default function NavDropdown({
  name,
  selected,
  children,
}: NavDropdownProps) {
  const [show, setShow] = useState(false);

  const onToggleMenu = () => setShow((show) => !show);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClickAway = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      !wrapperRef.current?.contains(target) && setShow(false);
    };

    document.addEventListener("mousedown", onClickAway);
    return () => document.removeEventListener("mousedown", onClickAway);
  }, [setShow]);

  const [selectedName, setSelectedName] = useState(
    selected ? selected.name : ""
  );

  const onSelect = (name: string) => {
    setSelectedName(name);
    setShow(false);
  };

  return (
    <div className="relative mr-0 md:mr-8">
      <button
        type="button"
        className="flex w-28 sm:w-full justify-end text-white p-1  flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cinchio-blue-500"
        data-testid={`${name}-menu-button`}
        id={`${name}-menu-button`}
        aria-expanded={show}
        aria-haspopup="true"
        onClick={() => onToggleMenu()}
      >
        <span className="sr-only">Open {name} menu</span>
        <span className="truncate">{selectedName || name}</span>
        <ChevronDownIcon className="w-6 h-6" />
      </button>

      {show ? (
        <div
          ref={wrapperRef}
          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby={`${name}-menu-button`}
          tabIndex={-1}
        >
          {children({ onSelect })}
        </div>
      ) : null}
    </div>
  );
}
