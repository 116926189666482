import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import ActionMenu from "../../../../components/ActionMenu";
import ActionMenuItem from "../../../../components/ActionMenu/ActionMenuItem";
import { EditIcon, InfoIcon, TrashcanIcon } from "../../../../components/Icons";
import Table from "../../../../components/Table";
import ToolTip from "../../../../components/ToolTip/ToolTip";
import { PERMISSIONS_USER } from "../../../../constants/permissions";
import { usePermissions } from "../../../../hooks/permissions/usePermissions";
import { UserInterface } from "../../types";

interface MembersTableProps {
  data: UserInterface[];
  onEditMember: (user: UserInterface) => void;
  onDeleteMember: (user: UserInterface) => void;
}

export default function MembersTable({
  data,
  onEditMember,
  onDeleteMember,
}: MembersTableProps) {
  const { hasPermission } = usePermissions();

  const columnHelper = createColumnHelper<UserInterface>();

  const membersColumns = useMemo(
    () => [
      columnHelper.accessor("email", {
        header: "Email",
        cell: (info) => (
          <span
            className={`flex w-72 2xl:w-full ${
              info.row.original.isPending && "text-gray-300"
            }`}
          >
            <span className={`${info.row.original.isPending && "mr-2"}`}>
              {info.row.original.email}
            </span>{" "}
            {info.row.original.isPending && (
              <ToolTip
                position="top"
                trigger={
                  <InfoIcon
                    role="button"
                    className="h-4 w-4"
                    arial-label="info tooltip"
                    data-testid="login pending icon"
                  />
                }
              >
                Login pending.
              </ToolTip>
            )}
          </span>
        ),
      }),
      columnHelper.accessor("regions", {
        header: "Organization",
        cell: (info) => (
          <span
            className={`block w-36 xl:w-56 2xl:w-[22rem] 3xl:w-96 truncate ${
              info.row.original.isPending && "text-gray-300"
            }`}
          >
            {info.row.original.regions.length &&
            info.row.original.sites.length === 0
              ? info.row.original.regions
                  .map((region) => region.name)
                  .join(", ")
              : info.row.original.sites.map((site) => site.name).join(", ")}
          </span>
        ),
      }),
      columnHelper.accessor("roles", {
        header: "Roles",
        cell: (info) => (
          <span
            className={`block w-36 xl:w-50 2xl:w-80 3xl:w-[34rem] truncate ${
              info.row.original.isPending && "text-gray-300"
            }`}
          >
            {info.row.original.roles.map((role) => role.name).join(", ")}
          </span>
        ),
      }),

      columnHelper.display({
        id: "actions",
        cell: (props) =>
          hasPermission(PERMISSIONS_USER.UPDATE) ||
          hasPermission(PERMISSIONS_USER.DELETE) ? (
            <div className="flex justify-end">
              <ActionMenu position="left">
                {hasPermission(PERMISSIONS_USER.UPDATE) &&
                  !props.row.original.isPending && (
                    <ActionMenuItem
                      onClick={() => {
                        onEditMember(props.row.original);
                      }}
                    >
                      <EditIcon className="w-6 h-6 text-gray-500 mr-2.5" />
                      Edit
                    </ActionMenuItem>
                  )}
                {hasPermission(PERMISSIONS_USER.DELETE) && (
                  <ActionMenuItem
                    onClick={() => onDeleteMember(props.row.original)}
                    className="text-red-500"
                  >
                    <TrashcanIcon className="w-6 h-6 mr-2.5" />
                    Delete
                  </ActionMenuItem>
                )}
              </ActionMenu>
            </div>
          ) : null,
      }),
    ],
    [columnHelper, onEditMember, onDeleteMember, hasPermission]
  );

  return <Table<UserInterface> data={data} columns={membersColumns} />;
}
