import { FieldRenderProps } from "react-final-form";
import { IndeterminedCheckIcon } from "../../Icons";
import CheckIcon from "../../Icons/CheckIcon";

interface CheckboxFieldProps {
  label?: React.ReactNode;
  indetermined?: "all" | "indetermined" | "none";
}

export default function Checkbox({
  input,
  meta,
  label,
  indetermined,
  ...rest
}: FieldRenderProps<string, any> & CheckboxFieldProps) {
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);

  return (
    <div className="relative">
      <div className="flex item-center">
        <input
          {...input}
          {...rest}
          checked={input.checked || indetermined === "all" ? true : false}
          id={`checkbox-${input.name}-${input.value}`}
          type="checkbox"
          className="peer w-6 h-6 opacity-0 absolute z-0"
          arial-label={input.name}
        />

        <div
          className={`w-6 h-6
          flex-shrink-0 justify-center items-center
          bg-white border rounded border-gray-300 text-transparent 
          peer-default:text-transparent peer-checked:text-white peer-checked:bg-cinchio-blue-500 peer-checked:border-cinchio-blue-500 peer-disabled:bg-gray-200 peer-disabled:border-gray-200
          peer-focus:ring-1 peer-focus:ring-cinchio-blue-500 peer-focus-visible:outline-0 peer-focus:border-cinchio-blue-500
          ${
            hasError
              ? "text-red-500 border-red-500 peer-checked:border-error-500"
              : ""
          }`}
        >
          {indetermined && indetermined === "indetermined" ? (
            <IndeterminedCheckIcon
              viewBox="0 0 24 24"
              className="-mt-[5px] -ml-[5px] w-8 h-8 text-cinchio-blue-500 pointer-events-none peer-checked:block peer-disabled:text-gray:300"
            />
          ) : (
            <CheckIcon
              viewBox="-140 -20 1230 1230"
              className="-mt-[1px] -ml-[1px] w-6 h-6  peer-default:hidden  peer-checked:block peer-checked:text-gray-300  pointer-events-none  "
            />
          )}
        </div>
        {label && (
          <label
            htmlFor={`checkbox-${input.name}-${input.value}`}
            className="ml-2.5 select-none cursor-pointer peer-disabled:text-gray-300"
          >
            {label}
          </label>
        )}
      </div>
      {hasError ? (
        <p className="text-xs text-red-500 h-6 absolute mt-0">
          {meta.error || meta.submitError}
        </p>
      ) : null}
    </div>
  );
}
