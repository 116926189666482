import { useMutation } from "@tanstack/react-query";
import { reportOrderIssue } from "../../services/orders";

export default function useReportOrderIssue() {
  return useMutation(
    ({
      restaurantId,
      orderId,
      notes,
    }: {
      restaurantId: string;
      orderId: number;
      notes: string;
    }) => reportOrderIssue(restaurantId, orderId, notes)
  );
}
