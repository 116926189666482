import { FieldRenderProps } from "react-final-form";
import Label from "../Label";

interface TextAreaProps {
  label?: string;
  helperText?: string;
  placeholder?: string;
  maxLength?: number;
}

const TextArea = ({
  label,
  helperText,
  placeholder,
  maxLength,
  input,
  meta,
  className,
  ...rest
}: FieldRenderProps<string, any> & TextAreaProps) => {
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);
  const count = input.value.length;

  return (
    <div className="relative">
      {label && <Label>{label}</Label>}
      <textarea
        {...input}
        {...rest}
        style={{ resize: "none" }}
        className={`input-field ${
          hasError ? "text-red-500 border-red-500 ring-1 ring-error-500" : ""
        } ${className}`}
        aria-label={input.name}
        placeholder={placeholder}
        maxLength={maxLength}
      ></textarea>

      <div className="flex justify-between text-gray-500 text-sm">
        {hasError ? (
          <p className="text-xs text-red-500 h-6  mt-0">
            {meta.error || meta.submitError}
          </p>
        ) : null}
        {!hasError && helperText ? (
          <p className="text-gray-500 text-sm">{helperText}</p>
        ) : null}
        {maxLength && (
          <p>
            {count}/{maxLength}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextArea;
