import { useQuery } from "@tanstack/react-query";
import { AVAILABLE_MENUS_QUERY_KEY } from "../../features/Stores/MenuManagement/constants";
import { fetchAvailableMenus } from "../../services/menus";
import useOrgId from "../organizations/useOrgId";

export default function useAvailableMenus(restaurantId: string) {
  const orgId = useOrgId();
  return useQuery(
    [AVAILABLE_MENUS_QUERY_KEY, orgId, restaurantId],
    () => fetchAvailableMenus(restaurantId),
    {
      enabled: restaurantId !== "",
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1 * 60 * 1000,
    }
  );
}
