import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import { toast } from "react-hot-toast";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../components/SidePanel/SidePanelLayout";
import Switch from "../../../../components/Switch/Switch";
import Typography from "../../../../components/Typography";
import InfoMessage from "../../../../components/InfoMessage";
import useUpdateAdvancedOrdering from "../../../../hooks/restaurants/useUpdateAdvancedOrdering";
import { STORE_QUERY_KEY } from "../../constants";
import { StoreInterface } from "../../types";

interface AdvancedOrderingSidePanelProps extends SidePanelVisibilityProps {
  store: StoreInterface;
  orgId: string | undefined;
}

export default function AdvancedOrderingSidePanel({
  open,
  store,
  orgId,
  onClose,
}: AdvancedOrderingSidePanelProps) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateAdvancedOrdering } = useUpdateAdvancedOrdering();

  const onSubmit = async (values: { isActive: boolean }) => {
    return updateAdvancedOrdering({
      restaurantId: store.id,
      isActive: values.isActive,
    })
      .then(() => {
        queryClient.invalidateQueries([STORE_QUERY_KEY, orgId, store.id]);
        onClose();
        toast.success("Advanced ordering successfully updated.");
      })
      .catch(() => {
        toast.error("Failed to update Advanced ordering. Please try again.");
      });
  };

  const initialValues = useMemo(
    () => ({ isActive: store.hasAdvancedOrdering }),
    [store]
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader
                title="Advanced ordering"
                subtitle={store.name}
                onClose={onClose}
              />

              <SidePanelContent>
                <div className="flex-col bg-gray-50 p-4 rounded-lg">
                  <div className="flex">
                    <div className="flex-1 flex-row">
                      <Typography as="body-1" className="font-semibold mb-1.5">
                        Advanced ordering
                      </Typography>
                      <span className="block text-xs text-gray-500 pr-7">
                        Turning advanced ordering on will allow guests to place
                        orders in advance for the same day.
                      </span>
                    </div>

                    <div className="flex items-center">
                      <Field
                        name="isActive"
                        type="checkbox"
                        render={({ input, meta }) => (
                          <Switch
                            label={input.checked ? "On" : "Off"}
                            labelPosition="left"
                            input={input}
                            meta={meta}
                            className="mb-0"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <Field
                    name="isActive"
                    type="checkbox"
                    subscription={{ value: true }}
                    render={({ input }) =>
                      !input.checked &&
                      store.hasAdvancedOrdering &&
                      store.hasCapacity ? (
                        <InfoMessage className="mt-6">
                          <Typography as="body-2">
                            Order throttling will be automatically disabled once
                            you save these settings and turn advanced ordering
                            off. Order throttling can only stay enabled if
                            advanced ordering is also enabled.
                          </Typography>
                        </InfoMessage>
                      ) : null
                    }
                  />
                </div>
              </SidePanelContent>

              <SidePanelFooter>
                <div className="flex justify-evenly">
                  <SecondaryButton onClick={onClose} className="w-full mr-1">
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    className="w-full ml-1"
                    type="submit"
                    disabled={submitting}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
