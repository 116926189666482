import { ORGANIZATION_REGIONS_API_ENDPOINT } from "../config/servicesEndpoints";
import { OrganizationRegionSetupInterface } from "../features/Organizations/types";
import http from "./http";

export function fetchOrganizationRegions(): Promise<
  OrganizationRegionSetupInterface[]
> {
  return http
    .get(ORGANIZATION_REGIONS_API_ENDPOINT)
    .then((response) => response.data);
}
