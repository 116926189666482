import { MenuItemModifierGroupInterface } from "../types";

export default function countMenuModifierGroupsErrors(
  modifierGroup: MenuItemModifierGroupInterface[]
): number {
  let count = 0;

  modifierGroup.forEach((group) => {
    count += group.errors.length;

    group.modifiers.forEach((modifier) => {
      count += modifier.errors.length;
    });
  });

  return count;
}
