import { ExclamationIcon } from "../../../../../components/Icons";
import Typography from "../../../../../components/Typography";
import { MenuItemModifierGroupInterface } from "../../types";
import MenuPreviewItemModifier from "../MenuPreviewItemModifier/MenuPreviewItemModifier";

export default function MenuPreviewItemModifierGroup({
  group,
}: {
  group: MenuItemModifierGroupInterface;
}) {
  return (
    <div>
      <Typography
        as="subtitle-1"
        className={`font-bold mb-2 ${group.errors.length ? "text-red-500" : ""
          }`}
      >
        {group.name}
      </Typography>

      {group.errors.map((err) => {
        return (
          <span
            key={err.property + err.errorCode}
            className="flex mb-1 text-red-500"
          >
            <Typography as="body-2">
              <ExclamationIcon
                className="w-[1.125rem] h-[1.125rem] mr-2"
                viewBox="2 2 20 20"
              />
            </Typography>
            <Typography as="body-2">{err.message}</Typography>
          </span>
        );
      })}

      {group.modifiers.map((modifier) => (
        <div key={group.name + modifier.item.productId}>
          <MenuPreviewItemModifier modifier={modifier} />
        </div>
      ))}
    </div>
  );
}
