import React from "react";
import { NavLink } from "react-router-dom";

interface NavDropdownItemProps extends React.ComponentPropsWithoutRef<"a"> {
  to: string;
  children: React.ReactNode;
}

export default function NavDropdownItem({
  to,
  children,
  ...rest
}: NavDropdownItemProps) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? "block bg-gray-100 text-black px-3 py-2 first-of-type:rounded-t-md last-of-type:rounded-b-md active"
          : "block text-black hover:bg-gray-50  px-3 py-2 first-of-type:rounded-t-md last-of-type:rounded-b-md"
      }
      {...rest}
    >
      {children}
    </NavLink>
  );
}
