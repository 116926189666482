import { OrderInterface } from "../../types";

import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";

import { CheckCircleIcon, CloseIcon } from "../../../../../components/Icons";

interface RefundConfirmationModalProps {
  order: OrderInterface;
  onClose: () => void;
}

export default function RefundConfirmationModal({
  order,
  onClose,
}: RefundConfirmationModalProps) {
  return (
    <div
      id="refund-confirmation-modal"
      tabIndex={-1}
      aria-hidden={false}
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-full bg-black bg-opacity-40"
    >
      <div
        role="dialog"
        className="relative w-full h-full md:h-auto md:max-w-md "
      >
        {/* <!-- Modal content --> */}
        <div className="flex flex-col w-full h-full md:h-auto md:rounded-lg md:shadow pt-10 bg-white">
          <div className="flex-none">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-toggle="refund-confirmation-modal"
              onClick={onClose}
            >
              <CloseIcon className="w-5 h-5" />
            </button>
          </div>

          {/*  <!-- Modal body --> */}
          <div className="p-6 grow">
            <div className="flex justify-center mb-5">
              <CheckCircleIcon className="w-11 h-11 text-cinchio-blue-500" />
            </div>
            <p className="text-lg text-center font-medium mb-5">
              Customer refund has been submitted for Order #
              {order.transactionId}!
            </p>

            <div className="flex flex-col">
              <div className="flex justify-between mb-3 items-center">
                <span className="text-sm font-medium">Order ID:</span>
                <span className="text-sm">{order.orderId}</span>
              </div>
              <div className="flex justify-between mb-3 items-center">
                <span className="text-sm font-medium">Last-4 Phone #:</span>
                <span className="text-sm">{order.lastFourPhone}</span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-sm font-medium">Total:</span>
                <span className="text-sm">${order.total.toFixed(2)}</span>
              </div>
            </div>
          </div>
          {/*  <!-- Modal footer --> */}
          <div className="flex-none py-4 px-6 pb-11 flex justify-center">
            {order && (
              <PrimaryButton className="w-full" onClick={() => onClose()}>
                Done
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
