import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import FeatureWidgetGrid from "../../../components/FeatureWidgetGrid";
import RequestStatusError from "../../../components/RequestStatusError";
import RequestStatusLoading from "../../../components/RequestStatusLoading";
import RequestStatusSuccess from "../../../components/RequestStatusSuccess";
import Typography from "../../../components/Typography";
import useOrgId from "../../../hooks/organizations/useOrgId";
import { usePaymentCurrencies } from "../../../hooks/payment/usePaymentCurrencies";
import { usePaymentGatewayFields } from "../../../hooks/payment/usePaymentGatewayFields";
import { usePaymentProviders } from "../../../hooks/payment/usePaymentProviders";
import useOrganizationRegions from "../../../hooks/regions/useOrganizationRegions";
import useCreateSitePaymentConfig from "../../../hooks/sites/useCreateSitePaymentConfig";
import useUpdateActiveSite from "../../../hooks/sites/useUpdateActiveSite";
import useUpdateSitePaymentConfig from "../../../hooks/sites/useUpdateSitePaymentConfig";
import useUserSites from "../../../hooks/sites/useUserSites";
import { fetchActiveSite } from "../../../services/sites";
import {
  ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY,
  ORGANIZATION_SITE_QUERY_KEY,
  USERS_SITES_QUERY_KEY,
} from "../constants";
import {
  RegionSiteWithRegionIncludedInterface,
  UpdateActiveSiteFormValues,
} from "../types";
import ActiveSiteCard from "./components/ActiveSiteCard";
import ActiveSiteModal from "./components/ActiveSiteModal";
import ResetSite from "./components/ResetSite";

export default function ActiveSites() {
  const { data, isLoading } = useUserSites();
  const orgId = useOrgId();

  const [queryParams] = useSearchParams();

  const queryClient = useQueryClient();

  const [siteToBeEdited, setSiteToBeEdited] = useState<
    RegionSiteWithRegionIncludedInterface | undefined | null
  >(null);

  useLayoutEffect(() => {
    const siteFromQueryParams =
      data && queryParams.get("site")
        ? data.find((site) => site.id === queryParams.get("site"))
        : null;

    if (siteFromQueryParams) {
      setSiteToBeEdited(siteFromQueryParams);
    }
  }, [data, queryParams]);

  const onShowEditModal = (site: RegionSiteWithRegionIncludedInterface) => {
    setSiteToBeEdited(site);
  };

  const onHideEditModal = () => setSiteToBeEdited(null);

  const onPrefetchSite = (site: RegionSiteWithRegionIncludedInterface) => {
    queryClient.prefetchQuery(
      [ORGANIZATION_SITE_QUERY_KEY, orgId, site.id],
      () => fetchActiveSite(site.id),
      {
        staleTime: 1 * 60 * 1000,
      }
    );
  };

  usePaymentProviders();

  usePaymentGatewayFields();

  usePaymentCurrencies();

  useOrganizationRegions();

  const {
    mutateAsync: updateActiveSite,
    data: updateActiveSiteData,
    status: updateActiveSiteStatus,
    error: updateActiveSiteError,
    reset: updateActiveSiteReset,
  } = useUpdateActiveSite();

  const {
    mutateAsync: updateSitePaymentConfig,
    status: updateSitePaymentConfigStatus,
    error: updateSitePaymentConfigError,
    reset: updateSitePaymentConfigReset,
  } = useUpdateSitePaymentConfig();

  const {
    mutateAsync: createSitePaymentConfig,
    status: createSitePaymentConfigStatus,
    error: createSitePaymentConfigError,
    reset: createSitePaymentConfigReset,
  } = useCreateSitePaymentConfig();

  const onUpdateActiveSite = async (values: UpdateActiveSiteFormValues) => {
    return updateActiveSite({
      siteId: values.siteId,
      region: values.region,
      location: values.location,
      colorConfig: values.colorConfig,
    })
      .then(async () => {
        onHideEditModal();
        queryClient.invalidateQueries([USERS_SITES_QUERY_KEY, orgId]);
        queryClient.invalidateQueries([
          ORGANIZATION_SITE_QUERY_KEY,
          orgId,
          values.siteId,
        ]);

        if (values.hasPaymentConfig) {
          return updateSitePaymentConfig({
            siteId: values.siteId,
            kiosk: values.kiosk,
            mobile: values.mobile,
          })
            .then(() => {
              queryClient.invalidateQueries([
                ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY,
                orgId,
                values.siteId,
              ]);
            })
            .catch(() => {
              queryClient.invalidateQueries([
                ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY,
                orgId,
                values.siteId,
              ]);
            });
        } else {
          return createSitePaymentConfig({
            siteId: values.siteId,
            kiosk: values.kiosk,
            mobile: values.mobile,
          })
            .then(() => {
              queryClient.invalidateQueries([
                ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY,
                orgId,
                values.siteId,
              ]);
            })
            .catch(() => {
              queryClient.invalidateQueries([
                ORGANIZATION_SITE_PAYMENT_CONFIG_QUERY_KEY,
                orgId,
                values.siteId,
              ]);
            });
        }
      })
      .catch(() => {
        queryClient.invalidateQueries([USERS_SITES_QUERY_KEY, orgId]);
        queryClient.invalidateQueries([
          ORGANIZATION_SITE_QUERY_KEY,
          orgId,
          values.siteId,
        ]);
      });
  };

  const _updateActiveSiteError = updateActiveSiteError
    ? (updateActiveSiteError as AxiosError<{ message: string }>)
    : null;

  const _updateSitePaymentConfigError = updateSitePaymentConfigError
    ? (updateSitePaymentConfigError as AxiosError<{ message: string }>)
    : null;

  const _createSitePaymentConfigError = createSitePaymentConfigError
    ? (createSitePaymentConfigError as AxiosError<{ message: string }>)
    : null;

  if (isLoading || !data) {
    return null;
  }

  return (
    <div className="pt-6">
      <Helmet>
        <title>Connect - Organization - Active sites</title>
      </Helmet>
      <FeatureWidgetGrid>
        {data.map((site) => (
          <ActiveSiteCard key={`active-site-card-${site.id}`} site={site}>
            <div className="flex justify-between">
              {site.name.toLowerCase().includes("lab onboard testing") ? (
                <ResetSite />
              ) : null}

              <PrimaryButton
                onMouseEnter={() => onPrefetchSite(site)}
                onClick={() => onShowEditModal(site)}
                className="py-1.5"
              >
                Edit
              </PrimaryButton>
            </div>
          </ActiveSiteCard>
        ))}
      </FeatureWidgetGrid>

      {siteToBeEdited ? (
        <ActiveSiteModal
          site={siteToBeEdited}
          onSubmit={onUpdateActiveSite}
          onClose={onHideEditModal}
        />
      ) : null}

      {updateActiveSiteStatus === "loading" && (
        <RequestStatusLoading>Submitting...</RequestStatusLoading>
      )}
      {updateActiveSiteStatus === "success" &&
        updateActiveSiteData &&
        updateSitePaymentConfigStatus === "success" && (
          <RequestStatusSuccess onCloseCallback={() => updateActiveSiteReset()}>
            <Typography as="caption">
              {updateActiveSiteData.name} has been successfully updated.
            </Typography>
          </RequestStatusSuccess>
        )}
      {updateActiveSiteStatus === "error" && _updateActiveSiteError && (
        <RequestStatusError onOkCallback={() => updateActiveSiteReset()}>
          <Typography as="caption">
            {_updateActiveSiteError?.response?.data.message}
          </Typography>
        </RequestStatusError>
      )}

      {updateSitePaymentConfigStatus === "error" &&
        _updateSitePaymentConfigError && (
          <RequestStatusError
            onOkCallback={() => updateSitePaymentConfigReset()}
          >
            <Typography as="caption">
              {_updateSitePaymentConfigError.response?.data.message}
            </Typography>
          </RequestStatusError>
        )}

      {createSitePaymentConfigStatus === "error" &&
        _createSitePaymentConfigError && (
          <RequestStatusError
            onOkCallback={() => createSitePaymentConfigReset()}
          >
            <Typography as="caption">
              {_createSitePaymentConfigError.response?.data.message}
            </Typography>
          </RequestStatusError>
        )}
    </div>
  );
}
