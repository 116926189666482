import { useMutation } from "@tanstack/react-query";
import { createStoreKioskSetup } from "../../services/stores";

export default function useCreateStoreKioskSetup() {
  return useMutation(
    ({
      storeId,
      screens,
    }: {
      storeId: string;
      screens: {
        header: string;
        headerLogo: string | undefined;
        welcome: (string | undefined)[];
        welcomeLogo: string;
      };
    }) => createStoreKioskSetup(storeId, screens)
  );
}
