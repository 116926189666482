import { createContext, useMemo } from "react";
import currencySymbol from "../../utils/currencySymbol";

interface MenuCurrencyContextProps {
  currency: string;
}

const MenuCurrencyContext = createContext<MenuCurrencyContextProps>({
  currency: "USD",
} as MenuCurrencyContextProps);

function MenuCurrencyProvider({
  currency,
  children,
}: {
  currency: string;
  children: React.ReactNode;
}) {
  const contextValue = useMemo(
    () => ({
      currency: currencySymbol(currency),
    }),
    [currency]
  );

  return (
    <MenuCurrencyContext.Provider value={contextValue}>
      {children}
    </MenuCurrencyContext.Provider>
  );
}

export { MenuCurrencyProvider, MenuCurrencyContext };
