import { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import Checkbox from "../../../../../components/Forms/Checkbox";
import FormControl from "../../../../../components/Forms/FormControl";
import TextArea from "../../../../../components/Forms/TextArea";
import SidePanel, {
  SidePanelVisibilityProps,
} from "../../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../../components/SidePanel/SidePanelLayout";
import Typography from "../../../../../components/Typography";
import SiteColorField from "../../../SiteSetup/components/SiteColorField";
import { StoreSetupFormValues } from "../../types";
import * as yup from "yup";
import { useValidationSchema } from "../../../../../hooks/validations/useValidationSchema";
import { OrganizationStoreInterface } from "../../../types";
import { AddCircleOutlinedIcon } from "../../../../../components/Icons";

const DESCRIPTION_LENGTH = 84;

const validateStore = yup.object({
  description: yup.string().required("Please enter English description."),
  descriptionSpanish: yup
    .string()
    .required("Please enter Spanish description."),
  color: yup.string().when("channels", {
    is: (value: [] | undefined) => value && value.length > 0,
    then: (schema) =>
      schema
        .required("Please enter a color")
        .matches(/^[0-9A-F]{6}$/i, "Please enter a valid color"),
    otherwise: (schema) => schema.nullable(),
  }),
  channels: yup
    .array()
    .test(
      "channels-requred-if-color",
      "Please select at least one channel",
      (value, context) => {
        if (context.parent.color) {
          if (!value || value.length === 0) return false;
        }
        return true;
      }
    ),
});

interface StoreSetupSidePanelProps extends SidePanelVisibilityProps {
  store: OrganizationStoreInterface;
  onSave: (values: StoreSetupFormValues & { [key: string]: unknown }) => void;
}
export default function StoreSetupSidePanel({
  open,
  store,
  onSave,
  onClose,
}: StoreSetupSidePanelProps) {
  const shouldShowColorFields = store.primaryColor && store.primaryColor !== "";

  const [showColorFields, setShowColorFields] = useState(shouldShowColorFields);

  const onShowColorFields = () => setShowColorFields(true);

  const validate = useValidationSchema(validateStore);

  const initialValues = useMemo(
    () => ({
      description: store.description,
      descriptionSpanish: store.descriptionSpanish,
      color: store.primaryColor ? store.primaryColor.replaceAll("#", "") : "",
      channels: store.colorChoice
        ? store.colorChoice === "both"
          ? ["mobile", "kiosk"]
          : store.colorChoice !== "none"
          ? [store.colorChoice.toLowerCase()]
          : []
        : [],
    }),
    [store]
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={onSave}
        validate={validate}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader
                title="Store description and branding"
                onClose={onClose}
              />

              <SidePanelContent>
                <div className="border-b border-b-gray-300 pb-3 mb-8">
                  <Typography as="h6" className="mb-4">
                    Store description
                  </Typography>
                  <Field<string>
                    name="description"
                    render={({ input, meta }) => (
                      <FormControl>
                        <TextArea
                          input={input}
                          meta={meta}
                          label="Store description (English)"
                          placeholder="Add a description"
                          maxLength={DESCRIPTION_LENGTH}
                          helperText={`Maximum ${DESCRIPTION_LENGTH} characters.`}
                        />
                      </FormControl>
                    )}
                  />

                  <Field<string>
                    name="descriptionSpanish"
                    render={({ input, meta }) => (
                      <FormControl>
                        <TextArea
                          input={input}
                          meta={meta}
                          label="Store description (Spanish)"
                          placeholder="Add a description"
                          maxLength={DESCRIPTION_LENGTH}
                          helperText={`Maximum ${DESCRIPTION_LENGTH} characters.`}
                        />
                      </FormControl>
                    )}
                  />
                </div>
                <Typography as="h6" className="mb-2.5">
                  Store brand color{" "}
                  <span className="font-normal">(optional)</span>
                </Typography>
                <Typography as="body-2" className="mb-4">
                  Defaulted to the primary brand color (set during partner
                  onboarding). You can change to a store brand color.
                </Typography>

                {!showColorFields ? (
                  <button
                    className="flex font-bold underline text-xs items-center"
                    onClick={onShowColorFields}
                  >
                    <AddCircleOutlinedIcon className="w-5 h-5 text-black mr-2" />
                    Add store color
                  </button>
                ) : (
                  <div className="flex flex-col">
                    <Field<string>
                      name="color"
                      render={({ input, meta }) => (
                        <FormControl>
                          <SiteColorField
                            label="6 digit hex code"
                            input={input}
                            meta={meta}
                          />
                        </FormControl>
                      )}
                    />

                    <Typography as="subtitle-1" className="font-semibold mb-4">
                      Apply color to following channels:
                    </Typography>
                    <Field
                      name="channels"
                      type="checkbox"
                      value="mobile"
                      render={({ input, meta }) => (
                        <FormControl>
                          <Checkbox label="Mobile" input={input} meta={meta} />
                        </FormControl>
                      )}
                    />
                    <Field
                      name="channels"
                      type="checkbox"
                      value="kiosk"
                      render={({ input, meta }) => (
                        <FormControl>
                          <Checkbox label="Kiosk" input={input} meta={meta} />
                        </FormControl>
                      )}
                    />
                  </div>
                )}
              </SidePanelContent>

              <SidePanelFooter>
                <div className="flex justinfy-evenly">
                  <SecondaryButton
                    onClick={onClose}
                    type="button"
                    className="w-full mr-1"
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    disabled={submitting}
                    isLoading={submitting}
                    type="submit"
                    className="w-full ml-1"
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
