import { useMutation } from "@tanstack/react-query";
import { inviteUser } from "../../services/users";

export default function useInviteUser() {
  return useMutation(
    ({
      email,
      regions,
      roles,
      sites,
    }: {
      email: string;
      regions: string[];
      roles: string[];
      sites?: string[];
    }) => inviteUser(email, regions, roles, sites)
  );
}
