import { ExclamationIcon } from "../../../../../components/Icons";
import Typography from "../../../../../components/Typography";

export default function MenuPreviewItemProperty({
  title,
  errors,
  children,
}: {
  title: string;
  errors: string[] | undefined;
  children: React.ReactNode;
}) {
  return (
    <span className={`block mb-1 ${!!errors ? "text-error-500" : ""}`}>
      <div className="flex">
        <Typography as="body-2" className="flex">
          {!errors ? null : (
            <span className="w-[1.125rem] mr-2">
              <ExclamationIcon
                className="w-[1.125rem] h-[1.125rem]"
                viewBox="2 2 20 20"
              />
            </span>
          )}
          <span className="">
            <strong className="mr-1">{title}</strong>{" "}
            <span>
              {!!errors
                ? errors.map((error, index) => (
                  <span key={`${title}-${index}-${error}`} className="mb-1">
                    {error}
                  </span>
                ))
                : children}
            </span>
          </span>
        </Typography>
      </div>
    </span>
  );
}
