import { useQuery } from "@tanstack/react-query";
import { ORGANIZATION_STORES_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchSiteStoresForOnboarding } from "../../services/stores";

export default function useSiteStoresForOnboarding(siteId: string) {
  return useQuery(
    [ORGANIZATION_STORES_QUERY_KEY, siteId],
    () => fetchSiteStoresForOnboarding(siteId),
    {
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000,
      retry: false,
      enabled: siteId !== "",
    }
  );
}
