import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import OrderList from "./components/OrderList";

import useTodaysOrders from "../../../hooks/orders/useTodaysOrders";
import OrderSearchForm from "./components/OrderSearchForm";

import RefundPromptSidePanel from "./components/RefundPromptSidePanel";
import { OrderInterface } from "./types";
import Spinner from "../../../components/Spinner";
import RefundProcessingModal from "./components/RefundProcessingModal";
import RefundConfirmationModal from "./components/RefundConfirmationModal";
import useRefundOrder from "../../../hooks/refund/useRefundOrder";
import RefundErrorModal from "./components/RefundErrorModal";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { ORDERS_QUERY_KEY } from "./constants";
import useRestaurantsOutletContext from "../../../context/restaurants/useRestaurantsOutletContext";
import RestaurantsStart from "../components/RestaurantsStart";
import FeatureTitle from "../../../components/FeatureTitle";
import OrderReportIssue from "./components/OrderReportIssue";
import useReportOrderIssue from "../../../hooks/refund/useReportOrderIssue";
import RequestStatusSuccess from "../../../components/RequestStatusSuccess";
import RequestStatusError from "../../../components/RequestStatusError";
import Typography from "../../../components/Typography";
import RefundConfirmModal from "./components/RefundConfirmModal";
import { Helmet } from "react-helmet-async";
import useOrgId from "../../../hooks/organizations/useOrgId";

export default function Refund() {
  const { site, store } = useRestaurantsOutletContext();
  const orgId = useOrgId();
  const [queryParams, setQueryParams] = useSearchParams();
  // search form
  const {
    isFetching,
    isSuccess,
    data: ordersData,
    remove,
  } = useTodaysOrders(store, queryParams.get("search") || null);

  // refund order
  const refundOrder = useRefundOrder();
  const [refundOrderError, setRefundOrderError] = useState<AxiosError | null>(
    null
  );

  const queryClient = useQueryClient();

  const onCloseRefundErrorModal = () => {
    setRefundOrderError(null);
    setSelectedOrder(null);
  };

  // search submit
  const onSearchSubmit = (values: { search: string }) => {
    remove();
    setQueryParams({
      ...values,
      site,
      store,
    });
  };

  const onSearchClear = () => {
    setQueryParams({ site, store });
  };

  const [selectedOrder, setSelectedOrder] = useState<OrderInterface | null>(
    null
  );

  // refund prompt

  const [showRefundPromptModal, setShowRefundPromptModal] = useState(false);

  const onShowRefundPromptModal = (order: OrderInterface) => {
    setSelectedOrder(order);
    setShowRefundPromptModal(true);
  };

  const onCancelRefundPromptModal = () => {
    setShowRefundPromptModal(false);
    setSelectedOrder(null);
  };

  const onRefund = async (order: OrderInterface) => {
    setShowRefundPromptModal(false);
    setShowConfirmRefundModal(false);
    setSelectedOrder(order);

    onShowRefundProcessingModal();

    refundOrder.mutate(
      {
        storeId: store,
        order,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([ORDERS_QUERY_KEY, orgId, store]);

          onHideRefundProcessModal();
          onShowRefundConfirmationModal();
        },
        onError: (error) => {
          onHideRefundProcessModal();
          setRefundOrderError(error as AxiosError);
        },
      }
    );
  };

  // confirm refund
  const [showConfirmRefundModal, setShowConfirmRefundModal] = useState(false);
  const onShowConfirmRefundModal = () => setShowConfirmRefundModal(true);
  const onHideConfirmRefundModal = () => setShowConfirmRefundModal(false);

  // refund processing modal
  const [showRefundProcessingModal, setShowRefundProcessingModal] =
    useState(false);

  const onShowRefundProcessingModal = () => setShowRefundProcessingModal(true);

  const onHideRefundProcessModal = () => setShowRefundProcessingModal(false);

  // refund confirmation modal
  const [showRefundConfirmationModal, setShowRefundConfirmationModal] =
    useState(false);

  const onShowRefundConfirmationModal = () =>
    setShowRefundConfirmationModal(true);
  const onCloseRefundConfirmationModal = () =>
    setShowRefundConfirmationModal(false);

  // report issue

  const [reportIssueOrder, setReportIssueOrder] =
    useState<OrderInterface | null>(null);

  const onShowReportIssue = (order: OrderInterface) =>
    setReportIssueOrder(order);
  const onHideReportIssue = () => setReportIssueOrder(null);

  const {
    mutateAsync: reportIssue,
    status: reportIssueStatus,
    error: reportIssueError,
    reset: reportIssueReset,
  } = useReportOrderIssue();

  const onReportIssue = (values: { notes: string }) => {
    return (
      reportIssueOrder &&
      reportIssue({
        restaurantId: store,
        orderId: reportIssueOrder.orderId,
        notes: values.notes,
      })
        .then(() => onHideReportIssue())
        .catch(() => null)
    );
  };

  const searchFormInitialValues = {
    site,
    store,
    search: queryParams.get("search") || "",
  };

  if (site === "" || store === "") {
    return <RestaurantsStart />;
  }

  const _errorReportIssue = reportIssueError
    ? (reportIssueError as AxiosError<{ message: string }>)
    : null;

  return (
    <>
      <Helmet>
        <title>Connect - Sites - Orders</title>
      </Helmet>
      <FeatureTitle className="mb-7">Orders</FeatureTitle>
      <div>
        <OrderSearchForm
          initialValues={searchFormInitialValues}
          resultsDisplayed={ordersData !== undefined}
          onSubmit={onSearchSubmit}
          onClear={onSearchClear}
        />
      </div>

      {isFetching && (
        <div className="mb-3">
          <Spinner>Searching...</Spinner>
        </div>
      )}

      {!isFetching && isSuccess && ordersData && (
        <div className="mb-3">
          <OrderList
            orders={ordersData}
            onRefundPrompt={onShowRefundPromptModal}
            onShowReportIssue={onShowReportIssue}
          />
        </div>
      )}

      <RefundPromptSidePanel
        open={showRefundPromptModal}
        order={selectedOrder}
        storeId={store}
        onRefund={onShowConfirmRefundModal}
        onReportIssue={onShowReportIssue}
        onClose={onCancelRefundPromptModal}
      />

      {selectedOrder && showConfirmRefundModal && (
        <RefundConfirmModal
          order={selectedOrder}
          onConfirm={() => onRefund(selectedOrder)}
          onClose={onHideConfirmRefundModal}
        />
      )}

      {selectedOrder && showRefundProcessingModal && <RefundProcessingModal />}

      {selectedOrder && showRefundConfirmationModal && (
        <RefundConfirmationModal
          order={selectedOrder}
          onClose={onCloseRefundConfirmationModal}
        />
      )}

      {reportIssueOrder && (
        <OrderReportIssue
          order={reportIssueOrder}
          onSubmit={onReportIssue}
          onClose={onHideReportIssue}
        />
      )}

      {refundOrderError && (
        <RefundErrorModal
          error={refundOrderError}
          onClose={onCloseRefundErrorModal}
        />
      )}

      {reportIssueStatus === "success" && (
        <RequestStatusSuccess onCloseCallback={() => reportIssueReset()}>
          <div className="flex flex-col">
            <Typography as="body-1" className="font-semibold mb-2">
              Thank you! Issue has been sent.
            </Typography>
            <Typography as="body-2">
              This issue will be reviewed and addressed by the support team as
              soon as possible.
            </Typography>
          </div>
        </RequestStatusSuccess>
      )}

      {reportIssueStatus === "error" && _errorReportIssue && (
        <RequestStatusError onOkCallback={() => reportIssueReset()}>
          <div className="flex flex-col">
            <Typography as="body-1">
              Failed to report issue! Please try again.
            </Typography>
            <Typography as="body-2">
              {_errorReportIssue?.response?.data.message}
            </Typography>
          </div>
        </RequestStatusError>
      )}
    </>
  );
}
