import { useQuery } from "@tanstack/react-query";
import { ORGANIZATION_PAYMENT_GATEWAY_FIELDS_QUERY_KEY } from "../../features/Organizations/constants";
import { fetchPaymentGatewayFields } from "../../services/payment";
import useOrgId from "../organizations/useOrgId";

export function usePaymentGatewayFields() {
  const orgId = useOrgId();
  return useQuery(
    [ORGANIZATION_PAYMENT_GATEWAY_FIELDS_QUERY_KEY, orgId],
    () => fetchPaymentGatewayFields(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 1000 * 60,
    }
  );
}
