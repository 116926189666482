import { ExclamationIcon } from "../../../../../components/Icons";
import Typography from "../../../../../components/Typography";

export default function MenuPreviewItemImage({
  url,
  errors,
}: {
  url: string | null;
  errors: string[] | undefined;
}) {
  return (
    <div className="!w-[100px] !h-[100px] ml-4">
      {!errors ? (
        <div
          role="presentation"
          className="bg-cover bg-center block !w-[100px] !h-[100px] rounded"
          style={{ backgroundImage: `url(${url})` }}
        />
      ) : (
        <div className="flex flex-col justify-center items-center w-[100px] h-[100px] rounded text-red-500 bg-gray-100">
          <ExclamationIcon
            className="w-[1.125rem] h-[1.125rem] mb-1"
            viewBox="2 2 20 20"
          />
          <Typography as="body-2" className="text-center">
            {errors[0]}
          </Typography>
        </div>
      )}
    </div>
  );
}
