import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import FeatureContentWrapper from "../../../components/FeatureContentWrapper";
import FeatureHeader from "../../../components/FeatureHeader";
import FeatureHeaderSiteDropdown from "../../../components/FeatureHeader/FeatureHeaderSiteDropdown";
import FeatureTitle from "../../../components/FeatureTitle";
import FeatureWidgetGrid from "../../../components/FeatureWidgetGrid";
import useActiveSite from "../../../hooks/sites/useActiveSite";
import useSiteStoresForOnboarding from "../../../hooks/stores/useSiteStoresForOnboarding";
import { OrganizationStoreInterface } from "../types";
import StoreCard from "./components/StoreCard";
import StoreSetupModal from "./components/StoreSetupModal";
import StoreSetupStart from "./components/StoreStoreStart/StoreSetupStart";

export default function StoreSetup() {
  const [queryParams] = useSearchParams();

  const siteId = queryParams.get("site") || "";

  useActiveSite(siteId);

  const { data: stores } = useSiteStoresForOnboarding(siteId);

  const [storeToBeEdited, setStoreToBeEdited] =
    useState<OrganizationStoreInterface | null>(null);

  const onShowStoreSetupModal = (store: OrganizationStoreInterface) => {
    setStoreToBeEdited(store);
  };

  const onHideStoreSetupModal = () => setStoreToBeEdited(null);

  if (siteId === "") {
    return (
      <FeatureContentWrapper>
        <Helmet>
          <title>Connect - Organization - Store setup</title>
        </Helmet>

        <div className="border-b border-b-gray-300 mb-7">
          <FeatureHeader>
            <FeatureHeaderSiteDropdown />
          </FeatureHeader>
        </div>
        <StoreSetupStart />
      </FeatureContentWrapper>
    );
  }

  return (
    <FeatureContentWrapper>
      <Helmet>
        <title>Connect - Organization - Store setup</title>
      </Helmet>
      <div className="border-b border-b-gray-300 mb-7">
        <FeatureHeader>
          <FeatureHeaderSiteDropdown />
        </FeatureHeader>
      </div>

      <div className="flex-auto flex flex-col">
        <div className="flex flex-col lg:flex-row">
          <FeatureTitle className="mb-8">Store setup</FeatureTitle>
        </div>

        <FeatureWidgetGrid>
          {stores && stores.length > 0
            ? stores.map((store) => (
                <StoreCard
                  key={`store-${store.centegraLocationId}-${store.name}`}
                  store={store}
                >
                  <PrimaryButton
                    className="py-1.5"
                    onClick={() => onShowStoreSetupModal(store)}
                  >
                    {store.isOnboarded && store.id !== null ? "Edit" : "Setup"}
                  </PrimaryButton>
                </StoreCard>
              ))
            : null}
        </FeatureWidgetGrid>
      </div>

      {storeToBeEdited ? (
        <StoreSetupModal
          siteId={siteId}
          store={storeToBeEdited}
          onClose={onHideStoreSetupModal}
        />
      ) : null}
    </FeatureContentWrapper>
  );
}
