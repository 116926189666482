import { useMutation } from "@tanstack/react-query";
import { addPromotion } from "../../services/promotions";

export default function useAddPromotion() {
  return useMutation(
    ({
      partnerId,
      discountCode,
      productId,
    }: {
      partnerId: string;
      discountCode: string;
      productId: number;
    }) => addPromotion(partnerId, discountCode, productId)
  );
}
