import HandshakeIcon from "../../../../../components/Icons/HandshakeIcon";
import OnboardCard from "../../../components/OnboardCard";
import { RegionSiteWithRegionIncludedInterface } from "../../../types";

interface ActiveSiteCardProps {
  site: RegionSiteWithRegionIncludedInterface;
  children: React.ReactNode;
}

export default function ActiveSiteCard({
  site,
  children,
}: ActiveSiteCardProps) {
  return (
    <div>
      <OnboardCard
        icon={
          <HandshakeIcon
            className="w-10 h-9 text-gray-500"
            viewBox="3 4 42 38"
          />
        }
        title={site.name}
        type="site"
      >
        {children}
      </OnboardCard>
    </div>
  );
}
