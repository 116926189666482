import { useState, useEffect, useRef } from "react";

import InputField from "../Forms/InputField";

import { FieldRenderProps } from "react-final-form";
import { SearchIcon } from "../Icons";

type SearchInputProps = FieldRenderProps<string, any> & {
  onClear?: () => void;
};

export default function SearchInput({
  input,
  meta,
  onClear,
  ...rest
}: SearchInputProps) {
  const [showClearButton, setShowClearButton] = useState(input.value !== "");

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    input.value !== "" ? setShowClearButton(true) : setShowClearButton(false);
  }, [input.value, setShowClearButton]);

  useEffect(() => {
    const onClickAway = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      wrapperRef.current?.contains(target) &&
      target.nodeName !== "P" &&
      input.value !== ""
        ? setShowClearButton(true)
        : setShowClearButton(false);
    };

    document.addEventListener("mousedown", onClickAway);
    return () => document.removeEventListener("mousedown", onClickAway);
  }, [input.value]);

  const isDisabled = rest.disabled || false;

  return (
    <div className="w-full relative mb-1" ref={wrapperRef}>
      <InputField
        aria-label="Search"
        input={input}
        meta={meta}
        {...rest}
        type="text"
        className="h-12"
        icon={
          <SearchIcon
            data-testid="search-icon"
            className={`w-6 h-6 text-gray-500 ${isDisabled && "opacity-50"}`}
          />
        }
        allowClear={showClearButton}
        onClear={() => {
          typeof onClear === "function" && onClear();
          input.onChange("");
          inputRef.current?.focus();
        }}
        disabled={isDisabled}
      />
    </div>
  );
}
