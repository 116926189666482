import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import ActionModal from "../../../../components/ActionModal";
import DangerButton from "../../../../components/Buttons/DangerButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { WarningOutlinedIcon } from "../../../../components/Icons";
import Pagination from "../../../../components/Pagination";
import Spinner from "../../../../components/Spinner";
import useOrgId from "../../../../hooks/organizations/useOrgId";
import useConnectUsers from "../../../../hooks/users/useConnectUsers";
import useDeleteConnectUser from "../../../../hooks/users/useDeleteConnectUser";
import useSearchConnectUsers from "../../../../hooks/users/useSearchConnectUser";
import useUpdateConnectUser from "../../../../hooks/users/useUpdateConnectUser";
import { CONNECT_USERS_QUERY_KEY } from "../../constants";
import { UpdateUserFormValuesIntefarce, UserInterface } from "../../types";
import EditUserSidePanel from "../EditUserSidePanel";
import MembersCard from "../MembersCard";
import MembersTable from "../MembersTable";
import SearchMembersForm from "../SearchMembersForm";

export default function Members() {
  const [queryParams, setQueryParams] = useSearchParams();
  const orgId = useOrgId();

  const currentPage = parseInt(queryParams.get("page") || "1", 10);

  const queryClient = useQueryClient();

  const { data, isLoading } = useConnectUsers(currentPage);

  const [memberToBeEdited, setMemberToBeEdited] =
    useState<UserInterface | null>(null);

  const updateUser = useUpdateConnectUser();

  const onUpdateMember = async (values: UpdateUserFormValuesIntefarce) => {
    const selectedRegions = values.validateSites ? [] : values.regions;

    let updateUserData: {
      auth0UserId: string;
      regions: string[];
      roles: string[];
      sites?: string[];
    } = {
      auth0UserId: values.id,
      regions: selectedRegions!,
      roles: Object.values(values.roles),
      sites: values.validateSites ? values.sites : [],
    };

    return updateUser
      .mutateAsync(updateUserData)
      .then(() => {
        queryClient.invalidateQueries([CONNECT_USERS_QUERY_KEY, orgId]);
        onHideEditMember();
        toast.success("User successfully updated.");
      })
      .catch((error) => {
        const _error = error as AxiosError<{ message: string }>;

        toast.error(`Failed to Update User. ${_error.response?.data.message}`);
      });
  };
  const onShowEditMember = (member: UserInterface) =>
    setMemberToBeEdited(member);
  const onHideEditMember = () => setMemberToBeEdited(null);

  const deleteUser = useDeleteConnectUser();
  const [memberToBeDeleted, setMemberToBeDeleted] =
    useState<UserInterface | null>(null);

  const [deletingMember, setDeletingMember] = useState(false);

  const onShowDeleteMember = (member: UserInterface) =>
    setMemberToBeDeleted(member);

  const onHideDeleteMember = () => {
    setMemberToBeDeleted(null);
    setDeletingMember(false);
  };

  const onDeleteMember = async (member: UserInterface) => {
    setDeletingMember(true);
    return deleteUser
      .mutateAsync({
        auth0UserId: member.id,
      })
      .then(() => {
        queryClient.invalidateQueries([CONNECT_USERS_QUERY_KEY, orgId]);
        onHideDeleteMember();
        onHideEditMember();
        toast.success(`${member.email} was successfully deleted.`);
      })
      .catch(() => {
        onHideDeleteMember();
        onHideEditMember();
        toast.error(`Failed to delete ${member.email}. Please try again.`);
      });
  };

  // search

  const {
    data: searchData,
    remove: removeSearch,
    isFetching: isSearchingUsers,
  } = useSearchConnectUsers(queryParams.get("email") || "");

  const searchFormInitialValues = {
    search: queryParams.get("email") || "",
  };

  const onSearch = (values: { search: string }) => {
    removeSearch();
    setQueryParams({
      email: values.search || "",
      page: "1",
    });
  };

  const onClearSearch = () => {
    setQueryParams({
      page: "1",
      email: "",
    });
  };

  if (isLoading || !data) {
    return null;
  }

  const { users, totalPages } = data;

  const membersData = searchData === undefined ? users : searchData.users;
  const membersTotalPages =
    searchData === undefined ? totalPages : searchData.totalPages;

  return (
    <div>
      <Helmet>
        <title>Connect - Users - Members</title>
      </Helmet>
      <div className="py-2">
        <SearchMembersForm
          initialValues={searchFormInitialValues}
          onSubmit={onSearch}
          onClear={onClearSearch}
        />
      </div>
      {isSearchingUsers && (
        <div className="mb-3">
          <Spinner>Searching...</Spinner>
        </div>
      )}

      {!isLoading && !isSearchingUsers && (
        <div className="block md:hidden">
          {membersData.length === 0 ? (
            <div>No results...</div>
          ) : (
            membersData.map((member) => (
              <MembersCard
                key={member.id}
                member={member}
                onEditMember={onShowEditMember}
                onDeleteMember={onShowDeleteMember}
              />
            ))
          )}
        </div>
      )}
      {!isLoading && !isSearchingUsers && (
        <div className="hidden md:block">
          <MembersTable
            data={membersData}
            onEditMember={onShowEditMember}
            onDeleteMember={onShowDeleteMember}
          />
        </div>
      )}
      {membersTotalPages > 1 ? (
        <div className="mt-3">
          <Pagination
            currentPage={currentPage}
            totalPages={membersTotalPages}
          />
        </div>
      ) : null}

      <EditUserSidePanel
        onUpdateUser={onUpdateMember}
        onDeleteUser={onShowDeleteMember}
        open={memberToBeEdited !== null}
        user={memberToBeEdited}
        onClose={onHideEditMember}
      />

      {memberToBeDeleted && (
        <ActionModal
          icon={<WarningOutlinedIcon className="w-9 h-9 mb-2" />}
          title="Delete member?"
          content={
            <div>
              Member {memberToBeDeleted.email} will be permanently deleted and
              will no longer have access to Connect. You will need to invite
              them again if they need Connect access.
            </div>
          }
          footer={
            <>
              <SecondaryButton onClick={onHideDeleteMember}>
                Cancel
              </SecondaryButton>
              <DangerButton
                className="ml-2"
                onClick={() => onDeleteMember(memberToBeDeleted)}
                disabled={deletingMember}
                isLoading={deletingMember}
              >
                Delete
              </DangerButton>
            </>
          }
          onClose={onHideDeleteMember}
        />
      )}
    </div>
  );
}
