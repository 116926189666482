import { useOutletContext } from "react-router-dom";

type RestaurantOutletContextType = {
  site: string;
  store: string;
  menu: string;
};

export default function useRestaurantsOutletContext() {
  return useOutletContext<RestaurantOutletContextType>();
}
