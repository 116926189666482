import Typography from "../../../../../components/Typography";

interface ChannelSetupCardProps {
  title: string;
  image: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

export default function ChannelSetupCard({
  title,
  image,
  className,
  children,
}: ChannelSetupCardProps) {
  return (
    <div
      className={`flex flex-col w-full sm:w-[15.563rem] items-center justify-center p-16 pt-12 pb-7 border border-dashed border-gray-300 rounded-md ${className}`}
    >
      <div className="!h-[4.625rem] items-end mb-[1.125rem]">{image}</div>
      <Typography as="body-1" className="font-semibold pt-[3px] mb-3">
        {title}
      </Typography>

      {children}
    </div>
  );
}
