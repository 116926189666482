interface AppLayoutProps {
  navbar: React.ReactNode;
  sidebar: React.ReactNode;
  children?: React.ReactNode;
}

export const AppLayout = ({ navbar, sidebar, children }: AppLayoutProps) => {
  return (
    <div className="relative h-full w-full overflow-hidden pt-[60px] md:pl-[312px]">
      {navbar}
      {sidebar}
      <div role="main" className="w-full h-full p-4 overflow-y-auto">
        {children}
      </div>
    </div>
  );
};
