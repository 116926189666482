import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-hot-toast";
import ActionModal from "../../../components/ActionModal";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import FeatureContentWrapper from "../../../components/FeatureContentWrapper";
import FeatureHeader from "../../../components/FeatureHeader";
import FeatureTitle from "../../../components/FeatureTitle";
import { WarningOutlinedIcon } from "../../../components/Icons";
import Typography from "../../../components/Typography";
import useOrgId from "../../../hooks/organizations/useOrgId";
import useAddRegion from "../../../hooks/regions/useAddRegion";
import useDeleteRegion from "../../../hooks/regions/useDeleteRegion";
import useOrganizationRegions from "../../../hooks/regions/useOrganizationRegions";
import useUpdateRegion from "../../../hooks/regions/useUpdateRegion";
import useUserSites from "../../../hooks/sites/useUserSites";
import {
  ORGANIZATION_REGIONS_QUERY_KEY,
  USERS_SITES_QUERY_KEY,
} from "../constants";
import {
  AddRegionFormValues,
  OrganizationRegionSetupInterface,
  UpdateRegionFormValues,
} from "../types";
import AddRegionSidePanel from "./components/AddRegionSidePanel";
import EditRegionSidePanel from "./components/EditRegionSidePanel";
import RegionSetupCard from "./components/RegionSetupCard";
import RegionSetupTable from "./components/RegionSetupTable";

export default function RegionSetup() {
  const queryClient = useQueryClient();
  const orgId = useOrgId();
  const { data, isLoading } = useOrganizationRegions();

  const [showAddRegion, setShowAddRegion] = useState(false);

  const onShowAddRegion = () => setShowAddRegion(true);
  const onHideAddRegion = () => setShowAddRegion(false);

  const { data: sites } = useUserSites();

  const addRegion = useAddRegion();

  const onAddRegion = async (values: AddRegionFormValues) => {
    return addRegion
      .mutateAsync({ name: values.name, sites: values.sites })
      .then(() => {
        queryClient.invalidateQueries([ORGANIZATION_REGIONS_QUERY_KEY, orgId]);
        queryClient.invalidateQueries([USERS_SITES_QUERY_KEY, orgId]);
        toast.success("Region successfully added.");

        onHideAddRegion();
      })
      .catch((err) => {
        const _error = err as AxiosError<{ message: string }>;

        toast.error(
          `Failed to add region. ${
            _error.response ? _error.response.data.message : ""
          }`
        );
      });
  };

  const [regionToBeEdited, setRegionToBeEdited] =
    useState<OrganizationRegionSetupInterface | null>(null);

  const onShowEditRegion = (region: OrganizationRegionSetupInterface) =>
    setRegionToBeEdited(region);

  const onHideEditRegion = () => setRegionToBeEdited(null);

  const updateRegion = useUpdateRegion();

  const onUpdateRegion = async (values: UpdateRegionFormValues) => {
    return updateRegion
      .mutateAsync({
        regionId: regionToBeEdited!.id,
        name: values.name,
        sites: values.sites,
      })
      .then(() => {
        queryClient.invalidateQueries([ORGANIZATION_REGIONS_QUERY_KEY, orgId]);
        queryClient.invalidateQueries([USERS_SITES_QUERY_KEY, orgId]);
        toast.success("Region successfully updated.");

        onHideEditRegion();
      })
      .catch((err) => {
        const _error = err as AxiosError<{ message: string }>;
        toast.error(
          `Failed to update region. ${
            _error.response ? _error.response?.data.message : ""
          }`
        );
      });
  };

  const [regionToBeDeleted, setRegionToBeDeleted] =
    useState<OrganizationRegionSetupInterface | null>(null);

  const onShowDeleteRegion = (region: OrganizationRegionSetupInterface) => {
    setRegionToBeDeleted(region);
  };
  const onHideDeleteRegion = () => setRegionToBeDeleted(null);

  const [isDeletingRegion, setIsDeletingRegion] = useState(false);

  const deleteRegion = useDeleteRegion();

  const onDeleteRegion = async (region: OrganizationRegionSetupInterface) => {
    setIsDeletingRegion(true);

    return deleteRegion
      .mutateAsync({ regionId: region.id })
      .then(() => {
        queryClient.invalidateQueries([ORGANIZATION_REGIONS_QUERY_KEY, orgId]);
        queryClient.invalidateQueries([USERS_SITES_QUERY_KEY, orgId]);
        toast.success("Region successfully deleted.");

        onHideDeleteRegion();
      })
      .catch((err) => {
        const _error = err as AxiosError<{ message: string }>;

        toast.error(
          `Failed to delete region. ${
            _error.response ? _error.response.data.message : ""
          }`
        );
      })
      .finally(() => setIsDeletingRegion(false));
  };

  if (isLoading || !data) {
    return null;
  }

  return (
    <FeatureContentWrapper>
      <Helmet>
        <title>Connect - Region setup</title>
      </Helmet>
      <div className="mb-8">
        <FeatureHeader>
          <div className="flex flex-grow items-center justify-between">
            <FeatureTitle className="mt-2">Region setup</FeatureTitle>
            <PrimaryButton onClick={onShowAddRegion}>Add region</PrimaryButton>
          </div>
        </FeatureHeader>
      </div>

      <div className="block md:hidden">
        {data.map((region) => (
          <RegionSetupCard
            key={`region-setup-card-${region.id}`}
            region={region}
            onEditRegion={onShowEditRegion}
            onDeleteRegion={onShowDeleteRegion}
          />
        ))}
      </div>

      <div className="hidden md:block">
        <RegionSetupTable
          data={data}
          onEditRegion={onShowEditRegion}
          onDeleteRegion={onShowDeleteRegion}
        />
      </div>

      <AddRegionSidePanel
        regions={data}
        sites={sites}
        onAddRegion={onAddRegion}
        open={showAddRegion}
        onClose={onHideAddRegion}
      />

      <EditRegionSidePanel
        region={regionToBeEdited}
        regions={data}
        sites={sites}
        onUpdateRegion={onUpdateRegion}
        onDeleteRegion={onShowDeleteRegion}
        open={regionToBeEdited !== null}
        onClose={onHideEditRegion}
      />

      {regionToBeDeleted && (
        <ActionModal
          icon={<WarningOutlinedIcon className="w-10 h-10" />}
          title={`Delete ${regionToBeDeleted.name} region?`}
          content={
            <div>
              {regionToBeDeleted.sites.length > 0 ? (
                <Typography as="body-2">
                  The {regionToBeDeleted.name} region has a{" "}
                  <strong>
                    total of {regionToBeDeleted.sites.length}{" "}
                    {regionToBeDeleted.sites.length === 1 ? "site" : "sites"}
                  </strong>{" "}
                  assigned. Before you can delete this region, please reassign
                  the sites to a new region.
                </Typography>
              ) : (
                <Typography as="body-2">
                  Are you sure you want to delete the {regionToBeDeleted.name}{" "}
                  region?
                </Typography>
              )}
            </div>
          }
          footer={
            regionToBeDeleted.sites.length > 0 ? (
              <div className="flex justify-end">
                <PrimaryButton onClick={onHideDeleteRegion}>Ok</PrimaryButton>
              </div>
            ) : (
              <div>
                <SecondaryButton className="mr-1" onClick={onHideDeleteRegion}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  className="ml-1"
                  onClick={() => onDeleteRegion(regionToBeDeleted)}
                  isLoading={isDeletingRegion}
                  disabled={isDeletingRegion}
                >
                  Yes, delete
                </PrimaryButton>
              </div>
            )
          }
          onClose={onHideDeleteRegion}
        />
      )}
    </FeatureContentWrapper>
  );
}
