import { sprintf } from "sprintf-js";
import {
  ONBOARD_PARTNERS_API_ENDPOINT,
  ORGANIZATION_CREATE_SITE_PAYMENT_CONFIG_API_ENDPOINT,
  ORGANIZATION_ONBOARD_SITE_API_ENDPOINT,
  ORGANIZATION_SITE_API_ENDPOINT,
  ORGANIZATION_SITE_PAYMENT_CONFIG_API_ENDPOINT,
  ORGANIZATION_UPDATE_ACTIVE_SITE_API_ENDPOINT,
  ORGANIZATION_UPDATE_SITE_PAYMENT_CONFIG_API_ENDPOINT,
  USER_SITES_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  ActiveSiteInterface,
  ActiveSitePaymentConfigInterface,
  OnboardPartnerInterface,
  RegionSiteWithRegionIncludedInterface,
} from "../features/Organizations/types";
import http from "./http";

export function fetchUserSites(): Promise<
  RegionSiteWithRegionIncludedInterface[]
> {
  return http.get(USER_SITES_API_ENDPOINT).then((response) => response.data);
}

export function fetchActiveSite(siteId: string): Promise<ActiveSiteInterface> {
  return http
    .get(sprintf(ORGANIZATION_SITE_API_ENDPOINT, { siteId }))
    .then((response) => response.data);
}

export function fetchSitePaymentConfig(
  siteId: string
): Promise<ActiveSitePaymentConfigInterface> {
  return http
    .get(sprintf(ORGANIZATION_SITE_PAYMENT_CONFIG_API_ENDPOINT, { siteId }))
    .then((response) => response.data);
}

export function updateActiveSite(
  siteId: string,
  region: string,
  location: {
    lat: string;
    long: string;
  },
  colorConfig: {
    palette: Record<string, string>;
    fontPalette: Record<string, string>;
  }
) {
  return http
    .patch(sprintf(ORGANIZATION_UPDATE_ACTIVE_SITE_API_ENDPOINT, { siteId }), {
      region,
      location,
      colorConfig,
    })
    .then((response) => response.data);
}

export function updateSitePaymentConfig(
  siteId: string,

  kiosk: {
    gateway: string;
    processor: string;
    config: Record<string, unknown>;
  },
  mobile: {
    gateway: string;
    processor: string;
    config: Record<string, unknown>;
  }
) {
  return http
    .put(
      sprintf(ORGANIZATION_UPDATE_SITE_PAYMENT_CONFIG_API_ENDPOINT, { siteId }),
      {
        kiosk,
        mobile,
      }
    )
    .then((response) => response.data);
}

export function onboardSite(
  centegraSiteId: number,
  regionId: string,
  location: {
    lat: string;
    long: string;
  },
  colorConfig: {
    palette: Record<string, string>;
    fontPalette: Record<string, string>;
  }
): Promise<any> {
  return http
    .post(ORGANIZATION_ONBOARD_SITE_API_ENDPOINT, {
      centegraSiteId,
      regionId,
      location,
      colorConfig,
    })
    .then((response) => response.data);
}

export function createSitePaymentConfig(
  siteId: string,

  kiosk: {
    gateway: string;
    processor: string;
    config: Record<string, unknown>;
  },
  mobile: {
    gateway: string;
    processor: string;
    config: Record<string, unknown>;
  }
): Promise<any> {
  return http
    .post(
      sprintf(ORGANIZATION_CREATE_SITE_PAYMENT_CONFIG_API_ENDPOINT, { siteId }),
      {
        kiosk,
        mobile,
      }
    )
    .then((response) => response.data);
}

export function fetchSitesForOnboarding(): Promise<OnboardPartnerInterface[]> {
  return http
    .get(ONBOARD_PARTNERS_API_ENDPOINT)
    .then((response) => response.data);
}
