import http from "./http";
import {
  ORDER_DETAILS_API_ENDPOINT,
  ORDER_REPORT_ISSUE_API_ENDPOINT,
  ORDER_REFUND_API_ENDPOINT,
  ORDERS_TODAY_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  OrderDetailsInterface,
  OrderInterface,
} from "../features/Stores/Orders/types";
import { sprintf } from "sprintf-js";

export function fetchTodaysOrders(
  storeId: string | null,
  filter: string | null
): Promise<OrderInterface[]> {
  return http
    .get(
      sprintf(ORDERS_TODAY_API_ENDPOINT, {
        storeId,
        filter,
      })
    )
    .then((response) => response.data);
}

export function fetchOrderDetails(
  storeId: string,
  orderId: number | undefined,
  channel: string | undefined
): Promise<OrderDetailsInterface> {
  return http
    .get(
      sprintf(ORDER_DETAILS_API_ENDPOINT, {
        storeId,
        orderId,
        channelId: channel,
      })
    )
    .then((response) => response.data);
}

//@todo update promise<any> to appropriate type
export function refundOrder(
  storeId: string | null,
  order: OrderInterface
): Promise<any> {
  return http.post(
    sprintf(ORDER_REFUND_API_ENDPOINT, {
      storeId,
      orderId: order.orderId,
      channelId: order.channel,
    })
  );
}

export function reportOrderIssue(
  restaurantId: string,
  orderId: number,
  notes: string
): Promise<any> {
  return http.post(
    sprintf(ORDER_REPORT_ISSUE_API_ENDPOINT, { restaurantId, orderId }),
    { notes }
  );
}
