import { useMemo } from "react";
import { Field, useForm } from "react-final-form";
import { useSearchParams } from "react-router-dom";
import usePartnerRestaurants from "../../hooks/restaurants/usePartnerRestaurants";
import Dropdown from "../Dropdown/Dropdown";
import FormControl from "../Forms/FormControl";

export default function FeatureHeaderStoreDropdown() {
  const [queryParams, setQueryParams] = useSearchParams();
  const form = useForm();

  const site = queryParams.get("site") || "";
  const store = queryParams.get("store") || "";

  const { data: stores } = usePartnerRestaurants(site);

  const storesData = useMemo(
    () =>
      stores
        ? stores.map((store) => ({
          value: store.restaurantId,
          label: store.name,
        }))
        : [],
    [stores]
  );

  return (
    <div className="block w-full mr-0 md:w-80 md:mr-4">
      <Field<string>
        name="store"
        initialValue={store}
        validateFields={[]}
        render={({ input, meta }) => (
          <FormControl>
            <Dropdown
              input={{
                ...input,
                onChange: (e) => {
                  input.onChange(e);
                  setQueryParams({
                    site,
                    store: e,
                    menu: "",
                  });
                  form.change("menu", "");
                },
              }}
              meta={meta}
              label="Store"
              placeholder="Select a store"
              disabled={!site}
              options={storesData}
            />
          </FormControl>
        )}
      />
    </div>
  );
}
