import { createColumnHelper } from "@tanstack/react-table";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import Table from "../../../../../components/Table";
import { PERMISSIONS_FEATURE_MANAGEMENT } from "../../../../../constants/permissions";
import { usePermissions } from "../../../../../hooks/permissions/usePermissions";
import { DiscountInterface } from "../../types";

interface PromotionsTableProps {
  data: DiscountInterface[];
  onDeletePromotion: (discountCode: string) => void;
}

export default function PromotionsTable({
  data,
  onDeletePromotion,
}: PromotionsTableProps) {
  const { hasPermission } = usePermissions();

  const columnHelper = createColumnHelper<DiscountInterface>();

  const discountsColumns = [
    columnHelper.accessor("discountCode", {
      header: "Promo code",
    }),
    columnHelper.accessor("percentage", {
      header: "Total promo",
      cell: (props) => `${props.getValue()}%`,
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <div className="flex justify-end">
          {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_DELETE) && (
            <PrimaryButton
              onClick={() =>
                onDeletePromotion(props.row.getValue("discountCode"))
              }
              className="inline py-1.5 px-4"
            >
              Delete
            </PrimaryButton>
          )}
        </div>
      ),
    }),
  ];

  return <Table<DiscountInterface> data={data} columns={discountsColumns} />;
}
