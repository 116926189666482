import {
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import Button from "../../../../../components/Buttons/Button";
import SearchInput from "../../../../../components/SearchInput";

import { ProductSuspensionItemInterface } from "../../types";
import ProductSuspensionCard from "../ProductSuspensionCard";
import ProductSuspensionToggle from "../ProductSuspensionToggle";
import MenuItemPlaceholderIcon from "../../../../../components/Icons/MenuItemPlaceholderIcon";

const FILTER_ITEMS_ALL = "all";
const FILTER_ITEMS_ON = "on";
const FILTER_ITEMS_OFF = "off";

interface ProductSuspensionsTableProps {
  restaurantId: string;
  orgId: string | undefined;
  data: ProductSuspensionItemInterface[];
}

export default function ProductSuspensionsTable({
  restaurantId,
  orgId,
  data,
}: ProductSuspensionsTableProps) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    {
      id: "isSuspended",
      value: FILTER_ITEMS_ALL,
    },
  ]);

  const columnHelper = createColumnHelper<ProductSuspensionItemInterface>();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("imageUrl", {
        header: "",
        size: 100,
        enableGlobalFilter: false,
        cell: (info) =>
          info.row.original.imageUrl && info.row.original.imageUrl !== "" ? (
            <img
              src={info.row.original.imageUrl}
              className="w-12 h-12 rounded-lg"
              alt={info.row.original.name}
            />
          ) : (
            <MenuItemPlaceholderIcon
              className="w-12 h-12 rounded-lg bg-[#979797]"
              viewBox="-35 -30 124 124"
            />
          ),
      }),
      columnHelper.accessor("name", {
        header: "Name",
        size: 256,
      }),
      columnHelper.accessor("description", {
        header: "Description",
        enableGlobalFilter: false,
        cell: (info) => (
          <div className="truncate">{info.row.original.description}</div>
        ),
      }),
      columnHelper.accessor("isSuspended", {
        header: () => <div className="text-right">Visible to guests?</div>,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        filterFn: (rows, _, filterValue) => {
          if (filterValue === FILTER_ITEMS_ALL) {
            return true;
          }
          if (filterValue === FILTER_ITEMS_ON) {
            return rows.original.isSuspended === false;
          }
          if (filterValue === FILTER_ITEMS_OFF) {
            return rows.original.isSuspended === true;
          }
          return true;
        },
        size: 200,

        cell: (info) => (
          <div className="flex justify-end">
            <ProductSuspensionToggle
              orgId={orgId}
              restaurantId={restaurantId}
              productId={info.row.original.productId}
              isSuspended={info.row.original.isSuspended}
              mobileView={false}
            />
          </div>
        ),
      }),
    ],
    [columnHelper, orgId, restaurantId]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER, // using MAX_SAFE_INTEGER as react-table uses 150px as default and something ignores the size defined for the column
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="w-full lg:w-80">
          <SearchInput
            placeholder="Search by name..."
            input={{
              name: "filter",
              value: globalFilter,
              onChange: (e) => setGlobalFilter(e.target ? e.target.value : e),
              onBlur: () => {},
              onFocus: () => {},
            }}
            meta={{}}
          />
        </div>
        <div className="flex md:justify-end mb-5 w-full md:w-[18.75rem]">
          <Button
            className={`w-full md:inline-flex text-sm rounded-r-none border-r-0 ${
              columnFilters[0].value === FILTER_ITEMS_ALL ? "bg-gray-100" : ""
            }`}
            onClick={() =>
              setColumnFilters([{ id: "isSuspended", value: FILTER_ITEMS_ALL }])
            }
          >
            All Items
          </Button>
          <Button
            className={`w-full md:inline-flex text-sm rounded-none ${
              columnFilters[0].value === FILTER_ITEMS_ON ? "bg-gray-100" : ""
            }`}
            onClick={() =>
              setColumnFilters([{ id: "isSuspended", value: FILTER_ITEMS_ON }])
            }
          >
            Items On
          </Button>
          <Button
            className={`w-full md:inline-flex text-sm rounded-l-none border-l-0 ${
              columnFilters[0].value === FILTER_ITEMS_OFF ? "bg-gray-100" : ""
            }`}
            onClick={() =>
              setColumnFilters([{ id: "isSuspended", value: FILTER_ITEMS_OFF }])
            }
          >
            Items Off
          </Button>
        </div>
      </div>

      <div className="md:hidden">
        <div className="flex flex-col max-h-[calc(100vh-510px)] overflow-x-hidden overflow-y-auto">
          <table className="table-fixed w-full  border-collapse ">
            <tbody className="text-sm text-black">
              {table.getRowModel().rows.length === 0 ? (
                <tr role="row">
                  <td role="cell">No results...</td>
                </tr>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <tr role="row" key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      if (!cell.id.includes("name")) {
                        return null;
                      }

                      return (
                        <td role="cell" key={cell.id}>
                          <div className="mb-7">
                            <ProductSuspensionCard
                              orgId={orgId}
                              restaurantId={restaurantId}
                              item={cell.row.original}
                            />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="hidden md:flex">
        <div className="w-full border border-gray-300 rounded-lg max-h-[calc(100vh-350px)] overflow-x-hidden overflow-y-auto ">
          <table className="table-fixed w-full  border-collapse">
            <thead className="bg-gray-50 text-gray-500 text-xs rounded-t-lg border-b border-gray-300">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  role="row"
                  key={headerGroup.id}
                  className="first:rounded-t-lg"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      role="rowheader"
                      key={header.id}
                      className={`text-left py-3 px-6 first:rounded-tl-lg last:rounded-tr-lg`}
                      style={{
                        width:
                          header.getSize() === Number.MAX_SAFE_INTEGER
                            ? "auto"
                            : header.getSize(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="text-sm text-black ">
              {table.getRowModel().rows.length === 0 ? (
                <tr
                  role="row"
                  className="border-b border-gray-300 last:border-0"
                >
                  <td role="cell" colSpan={4} className="px-6 py-4">
                    No results...
                  </td>
                </tr>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <tr
                    role="row"
                    key={row.id}
                    className="border-b border-gray-300 last:border-0"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        role="cell"
                        key={cell.id}
                        style={{
                          width:
                            cell.column.getSize() === Number.MAX_SAFE_INTEGER
                              ? "auto"
                              : cell.column.getSize(),
                        }}
                        className=" px-6 py-4"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
