import { AxiosError } from "axios";
import {
  IDENTITY_ACTIVATE_API_ENDPOINT,
  IDENTITY_API_ENDPOINT,
} from "../config/servicesEndpoints";
import { IdentityInterface } from "../context/permissions/PermissionsContext";
import http from "./http";

export function fetchIdentity(): Promise<IdentityInterface> {
  return http
    .get(IDENTITY_API_ENDPOINT)
    .then((response) => response.data)
    .catch((err) => {
      const error = err as AxiosError;
      if (error.response?.status === 404) {
        return http.post(IDENTITY_ACTIVATE_API_ENDPOINT).then(() => {
          return http
            .get(IDENTITY_API_ENDPOINT)
            .then((response) => response.data);
        });
      }
    });
}
