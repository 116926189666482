import FeatureWidget from "../../../../components/FeatureWidget";
import Typography from "../../../../components/Typography";
import useStore from "../../../../hooks/stores/useStore";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { useState } from "react";
import QrCodeSidePanel from "../QrCodeSidePanel";

interface QrDownloadGenerationWidgetProps {
  storeId: string;
}

export default function QrCodeGenerationWidget({
  storeId,
}: QrDownloadGenerationWidgetProps) {
  const { data: store, isLoading } = useStore(storeId);

  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  if (isLoading && !store) {
    return null;
  }
  return (
    <div>
      <FeatureWidget
        title={
          <div className="flex items-center">
            <Typography className="mr-2">QR codes / URL</Typography>
          </div>
        }
        content="Download QR codes and preview URL for marketing needs."
        action={
          <PrimaryButton
            data-testid="viewQRButton"
            className="w-full"
            onClick={onOpen}
          >
            View QR codes
          </PrimaryButton>
        }
      />
      <QrCodeSidePanel open={open} store={store} onClose={onClose} />
    </div>
  );
}
