interface RoundUpsContentProps {
  children?: React.ReactNode;
  className?: string;
}
export default function RoundUpsContent({
  children,
  className,
}: RoundUpsContentProps) {
  return <div className={`flex-auto ${className}`}>{children}</div>;
}
