import { OrderInterface } from "../../features/Stores/Orders/types";
import { useMutation } from "@tanstack/react-query";
import { refundOrder } from "../../services/orders";

export default function useRefundOrder() {
  return useMutation(
    ({ storeId, order }: { storeId: string | null; order: OrderInterface }) =>
      refundOrder(storeId, order)
  );
}
