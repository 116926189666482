import { useState } from "react";
import { Link } from "react-router-dom";

export interface TabItemInterface {
  id: string;
  header: React.ReactNode;
  content: React.ReactNode;
  asRoute?: boolean;
  route?: string;
}
interface TabsPropsInterface {
  defaultTab?: string;
  items: [TabItemInterface, ...TabItemInterface[]];
}

export default function Tabs({ defaultTab, items }: TabsPropsInterface) {
  const selectDefaultTab = defaultTab
    ? items.find((tab) => tab.id.toLowerCase() === defaultTab.toLowerCase())
    : null;
  const [selectedTab, setSelectedTab] = useState(
    selectDefaultTab ? selectDefaultTab : items[0]
  );

  return (
    <div className="w-full">
      <ul
        className="flex flex-wrap -mb-px text-sm font-medium text-center border-b border-b-gray-300"
        role="tablist"
      >
        {items.map((item) => (
          <li role="presentation" key={`tab-${item.id}`}>
            {item.asRoute && item.route ? (
              <Link
                to={item.route}
                className="inline-block p-4 aria-selected:border-b-2 aria-selected:border-b-black text-sm leading-[1.25rem] font-normal aria-selected:font-semibold"
                id={`tab-${item.id}`}
                role="tab"
                aria-selected={
                  item.id.toLowerCase() === selectedTab.id.toLowerCase()
                }
                onClick={() => setSelectedTab(item)}
              >
                {item.header}
              </Link>
            ) : (
              <button
                className="inline-block p-4 aria-selected:border-b-2 aria-selected:border-b-black text-sm leading-[1.25rem] font-normal aria-selected:font-semibold"
                id={`tab-${item.id}`}
                type="button"
                role="tab"
                aria-selected={
                  item.id.toLowerCase() === selectedTab.id.toLowerCase()
                }
                onClick={() => setSelectedTab(item)}
              >
                {item.header}
              </button>
            )}
          </li>
        ))}
      </ul>
      <div id={`tabpanel-${selectedTab.id}`} role="tabpanel" className="py-4">
        {selectedTab.content}
      </div>
    </div>
  );
}
