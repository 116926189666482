import { useMemo } from "react";
import ExternalLink from "../../../../../components/ExternalLink";
import LaunchIcon from "../../../../../components/Icons/LauchIcon";
import { useMenuCurrency } from "../../hooks/useMenuCurrency";
import { useMenuOrderType } from "../../hooks/useMenuOrderType";
import {
  BothPriceType,
  DineInPriceType,
  MenuItemModifierGroupModifierInterface,
  TakeOutPriceType,
} from "../../types";
import MenuPreviewItemProperty from "../MenuPreviewItemProperty";

export default function MenuPreviewItemModifier({
  modifier,
}: {
  modifier: MenuItemModifierGroupModifierInterface;
}) {
  const errors: Record<string, string[]> = useMemo(
    () =>
      modifier.errors.reduce((acc: Record<string, string[]>, item) => {
        if (Array.isArray(acc[item.property.toLowerCase()])) {
          return {
            ...acc,
            [item.property.toLowerCase()]: acc[
              item.property.toLowerCase()
            ].concat(item.message),
          };
        }
        return { ...acc, [item.property.toLowerCase()]: [item.message] };
      }, {}),
    [modifier]
  );

  const { currency } = useMenuCurrency();

  const { orderType } = useMenuOrderType();

  const dineInPrice = modifier.item.prices.find(
    (price) => price.type.toLowerCase() === DineInPriceType.toLowerCase()
  );
  const dineInTaxRate = dineInPrice
    ? dineInPrice.taxRate || dineInPrice.taxRate === 0
      ? dineInPrice.taxRate * 100
      : null
    : null;

  const takeOutPrice = modifier.item.prices.find(
    (price) => price.type.toLowerCase() === TakeOutPriceType.toLowerCase()
  );
  const takeOutTaxRate = takeOutPrice
    ? takeOutPrice.taxRate || takeOutPrice.taxRate === 0
      ? takeOutPrice.taxRate * 100
      : null
    : null;

  const showTakeOut =
    orderType.toLowerCase() === TakeOutPriceType.toLowerCase() ||
    orderType.toLowerCase() === BothPriceType.toLowerCase();

  const showDineIn =
    orderType.toLowerCase() === DineInPriceType.toLowerCase() ||
    orderType.toLowerCase() === BothPriceType.toLowerCase();

  return (
    <div className="border-b border-gray-300 last:border-b-0 py-2">
      <MenuPreviewItemProperty title="Product ID:" errors={errors["productid"]}>
        <ExternalLink url={modifier.item.deeplink}>
          <span className="mr-1">{modifier.item.productId}</span>
          <LaunchIcon className="w-[1.125rem] h-[1.125rem]" />
        </ExternalLink>
      </MenuPreviewItemProperty>
      <MenuPreviewItemProperty title="Name:" errors={errors["name"]}>
        {modifier.item.name}
      </MenuPreviewItemProperty>
      {showTakeOut && (
        <div className="mt-3.5">
          <MenuPreviewItemProperty
            title="Takeaway Price:"
            errors={errors["pricetakeout"]}
          >
            {takeOutPrice && (takeOutPrice.price || takeOutPrice.price === 0)
              ? `${currency}${takeOutPrice.price}`
              : null}
          </MenuPreviewItemProperty>
        </div>
      )}

      {showTakeOut && (
        <MenuPreviewItemProperty
          title="Takeaway Tax:"
          errors={errors["taxratetakeout"]}
        >
          {takeOutTaxRate || takeOutTaxRate === 0
            ? `${takeOutTaxRate.toFixed()}%`
            : null}
        </MenuPreviewItemProperty>
      )}

      {showTakeOut && (
        <MenuPreviewItemProperty
          title="Takeaway Price Excludes Tax:"
          errors={errors["pricingnettakeout"]}
        >
          {takeOutPrice && takeOutPrice.pricingNet ? "Yes" : "No"}
        </MenuPreviewItemProperty>
      )}

      {showDineIn && (
        <div className="mt-3.5">
          <MenuPreviewItemProperty
            title="Dine In Price:"
            errors={errors["pricedinein"]}
          >
            {dineInPrice && (dineInPrice.price || dineInPrice.price === 0)
              ? `${currency}${dineInPrice.price}`
              : null}
          </MenuPreviewItemProperty>
        </div>
      )}

      {showDineIn && (
        <MenuPreviewItemProperty
          title="Dine In Tax:"
          errors={errors["taxratedinein"]}
        >
          {dineInTaxRate || dineInTaxRate === 0
            ? `${dineInTaxRate.toFixed()}%`
            : null}
        </MenuPreviewItemProperty>
      )}

      {showDineIn && (
        <MenuPreviewItemProperty
          title="Dine In Price Excludes Tax:"
          errors={errors["pricingnetdinein"]}
        >
          {dineInPrice && dineInPrice.pricingNet ? "Yes" : "No"}
        </MenuPreviewItemProperty>
      )}
    </div>
  );
}
